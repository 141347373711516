import { Box, Grid, Typography } from "@mui/material";
import Introduction from "./sections/cards";
import ourstorydata from './ourstorydata.json'
import { useEffect, useState } from "react";
import Banner from "./sections/banner";

const OurStory = () => {
    const [data, setData] = useState(null)
    useEffect(() => {
        setData(ourstorydata)
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [])
    return (
        <>
            <Box
                className="our_story_page_wrapper"
                style={{
                    backgroundImage:
                        "url('../landowner/assets/images/homepage/homepage_bg.png')",
                }}
            >
                <Grid container maxWidth="lg" m="auto">
                    <Banner ourstorydata={data} />
                </Grid>
                <Grid
                    container
                    maxWidth="lg"
                    m="auto"
                    sx={{ justifyContent: "center" }}
                >
                    {data && <Introduction ourstorydata={data} />}
                </Grid>
            </Box>
        </>
    )
};

export default OurStory;

