import { Avatar, Box, Button, CardMedia, Chip, CircularProgress, Dialog, Divider, Grid, IconButton, Skeleton, TextField, Typography } from "@mui/material";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Rating from '@mui/material/Rating';
import BookingService from "../../services/bookings";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { ROUTE_DASHBOARD, ROUTE_REVIEW_SUCCEFULL } from "../../constants/page"
import { exponentialBackoff, extractDataFromArray, findExperienceDataByType, generatePhrase, toastMessage, } from "../../utils/common";
import * as Icon from "@mui/icons-material";
import { CommonContext } from "../../store/context/commonContext";
import UploadReviewPhotos from "../submitReview/reviewCard/index"
import Review from "../../services/review";
import moment from "moment";
import config from "../../config";
import GreyLabel from "../../commonComponents/formElement/GreyLabel"
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import CreateChip from "../../commonComponents/CreateChip";
import errorAndHelperText from "../../commonComponents/CommonConstants/errorAndHelperText.json"



const labels = {
    0.5: '0.5',
    1: '1',
    1.5: '1.5',
    2: '2',
    2.5: '2.5',
    3: '3',
    3.5: '3.5',
    4: '4',
    4.5: '4.5',
    5: '5',
};

function getLabelText(value) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
}


const CollectReviewData = ({ checkData, clickedSubmit, setToggle, toggle, bookingId, reviewData, section, setReviewData, reviewPresent }) => {
    const [value, setValue] = useState(0);
    const [hover, setHover] = useState(-1);
    const [reviewText, setReviewText] = useState('');
    const [image, setImage] = useState("");
    const { isTablet, setReviewPresent } = CommonContext();
    const limit = 12;



    useEffect(() => {
        if (reviewPresent && reviewPresent[section]) {
            const existingReview = reviewPresent[section];
            setValue(existingReview.rating || 0);
            setReviewText(existingReview.review || '');
        }
        setReviewData(reviewPresent)
    }, [reviewPresent, section]);

    const handleRatingChange = (event, newValue) => {
        setValue(newValue);
        setHover(-1);
        setReviewData((prevState) => {
            // console.log(prevState);
            return ({
                ...prevState,
                [section]: {
                    ...prevState[section],
                    rating: newValue
                }
            })
        });
        setToggle(true)
    };
    // console.log("rating", value)

    const handleReviewChange = (newReviewText) => {
        setReviewText(newReviewText);
        setReviewData(prevState => ({
            ...prevState,
            [section]: {
                ...prevState[section],
                review: newReviewText
            }
        }));
        setToggle(true)

    };


    const getHeaderText = (section) => {
        switch (section) {
            case 'listing':
                return 'What did you like about this property?';
            case 'package':
                return 'Tell us about the Package';
            case 'host':
                return 'Tell us about your host';
            case 'experience':
                return 'Tell us about the experience';
            default:
                return '';
        }
    }
    const renderTitle = getHeaderText(section);

    // console.log(image)



    return (
        <>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>{renderTitle}</Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: isTablet ? 2 : 3, mb: 2 }}>
                <Box sx={{ display: "flex", flexDirection: isTablet ? "column" : "row", gap: isTablet ? 2 : "30%" }}>
                    <GreyLabel required label="Rating" variant="body1" color="var(--grey900)" sx={{ minWidth: "3.5rem", marginBottom: "0" }}></GreyLabel>

                    <Box sx={{ display: "flex", flexDirection: "column", gap: 0.5, width: "100%" }}>

                        <Box
                            sx={{
                                width: 200,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <Rating
                                name="half-rating"
                                value={value}
                                precision={0.5}
                                getLabelText={getLabelText}
                                onChange={handleRatingChange}
                                onChangeActive={(event, newHover) => {
                                    setHover(newHover);
                                }}
                                emptyIcon={<Icon.StarBorderOutlined style={{ opacity: 0.55, color: "#faaf00" }} fontSize="inherit" />}
                            />
                            {value !== null && (
                                <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</Box>
                            )}
                        </Box>
                        {
                            (checkData[section] && (value == null || value == '')) && <Typography variant="caption" sx={{ color: "red" }}>Rating is required</Typography>
                        }
                    </Box>

                </Box>
                <Box sx={{ display: "flex", flexDirection: isTablet ? "column" : "row", gap: isTablet ? 2 : "30%" }}>
                    <Typography variant="body1" color="var(--grey900)" sx={{ minWidth: "3.5rem" }}>Review</Typography>
                    <TextField
                        fullWidth
                        multiline
                        label="Review"
                        placeholder="Type your review here"
                        value={reviewText}
                        onChange={(e) => handleReviewChange(e.target.value)}
                        inputProps={{ maxLength: 400 }}

                    />
                </Box>
                <Box sx={{ display: "flex", flexDirection: isTablet ? "column" : "row", gap: isTablet ? 2 : "30%" }}>
                    <Typography variant="body1" color="var(--grey900)" sx={{ minWidth: "3.5rem" }}>Photos</Typography>
                    <Box sx={{ display: 'flex', flexDirection: "column" }}>
                        <UploadReviewPhotos setToggle={setToggle} reviewData={reviewData} setImage={setImage} setReviewData={setReviewData} section={section} image={image} reviewPresent={reviewPresent} limit={limit} />
                    </Box>
                </Box>
            </Box>
        </>
    );
}



const SubmitExpReview = () => {
    const [packageType, setPackageType] = useState('')
    const [searchParams, setSearchParams] = useSearchParams();
    const [loading, setLoading] = useState(true)
    const [bookingData, setBookingData] = useState({})
    const [toggle, setToggle] = useState(false)
    const [showTitle, setShowTitle] = useState(false)
    const [redirectBooking, setRedirectBooking] = useState(false)
    const [loader, setLoader] = useState(false)
    const [clickedSubmit, setClickedSubmit] = useState(false)
    const [openModal, setOpenModal] = useState(false)
    const { isMobile } = CommonContext();
    const [bookinInfo, setBookinInfo] = useState({
        bookingImg: "",
        property_name: "",
        listing_name: "",
        area: 0,
        location: "",
        guest_count: "",
        days: "",
        start_date: "",
        end_date: "",
        host_name: "",
        profile_pic: "",
        thumbnail_url: "",
        listing_seq: '',
        listing_slug: '',
    })
    // console.log(bookinInfo)
    const [bookingImage, setBookingImage] = useState("")
    const bookingId = searchParams.get('bookingId')
    const navigate = useNavigate()
    const { isTablet, setReviewPresent, reviewPresent } = CommonContext();
    const [iconPath, setIconPath] = useState('')

    useEffect(() => {
        BookingService.getBookingDetails(bookingId, (data) => {
            setLoading(false)
            setBookingData(data)
            setPackageType(extractDataFromArray(data, ['package_type'], ''))
        }, (error) => {
            // toast.error(error, {
            //     theme: 'colored',
            // })
            toastMessage({
                message: error,
                type: 'error',
            });
            setOpenModal(true)
        }
        )
    }, [])

    exponentialBackoff(() => {
        return extractDataFromArray(bookingData, ['booking', 'booking_status'], '') === 'M'
    }, 10, 3000, () => {
        if (extractDataFromArray(bookingData, ['booking', 'booking_status'], '') !== 'M') {
            // toast.error("Trip not completed yet!", {
            //     theme: 'colored',
            // })
            toastMessage(errorAndHelperText.submit_experience_review.trip_incomplete);
            navigate(`${ROUTE_DASHBOARD}`)
        } else {
        }
    })

    useEffect(() => {
        // Added timoutId function and cleared setTimeout below it, also added dependency array with bookingData as dependency to stop infinite rendering of submit review page       
        setIconPath(() => {
            if (extractDataFromArray(bookingData, ['package', 'experience_data', 'general_info_01ab', 'experience_type'], '') === 'Recreational Event') {
                return '../landowner/assets/images/event_exp_icon.svg'
            } else if (extractDataFromArray(bookingData, ['package', 'experience_data', 'general_info_01ab', 'experience_type'], '') === 'Outdoor Activity') {
                return '../landowner/assets/images/outdoor_activity_exp_icon.svg'
            } else if (extractDataFromArray(bookingData, ['package', 'experience_data', 'general_info_01ab', 'experience_type'], '') === 'Guided Tour') {
                return '../landowner/assets/images/tour_guide_exp_icon.svg'
            } else if (extractDataFromArray(bookingData, ['package', 'experience_data', 'general_info_01ab', 'experience_type'], '') === 'In-Person Training') {
                return '../landowner/assets/images/in-person.svg'
            }
        })
        const timeoutId = setTimeout(() => {

            setBookinInfo({
                bookingImg: extractDataFromArray(bookingData, ['package', 'experience_data', 'photos_01cd', 'photo_list', 0, 'url']),
                property_name: extractDataFromArray(bookingData, ['package', 'experience_data', 'general_info_01ab', 'title'], ''),
                package_name: extractDataFromArray(bookingData, ['package', 'experience_data', 'general_info_01ab', 'title'], ''),
                area: extractDataFromArray(bookingData, ['listing', 'area']),
                location: extractDataFromArray(bookingData, ['package', 'experience_data', 'location_03cd', 'city']),
                guest_count: extractDataFromArray(bookingData, ['booking', 'guest_count']),
                days: extractDataFromArray(bookingData, ['booking', 'booking_days']),
                start_date: extractDataFromArray(bookingData, ['booking', 'check_in_date']),
                end_date: extractDataFromArray(bookingData, ['booking', 'check_out_date']),
                host_name: extractDataFromArray(bookingData, ['lister', 'name']),
                activity_type: extractDataFromArray(bookingData, ['package', 'activity_type']),
                profile_pic: extractDataFromArray(bookingData, ['lister', 'profile_pic'], ''),
                type: extractDataFromArray(bookingData, ['package', 'experience_data', 'photos_01cd', 'photo_list', 0, 'type']),
                thumbnail_url: extractDataFromArray(bookingData, ['package', 'experience_data', 'photos_01cd', 'photo_list', 0, 'thumbnail_url']),
                experience_seq: extractDataFromArray(bookingData, ['package', 'seq'], ''),
                experience_slug: extractDataFromArray(bookingData, ['experience_data', 'general_info_01ab', 'slug'], '')
            })
        }, 200);
        return () => clearTimeout(timeoutId);
    }, [bookingData])


    const getActivityType = (bookinInfo) => {
        switch (bookinInfo.activity_type) {
            case 'F':
                return 'Fishing';
            case 'H':
                return 'Hunting';
            case 'BW':
                return 'Bird Watching';
            case 'FR':
                return 'Farm';
            case 'host':
                return 'Tell us about your host';
            default:
                return '';
        }
    }
    const renderTitle = getActivityType(bookinInfo);

    const [checkData, setCheckData] = useState({
        host: false,
        experience: false,
    })


    const [reviewData, setReviewData] = useState({
        experience: {
            rating: 0,
            review: '',
            images: []
        },
        host: {
            rating: 0,
            review: '',
            images: []
        }
    });

    useEffect(() => {
        config.module === 'guest' && Review.getReviewDetails(bookingId, (data) => {
            if (data.length === 0) {
                let obj = {
                    experience: {
                        images: "[]", rating: "", review: ""
                    },
                    host: {
                        images: "[]", rating: "", review: ""
                    }

                }

                setShowTitle(obj.length === undefined)
                setReviewPresent(obj)
                setLoading(false)

            } else {
                if (!data.experience) {
                    data.experience = {
                        images: "[]", rating: 0, review: ""
                    }
                }
                if (!data.host) {
                    data.hosts = {
                        images: "[]", rating: 0, review: ""
                    }
                }

                setReviewPresent(data)
            }


        }, (error) => {
            // toast.error(error, {
            //     theme: 'colored',
            // })
            toastMessage({
                message: error,
                type: 'error',
            });

        })
    }, [])


    const handleSubmitReview = () => {

        setClickedSubmit(true)
        setLoader(true)

        let obj = reviewData

        // console.log('submittion data',obj);

        if (obj.experience.images?.length === 0 && obj.host.images.length === 0) {
            obj.experience.images = JSON.stringify(obj.experience.images)


            obj.host.images = JSON.stringify(obj.host.images)
        } else if (obj.experience.images?.length === 0 && obj.host.images.length !== 0) {
            obj.experience.images = JSON.stringify(obj.experience.images)

        } else if (obj.experience.images?.length !== 0 && obj.host.images.length === 0) {
            obj.host.images = JSON.stringify(obj.host.images)

        }

        let arr = [false, false]

        if (obj.host.rating === "" || obj.host.rating === null) {
            arr[0] = true
        } else {
            arr[0] = false
        }

        if (obj.experience.rating === "" || obj.experience.rating === null) {
            arr[1] = true
        } else {
            arr[1] = false
        }


        setCheckData({ host: arr[0], experience: arr[1] })



        const finalJsonData = {
            booking_id: bookingId,
            experience_seq: extractDataFromArray(bookingData, ['package', 'seq'], ''),
            host_seq: extractDataFromArray(bookingData, ['lister', 'seq'], ''),
            ...obj,
        };

        console.log(finalJsonData);

        Review.saveExpReviews(finalJsonData, (res) => {
            if (res === true) {
                setRedirectBooking(true)
                navigate(`/reviewsuccefull?bookingId=${bookingId}`)
                // toast.success("Review Submitted Successfully", { theme: 'colored' })
                toastMessage(errorAndHelperText.submit_experience_review.success);
                setLoader(false)
            }
        },
            (error) => {
                setToggle(true)
                setRedirectBooking(false)
                // console.log("loadedddd", loader)
                // toast.error(error, {
                //     theme: 'colored',
                // })
                toastMessage({
                    message: error,
                    type: 'error',
                });

            }).finally(() => {
                setLoader(false);
                setToggle(true)
            });
        setReviewData(reviewData)

    };

    // console.log("Section : ", checkData)


    return (<>

        <Box sx={{ display: 'flex', gap: 2, alignItems: isTablet ? 'flex-start' : 'center', mb: { xs: 0, sm: 0, md: 3, lg: 3 }, justifyContent: "space-between", flexDirection: isTablet ? "column" : "row" }}>
            {loading && <Skeleton variant="rectangular" sx={{ borderRadius: theme => `${theme.card.borderRadius}`, height: 20 }} />}
            {!loading &&
                <Box sx={{ display: "flex", gap: 2, alignItems: 'baseline' }}>
                    <IconButton sx={{ color: "#000000", p: 0 }} onClick={() => { navigate(`/booking/${bookingId}`) }}> <Icon.ArrowBack /></IconButton>
                    <Typography variant="h4" >
                        {showTitle ? "Submit Your Review" : "You have already reviewed this experience."}
                    </Typography>
                </Box>}
            {
                !loading &&
                (

                    showTitle ? "" :
                        <Box sx={{ display: "flex", color: "var(--primary)", gap: 1, mb: isTablet ? 2 : "" }}><Icon.VerifiedOutlined /><Typography variant="body1" sx={{ color: "var(--primary)", fontWeight: "600" }}>Submitted</Typography></Box>
                )

            }

        </Box>
        {
            !loading &&
            <Typography variant="body1" sx={{ mb: 2 }}>
                {showTitle ? "" : "You can still make changes if you want."}
            </Typography>
        }
        <Box sx={{}}>
            <Grid container spacing={!isTablet ? 3 : 2}>
                <Grid item md={7} sm={12} xs={12}>
                    {
                        loading && <Skeleton variant="rectangular" sx={{ borderRadius: theme => `${theme.card.borderRadius}`, height: 800 }} />
                    }
                    {
                        !loading &&
                        <Box sx={!isTablet ? { border: '1px solid var(--grey500)', p: 3, borderRadius: theme => `${theme.card.borderRadius}` } : { border: '1px solid var(--grey500)', p: 3, borderRadius: theme => `${theme.card.borderRadius}` }}>
                            <CollectReviewData
                                reviewData={reviewData}
                                setReviewData={setReviewData}
                                section="experience"
                                bookingId={bookingId}
                                reviewPresent={reviewPresent}
                                setToggle={setToggle}
                                toggle={toggle}
                                clickedSubmit={clickedSubmit}
                                checkData={checkData}
                            />
                            <Divider sx={{ width: 1, mb: 3 }} />
                            <CollectReviewData
                                reviewData={reviewData}
                                setReviewData={setReviewData}
                                section="host"
                                bookingId={bookingId}
                                reviewPresent={reviewPresent}
                                setToggle={setToggle}
                                toggle={toggle}
                                clickedSubmit={clickedSubmit}
                                checkData={checkData}
                            />
                            <Box sx={{ display: "flex", flexDirection: "row", gap: "30%" }}>
                                <Box sx={{ minWidth: "3.5rem", display: isTablet ? "none" : "block" }} />

                                <Button fullWidth disabled={!toggle || loader} variant="contained" sx={{ backgroundColor: "var(--primary)", color: "#FFFFFF" }} onClick={handleSubmitReview}>Submit Review
                                    {
                                        loader &&
                                        <CircularProgress size="20px" sx={{ ml: 2, color: '#fff' }} />
                                    }</Button>
                            </Box>
                        </Box>
                    }
                </Grid>
                <Grid container justifyContent="center" height="fit-content" item md={5} sm={12} xs={12}>
                    {
                        loading && <Skeleton variant="rectangular" sx={{ borderRadius: theme => `${theme.card.borderRadius}`, height: 600 }} />
                    }
                    {
                        !loading &&
                        <Box sx={!isTablet ? { border: '1px solid var(--grey500)', p: 3, borderRadius: theme => `${theme.card.borderRadius}`, width: "100%" } : { border: '1px solid var(--grey500)', p: 3, borderRadius: theme => `${theme.card.borderRadius}`, width: "100%" }}>
                            <Box sx={{ width: "100%" }}>
                                {bookinInfo.type === 'VIDEO' ?
                                    <>
                                        {
                                            bookinInfo.thumbnail_url === "" ?
                                                <div className='' style={{ position: 'relative', display: 'flex', marginBottom: '16px' }}>
                                                    <div className='' style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: '8px' }}>
                                                        <PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '2rem', width: '2rem' }} />
                                                    </div>
                                                    <CardMedia
                                                        component='video'
                                                        sx={{
                                                            width: '100%',
                                                            backgroundSize: 'cover',
                                                            borderRadius: '8px',
                                                            position: 'relative',
                                                            objectFit: 'cover',
                                                            height: 200
                                                        }}
                                                        image={bookinInfo.bookingImg}
                                                    />
                                                </div>
                                                :
                                                <div className='' style={{ position: 'relative', display: 'flex', marginBottom: '16px' }}>
                                                    <div className='' style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: '8px' }}>
                                                        <PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '2rem', width: '2rem' }} />
                                                    </div>
                                                    <Box sx={{
                                                        borderRadius: 2, backgroundImage: `url("${bookinInfo.thumbnail_url}")`,
                                                        backgroundRepeat: "no-repeat",
                                                        backgroundPosition: "center",
                                                        backgroundSize: "cover !important", width: "100%", height: 200,
                                                    }} />
                                                </div>
                                        }
                                    </>
                                    :
                                    <Box sx={{
                                        borderRadius: 2, backgroundImage: `url("${bookinInfo.bookingImg}")`,
                                        backgroundRepeat: "no-repeat",
                                        backgroundPosition: "center",
                                        backgroundSize: "cover !important", width: "100%", height: 200, mb: 2
                                    }} />

                                }
                                <Box sx={{ display: "flex", flexDirection: "column", gap: 1, mb: 2 }}>
                                    {/* <Typography variant="body1">{bookinInfo.property_name}</Typography> */}
                                    <Typography variant="h6" sx={{ fontWeight: "bold" }}>{bookinInfo.package_name}</Typography>
                                    <Box>
                                        <Typography variant="body1" component={'span'}>{" Nearest Town, " + bookinInfo.location}</Typography>
                                    </Box>
                                    {
                                        packageType === 'experience' &&

                                        <Box sx={{
                                            "& .MuiChip-root": {
                                                width: 'fit-content',
                                                height: '28px !important'
                                            },
                                            display: 'flex',
                                            gap: 1,
                                            flexWrap: 'wrap',
                                        }}>
                                            {/* {<Chip
                                                icon={<Icon.AccessTimeOutlined />}
                                                // label={`${extractDataFromArray(props, ['durationObj', 'value'], '')} ${extractDataFromArray(props, ['durationObj', 'unit'], '')}`}
                                                label={`${generatePhrase(
                                                    extractDataFromArray(bookingData, ['package', 'experience_data', 'pricing_01qr', 'package_duration', 'value'], 0),
                                                    (extractDataFromArray(bookingData, ['package', 'experience_data', 'pricing_01qr', 'package_duration', 'unit'], 'hours') === 'hours' ? 'Hour' : 'Day')
                                                )}`}
                                            />} */}
                                            {<CreateChip
                                                // label={`${extractDataFromArray(props, ['durationObj', 'value'], '')} ${extractDataFromArray(props, ['durationObj', 'unit'], '')}`}
                                                chipType={'time'}
                                                caption={`${generatePhrase(
                                                    extractDataFromArray(bookingData, ['package', 'experience_data', 'pricing_01qr', 'package_duration', 'value'], 0),
                                                    (extractDataFromArray(bookingData, ['package', 'experience_data', 'pricing_01qr', 'package_duration', 'unit'], 'hours') === 'hours' ? 'Hour' : 'Day')
                                                )}`}
                                            />}
                                            {/* {<Chip
                                                icon={<img src={iconPath} style={{ height: '16px', width: '16px' }} />}
                                                label={`${extractDataFromArray(bookingData, ['package', 'experience_data', 'general_info_01ab', 'experience_type'], '')}`}
                                            />} */}
                                            {<CreateChip
                                                chipType={`${extractDataFromArray(bookingData, ['package', 'experience_data', 'general_info_01ab', 'experience_type'], '')}`}
                                                caption={`${extractDataFromArray(bookingData, ['package', 'experience_data', 'general_info_01ab', 'experience_type'], '')}`}
                                            />}
                                        </Box>
                                    }
                                </Box>
                                <Box >
                                    <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                                        <Icon.PersonOutline fontSize="medium" />
                                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>&nbsp;{generatePhrase(bookinInfo.guest_count, 'Guest')}</Typography>
                                    </Box>
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                        <Icon.CalendarToday fontSize="medium" />
                                        <Typography variant="body1" sx={{ fontWeight: "bold" }}>&nbsp;{generatePhrase(bookinInfo.days, 'Day')} {"(" + moment(bookinInfo.start_date, "YYYY-MM-DD HH:mm:ss").format("DD") + "-" + moment(bookinInfo.end_date, "YYYY-MM-DD HH:mm:ss").format("DD MMM") + ")"}</Typography>
                                    </Box>
                                </Box>
                                <Divider sx={{ width: 1, mt: 3, mb: 3 }} />
                                <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                                    <Avatar
                                        alt="Remy Sharp"
                                        src={bookinInfo.profile_pic}
                                        sx={{ width: 52, height: 52 }}
                                    />
                                    <Box>
                                        <Typography variant="caption">Host</Typography>
                                        <Typography variant="h6" sx={{ fontWeight: "bold" }}>{bookinInfo.host_name}</Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    }
                </Grid>
            </Grid>
        </Box>
        <Dialog
            open={
                openModal
            }
        >
            <Box>
                <Box sx={{ p: 2.5 }}>
                    <Typography variant="h6" sx={{ fontWeight: 700 }}>
                        Invalid Booking</Typography>
                </Box>
                <Divider />
                <Box sx={{ p: 2.5 }}>
                    <Typography>
                        Looks like, the experience you are trying to review wasn't booked from this FarWide account.
                        Please login to the FarWide account used at the time of booking to review this property.</Typography>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: 1,
                        p: 2.5,
                        width: "100%",
                    }}
                >
                    <Button
                        variant="text"
                        color="secondary"
                        sx={{
                            border: "1px solid #bdbdbd"
                        }}
                        onClick={() =>
                            navigate(`${ROUTE_DASHBOARD}`)
                        }
                    >
                        OK
                    </Button>
                </Box>
            </Box>
        </Dialog>
    </>)
}

export default SubmitExpReview;