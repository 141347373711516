import { Box, Typography } from "@mui/material";
import MediaCard from "../../previewPageComponents/mediaCard";

const Banner = ({ ourstorydata }) => {
    // console.log("Banner", ourstorydata);
    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    px: { xs: 2, lg: 3 },
                }}
            >
                <Typography variant="h3" fontWeight={700} color="#1B1B1B" sx={{mb:6, lineHeight:{}}} >{ourstorydata?.ourStory?.heading}</Typography>
                <Box
                    sx={{
                        width: '100%',
                        mb: { xs: 6, sm: 6, md: 12 }
                    }}
                >
                    <MediaCard
                        imageObj={ourstorydata?.ourStory?.media}
                        width='100%'
                        height='490px'
                        borderRadius={'16px'}
                    />
                </Box>
                <Typography variant="h6" color="#000000" sx={{textAlign:'center', fontWeight:400}}  >FarWide embodies a passion for helping protect and expand outdoor recreational opportunities for today's sportsmen and those to come in future years. Our founder, Steve Raymond, has a vision to leverage technology and conservation to create greater opportunities for outdoor access and recreation while also protecting and improving fish and wildlife habitat and resources. FarWide lives that vision every day with our customers and employees.</Typography>
            </Box>
        </>
    )
};

export default Banner; 