import * as React from "react";
import { Box, Grid } from "@mui/material";
import 'swiper/css';
import TitleSection from "../home/section/Titlesection";
import FeatureSection from "../home/section/FeatureSection";
import Support from "../home/section/Support";
import "./style.css";
import ListPropertySection from "./section/ListPropertySection";
import DownloadSection from "./section/DownloadSection";
import FarwideSearch from "./section/FarwideSearch";
import { useEffect } from "react";
import { CommonContext } from "../../store/context/commonContext";
import EmailInput from "./section/EmailInput";
import Blogs from "./section/Blogs";
import GetreadySection from "./section/GetreadySection";
import FeatureSectionCommon from "./section/FeatureSectionCommon";
import { ACCESS } from "../../commonComponents/CommonConstants/commonKeys";
import GooglePureOneTap from "../../commonComponents/authComponents/googleOneTap"
import { UserAuth } from "../../store/context/userContext";
import { empty } from "../../utils/common";

const Home = () => {
	const { setSearchFilters, setShowSearchType } = CommonContext()
	const { user, loginUserWithThirdPartyApp } = UserAuth()
	useEffect(() => {
		setSearchFilters({ 'activity': "access" });
		setSearchFilters({ 'kw': "" });
		setShowSearchType('')
		window.scrollTo({ top: 0, behavior: 'smooth' });
	}, []);

	const handleGoogleSuccess = ({ idToken }) => {
		// Handle successful authentication
		// console.log(' idToken :-', idToken);
		loginUserWithThirdPartyApp(idToken, () => {
			console.log("successfull call");
		}, () => {
			console.log("failed call");
		})
		// Proceed with login/signup flow
	};

	const handleGoogleError = (error) => {
		// Handle authentication errors
		console.log('Google One Tap failed :-', error);
	};
	return (
		<>
			{
				empty(user) &&
				<div>
					<GooglePureOneTap
						onSuccess={handleGoogleSuccess}
						onError={handleGoogleError}
						context="signin" // or "signup"
					/>
					{/* Rest of your login page content */}
				</div>
			}
			<Box
				className="home_wrapper"
				style={{
					backgroundImage: "url('../assets/images/homepage/homepage_bg.png')",
				}}
			>
				<Grid
					container
					maxWidth="lg"
					sx={{ m: "auto" }}
					direction="row"
					justifyContent="center"
				>
					{/* header */}
					<TitleSection />
				</Grid >
			</Box>

			<Box>
				<Grid
					item
					container
					maxWidth="lg"
					sx={{ m: "auto", px: { xs: 2, md: 2, lg: 0 } }}
					direction="row"
					justifyContent="center"

				>
					{/* Features */}
					{/* <EmailInput /> */}
					<GetreadySection />
					<Blogs />
					{/* Featured Properties */}
					<Box sx={{ width: '100%' }}>
						<FeatureSectionCommon type={ACCESS.value} />
					</Box>

					<ListPropertySection />
					<FarwideSearch />
					<DownloadSection />
				</Grid >
			</Box>





			{/* sub footer */}
			<Support />
			{/*  footer */}
		</>
	);
};

export default Home;
