import axios from "axios";
import config from "../config";
import { extractDataFromArray, parseResponseAxios, toastMessage } from "../utils/common";
import { toast } from "react-toastify";


const TransactionService = {


	getAllTransactions: async (kw, sort, start, limit, type, callback = () => { }) => {
		await axios.get(config.apiUrl + "/services/api", {
			params: {
				cmd: "getAllTransactions",
				kw: kw,
				sort: sort,
				start: start,
				limit: limit,
				type: type

			}
		}).then((response) => {
			response = parseResponseAxios(response)
			if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
				callback(extractDataFromArray(response, ['data'], []))
			}
		});
	},
	getTransactionDetails: async (transaction_seq, callback = () => { }, errCallback = () => { }) => {
		await axios.get(config.apiUrl + "/services/api", {
			params: {
				cmd: "getTransactionDetails",
				transaction_seq: transaction_seq,
			}
		}).then((response) => {
			response = parseResponseAxios(response)
			if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
				callback(extractDataFromArray(response, ['data'], []))
			} else {


				// toast.error(extractDataFromArray(response,['errorMsg'],'Something went wrong, Please try again lator'), {
				// 	theme: "colored",
				// })
				toastMessage({
					message: extractDataFromArray(response, ['errorMsg'], 'Something went wrong, Please try again later'),
					type: 'error',
				});
				errCallback(extractDataFromArray(response, ['errorMsg'], []))
			}

		});
	},
	DownloadInvoice: async (transaction_seq, successCB = () => { }) => {
		await axios.get(config.apiUrl + "/services/api", {
			params: {
				transaction_seq: transaction_seq,
				cmd: "downloadInvoice"
			},
			responseType: 'blob' // Set the response type to 'blob'
		}).then((response) => {
			// response = parseResponseAxios(response);
			successCB(response);
		});
	},
};

export default TransactionService;