import * as React from "react";

import * as Icon from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";


const GetreadySection = (props) => {
    const featureData = [
        {
            icon: <Icon.SearchOutlined />,
            text: 'Explore Access & Experiences.',
            description: 'Search by state, activity or more.',
        },
        {
            icon: <Icon.MobileFriendlyOutlined />,
            text: 'Connect with Hosts',
            description: 'Communicate, schedule and plan directly.',
        },
        {
            icon: <Icon.EventOutlined />,
            text: 'Book Directly Online',
            description: 'Pay as you book. No membership fees!',
        },
        {
            icon: <Icon.ParkOutlined />,
            text: 'Experience New Adventures!',
            description: 'Enjoy solitude and fewer crowds!',
        },
    ];
    return (
        <Grid container sx={{ mt: { xs: 6, md: 12 }, mb: { xs: 6, md: 12 } }} >
            <Grid item xs={12} sm={12} md={12} lg={6} sx={{ textAlign: { xs: "center", md: "center", lg: 'left' } }}>
                <Box sx={{ display: { xs: 'none', md: 'block' }, height: 'calc(100% - 30px)' }}>
                    <img className='fw_home_feature_image' src="../assets/images/homepage/features_image_1.png" alt="FarWide" style={{ maxWidth: "100%", borderRadius: '16px', height: '100%' }} />
                </Box>
                <Box sx={{ display: { xs: 'block', md: 'none' } }}>
                    <img className='fw_home_feature_image' src="../assets/images/homepage/stayTunedImage.png" alt="FarWide" style={{ maxWidth: "100%", borderRadius: '16px' }} />
                </Box>

            </Grid>
            <Grid container item xs={12} sm={12} md={12} lg={6}  >
                <Grid item xs={12} sm={12} md={12} sx={{}}>
                    <Typography variant="h4" sx={{ mb: 3 }}>Better Outdoor Experiences.</Typography>
                    <Typography variant="h6" sx={{ mb: { sm: 4 }, color: '#85878A', fontWeight: 'normal' }} >Explore our unique
                        collection of recreational access and experiences. All without the headache of over-crowded resources.</Typography>
                    <Typography variant="h6" sx={{ fontWeight: '700', mt: 6, mb: 2, p: 0 }}>HOW IT WORKS:</Typography>
                </Grid>

                <Grid container spacing={2}>
                    {featureData.map((feature, index) => (
                        <Grid key={index} item xs={6} sm={6} md={6}>
                            <Box className="hm_circle_icon">{feature.icon}</Box>
                            <Typography sx={{ mb: { md: 0.5 }, fontWeight: '700' }} >{feature.text}</Typography>
                            <Typography sx={{ mb: { sm: 3 }, color: '#85878A' }} >{feature.description}</Typography>
                        </Grid>
                    ))}
                </Grid>
            </Grid>

        </Grid>
    );
};

export default GetreadySection;
