import { Box, Divider, IconButton, TextField, Typography } from "@mui/material"
import * as Icon from '@mui/icons-material';
import GreyLabel from "../formElement/GreyLabel";
import { useState } from "react";
import { empty, toastMessage } from "../../utils/common";
import ListingService from "../../services/listing";
import LoaderButton from "../LoaderButton";
import errorAndHelperText from "../CommonConstants/errorAndHelperText.json"

const ApprovalContent = ({ handleClose, listing_seq, listing_type, setCurrentStatus }) => {
    const [remark, setRemark] = useState('')
    const [error, setError] = useState('')
    const [isLoading, setisLoading] = useState(false)
    const handleClick = () => {
        if (empty(remark)) {
            setError(errorAndHelperText.helperTextsObj.required_field)
            return;
        }
        setisLoading(true)
        setError('')
        ListingService.approveListing(listing_seq, remark, listing_type, (data) => {
            // toast.success("Verification status updated.", { theme: 'colored' })
            toastMessage(errorAndHelperText.verification_status_update.success)
            setCurrentStatus('V')
            setisLoading(false)
            handleClose()
        })
    }


    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ py: 1.5, px: 2.5, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6" sx={{ fontWeight: 700 }}> Approve Listing</Typography>
                <IconButton onClick={handleClose}><Icon.Close /> </IconButton>
            </Box>
            <Divider />
            <Box sx={{ p: 2.5, width: "600px" }}>
                <GreyLabel label="Remark" required></GreyLabel>
                <TextField
                    multiline
                    fullWidth
                    rows={5}
                    placeholder="Type here..."
                    value={remark}
                    onInput={(e) => { setRemark(e.target.value) }}
                    helperText={error}
                    error={!empty(error)}
                    sx={{ mb: 3 }}
                />
                <LoaderButton variantType="contained" buttonColor="primary" onClick={handleClick} buttonText={'Approve'} isLoading={isLoading} isFullWidth={true} />
            </Box>
        </Box>
    )
}

export default ApprovalContent;