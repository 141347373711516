import { Box, Grid } from "@mui/material";
import AppBanner from "./sections/appBanner";
import AppInformation from "./sections/appInformationFirst";
import AppInformationSecond from "./sections/appInfo2";
import FAQSection from "./sections/faqSection";
import AppScreenshotsSlider from "./sections/appScreenshotsSlider";
import DownloadApp from "./sections/downloadApp";

const screenshots = [
    { url: "//farwide.s3.amazonaws.com/asset/7PawXkWcmD8Y3FIAXxCFxLAp/2221/676e42e0c7e531735279328.webp", alt: 'Searchable Map, Tools, and Access!' },
    { url: "//farwide.s3.amazonaws.com/asset/ICM_hCLoopwfvu2eIKEOTIPR/2221/676e42de4cf721735279326.webp", alt: 'Search hunting units and access' },
    { url: "//farwide.s3.amazonaws.com/asset/xPkPu-ZuO05usLIjCiAoEwGJ/2221/676e42de8eeee1735279326.webp", alt: 'Locate relevant unit boundaries and access points' },
    { url: "//farwide.s3.amazonaws.com/asset/0GfX3cSmBTy6jWtIT6CpNvaA/2221/676e42de5151e1735279326.webp", alt: 'Utilize map layers for access and planning' },
    { url: "//farwide.s3.amazonaws.com/asset/TERptsSfdoLWoGiJduXACu9N/2221/6777da033b1051735907843.webp", alt: 'Personalize your waypoints and experience' },
    { url: "//farwide.s3.amazonaws.com/asset/MRmk0PGGXmo2FqyYTlfzePTO/2221/676e42ded82551735279326.webp", alt: 'Elevate your maps with 3D imaging' },
    { url: "//farwide.s3.amazonaws.com/asset/X8D4A_HVRDU4ti_iER0cNCXQ/2221/676e42de8e58a1735279326.webp", alt: 'Use area draw and line distance for better route planning' },
    { url: "//farwide.s3.amazonaws.com/asset/Czrf1ufUAAKjqZNbsF6Gjn_g/2221/676e42e0584fe1735279328.webp", alt: 'Locate private boundary and property lines' },
    { url: "//farwide.s3.amazonaws.com/asset/xW__qjDKNehe1sSovPHgAs39/2221/676e42e05850c1735279328.webp", alt: 'Take your maps off grid!' },
    { url: "//farwide.s3.amazonaws.com/asset/N_Wxf2wSjbv7KvKWwjbvZbT9/2221/6777d9982a63a1735907736.webp", alt: 'Never lose yourself or friends with dual GPS tracking' },
    { url: "//farwide.s3.amazonaws.com/asset/8Ao9zG2HcSoQiRujmMd9mz9W/2221/676e42e0093cc1735279328.webp", alt: 'Discover PRIVATE land and access with our Experience feature' },
    { url: "//farwide.s3.amazonaws.com/asset/qoNRNFJnmOGmJ1Vueb7U1oKh/2221/676e42e085ede1735279328.webp", alt: 'Search inventory amongst private access and experiences.' },
    { url: "//farwide.s3.amazonaws.com/asset/yiA4rnegX17tW7iCnWJzQrtz/2221/676e42e070e0e1735279328.webp", alt: 'Book a memorable experience with FarWide!' },
    // Add more screenshots as needed
];

const AppPAge = () => {
    return (
        <>
            <Box
                className="our_story_page_wrapper"
                style={{
                    backgroundImage:
                        "url('../landowner/assets/images/homepage/homepage_bg.png')",
                }}
            >
                <Grid container maxWidth="lg" m="auto">
                    <AppBanner />
                </Grid>
                <Box
                    sx={{
                        background: '#F5F5F6'
                    }}
                >
                    <Grid
                        container
                        maxWidth="lg"
                        m="auto"
                        sx={{ justifyContent: "center" }}
                    >
                        <AppInformation />
                    </Grid>
                </Box>
                <Grid
                    container
                    maxWidth="lg"
                    m="auto"
                    sx={{ justifyContent: "center" }}
                >
                    <AppInformationSecond />
                </Grid>
                <Box
                    sx={{
                    }}
                >
                    <AppScreenshotsSlider screenshots={screenshots} />
                </Box>
                <Grid
                    container
                    maxWidth="lg"
                    m="auto"
                    sx={{ justifyContent: "center" }}
                >
                    <DownloadApp />
                </Grid>
                <Box
                    sx={{
                        background: '#F5F5F6',
                        mt: { xs: 6, sm: 6 }
                    }}
                >
                    <Grid
                        container
                        maxWidth="lg"
                        m="auto"
                        sx={{ justifyContent: "center" }}
                    >
                        <FAQSection />
                    </Grid>
                </Box>
            </Box>
        </>
    )
};

export default AppPAge;