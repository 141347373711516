import { Box, Button, Divider, IconButton, Typography } from "@mui/material"
import * as Icon from '@mui/icons-material';
import ListingService from "../../services/listing";
import { useState } from "react";
import LoaderButton from "../LoaderButton";
import { toastMessage } from "../../utils/common";
import errorAndHelperText from "../CommonConstants/errorAndHelperText.json"

const MoveToPendingContent = ({ handleClose, listing_seq, listing_type, setCurrentStatus }) => {
    const [isLoading, setisLoading] = useState(false)

    const handleClick = () => {
        setisLoading(true)
        ListingService.setPendingListing(listing_seq, listing_type, (data) => {
            toastMessage(errorAndHelperText.listing_moved_to_pending.success)
            setCurrentStatus('P')
            handleClose()
            setisLoading(false)
        })
    }


    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ py: 1.5, px: 2.5, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6" sx={{ fontWeight: 700 }}> Move to Pending</Typography>
                <IconButton onClick={handleClose}><Icon.Close /> </IconButton>
            </Box>
            <Divider />
            <Box sx={{ p: 2.5, width: "600px" }}>
                <Typography sx={{ mb: 4 }}>
                    Are you sure you want to move this listing to pending?
                </Typography>

                <Box sx={{ display: 'flex', justifyContent: 'right', gap: 1 }}>

                    <Button variant="outlined" color="secondary" onClick={handleClose}>Cancel</Button>
                    <LoaderButton
                        variantType={'contained'}
                        buttonColor={'secondary'}
                        onClick={handleClick}
                        isLoading={isLoading}
                        buttonText={'Move to Pending'}
                    />
                </Box>
            </Box>
        </Box>
    )
}

export default MoveToPendingContent;