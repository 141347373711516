import React, { useEffect, useState, useRef } from "react";
import 'swiper/css';

import SwiperCore from "swiper/core";

// Importing PhotoSwipeLightbox for photo gallery/lightbox functionality
import PhotoSwipeLightbox from "photoswipe/lightbox";
import "photoswipe/style.css";

// Import custom components
import EnquiryPopUp from "../EnquiryPopUp"
import PackageService from "../../services/package";
import ListingService from "../../services/listing";
import { extractDataFromArray, generatePhraseReturnText } from "../../utils/common";
import config from "../../config";
import Header from "../previewPageComponents/Header";
import About from "../previewPageComponents/About";
import PackageList from "../previewPageComponents/PackageList";
import Lodging from "../previewPageComponents/Lodging";
import GroundRules from "../previewPageComponents/GroundRules";
import SkeletonPropertyPreview from "../placeholders/SkeletonPropertyPreview";
import { useNavigate, useParams } from "react-router";
import { Avatar, Box, Button, Card, Grid, Typography } from "@mui/material";
import HeaderGallery from "../previewPageComponents/HeaderGallery";
import Location from "../previewPageComponents/Location";
import LandOwner from "../previewPageComponents/LandOwner";
import { Navigation } from "swiper/modules";
import { CommonContext } from "../../store/context/commonContext";
import AdminBanner from "../previewPageComponents/AdminBanner";
import ReviewRecievedCard from "../revieRecievedCard";
import { Link } from "react-router-dom";

// Use Swiper for navigation in galleries
SwiperCore.use([Navigation]);

// Main PropertyDetails component
const PropertyDetails = (props) => {
	// State hooks for loading status, listing data, packages, and other UI states
	const [loading, setLoading] = useState(true);
	const { property_id } = useParams(); // Extract property_id from URL params
	let listingSeq = property_id.split("-");
	listingSeq = listingSeq[listingSeq.length - 1]; // Extract listing sequence number
	const [listingData, setListingData] = useState(null); // Store property details
	const [packages, setPackages] = useState([]); // Store available packages
	// console.log(packages);
	const navigate = useNavigate(); // Navigation hook

	// Fetch property data based on listing sequence number
	useEffect(() => {
		ListingService.getListingData(listingSeq, (data) => {
			setLoading(false);
			setListingData(data);
			// Update URL with the slug and listing sequence
			window.history.replaceState(null, null, window.location.origin + (config.module === 'lister' ? "/landowner" : '') + "/property/" + data.slug + "-" + listingSeq);
		}, (errorMessage) => {
			// Redirect to unauthorized page on error
			navigate('/unauthorized', { state: { errorMessage } });
		});

		// Fetch all packages related to the current listing
		async function getAllPackagesData() {
			await PackageService.getAllListingPackagesData(listingSeq, '', (data) => {
				const filteredPackages = data.filter(
					(pkg) => parseInt(pkg.listing_seq) === parseInt(listingSeq)
				);
				setPackages(filteredPackages); // Update packages state
			});
		}

		getAllPackagesData();
	}, []); // Empty dependency array ensures this effect runs only once on mount

	// Initialize the photo swipe gallery/lightbox functionality
	useEffect(() => {
		let lightbox = new PhotoSwipeLightbox({
			gallery: "#headerGallery", // Target gallery element
			children: "a", // Target anchor tags for images
			pswpModule: () => import("photoswipe"), // Dynamically import PhotoSwipe module
		});
		lightbox.init();

		return () => {
			// Clean up lightbox instance when component unmounts
			lightbox = null;
		};
	}, []);

	// Handle Enquiry Popup open/close state
	const [openEnquiryPopup, setOpenEnquiryPopup] = useState(false);
	const [listerSeq, setListerSeq] = useState('');
	const [paymentDetails, setPaymentDetails] = useState({});

	const { isMobile, isNineHundred } = CommonContext(); // Get context values

	const handleOpen = () => {
		setOpenEnquiryPopup(true); // Open enquiry popup
	};

	const handleClose = () => {
		setOpenEnquiryPopup(false); // Close enquiry popup
	};

	const sectionRef = useRef(null);

	// Scroll into view functionality when a button is clicked
	const handleButtonClick = () => {
		sectionRef.current.scrollIntoView({ behavior: "smooth" });
	};

	// Extract the lister sequence when listing data is available
	useEffect(() => {
		setListerSeq(extractDataFromArray(listingData, ['lister_seq'], ''));
	}, [listingData]);

	// Fetch cancellation policy when lister sequence is available
	useEffect(() => {
		if (config.module !== 'admin') {
			PackageService.getCancellationPolicyData('lister', listerSeq, (data) => {
				setPaymentDetails(data);
			});
		}
	}, [listerSeq]);

	// Show loading skeleton if the data is still being fetched
	if (loading) {
		return <SkeletonPropertyPreview />;
	}

	return (
		<Box sx={{ pb: 4 }}>
			{/* Enquiry popup dialog */}
			{openEnquiryPopup && (
				<EnquiryPopUp
					open={openEnquiryPopup}
					packages={packages}
					handleClose={handleClose}
					listingData={listingData}
					setOpenEnquiryPopup={setOpenEnquiryPopup}
				/>
			)}

			{/* Main Property Details Layout */}
			<Grid container spacing={8} sx={{ p: 2 }}>
				{
					// Admin specific banner (if applicable)
					config.module === 'admin' &&
					<Grid item md={12} xs={12}>
						<AdminBanner
							lister_name={extractDataFromArray(listingData, ['fname'], 'Test') + " " + extractDataFromArray(listingData, ['lname'], 'Test')}
							lister_pic={extractDataFromArray(listingData, ['profile_pic'], '')}
							lister_seq={extractDataFromArray(listingData, ['lister_seq'], '')}
							listing_seq={extractDataFromArray(listingData, ['listing_seq'], '')}
							status={extractDataFromArray(listingData, ['verification_status'], '')}
							listing_type={'access'}
						/>
					</Grid>
				}

				{/* Header and Gallery */}
				<Grid item md={12} xs={12} sm={12}>
					<Box sx={{ display: 'flex', flexDirection: { sm: 'column', xs: 'column-reverse' }, width: '100%', mb: { xs: 0, sm: 0, md: 6 } }}>
						<Header
							type="property"
							seq={extractDataFromArray(listingData, ['listing_seq'], 0)}
							listing_seq={extractDataFromArray(listingData, ['listing_seq'], 0)}
							title={extractDataFromArray(listingData, ['title'], '')}
							rating={extractDataFromArray(listingData, ['average_rating'], '4.5')}
							reviewCount={extractDataFromArray(listingData, ['review_count'], '0')}
							address={extractDataFromArray(listingData, ['listing_json', 'location_info', 'property_address'], '')}
							areaSize={extractDataFromArray(listingData, ['listing_json', 'location_info', 'property_area'], '')}
							nearestTown={extractDataFromArray(listingData, ['listing_json', 'location_info', 'nearest_town'], '')}
							ltln={extractDataFromArray(listingData, ['listing_json', 'location_info', 'property_ltln'], '')}
						/>
						<HeaderGallery images={extractDataFromArray(listingData, ['listing_media', 'media_library'], [])} type="property" seq={extractDataFromArray(listingData, ['listing_seq'], 0)} />
					</Box>
				</Grid>
			</Grid>

			{/* Property Details Sections */}
			<Grid container spacing={!isMobile && 8} sx={{ p: 2 }}>
				<Grid item xs={12} sm={12} md={7} sx={{
					// '@media (max-width: 900px)': {
					// 	maxWidth: '75% !important', // Applies only for screens 900px and wider
					// 	flexBasis: '75% !important', // Applies only for screens 900px and wider
					// },
				}}>
					{/* About Section */}
					<Grid item xs={12} sm={12} md={12} sx={{ mb: 5 }}>
						<About desc={extractDataFromArray(listingData, ['description'], '')} />
					</Grid>

					{/* Package List Section */}
					<Grid item xs={12} sm={12} md={12} sx={{ mb: 5 }}>
						<PackageList packages={packages} listingData={listingData} sectionRef={sectionRef} />
					</Grid>

					{/* Lodging Details Section */}
					{extractDataFromArray(listingData, ["listing_json", "lodging_details", "available"], 'No') === "Yes" &&
						<Grid item xs={12} sm={12} md={12} sx={{ mb: 5 }}>
							<Lodging data={extractDataFromArray(listingData, ["listing_json", "lodging_details"], {})} />
						</Grid>
					}

					{/* Ground Rules Section */}
					<Grid item xs={12} sm={12} md={12} sx={{ mb: 5 }}>
						<GroundRules
							arrivalInstructions={extractDataFromArray(listingData, ["listing_json", "arrival_instructions"], {})}
							groundRules={extractDataFromArray(listingData, ["listing_json", "ground_rule"], {})}
						/>
					</Grid>

					{/* Landowner Tips Section (if available) */}
					<Grid item xs={12} sm={12} md={12} sx={{ mb: 5 }}>
						{extractDataFromArray(listingData, ['listing_json', 'arrival_instructions', 'land_owner_tips'], '') ? (
							<Box>
								<Typography sx={{ color: '#1b1b1b', fontWeight: '500' }}>Landowner Rules:</Typography>
								<Typography color="grey" sx={{ mb: 2 }}>
									{extractDataFromArray(listingData, ['listing_json', 'arrival_instructions', 'land_owner_tips'], '')}
								</Typography>
							</Box>
						) : ""}
						<Typography sx={{ fontWeight: '500', textDecoration: 'underline' }}>Cancellation Policy:</Typography>
						<Typography color="grey" sx={{ mb: 5 }}>
							{extractDataFromArray(paymentDetails, ['messageToguest'], '')}
						</Typography>
					</Grid>

					{/* Review Section */}
					<Grid item xs={12} sm={12} md={12} sx={{ mb: 5 }}>
						<ReviewRecievedCard listingData={listingData} type={"L"} listingSeq={listingSeq} />
					</Grid>

					{/* Landowner Contact and Location Section */}
					<Grid item xs={12} sm={12} md={12} sx={{ mb: 5 }}>
						<LandOwner listingData={listingData} setOpenEnquiryPopup={setOpenEnquiryPopup} />
					</Grid>

					{/* Property Location (Map) */}
					<Grid item xs={12} sm={12} md={12}>
						<Location lat={parseFloat(extractDataFromArray(listingData, ['lat'], 0))} lon={parseFloat(extractDataFromArray(listingData, ['lon'], 0))} />
					</Grid>
				</Grid>

				{/* Right Sidebar for Mobile Users */}
				{isNineHundred && config.module === 'guest' && (
					<Grid item xs={12} sm={12} md={5}>
						<Box sx={{ background: '#fff', position: 'fixed', bottom: '0', left: '0', width: '100%', padding: '0 16px 16px 16px', boxShadow: '0 0px 18px 0 #00000020;', zIndex: '99' }}>
							{extractDataFromArray(listingData, ['status'], '') === 'D' ?
								<>
									<Box
										sx={{
											mt: 2,
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
											mb: packages.length > 0 && 2,
											justifyContent: 'center',
											color: '#e94a4a',
										}}
									>
										Currently unavailable
									</Box>
									{packages.length > 0 && <Button
										fullWidth
										variant='contained'
										sx={{
											backgroundColor: 'var(--black)',
											":hover": {
												backgroundColor: 'var(--black)'
											}
										}}
										onClick={() => { setOpenEnquiryPopup(true) }}
									>
										Request Availability
									</Button>}
								</>
								:
								<Button
									fullWidth
									sx={{ mt: 2, borderRadius: '8px', backgroundColor: '#E0F7EC', textTransform: 'none' }}
									onClick={handleButtonClick}
								>
									View ({packages.length}) {generatePhraseReturnText(packages.length, 'Package')}
								</Button>}
						</Box>
					</Grid>
				)}

				{/* Right Sidebar for Desktop Users */}
				{!isNineHundred && extractDataFromArray(listingData, ['status'], '') !== 'D' && (
					<Grid item xs={12} sm={12} md={5}>
						{config.module === 'guest' && packages && (
							<Card
								sx={{
									p: 3,
									borderRadius: (theme) => `${theme.card.borderRadius}`,
								}}
								variant="outlined"
								className="propdetails_right_card"
							>
								<Box sx={{ display: "flex" }}>
									<Typography>
										Price starts at &nbsp;
									</Typography>
									<Typography variant="body1" sx={{ fontWeight: 700 }}>
										$
										{Math.min(
											...packages.map((item) => item.package_json.price_per_guest)
										)}
									</Typography>
								</Box>
								<Button
									fullWidth
									size="large"
									sx={{ mt: 2, borderRadius: '8px', backgroundColor: '#E0F7EC', textTransform: 'none', fontSize: "1rem" }}
									onClick={handleButtonClick}
								>
									View ({packages.length}) {generatePhraseReturnText(packages.length, 'Package')}
								</Button>
								<Box sx={{ display: "flex", justifyContent: "space-between", mt: 3, alignItems: "center" }}>
									<div>
										<Typography variant="caption" className="grey900">
											Hosted by
										</Typography>
										<Typography variant="h6" sx={{ fontWeight: 700 }}>
											{extractDataFromArray(listingData, ['fname'], '')} {extractDataFromArray(listingData, ['lname'], '')}
										</Typography>

										{/* Contact Button for Guest Users */}
										{config.module === "guest" && (
											<Link to="">
												<Button size="small" variant="light" sx={{ fontWeight: 600 }} onClick={handleOpen}>
													{"Contact " + extractDataFromArray(listingData, ['fname'], '')}
												</Button>
											</Link>
										)}
									</div>
									<div>
										<Avatar
											className="ft_hdr_avtar"
											sx={{ width: 75, height: 75 }}
											alt="Danny Sharp"
											src={extractDataFromArray(listingData, ['profile_pic'], '')}
										/>
									</div>
								</Box>
							</Card>
						)}
					</Grid>
				)}

				{!isNineHundred && extractDataFromArray(listingData, ['status'], '') === 'D' && (
					<Grid item xs={12} sm={12} md={5}>
						{config.module === 'guest' && packages && (
							<Box sx={{
								border: '1px solid #E5E7EB',
								borderRadius: '16px',
								p: 3,
								height: 'fit-content',
								position: 'sticky',
								top: '96px',
								right: '20px',
							}}>
								<Box
									sx={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										mb: packages.length > 0 && 2,
										justifyContent: 'center',
										color: '#e94a4a',
									}}
								>
									Currently unavailable
								</Box>
								{packages.length > 0 && <Button
									fullWidth
									variant='contained'
									sx={{
										backgroundColor: 'var(--black)',
										":hover": {
											backgroundColor: 'var(--black)'
										}
									}}
									onClick={() => { setOpenEnquiryPopup(true) }}
								>
									Request Availability
								</Button>}
							</Box>
						)}
					</Grid>
				)}
			</Grid>
		</Box>
	);
};

export default PropertyDetails;  