import { Box, Divider, Typography } from "@mui/material"
import { Link, useLocation } from "react-router-dom"
import { RESPONSIVE_SPACING } from "../../CommonConstants/commonKeys"
import { CommonContext } from "../../../store/context/commonContext"
import config from "../../../config"
import { currentYear } from "../../../utils/common"

const SmallFooter = () => {
    const { isTablet, isNineHundred } = CommonContext()
    const location = useLocation()

    const locations = ['/checkout', '/payment'];

    if (isNineHundred && locations.some(path => location.pathname.startsWith(path))) {
        return;
    }
    return (
        <Box sx={{ width: '100%', mt: 'auto' }}>
            <Divider />
            <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', p: RESPONSIVE_SPACING }}>
                <Typography variant='caption' sx={{ color: 'var(--textGrey)', textAlign: 'center', display: 'flex', gap: 1, flexWrap: 'wrap', justifyContent: 'center', flexDirection: isTablet ? 'column' : 'row' }}>
                    <Box>
                        &copy; {currentYear()} &nbsp;
                        <Link to={(config.module === 'admin' || config.module === 'lister') ? config.origin : '/'} style={{ color: 'var(--textGrey)' }}>
                            farwide.com
                        </Link>
                    </Box>
                    <Box>
                        <Link to={config.privacyTermsLink} style={{ color: 'var(--textGrey)' }}>
                            Terms & Conditions
                        </Link>
                        &nbsp;|&nbsp;
                        <Link to={config.privacyTermsLink} style={{ color: 'var(--textGrey)' }}>
                            Privacy Policy
                        </Link>
                    </Box>
                </Typography>
            </Box>
        </Box>
    )
}

export default SmallFooter