import React, { useEffect } from 'react';
import config from '../../config';

const GooglePureOneTap = ({
    onSuccess,
    onError,
    context = 'signin' // 'signin' or 'signup'
}) => {


    // IMPORTANT :- When google one tap is closed by user by clicking on close icon i.e. "X" icon, then google one tap is not shown for atleast 1-2 hour from google by defaut, as it thinks user is irritated by the pop up and does not want to see it again and again

    useEffect(() => {
        // Dynamically load Google One Tap script
        const script = document.createElement('script');
        script.src = 'https://accounts.google.com/gsi/client';
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);

        script.onload = () => {
            if (window.google && window.google.accounts) {
                // Initialize Google One Tap
                window.google.accounts.id.initialize({
                    client_id: config.cliendId,
                    callback: handleCredentialResponse,
                    auto_select: false,
                    context: context,
                    prompt_parent_id: 'g_id_onload',
                    state_cookie_domain: window.location.hostname,
                    use_fedcm_for_prompt: true,
                    scope: 'openid profile email',
                    prompt: 'consent select_account'
                });

                // Explicitly show the prompt
                // window.google.accounts.id.prompt((notification) => {
                //     console.log('One Tap Prompt Notification', notification);
                //     if (notification.isNotDisplayed()) {
                //         console.log("Not display reason : -", notification.getNotDisplayedReason());
                //     } else if (notification.isSkippedMoment()) {
                //         console.log("Skipped reason : -", notification.getSkippedReason());
                //     } else if (notification.isDismissedMoment()) {
                //         console.log("Dismissed reason : -", notification.getDismissedReason());
                //     }
                // });
            }
        };

        // Cleanup function
        return () => {
            if (window.google && window.google.accounts) {
                window.google.accounts.id.cancel();
            }
        };
    }, [context]);

    const handleCredentialResponse = (response) => {
        try {
            // Decode the credential (JWT token)
            const credentialDecoded = response.credential;

            // Verify and parse the credential
            const credentialData = parseJwt(credentialDecoded);

            // Prepare comprehensive user data object
            const userData = {
                idToken: credentialDecoded,
                email: credentialData.email,
                name: credentialData.name,
                picture: credentialData.picture,
                googleId: credentialData.sub,
                givenName: credentialData.given_name,
                familyName: credentialData.family_name,
                emailVerified: credentialData.email_verified
            };
            // console.log("userData :-", userData);
            // Call success callback with user data
            onSuccess({
                idToken: userData.idToken,
            });
        } catch (error) {
            // Detailed error logging
            console.error('Google One Tap Detailed Error:', {
                message: error.message,
                stack: error.stack,
                name: error.name
            });

            // Call error callback if something goes wrong
            onError ? onError(error) : console.error('Google One Tap Error:', error);
        }
    };

    // Utility function to decode JWT token
    const parseJwt = (token) => {
        try {
            const base64Url = token.split('.')[1];
            const base64 = base64Url.replace('-', '+').replace('_', '/');
            return JSON.parse(window.atob(base64));
        } catch (error) {
            console.error('JWT Parsing Error:', error);
            throw error;
        }
    };

    // No visible rendering
    return null;
};

export default GooglePureOneTap;