import { Box, ClickAwayListener, Divider, Fade, IconButton, Menu, MenuItem, Paper, Popper, Typography } from "@mui/material";
import { empty, extractDataFromArray, generatePhrase, isValueEqual, setValueIfEmpty, ucFirst } from "../../utils/common";
import { styled } from '@mui/material/styles';
import * as Icon from '@mui/icons-material'
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { useEffect, useState } from "react";
import CustomTooltip from "../CustomTooltip";
import { CommonContext } from "../../store/context/commonContext";


const CountBox = styled(Box)(
    ({ theme }) => `
    width: 100%;
    background-color : var(--grey300);
    border-radius : ${theme.card.borderRadiusMd};
    padding: 1.25rem;
    display : flex;
    gap : 1rem;
  `,
);

const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));



const LodgingCountBox = ({ flex, title, fontWeight, tooltip }) => {

    fontWeight = empty(fontWeight) ? 400 : fontWeight
    return (
        <Box sx={{ display: 'flex', flex, gap: 1 }}>
            <Typography sx={{ display: 'flex', gap: 1, width: flex === "100%" ? 'unset' : "100%", mb: 1, fontWeight }}>
                {title}

            </Typography>
            {
                !empty(tooltip) &&
                <CustomTooltip content={tooltip} />
            }
        </Box>
    )
}

const AvailableOptionsList = ({ header, tooltip, list }) => {
    return (
        <Box sx={{ mb: 2 }}>
            <Box sx={{ display: 'flex', gap: 1, mb: 1 }}>
                <Typography sx={{ fontWeight: 700 }}>{header}</Typography>
                <CustomTooltip
                    content={
                        <Typography  >{tooltip}</Typography>
                    }
                />
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {
                    list.map((amenity, i) => {
                        return (
                            <Box sx={{ display: 'flex', gap: 1, flex: { xs: '100%', sm: '50%' }, width: '100%', mb: 1 }} key={"lodging_amenity_" + i}>
                                <Icon.Verified color="primary" />
                                <Typography color="grey">{amenity}</Typography>
                            </Box>
                        )
                    })
                }
            </Box>
        </Box>
    )
}

const Lodging = ({ data }) => {
    let lodging_type = extractDataFromArray(data, ['lodging_type'], 'cabin').toLocaleLowerCase();
    let lodging_type_data = extractDataFromArray(data, [lodging_type], {});
    const { isMobile } = CommonContext()
    return (
        <>
            <Typography variant="h5" fontWeight={600}>
                {ucFirst(lodging_type)} Details
            </Typography>
            <Divider sx={{ mb: 3, mt: 2, borderColor: 'var(--grey500)' }} />
            <Box sx={{ display: 'flex', gap: 2, mb: 3, flexWrap: { xs: 'wrap', sm: 'wrap', md: 'unset' }, flexDirection: 'column' }}>
                <CountBox>
                    <Icon.PersonOutlineOutlined />

                    <Box>
                        <Typography sx={{ fontWeight: 700 }}>{generatePhrase(extractDataFromArray(lodging_type_data, ['total_guests_accommodate'], 0), 'Guest')}</Typography>
                    </Box>
                </CountBox>
                <CountBox>
                    <Icon.BedOutlined />
                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        <LodgingCountBox flex={"100%"} title={generatePhrase(extractDataFromArray(lodging_type_data, ['total_bedrooms'], 0), 'Bedroom')} fontWeight={700} tooltip={extractDataFromArray(lodging_type_data, ['additional_info'], '')} />
                        {extractDataFromArray(lodging_type_data, ['total_double_beds'], 0) != 0 && <LodgingCountBox flex={!isMobile ? "50%" : "100%"} title={generatePhrase(extractDataFromArray(lodging_type_data, ['total_double_beds'], 0), ' Double Bed')} />}
                        {extractDataFromArray(lodging_type_data, ['total_bunk_beds'], 0) != 0 && <LodgingCountBox flex={!isMobile ? "50%" : "100%"} title={generatePhrase(extractDataFromArray(lodging_type_data, ['total_bunk_beds'], 0), " Bunk Bed")} />}
                        {extractDataFromArray(lodging_type_data, ['total_king_beds'], 0) != 0 && <LodgingCountBox flex={!isMobile ? "50%" : "100%"} title={generatePhrase(extractDataFromArray(lodging_type_data, ['total_king_beds'], 0), " King Bed")} />}
                        {extractDataFromArray(lodging_type_data, ['total_queen_beds'], 0) != 0 && <LodgingCountBox flex={!isMobile ? "50%" : "100%"} title={generatePhrase(extractDataFromArray(lodging_type_data, ['total_queen_beds'], 0), " Queen Bed")} />}
                        {extractDataFromArray(lodging_type_data, ['total_single_beds'], 0) != 0 && <LodgingCountBox flex={!isMobile ? "50%" : "100%"} title={generatePhrase(extractDataFromArray(lodging_type_data, ['total_single_beds'], 0), " Single Bed")} />}
                        {extractDataFromArray(lodging_type_data, ['total_sofa_beds'], 0) != 0 && <LodgingCountBox flex={!isMobile ? "50%" : "100%"} title={generatePhrase(extractDataFromArray(lodging_type_data, ['total_sofa_beds'], 0), " Sofa Bed")} />}
                    </Box>
                </CountBox>
                <CountBox>
                    <Icon.BathtubOutlined />

                    <Box sx={{ display: 'flex', flexWrap: 'wrap', width: "100%" }}>
                        {<Typography sx={{ display: 'flex', gap: 1, flex: '100%', width: '100%', mb: 1, fontWeight: 700 }} >{generatePhrase(extractDataFromArray(lodging_type_data, ['bathroom', 'total'], 0), 'Bathroom')}</Typography>}
                        {
                            isValueEqual(extractDataFromArray(lodging_type_data, ['bathroom', 'shower_available'], 'No'), "Yes") ?
                                <Typography color="grey" sx={{ display: 'flex', gap: 1, flex: { xs: '100%', sm: '50%' }, width: '100%', mb: 1 }}>Shower</Typography> : <></>
                        }
                        {
                            isValueEqual(extractDataFromArray(lodging_type_data, ['bathroom', 'hot_water_available'], 'No'), "Yes") ?
                                <Typography color="grey" sx={{ display: 'flex', gap: 1, flex: { xs: '100%', sm: '50%' }, width: '100%', mb: 1 }}>Hot Water</Typography> : <></>
                        }
                    </Box>
                </CountBox>
            </Box>
            <AvailableOptionsList
                header={ucFirst(lodging_type)}
                list={extractDataFromArray(lodging_type_data, ['amenities', 'list'], [])}
                tooltip={setValueIfEmpty(extractDataFromArray(lodging_type_data, ['amenities', 'additional_info'], ''), 'Amenities available for this property')}
            />
            {
                isValueEqual(extractDataFromArray(lodging_type_data, ['kitchen_area', 'available'], 'No'), 'Yes') ?
                    <AvailableOptionsList
                        header={"Kitchen"}
                        list={extractDataFromArray(lodging_type_data, ['kitchen_area', 'accessories'], [])}
                        tooltip={setValueIfEmpty(extractDataFromArray(lodging_type_data, ['kitchen_area', 'additional_info'], ''), 'Amenities available for this property')}
                    /> : <></>
            }
            {
                isValueEqual(extractDataFromArray(lodging_type_data, ['garbage_area', 'available'], 'No'), 'Yes') ?
                    <AvailableOptionsList
                        header={"Garbage Area"}
                        list={extractDataFromArray(lodging_type_data, ['garbage_area', 'garbage_area'], [])}
                        tooltip={setValueIfEmpty(extractDataFromArray(lodging_type_data, ['garbage_area', 'additional_info'], ''), 'Amenities available for this property')}
                    /> : <></>
            }
            {
                isValueEqual(extractDataFromArray(lodging_type_data, ['drinking_water', 'available'], 'No'), 'Yes') ?
                    <AvailableOptionsList
                        header={"Drinking Water"}
                        list={extractDataFromArray(lodging_type_data, ['drinking_water', 'sources'], [])}
                        tooltip={setValueIfEmpty(extractDataFromArray(lodging_type_data, ['drinking_water', 'additional_info'], ''), 'Amenities available for this property')}
                    /> : <></>
            }
            {
                !empty(extractDataFromArray(lodging_type_data, ['overall_lodging_experience'], '')) ?
                    <>
                        <Typography sx={{ fontWeight: 500 }}>{ucFirst(lodging_type)} Description :</Typography>
                        <Typography color="grey">{extractDataFromArray(lodging_type_data, ['overall_lodging_experience'], '')}</Typography>
                    </> : <></>
            }
        </>
    )
}

export default Lodging;