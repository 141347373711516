import { Box, Divider, FormHelperText, IconButton, MenuItem, Select, TextField, Typography } from "@mui/material"
import * as Icon from '@mui/icons-material';
import GreyLabel from "../formElement/GreyLabel";
import { useEffect, useState } from "react";
import { empty, extractDataFromArray, toastMessage, validateJSON } from "../../utils/common";
import ListingService from "../../services/listing";
import LoaderButton from "../LoaderButton";
import errorAndHelperText from "../CommonConstants/errorAndHelperText.json"

const validationSchema = {
    reason: {
        type: 'string',
        required: true,
        requiredMsg: 'Reason is required',
    },
    remark: {
        type: 'string',
        required: true,
        requiredMsg: 'Field is required',
        max: 5000,
        maxMsg: "Maximum 5000 characters allowed.",
    },
    otherReason: {
        type: 'string',
        required: true,
        requiredMsg: 'Reason is required',
        checkIf: {
            field: 'reason',
            is: 'Others',
        },
    },

}

const SpamContent = ({ handleClose, listing_seq, listing_type, setCurrentStatus }) => {
    const [reasonOptions, setReasonOptions] = useState([])
    const [declineData, setDeclineData] = useState({ reason: '*', remark: '', otherReason: '' })
    const [errors, setErrors] = useState('')
    const [isLoading, setisLoading] = useState(false)
    const handleClick = () => {
        let errorArray = validateJSON(declineData, validationSchema)
        setErrors(errorArray)
        if (!empty(errorArray)) {
            return;
        }
        setisLoading(true)
        ListingService.spamListing(listing_seq, declineData.reason, declineData.otherReason, declineData.remark, listing_type, (data) => {
            // toast.success("Verification status updated.", { theme: 'colored' })
            toastMessage(errorAndHelperText.verification_status_update.success)
            setCurrentStatus('S')
            handleClose()
            setisLoading(false)
        })
    }
    const getReasons = () => {
        ListingService.getSpamReasons((data) => {
            setReasonOptions(data)
        })
    }


    useEffect(() => {
        getReasons()
    }, [])


    return (
        <Box sx={{ width: '100%' }}>
            <Box sx={{ py: 1.5, px: 2.5, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6" sx={{ fontWeight: 700 }}> Spam Listing</Typography>
                <IconButton onClick={handleClose}><Icon.Close /> </IconButton>
            </Box>
            <Divider />
            <Box sx={{ p: 2.5, width: "600px" }}>
                <GreyLabel label="Spam Reason" required></GreyLabel>
                <Select
                    fullWidth
                    size="small"
                    value={declineData.reason}
                    onChange={(e) => {
                        setDeclineData({ ...declineData, reason: e.target.value })
                    }}
                    IconComponent={Icon.KeyboardArrowDownOutlined}
                    sx={{ mb: 3 }}
                >
                    <MenuItem key={"*"} value={"*"} sx={{ display: "none" }}>Mention a reason for spam</MenuItem>
                    {
                        Array.isArray(reasonOptions) && reasonOptions.map(reason => (
                            <MenuItem key={reason} value={reason}>{reason}</MenuItem>
                        ))
                    }
                </Select>
                {
                    !empty(extractDataFromArray(errors, 'reason', '')) &&
                    <FormHelperText sx={{ marginLeft: 0 }} error={true}>{extractDataFromArray(errors, 'reason', '')}</FormHelperText>
                }
                {
                    declineData.reason === 'Others' && <>
                        <GreyLabel label="Other Reason" required></GreyLabel>
                        <TextField
                            fullWidth
                            value={declineData.otherReason}
                            onInput={(e) => {
                                setDeclineData({ ...declineData, otherReason: e.target.value })
                                setErrors('')
                            }}
                            sx={{
                                mb: 3
                            }}
                            error={errors.otherReason ? true : false}
                            helperText={errors.otherReason ? errors.otherReason : ''}
                        ></TextField>
                    </>
                }
                <GreyLabel label="Remark" required></GreyLabel>
                <TextField
                    multiline
                    rows={5}
                    fullWidth
                    value={declineData.remark}
                    onInput={(e) => {
                        setDeclineData({ ...declineData, remark: e.target.value })
                        setErrors('')
                    }}
                    sx={{
                        mb: 3
                    }}
                    error={errors.remark ? true : false}
                    helperText={errors.remark ? errors.remark : ''}
                ></TextField>
                <LoaderButton
                    variantType={'contained'}
                    buttonColor={'secondary'}
                    sx={{ ":hover": { backgroundColor: "grey" } }}
                    onClick={handleClick}
                    isLoading={isLoading}
                    isFullWidth={true}
                    buttonText={'Spam'}
                />
            </Box>
        </Box>
    )
}

export default SpamContent;