import {
    Box,
    IconButton,
    InputBase,
    Paper,
    Typography,
} from "@mui/material";
import * as Icon from "@mui/icons-material";
import { useEffect, useState } from "react";
import { MessagesApi } from "../../../services/messagesApi";
import { useParams } from "react-router-dom";
import ConnectionList from "./ConnectionList";
import { MessageContext } from "../../../store/context/messageContext";
import { extractDataFromArray } from "../../../utils/common";

const MessagesDashboard = ({
    setResetScroll,
    setFetchUserhMessages,
    fetchUserMessages,
    setSearch,
    search,
    connections,
    setConnections,
    active,
    setActive,
    setToggleNewMessage,
    toggleNewMessage,
    setLoadingUser,
    isComingFromContactPage
}) => {

    // useEffect(()=>{
    //     console.log("rendered",isComingFromContactPage);
    // },[])

    const {
        randomNumber, isMobile, newMessageReceivedStatus, setProfilePhoto, setuserDetail, hideMessages, heightVariable
    } =
        MessageContext();

    const param = useParams();
    const [config, setConfig] = useState({
        start: 0,
        limit: ""
    });


    const fetchConnections = () => {

        MessagesApi.getAllMessages(search, config.limit, config.start, (data) => {

            setConfig((prev) => {
                return { ...config, limit: prev.limit + config.limit }
            })

            for (const item of data) {

                if (item.conversation && extractDataFromArray(item, ['conversation', 'seq'], '') === param.seq) {
                    setProfilePhoto(item.conversation.profile_pic);
                    break;
                }
            }

            setConnections([...data])

        }, (error) => {
            // console.log(error)
        });
    };


    useEffect(() => {
        fetchConnections();
    }, [search]);


    useEffect(() => {

        if (newMessageReceivedStatus === true || toggleNewMessage) {

            fetchConnections();
            setToggleNewMessage(false)

        }
    }, [newMessageReceivedStatus, toggleNewMessage, randomNumber, hideMessages])

    return (
        <Box
            className="MESSAGES"
            sx={
                isMobile
                    ? {
                        borderRadius: 4.5,
                        border: "1px solid #E5E7EB",
                        textAlign: "left",
                        overflow: "hidden",
                        // height: "calc(100svh - 146px)", // commented out after search box was hidden,  using svh instead of vh for mobile view
                        height: "calc(100svh - 247px)",     //Changed height 180px to 247px after footer was added 

                    }
                    : {
                        width: 327,
                        borderRadius: 4.5,
                        border: "1px solid #E5E7EB",
                        // height: "calc(100vh - 196px)", using svh instead of vh for mobile view
                        // height: "calc(100svh - 290px)",  // Changed height 196px (After search box was hidden) to 263px then to 290px after footer was added (Before Dec 9 2024)
                        height: heightVariable, // Added this after footer was added to fix messages not showing on smaller desktop devices (Dec 9 2024)
                        textAlign: "left",
                        overflow: "hidden",
                    }
            }
        >
            {/* Hidden until backend search issue is fixed */}
            {/* <Box
                sx={{
                    padding: 2,
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                }}
            >
                <Box
                    sx={
                        isMobile && connections.length === 0
                            ? { display: "flex" }
                            : { display: "flex" }
                    }
                >
                    <Paper
                        component="form"
                        variant="outlined"
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            width: "100%",
                            height: 42,
                            borderRadius: 1.5,
                            border: "1px solid #C5C5C7",
                            justifyContent: "space-between",
                            paddingLeft: 1.625,
                        }}
                    >
                        <InputBase
                            sx={{
                                fontSize: "16px",
                            }}
                            placeholder="Search"
                            inputProps={{ "aria-label": "Search Customer" }}
                            value={search}
                            onInput={(e) => {
                                setSearch(e.target.value);
                            }}
                            onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                    e.preventDefault();
                                }
                            }}
                        />
                        <IconButton type="button" sx={{ p: 1.25 }} aria-label="search" onClick={() => { setSearch(search) }}>
                            <Icon.Search />
                        </IconButton>
                    </Paper>
                </Box>
            </Box> */}
            {isMobile && connections.length === 0 ? (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        // height: "calc(100vh - 250px)",  using svh instead of vh for mobile view
                        height: "calc(100svh - 250px)",
                        width: "100%",
                    }}
                >
                    {/* <img
                        src={"chat_image.svg"}
                        alt="message"
                        width={241}
                        height={133}
                    /> */}
                    <Typography variant="body1" sx={{ fontWeight: 700 }} >No Results Found</Typography>
                </Box>
            ) : (
                <Box
                    className="thinScrollBar"
                    sx={
                        isMobile
                            ? {
                                display: "flex",
                                flexDirection: "column",
                                overflowY: "auto",
                                overflowX: "hidden",
                                // height: "calc(100svh - 220px)", // originally vh unit was usded instead of svh and it was Removed to sync with messages dashboard height in mobile view,  using svh instead of vh for mobile view
                                height: "calc(100svh - 180px)", // Added this after search box hidden
                                scrollbarWidth: "thin",
                                scrollbarColor: "auto",
                            }
                            : {
                                display: "flex",
                                flexDirection: "column",
                                overflowY: "auto",
                                overflowX: "hidden",
                                // height: "calc(100vh - 268px)", // using svh instead of vh for mobile view
                                height: heightVariable, // Changed 268px to 286px (Before Dec 9 2024) then to 192px (Dec 9 2024) fix last connection not showing on desktop issue after footer was added 
                                scrollbarWidth: "thin",
                                scrollbarColor: "auto",
                            }
                    }
                >
                    {
                        connections.length > 0 && connections?.map((conversation, i) => {
                            const isLastConnection = i === connections.length - 1;
                            return (
                                <ConnectionList
                                    setResetScroll={setResetScroll}
                                    search={search}
                                    setFetchUserhMessages={setFetchUserhMessages}
                                    fetchUserMessages={fetchUserMessages}
                                    key={i}
                                    index={i}
                                    conversation={conversation}
                                    setActive={setActive}
                                    setuserDetail={setuserDetail}
                                    i={i}
                                    active={active}
                                    setSearch={setSearch}
                                    fetchConnections={fetchConnections}
                                    setLoadingUser={setLoadingUser}
                                    isLastConnection={isLastConnection}
                                    connections={connections}
                                />
                            );
                        })
                    }

                </Box>
            )}
        </Box>
    );
};

export default MessagesDashboard;
