import { Box, Divider, Typography } from "@mui/material";
import * as Icon from '@mui/icons-material';
import { empty, extractDataFromArray, isValueEqual } from "../../utils/common";
import { styled } from '@mui/material/styles';
import CustomTooltip from "../CustomTooltip";


const ColumnBox = styled(Box)(
	({ theme }) => `
        display: flex;
        gap: 0.5rem;
        flex: 50%;
        ${theme.breakpoints.down("sm")} {
            flex: 100%;
          }
        width: 100%;
        margin-bottom: 0.5rem;
  `,
);


const Sources = ({ title, icon, sources = [], tooltip }) => {

	return (
		<>
			<Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }} >
				{icon}
				<Typography sx={{ fontWeight: 700 }}> {title} </Typography>
				{
					!empty(tooltip) && <CustomTooltip content={tooltip} />
				}
			</Box>
			<Box sx={{ display: 'flex', flexWrap: 'wrap', mb: 2 }}>
				{
					sources.map((source, i) => {
						return (
							<ColumnBox key={title + "_" + i}>
								<Icon.ArrowForward />
								<Typography sx={{color:'var(--grey900)'}}>{source}</Typography>
							</ColumnBox>
						)
					})
				}
			</Box>
		</>
	)
}

const AddOns = ({ title, icon, value, tooltip }) => {

	return (
		<>
			<ColumnBox sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }} >
				{icon}
				<Typography sx={{ fontWeight: 700 }}> {title}: </Typography>
				<Typography> {value}</Typography>
				{
					!empty(tooltip) && <CustomTooltip content={tooltip} />
				}

			</ColumnBox>

		</>
	)
}

const LandDetails = ({ data, activity }) => {
	let isDetailsAvailable = isValueEqual(extractDataFromArray(data, ['food_sources', 'available'], 'No'), 'Yes') &&
		isValueEqual(extractDataFromArray(data, ['water_sources', 'available'], 'No'), 'Yes') &&
		isValueEqual(extractDataFromArray(data, ['shelter_sources', 'available'], 'No'), 'Yes') &&
		isValueEqual(extractDataFromArray(data, ['food_plots_available', 'available'], 'No'), 'Yes')

	if (!isDetailsAvailable) {
		return <></>
	}
	return (
		<>
			<Typography variant="h5" fontWeight={600}>
				Land Details
			</Typography>
			<Divider sx={{ mb: 3, mt: 2 }} />
			<Box>
				{
					isValueEqual(extractDataFromArray(data, ['food_sources', 'available'], 'No'), 'Yes') &&
					<Sources
						title={"Food"}
						icon={<Icon.Grass />}
						sources={extractDataFromArray(data, ['food_sources', 'sources'], [])}
						tooltip={extractDataFromArray(data, ['food_sources', 'additional_info'], '')}
					/>
				}
				{
					isValueEqual(extractDataFromArray(data, ['water_sources', 'available'], 'Yes'), 'Yes') &&
					<Sources
						title={"Water"}
						icon={<Icon.WaterDropOutlined />}
						sources={extractDataFromArray(data, ['water_sources', 'sources'], [])}
						tooltip={extractDataFromArray(data, ['water_sources', 'additional_info'], '')}
					/>
				}
				{
					isValueEqual(extractDataFromArray(data, ['shelter_sources', 'available'], 'No'), 'Yes') &&
					<Sources
						title={"Shelter"}
						icon={<Icon.NightShelterOutlined />}
						sources={extractDataFromArray(data, ['shelter_sources', 'sources'], [])}
						tooltip={extractDataFromArray(data, ['shelter_sources', 'additional_info'], '')}
					/>
				}
				{
					isValueEqual(activity, 'H') &&
					<Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }} >
						<Icon.RestaurantMenu />
						<Typography sx={{ fontWeight: 700 }}> Food Plot: </Typography>
						<Typography sx={{color:'var(--grey900)'}} > {isValueEqual(extractDataFromArray(data, ['food_plots_available'], 'No'), 'Yes') ? "Available" : 'N/A'}</Typography>
					</Box>
				}
			</Box>
		</>
	)
}

export default LandDetails;
