import { Avatar, Box, Divider, Typography, Button } from "@mui/material";
import * as Icon from "@mui/icons-material";
import { useLocation, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import ExpereinceOverviewImages from "./expOverviewImages";
import { empty, extractDataFromArray, generatePhrase } from "../../../utils/common";
import ExperienceService from "../../../services/experience";
import { ExperienceContext } from "../../../store/context/experienceContext";
import { CommonContext } from "../../../store/context/commonContext";
import config from "../../../config";
import { RESPONSIVE_SPACING } from "../../CommonConstants/commonKeys";


const AboutHost = ({ formData, isMobileButtonClicked = false }) => {

    const { experienceData, experienceSequence } = ExperienceContext()
    const { setOpenExpEnquiryPopup } = CommonContext()

    const [listerData, setListerData] = useState(extractDataFromArray(experienceData, ['lister'], null))
    const [listerReview, setlisterReview] = useState({});
    const [litsterRating, setLitsterRating] = useState({});
    const { screenWidth } = CommonContext();
    const { exp_seq } = useParams()
    const location = useLocation()


    const [name, setName] = useState('')

    useEffect(() => {

        if (!listerData) {
            if (location.pathname.includes('createExperience')) {
                const expereinceSeq = experienceSequence
                ExperienceService.getExperiencePackageData(expereinceSeq, (data) => {
                    setListerData(extractDataFromArray(data, ['lister'], {}))
                })
                return
            } else {
                let expereinceSeq = exp_seq.split("-")
                expereinceSeq = expereinceSeq[expereinceSeq.length - 1]
                ExperienceService.getExperiencePackageData(expereinceSeq, (data) => {
                    setListerData(extractDataFromArray(data, ['lister'], {}))
                })
            }

        }
    }, [experienceSequence])

    useEffect(() => {
        setlisterReview(extractDataFromArray(listerData, ['review_count'], 0))
        setLitsterRating(extractDataFromArray(listerData, ['average_rating'], 0))
        setName(`${extractDataFromArray(listerData, ['fname'], '')} ${extractDataFromArray(listerData, ['lname'], '')}`)
    }, [listerData])


    const handleOpen = () => {
        // setOpen(true);
        setOpenExpEnquiryPopup(true);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: isMobileButtonClicked ? 2 : RESPONSIVE_SPACING }}>
            <Typography variant="h5" sx={{ fontWeight: '600', fontSize: isMobileButtonClicked && '1.25rem' }}>
                About Host
            </Typography>
            <Box
                sx={{
                    display: 'flex',
                    gap: 2,
                    justifyContent: 'space-between',
                    flexDirection: screenWidth <= 400 ? 'column' : 'row',
                }}
            >
                <Box sx={{
                    display: 'flex',
                    gap: 2,
                    width: '100%'
                }}>
                    <Avatar
                        src={extractDataFromArray(listerData, ['profile_pic'], '')}
                        sx={{
                            // ...theme.typography.mediumAvatar,
                            width: '75px',
                            height: '75px',
                            cursor: 'pointer'
                        }}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', flexWrap: 'wrap', gap: 1 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <Typography sx={{ fontSize: '12px', color: 'grey' }}>Hosted by</Typography>
                            <Typography variant="h6" sx={{ fontWeight: 700 }}>{name}</Typography>
                            {
                                !empty(listerReview) &&
                                <Box sx={{ display: "flex", gap: 1, }}>
                                    <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
                                        <Icon.Star sx={{ color: "#F7C32E" }} />
                                        <Typography variant="body1" fontWeight={'bold'}>
                                            {parseFloat(litsterRating).toFixed(1)}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                                        <Typography variant="body1" color='grey300'>
                                            {generatePhrase(listerReview, 'review')}
                                        </Typography>
                                    </Box>
                                </Box>
                            }
                        </Box>
                        {config.module === 'guest' && <Box
                            sx={{
                                display: 'flex',
                                gap: 2,
                                alignItems: 'end'

                            }}
                        >
                            <Button variant="light" onClick={handleOpen} sx={{ pl: 1.5, pr: 1.5, pt: 1, pb: 1, fontSize: "0.875rem", fontWeight: 600, }} >{"Contact " + extractDataFromArray(listerData, ['fname'], '')}</Button>
                        </Box>
                        }
                    </Box>
                </Box>
            </Box>
            {extractDataFromArray(formData, ['about_host'], '') !== '' && <Typography variant="subtitle1" sx={{ color: 'var(--textGrey)' }}>
                {extractDataFromArray(formData, ['about_host'], '')}
            </Typography>}
            {extractDataFromArray(formData, ['certificate_list'], []).length > 0 && <ExpereinceOverviewImages images={extractDataFromArray(formData, ['certificate_list'], [])} />}
        </Box>
    )
}

export default AboutHost;
