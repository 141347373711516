import axios from "axios";
import config from "../config/index";
import { AUTH_TOKEN } from "../constants/page";
import { extractDataFromArray, parseResponseAxios, toastMessage } from "../utils/common";
import errorAndHelperText from "../commonComponents/CommonConstants/errorAndHelperText.json"

export const MessagesApi = {
  getAllMessages: async (search = "", limit, start, successCB = () => { }, errorCB = () => { }) => {

    return axios
      .get(config.apiUrl + `/services/api`, {
        params: {
          // Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
          cmd: "getAllMessages",
          kw: search,
          limit: limit,
          start: start
        },
      })
      .then((response) => {
        // console.log("extracted", response)
        response = extractDataFromArray(response, ['data'], [])
        //  response = parseResponseAxios(response)
        if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
          // const responseArray = extractDataFromArray(response, ['records'], [])
          const responseArray = parseResponseAxios(response)
          // console.log("responseArray", responseArray)
          // console.log("filtered", responseArray.records)
          let array = extractDataFromArray(responseArray, ['records'], [])
          // console.log("array", array)
          successCB(array);
        }

      })
      .catch((err) => {
        errorCB(err);
      });
  },
  getUserInfo: async (u_seq, type, successCB = () => { }, errorCB = () => { }) => {
    return axios.get(config.apiUrl + `/services/api`, {
      params: {
        // Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        cmd: "getUserInfo",
        u_seq: u_seq,
        type: type
      },
    })
      .then((response) => {
        response = parseResponseAxios(response)
        // console.log("User Info responce", response)

        if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
          const responseArray = extractDataFromArray(response, ['data'], [])
          // console.log("user Info 2222", responseArray) 

          const userInfo = config.module === "lister" ? extractDataFromArray(responseArray, ['guest'], []) : extractDataFromArray(responseArray, ['lister'], [])

          // console.log("user Info 3", userInfo)
          successCB(userInfo);
        }
      })
      .catch((err) => {
        errorCB(err);
      });
  },
  getUserMessages: async (sender_seq, limit, start, type, successCB = () => { }, errorCB = () => { }) => {
    // console.log("api called")
    // console.log("sender_seq api", sender_seq)
    return axios
      .get(config.apiUrl + `/services/api`, {
        params: {
          cmd: "getUserMessages",
          sender_seq: sender_seq,
          limit: limit,
          start: start,
          type: type
        },
      })
      .then((response) => {
        response = parseResponseAxios(response)
        // console.log("response in api file", response)

        if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
          const responseArray = extractDataFromArray(response, ['data'], [])

          let array = extractDataFromArray(responseArray, ['records'], [])

          // console.log("chat array in api", array)
          successCB(array);
        }

      })
      .catch((err) => {
        errorCB(err);
      });
  },
  getConnectionMessages: (receivername, successCallback, errorCallback) => {
    return axios
      .get(config.apiUrl + `/message/${receivername}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        },
      })
      .then((res) => {
        successCallback(res);
      })
      .catch((err) => {
        errorCallback(err);
      });
  },
  setMessage: (sendMessage, successCB = () => { }, errorCB = () => { }) => {
    // console.log("sent messages", message)
    // console.log("sendMessage", sendMessage)
    const formData = new FormData();
    formData.append('cmd', 'setMessage');
    formData.append('receiver_seq', sendMessage.receiver_seq);
    formData.append('receiver_type', sendMessage.receiver_type);
    formData.append('package_seq', sendMessage.package_seq);
    formData.append('message', sendMessage.message);
    formData.append('message_type', sendMessage.message_type);
    formData.append('attachments', sendMessage.attachments);
    formData.append('device', sendMessage.device);
    formData.append('ip', sendMessage.ip);
    // console.log("heyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyyy", JSON.stringify(sendMessage))
    return axios
      .post(config.apiUrl + `/services/api`, formData)
      .then((res) => {
        successCB(res);
      })
      .catch((err) => {
        errorCB(err);
      });
  },
  getNewMessageReceivedStatus: async (successCB = () => { }, errorCB = () => { }) => {
    return axios
      .get(config.apiUrl + `/services/api`, {
        params: {
          // Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
          cmd: "getNewMessageReceivedStatus",
        },
      })
      .then((response) => {
        // console.log("new user ", response)
        // response = extractDataFromArray(response, ['data'], [])
        //  response = parseResponseAxios(response)
        if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
          // const responseArray = extractDataFromArray(response, ['records'], [])
          const responseArray = parseResponseAxios(response)
          // console.log("responseArray", responseArray)
          // console.log("filtered", responseArray.records)
          let array = extractDataFromArray(responseArray, ['records'], [])
          // console.log("array", array)
          successCB(array);
        }

      })
      .catch((err) => {
        errorCB(err);
      });
  },
  sendMessageToConnection: (message, successCallback, errorCallback) => {
    return axios
      .post(config.apiUrl + "/message", JSON.stringify(message), {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        },
      })
      .then((res) => {
        if (res.data.errorCode === 1) {
          successCallback(res.data.errorMessage);
        } else {
          successCallback(res.data.message);
        }
      })
      .catch((err) => {
        errorCallback(err);
      });
  },
  getConnectionInfo: (user, successCallback, errorCallback) => {
    return axios
      .get(config.apiUrl + `/connection/${user}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
        },
      })
      .then((res) => {
        successCallback(res);
      })
      .catch((err) => {
        errorCallback(err);
      });
  },
  getNewUserMessages: async (message_seq, sender_seq, limit, successCB = () => { }, errorCB = () => { }) => {
    return axios
      .get(config.apiUrl + `/services/api`, {
        params: {
          cmd: "getNewUserMessages",
          message_seq: message_seq,
          sender_seq: sender_seq,
          limit: limit,
        },
      })
      .then((response) => {
        response = parseResponseAxios(response)
        // console.log("response in api file", response)

        if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
          const responseArray = extractDataFromArray(response, ['data'], [])

          let array = extractDataFromArray(responseArray, ['records'], [])

          // console.log("chat array in api", responseArray)

          successCB(array);
        }

      })
      .catch((err) => {
        errorCB(err);
      });
  },
  isChatHistory: async (successCB = () => { }, errorCB = () => { }) => {
    // console.log("api called")
    // console.log("sender_seq api", sender_seq)
    return axios
      .get(config.apiUrl + `/services/api`, {
        params: {
          cmd: "isChatHistoryAvailable",

        },
      })
      .then((response) => {
        response = parseResponseAxios(response)
        // console.log("isChatHistoryAvailable", response)

        if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
          const responseArray = extractDataFromArray(response, ['data'], [])

          // let array = extractDataFromArray(responseArray, ['data'], [])

          // console.log("isChatHistoryAvailable data responce", array)
          successCB(responseArray);
        }

      })
      .catch((err) => {
        errorCB(err);
      });
  }, getNewMessageReceivedStatus: async (successCB = () => { }, errorCB = () => { }) => {
    // console.log("api called")
    // console.log("sender_seq api", sender_seq)
    return axios
      .get(config.apiUrl + `/services/api`, {
        params: {
          cmd: "getNewMessageReceivedStatus",

        },
      })
      .then((response) => {
        response = parseResponseAxios(response)
        // console.log("isChatHistoryAvailable", response)

        if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
          const responseArray = extractDataFromArray(response, ['data'], [])

          // let array = extractDataFromArray(responseArray, ['data'], [])

          // console.log("isChatHistoryAvailable data responce", array)
          successCB(responseArray);
        }

      })
      .catch((err) => {
        errorCB(err);
      });
  }, getUserLastSeen: async (user_seq, successCB = () => { }, errorCB = () => { }) => {
    // console.log("api called")
    // console.log("sender_seq api", sender_seq)
    return axios
      .get(config.apiUrl + `/services/api`, {
        params: {
          cmd: "getUserLastSeen",
          user_seq: user_seq
        },
      })
      .then((response) => {
        // response = parseResponseAxios(response)
        // console.log("getUserLastSeen", response)

        if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
          const responseArray = extractDataFromArray(response, ['data'], [])
          // console.log("1 getUserLastSeen data responce", responseArray)
          let array = extractDataFromArray(responseArray, ['data'], [])

          // console.log("getUserLastSeen data responce", array)
          successCB(array);
        }

      })
      .catch((err) => {
        errorCB(err);
      });
  }, getOlderUserMessages: async (message_seq, sender_seq, limit, type, successCB = () => { }, errorCB = () => { }) => {
    return axios
      .get(config.apiUrl + `/services/api`, {
        params: {
          cmd: "getOlderUserMessages",
          message_seq: message_seq,
          sender_seq: sender_seq,
          limit: limit,
          type: type
        },
      })
      .then((response) => {
        response = parseResponseAxios(response)
        // console.log("getOlderUserMessages", response)

        if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
          const responseArray = extractDataFromArray(response, ['data'], [])

          let array = extractDataFromArray(responseArray, ['records'], [])

          // console.log("getOlderUserMessages chat array in api", array)
          successCB(array);
        }

      })
      .catch((err) => {
        errorCB(err);
      });
  },
  getNewMessageReceivedStatus: async (successCB = () => { }, errorCB = () => { }) => {
    return axios
      .get(config.apiUrl + `/services/api`, {
        params: {
          // Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
          cmd: "getNewMessageReceivedStatus",
        },
      })
      .then((response) => {
        // console.log("new user ", response)
        // response = extractDataFromArray(response, ['data'], [])
        //  response = parseResponseAxios(response)
        if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
          // const responseArray = extractDataFromArray(response, ['records'], [])
          const responseArray = parseResponseAxios(response)

          // console.log("filtered", responseArray.records)
          let array = extractDataFromArray(responseArray, ['data'], [])
          // console.log("array", array)
          successCB(array);
        }

      })
      .catch((err) => {
        errorCB(err);
      });
  }, getNewUserMessageReceivedStatus: (senderSeqToSendChat, successCB = () => { }, errorCB = () => { }) => {
    // console.log("sender_seq", senderSeqToSendChat)
    return axios
      .get(config.apiUrl + `/services/api`, {
        params: {
          // Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
          cmd: "getNewUserMessageReceivedStatus",
          u_seq: senderSeqToSendChat
        },
      })
      .then((response) => {
        // console.log("getNewUserMessageReceivedStatus in api ", response)
        // response = extractDataFromArray(response, ['data'], [])
        //  response = parseResponseAxios(response)
        if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
          // const responseArray = extractDataFromArray(response, ['records'], [])
          const responseArray = parseResponseAxios(response)
          // console.log("responseArray", responseArray)
          // console.log("filtered", responseArray.records)
          let array = extractDataFromArray(responseArray, ['data'], [])
          // console.log("array", array)
          successCB(array);
        }

      })
      .catch((err) => {
        errorCB(err);
      });
  },
  sendEnquiry: async (data, successCB = () => { }, errorCB = () => { }) => {
    const formData = new FormData();
    formData.append('cmd', 'sendEnquiry');
    formData.append('package_seq', data.package_seq);
    formData.append('lister_seq', data.lister_seq);
    formData.append('guest_count', data.guest_count);
    formData.append('check_in_date', data.check_in_date);
    formData.append('check_out_date', data.check_out_date);
    formData.append('message', data.message);
    formData.append('package_type', data.package_type)
    return axios
      .post(config.apiUrl + `/services/api`, formData)
      .then((res) => {
        res = parseResponseAxios(res);
        if (extractDataFromArray(res, ['errorCode'], 0) === 0) {
          // toast.success("Enquiry sent successfully.", { theme: 'colored' })
          toastMessage(errorAndHelperText.enquiry_sent.success);
          const data = extractDataFromArray(res, ['data'], []);
          successCB(data);
        } else {
          // toast.error(extractDataFromArray(res, ['errorMsg'], 0), {
          //   theme: 'colored'
          // })
          toastMessage({
            message: extractDataFromArray(res, ['errorMsg'], 0),
            type: 'error',
          });
        }
      });
  }
};




