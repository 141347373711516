import { Box, Divider, Typography } from "@mui/material"
import { capitalizeWords } from "../../utils/common";

const OptionalAddOns = ({ optionalAddOns }) => {
    return (
        <Box>
            <Typography variant="h5" fontWeight={600} sx={{ mb: 1 }}>Optional Add Ons</Typography>
            <Typography variant="body1" sx={{ color: 'grey' }} >Optional add-on facilities, such as lodging, enhance your stay. You can add these at the time of booking, as required.</Typography>

            <Divider sx={{ my: 3 }} />
            <Box sx={{ mb: 7, p: 2, backgroundColor: 'var(--grey300)', borderRadius: 2 }}>
                {
                    optionalAddOns.map((option) => {
                        return (
                            !option.is_mandatory &&
                            <Typography>{capitalizeWords(option.caption)} ${option.amount} / Night</Typography>
                        )
                    })
                }
            </Box>
        </Box>
    )
}

export default OptionalAddOns;