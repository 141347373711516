import { Box, Button, IconButton, Skeleton, Typography } from "@mui/material";
import * as Icon from "@mui/icons-material";
import { useEffect, useState } from "react";
import { CommonContext } from "../../store/context/commonContext";
import { useNavigate } from "react-router";
import { Link, useSearchParams } from "react-router-dom";
import BookingService from "../../services/bookings";
import { extractDataFromArray } from "../../utils/common";


const ReviewSuccessFull = () => {
    const { isTablet, setReviewPresent } = CommonContext();
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();

    const [loading, setLoading] = useState(true)
    const bookingId = searchParams.get('bookingId')
    const [bookingData, setBookingData] = useState({})
    const [slug, setSlug] = useState('')
    const [packageType, setPackageType] = useState('')
    const [path, setPath] = useState('')

    console.log("successfullll", bookingId);

    useEffect(() => {
        BookingService.getBookingDetails(bookingId, (data) => {
            // setLoaderButton(false)
            console.log("data", data);
            setBookingData(data)
        }, (errorMessage) => {

            // navigate("/404")
        })
    }, [bookingId])

    useEffect(() => {
        console.log("bookinmg data state", bookingData);
        console.log("extractDataFromArray(bookingData, ['package_type'])", extractDataFromArray(bookingData, ['package_type']));
        setPackageType(extractDataFromArray(bookingData, ['package_type'], ''))
        if (extractDataFromArray(bookingData, ['package_type']) === 'experience') {
            setPath(`/experience/${extractDataFromArray(bookingData, ['package', 'experience_data', 'general_info_01ab', 'slug'])}-${extractDataFromArray(bookingData, ['package', 'seq'])}`)
        } else {
            setPath(`/package/${extractDataFromArray(bookingData, ['package', 'slug'])}-${extractDataFromArray(bookingData, ['package', 'seq'])}`)
        }
    }, [bookingData])

    useEffect(() => {
        console.log("pathhhh", path);
    }, [path])


    return (
        <>
            <Box sx={{ display: 'flex', gap: 2, alignItems: { xs: 'flex-start', sm: 'flex-start', md: 'center' }, mb: { xs: 1, sm: 1, md: 3, lg: 3 }, justifyContent: "space-between", flexDirection: isTablet ? "column" : "row" }}>
                <Box sx={{ display: "flex", gap: 2, }}>
                    <IconButton sx={{ color: "#000000", p: 0 }} onClick={() => { navigate(`/booking/${bookingId}`) }}> <Icon.ArrowBack /></IconButton>
                    <Typography variant="h4" >
                        Submit Your Review
                    </Typography>
                </Box>
            </Box>
            <Box>
                <Box
                    sx={{
                        background: '#E0F7EC',
                        borderRadius: 4,
                        padding: 3,
                    }}
                >
                    <Box
                        sx={{
                            mb: 2
                        }}
                    >
                        <Typography variant="h5" sx={{ color: '#26C77A', fontWeight: 600 }} >Thanks for your review.</Typography>
                        <Typography variant="body1" sx={{ color: '#1B1B1B' }}>You can see your review on the Package details page</Typography>
                    </Box>
                    <Link
                        to={path}
                    >
                        <Button
                            variant='contained'
                            size="small"
                            sx={{
                            }}
                        >
                            View Package
                        </Button>
                    </Link>
                </Box>
            </Box>
        </>
    )
};

export default ReviewSuccessFull;