import { Box, Button, CardMedia, Dialog, Grid, IconButton, Typography, } from "@mui/material";
import * as Icon from "@mui/icons-material";
import { styled } from '@mui/material/styles';


import { useEffect, useState } from "react";

// core version + navigation, pagination modules:
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import { Pagination } from 'swiper/modules';
// import Swiper and modules styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';


import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';

import { CommonContext } from "../../../store/context/commonContext";
import { empty, extractDataFromArray, toastMessage } from "../../../utils/common";
import config from "../../../config";
import { UserAuth } from "../../../store/context/userContext";
import WishlistService from "../../../services/wishlist";
import ShareButton from "../../ShareButton";



const ImageBox = styled(Box)(
    ({ theme }) => `
        border-radius: ${theme.card.borderRadius};
        overflow: hidden;
        width: 100%;
        background-repeat: no-repeat;
        background-position: center center;
        background-size : cover;
        cursor : pointer;
  `,
);

const GalleryGrid = ({ images, handleOpen }) => {

    return (
        <Grid container sx={{}} spacing={1}>
            {
                images.length === 1 &&
                <>
                    <Grid item md={12} sm={12} xs={12} >
                        {
                            extractDataFromArray(images, [0, 'type'], '') === 'IMAGE'
                                // false
                                ?
                                <ImageBox
                                    onClick={() => handleOpen(0)}
                                    sx={{
                                        height: '400px',
                                        backgroundImage: "url('" + extractDataFromArray(images, [0, 'url'], '') + "')",
                                    }}
                                />
                                :
                                <>
                                    {
                                        extractDataFromArray(images, [0, 'thumbnail_url'], '') !== ''
                                            // true
                                            ?
                                            <div className='' onClick={() => handleOpen(0)} style={{ position: 'relative', display: 'flex' }}>
                                                <div className='' style={{ position: 'absolute', cursor: 'pointer', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: '16px' }}>
                                                    <PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '4rem', width: '4rem' }} />
                                                </div>
                                                <ImageBox
                                                    onClick={() => handleOpen(0)}
                                                    sx={{
                                                        height: '400px',
                                                        backgroundImage: "url('" + extractDataFromArray(images, [0, 'thumbnail_url'], '') + "')",
                                                    }}
                                                />
                                            </div>
                                            :
                                            <div className='' onClick={() => handleOpen(0)} style={{ position: 'relative', display: 'flex' }}>
                                                <div className='' style={{ position: 'absolute', cursor: 'pointer', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: '16px' }}>
                                                    <PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '4rem', width: '4rem' }} />
                                                </div>
                                                <CardMedia
                                                    component={'video'}
                                                    sx={{
                                                        height: '400px',
                                                        objectFit: 'cover',
                                                        borderRadius: '16px !important'
                                                    }}
                                                    image={extractDataFromArray(images, [0, 'url'], '')}

                                                />
                                            </div>
                                    }
                                </>
                        }
                    </Grid>
                </>
            }
            {
                images.length === 2 &&
                <>
                    <Grid item md={6} sm={6} xs={12}>
                        {extractDataFromArray(images, [0, 'type'], '') === 'IMAGE'
                            ?
                            <ImageBox
                                onClick={() => handleOpen(0)}
                                sx={{
                                    height: '400px',
                                    backgroundImage: "url('" + extractDataFromArray(images, [0, 'url'], '') + "')",
                                }}
                            />
                            :
                            <>
                                {
                                    extractDataFromArray(images, [0, 'thumbnail_url'], '') !== ''
                                        // true
                                        ?
                                        <div className='' onClick={() => handleOpen(0)} style={{ position: 'relative', display: 'flex' }}>
                                            <div className='' style={{ position: 'absolute', cursor: 'pointer', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: '16px' }}>
                                                <PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '4rem', width: '4rem' }} />
                                            </div>
                                            <ImageBox
                                                onClick={() => handleOpen(0)}
                                                sx={{
                                                    height: '400px',
                                                    backgroundImage: "url('" + extractDataFromArray(images, [0, 'thumbnail_url'], '') + "')",
                                                }}
                                            />
                                        </div>
                                        :
                                        <div className='' onClick={() => handleOpen(0)} style={{ position: 'relative', display: 'flex' }}>
                                            <div className='' style={{ position: 'absolute', cursor: 'pointer', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: '16px' }}>
                                                <PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '4rem', width: '4rem' }} />
                                            </div>
                                            <CardMedia
                                                component={'video'}
                                                sx={{
                                                    height: '400px',
                                                    objectFit: 'cover',
                                                    borderRadius: '16px !important'
                                                }}
                                                image={extractDataFromArray(images, [0, 'url'], '')}

                                            />
                                        </div>
                                }
                            </>
                        }
                    </Grid>
                    <Grid sx={{ display: 'flex' }} item md={6} sm={6} xs={12}>
                        {extractDataFromArray(images, [1, 'type'], '') === 'IMAGE'
                            ?
                            <ImageBox
                                onClick={() => handleOpen(1)}
                                sx={{
                                    height: '400px',
                                    backgroundImage: "url('" + extractDataFromArray(images, [1, 'url'], '') + "')",
                                }}
                            />
                            :
                            <>
                                {
                                    extractDataFromArray(images, [1, 'thumbnail_url'], '') !== ''
                                        // true
                                        ?
                                        <div className='' onClick={() => handleOpen(1)} style={{ position: 'relative', display: 'flex', width: '100%' }}>
                                            <div className='' style={{ position: 'absolute', cursor: 'pointer', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: '16px' }}>
                                                <PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '4rem', width: '4rem' }} />
                                            </div>
                                            <ImageBox
                                                onClick={() => handleOpen(1)}
                                                sx={{
                                                    height: '400px',
                                                    backgroundImage: "url('" + extractDataFromArray(images, [1, 'thumbnail_url'], '') + "')",
                                                }}
                                            />
                                        </div>
                                        :
                                        <div className='' onClick={() => handleOpen(1)} style={{ position: 'relative', display: 'flex', width: '100%' }}>
                                            <div className='' style={{ position: 'absolute', cursor: 'pointer', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: '16px' }}>
                                                <PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '4rem', width: '4rem' }} />
                                            </div>
                                            <CardMedia
                                                component={'video'}
                                                sx={{
                                                    height: '400px',
                                                    objectFit: 'cover',
                                                    borderRadius: '16px !important'
                                                }}
                                                image={extractDataFromArray(images, [1, 'url'], '')}

                                            />
                                        </div>
                                }
                            </>
                        }
                    </Grid>
                </>
            }
            {
                images.length === 3 &&
                <>
                    <Grid item md={6} sm={12} xs={12}>
                        {extractDataFromArray(images, [0, 'type'], '') === 'IMAGE'
                            ?
                            <ImageBox
                                onClick={() => handleOpen(0)}
                                sx={{
                                    height: '400px',
                                    backgroundImage: "url('" + extractDataFromArray(images, [0, 'url'], '') + "')",
                                }}
                            />
                            :
                            <>
                                {
                                    extractDataFromArray(images, [0, 'thumbnail_url'], '') !== ''
                                        // true
                                        ?
                                        <div className='' onClick={() => handleOpen(0)} style={{ position: 'relative', display: 'flex' }}>
                                            <div className='' style={{ position: 'absolute', cursor: 'pointer', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: '16px' }}>
                                                <PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '4rem', width: '4rem' }} />
                                            </div>
                                            <ImageBox
                                                onClick={() => handleOpen(0)}
                                                sx={{
                                                    height: '400px',
                                                    backgroundImage: "url('" + extractDataFromArray(images, [0, 'thumbnail_url'], '') + "')",
                                                }}
                                            />
                                        </div>
                                        :
                                        <div className='' onClick={() => handleOpen(0)} style={{ position: 'relative', display: 'flex' }}>
                                            <div className='' style={{ position: 'absolute', cursor: 'pointer', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: '16px' }}>
                                                <PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '4rem', width: '4rem' }} />
                                            </div>
                                            <CardMedia
                                                component={'video'}
                                                sx={{
                                                    height: '400px',
                                                    objectFit: 'cover',
                                                    borderRadius: '16px !important'
                                                }}
                                                image={extractDataFromArray(images, [0, 'url'], '')}

                                            />
                                        </div>
                                }
                            </>
                        }
                    </Grid>
                    <Grid item container md={6} sm={12} xs={12} spacing={1}>
                        <Grid item md={12} sm={6} xs={6}>
                            {extractDataFromArray(images, [1, 'type'], '') === 'IMAGE'
                                ?
                                <ImageBox
                                    onClick={() => handleOpen(1)}
                                    sx={{
                                        height: '196px',
                                        backgroundImage: "url('" + extractDataFromArray(images, [1, 'url'], '') + "')",
                                    }}
                                />
                                :
                                <>
                                    {
                                        extractDataFromArray(images, [1, 'thumbnail_url'], '') !== ''
                                            // true
                                            ?
                                            <div className='' onClick={() => handleOpen(1)} style={{ position: 'relative', display: 'flex' }}>
                                                <div className='' style={{ position: 'absolute', cursor: 'pointer', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: '16px' }}>
                                                    <PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '4rem', width: '4rem' }} />
                                                </div>
                                                <ImageBox
                                                    onClick={() => handleOpen(1)}
                                                    sx={{
                                                        height: '196px',
                                                        backgroundImage: "url('" + extractDataFromArray(images, [1, 'thumbnail_url'], '') + "')",
                                                    }}
                                                />
                                            </div>
                                            :
                                            <div className='' onClick={() => handleOpen(1)} style={{ position: 'relative', display: 'flex' }}>
                                                <div className='' style={{ position: 'absolute', cursor: 'pointer', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: '16px' }}>
                                                    <PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '4rem', width: '4rem' }} />
                                                </div>
                                                <CardMedia
                                                    component={'video'}
                                                    sx={{
                                                        height: '196px',
                                                        objectFit: 'cover',
                                                        borderRadius: '16px !important'
                                                    }}
                                                    image={extractDataFromArray(images, [1, 'url'], '')}

                                                />
                                            </div>
                                    }
                                </>
                            }
                        </Grid>
                        <Grid item md={12} sm={6} xs={6}>
                            {extractDataFromArray(images, [2, 'type'], '') === 'IMAGE'
                                ?
                                <ImageBox
                                    onClick={() => handleOpen(2)}
                                    sx={{
                                        height: '196px',
                                        backgroundImage: "url('" + extractDataFromArray(images, [2, 'url'], '') + "')",
                                    }}
                                />
                                :
                                <>
                                    {
                                        extractDataFromArray(images, [2, 'thumbnail_url'], '') !== ''
                                            // true
                                            ?
                                            <div className='' onClick={() => handleOpen(2)} style={{ position: 'relative', display: 'flex' }}>
                                                <div className='' style={{ position: 'absolute', cursor: 'pointer', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: '16px' }}>
                                                    <PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '4rem', width: '4rem' }} />
                                                </div>
                                                <ImageBox
                                                    onClick={() => handleOpen(2)}
                                                    sx={{
                                                        height: '196px',
                                                        backgroundImage: "url('" + extractDataFromArray(images, [2, 'thumbnail_url'], '') + "')",
                                                    }}
                                                />
                                            </div>
                                            :
                                            <div className='' onClick={() => handleOpen(2)} style={{ position: 'relative', display: 'flex' }}>
                                                <div className='' style={{ position: 'absolute', cursor: 'pointer', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: '16px' }}>
                                                    <PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '4rem', width: '4rem' }} />
                                                </div>
                                                <CardMedia
                                                    component={'video'}
                                                    sx={{
                                                        height: '196px',
                                                        objectFit: 'cover',
                                                        borderRadius: '16px !important'
                                                    }}
                                                    image={extractDataFromArray(images, [2, 'url'], '')}

                                                />
                                            </div>
                                    }
                                </>
                            }
                        </Grid>
                    </Grid>
                </>
            }
            {
                images.length === 4 &&
                <>
                    <Grid item md={6} sm={12} xs={12}>
                        {extractDataFromArray(images, [0, 'type'], '') === 'IMAGE'
                            ?
                            <ImageBox
                                onClick={() => handleOpen(0)}
                                sx={{
                                    height: '400px',
                                    backgroundImage: "url('" + extractDataFromArray(images, [0, 'url'], '') + "')",
                                }}
                            />
                            :
                            <>
                                {
                                    extractDataFromArray(images, [0, 'thumbnail_url'], '') !== ''
                                        // true
                                        ?
                                        <div className='' onClick={() => handleOpen(0)} style={{ position: 'relative', display: 'flex' }}>
                                            <div className='' style={{ position: 'absolute', cursor: 'pointer', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: '16px' }}>
                                                <PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '4rem', width: '4rem' }} />
                                            </div>
                                            <ImageBox
                                                onClick={() => handleOpen(0)}
                                                sx={{
                                                    height: '400px',
                                                    backgroundImage: "url('" + extractDataFromArray(images, [0, 'thumbnail_url'], '') + "')",
                                                }}
                                            />
                                        </div>
                                        :
                                        <div className='' onClick={() => handleOpen(0)} style={{ position: 'relative', display: 'flex' }}>
                                            <div className='' style={{ position: 'absolute', cursor: 'pointer', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: '16px' }}>
                                                <PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '4rem', width: '4rem' }} />
                                            </div>
                                            <CardMedia
                                                component={'video'}
                                                sx={{
                                                    height: '400px',
                                                    objectFit: 'cover',
                                                    borderRadius: '16px !important'
                                                }}
                                                image={extractDataFromArray(images, [0, 'url'], '')}

                                            />
                                        </div>
                                }
                            </>
                        }
                    </Grid>
                    <Grid item container md={6} sm={12} xs={12} spacing={1}>
                        <Grid item md={12} sm={4} xs={6}>
                            {extractDataFromArray(images, [1, 'type'], '') === 'IMAGE'
                                ?
                                <ImageBox
                                    onClick={() => handleOpen(1)}
                                    sx={{
                                        height: '196px',
                                        backgroundImage: "url('" + extractDataFromArray(images, [1, 'url'], '') + "')",
                                    }}
                                />
                                :
                                <>
                                    {
                                        extractDataFromArray(images, [1, 'thumbnail_url'], '') !== ''
                                            // true
                                            ?
                                            <div className='' onClick={() => handleOpen(1)} style={{ position: 'relative', display: 'flex' }}>
                                                <div className='' style={{ position: 'absolute', cursor: 'pointer', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: '16px' }}>
                                                    <PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '4rem', width: '4rem' }} />
                                                </div>
                                                <ImageBox
                                                    onClick={() => handleOpen(1)}
                                                    sx={{
                                                        height: '196px',
                                                        backgroundImage: "url('" + extractDataFromArray(images, [1, 'thumbnail_url'], '') + "')",
                                                    }}
                                                />
                                            </div>
                                            :
                                            <div className='' onClick={() => handleOpen(1)} style={{ position: 'relative', display: 'flex' }}>
                                                <div className='' style={{ position: 'absolute', cursor: 'pointer', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: '16px' }}>
                                                    <PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '4rem', width: '4rem' }} />
                                                </div>
                                                <CardMedia
                                                    component={'video'}
                                                    sx={{
                                                        height: '196px',
                                                        objectFit: 'cover',
                                                        borderRadius: '16px !important'
                                                    }}
                                                    image={extractDataFromArray(images, [1, 'url'], '')}

                                                />
                                            </div>
                                    }
                                </>
                            }
                        </Grid>
                        <Grid item md={6} sm={4} xs={6}>
                            {extractDataFromArray(images, [2, 'type'], '') === 'IMAGE'
                                ?
                                <ImageBox
                                    onClick={() => handleOpen(2)}
                                    sx={{
                                        height: '196px',
                                        backgroundImage: "url('" + extractDataFromArray(images, [2, 'url'], '') + "')",
                                    }}
                                />
                                :
                                <>
                                    {
                                        extractDataFromArray(images, [2, 'thumbnail_url'], '') !== ''
                                            // true
                                            ?
                                            <div className='' onClick={() => handleOpen(2)} style={{ position: 'relative', display: 'flex' }}>
                                                <div className='' style={{ position: 'absolute', cursor: 'pointer', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: '16px' }}>
                                                    <PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '4rem', width: '4rem' }} />
                                                </div>
                                                <ImageBox
                                                    onClick={() => handleOpen(2)}
                                                    sx={{
                                                        height: '196px',
                                                        backgroundImage: "url('" + extractDataFromArray(images, [2, 'thumbnail_url'], '') + "')",
                                                    }}
                                                />
                                            </div>
                                            :
                                            <div className='' onClick={() => handleOpen(2)} style={{ position: 'relative', display: 'flex' }}>
                                                <div className='' style={{ position: 'absolute', cursor: 'pointer', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: '16px' }}>
                                                    <PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '4rem', width: '4rem' }} />
                                                </div>
                                                <CardMedia
                                                    component={'video'}
                                                    sx={{
                                                        height: '196px',
                                                        objectFit: 'cover',
                                                        borderRadius: '16px !important'
                                                    }}
                                                    image={extractDataFromArray(images, [2, 'url'], '')}

                                                />
                                            </div>
                                    }
                                </>
                            }
                        </Grid>
                        <Grid item md={6} sm={4} xs={6} sx={{ position: 'relative' }}>
                            {extractDataFromArray(images, [3, 'type'], '') === 'IMAGE'
                                ?
                                <ImageBox
                                    onClick={() => handleOpen(3)}
                                    sx={{
                                        height: '196px',
                                        backgroundImage: "url('" + extractDataFromArray(images, [3, 'url'], '') + "')",
                                    }}
                                />
                                :
                                <>
                                    {
                                        extractDataFromArray(images, [3, 'thumbnail_url'], '') !== ''
                                            // true
                                            ?
                                            <div className='' onClick={() => handleOpen(3)} style={{ position: 'relative', display: 'flex' }}>
                                                <div className='' style={{ position: 'absolute', cursor: 'pointer', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: '16px' }}>
                                                    <PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '4rem', width: '4rem' }} />
                                                </div>
                                                <ImageBox
                                                    onClick={() => handleOpen(3)}
                                                    sx={{
                                                        height: '196px',
                                                        backgroundImage: "url('" + extractDataFromArray(images, [3, 'thumbnail_url'], '') + "')",
                                                    }}
                                                />
                                            </div>
                                            :
                                            <div className='' onClick={() => handleOpen(3)} style={{ position: 'relative', display: 'flex' }}>
                                                <div className='' style={{ position: 'absolute', cursor: 'pointer', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: '16px' }}>
                                                    <PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '4rem', width: '4rem' }} />
                                                </div>
                                                <CardMedia
                                                    component={'video'}
                                                    sx={{
                                                        height: '196px',
                                                        objectFit: 'cover',
                                                        borderRadius: '16px !important'
                                                    }}
                                                    image={extractDataFromArray(images, [3, 'url'], '')}

                                                />
                                            </div>
                                    }
                                </>
                            }
                        </Grid>
                    </Grid>
                </>

            }
            {
                images.length >= 5 &&
                <>
                    <Grid item md={6} sm={12} xs={12}>
                        {extractDataFromArray(images, [0, 'type'], '') === 'IMAGE'
                            ?
                            <ImageBox
                                onClick={() => handleOpen(0)}
                                sx={{
                                    height: '400px',
                                    backgroundImage: "url('" + extractDataFromArray(images, [0, 'url'], '') + "')",
                                }}
                            />
                            :
                            <>
                                {
                                    extractDataFromArray(images, [0, 'thumbnail_url'], '') !== ''
                                        // true
                                        ?
                                        <div className='' onClick={() => handleOpen(0)} style={{ position: 'relative', display: 'flex' }}>
                                            <div className='' style={{ position: 'absolute', cursor: 'pointer', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: '16px' }}>
                                                <PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '4rem', width: '4rem' }} />
                                            </div>
                                            <ImageBox
                                                onClick={() => handleOpen(0)}
                                                sx={{
                                                    height: '400px',
                                                    backgroundImage: "url('" + extractDataFromArray(images, [0, 'thumbnail_url'], '') + "')",
                                                }}
                                            />
                                        </div>
                                        :
                                        <div className='' onClick={() => handleOpen(0)} style={{ position: 'relative', display: 'flex' }}>
                                            <div className='' style={{ position: 'absolute', cursor: 'pointer', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: '16px' }}>
                                                <PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '4rem', width: '4rem' }} />
                                            </div>
                                            <CardMedia
                                                component={'video'}
                                                sx={{
                                                    height: '400px',
                                                    objectFit: 'cover',
                                                    borderRadius: '16px !important'
                                                }}
                                                image={extractDataFromArray(images, [0, 'url'], '')}

                                            />
                                        </div>
                                }
                            </>
                        }
                    </Grid>
                    <Grid item container md={6} sm={12} xs={12} spacing={1}>
                        <Grid item md={12} sm={4} xs={6}>
                            {extractDataFromArray(images, [1, 'type'], '') === 'IMAGE'
                                ?
                                <ImageBox
                                    onClick={() => handleOpen(1)}
                                    sx={{
                                        height: '196px',
                                        backgroundImage: "url('" + extractDataFromArray(images, [1, 'url'], '') + "')",
                                    }}
                                />
                                :
                                <>
                                    {
                                        extractDataFromArray(images, [1, 'thumbnail_url'], '') !== ''
                                            // true
                                            ?
                                            <div className='' onClick={() => handleOpen(1)} style={{ position: 'relative', display: 'flex' }}>
                                                <div className='' style={{ position: 'absolute', cursor: 'pointer', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: '16px' }}>
                                                    <PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '4rem', width: '4rem' }} />
                                                </div>
                                                <ImageBox
                                                    onClick={() => handleOpen(1)}
                                                    sx={{
                                                        height: '196px',
                                                        backgroundImage: "url('" + extractDataFromArray(images, [1, 'thumbnail_url'], '') + "')",
                                                    }}
                                                />
                                            </div>
                                            :
                                            <div className='' onClick={() => handleOpen(1)} style={{ position: 'relative', display: 'flex' }}>
                                                <div className='' style={{ position: 'absolute', cursor: 'pointer', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: '16px' }}>
                                                    <PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '4rem', width: '4rem' }} />
                                                </div>
                                                <CardMedia
                                                    component={'video'}
                                                    sx={{
                                                        height: '196px',
                                                        objectFit: 'cover',
                                                        borderRadius: '16px !important'
                                                    }}
                                                    image={extractDataFromArray(images, [1, 'url'], '')}

                                                />
                                            </div>
                                    }
                                </>
                            }
                        </Grid>
                        <Grid item md={6} sm={4} xs={6}>
                            {extractDataFromArray(images, [2, 'type'], '') === 'IMAGE'
                                ?
                                <ImageBox
                                    onClick={() => handleOpen(2)}
                                    sx={{
                                        height: '196px',
                                        backgroundImage: "url('" + extractDataFromArray(images, [2, 'url'], '') + "')",
                                    }}
                                />
                                :
                                <>
                                    {
                                        extractDataFromArray(images, [2, 'thumbnail_url'], '') !== ''
                                            // true
                                            ?
                                            <div className='' onClick={() => handleOpen(2)} style={{ position: 'relative', display: 'flex' }}>
                                                <div className='' style={{ position: 'absolute', cursor: 'pointer', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: '16px' }}>
                                                    <PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '4rem', width: '4rem' }} />
                                                </div>
                                                <ImageBox
                                                    onClick={() => handleOpen(2)}
                                                    sx={{
                                                        height: '196px',
                                                        backgroundImage: "url('" + extractDataFromArray(images, [2, 'thumbnail_url'], '') + "')",
                                                    }}
                                                />
                                            </div>
                                            :
                                            <div className='' onClick={() => handleOpen(2)} style={{ position: 'relative', display: 'flex' }}>
                                                <div className='' style={{ position: 'absolute', cursor: 'pointer', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: '16px' }}>
                                                    <PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '4rem', width: '4rem' }} />
                                                </div>
                                                <CardMedia
                                                    component={'video'}
                                                    sx={{
                                                        height: '196px',
                                                        objectFit: 'cover',
                                                        borderRadius: '16px !important'
                                                    }}
                                                    image={extractDataFromArray(images, [2, 'url'], '')}

                                                />
                                            </div>
                                    }
                                </>
                            }
                        </Grid>
                        <Grid item md={6} sm={4} xs={6} sx={{ position: 'relative' }}>
                            {extractDataFromArray(images, [3, 'type'], '') === 'IMAGE'
                                ?
                                <ImageBox
                                    onClick={() => handleOpen(3)}
                                    sx={{
                                        height: '196px',
                                        backgroundImage: "url('" + extractDataFromArray(images, [3, 'url'], '') + "')",
                                    }}
                                />
                                :
                                <>
                                    {
                                        extractDataFromArray(images, [3, 'thumbnail_url'], '') !== ''
                                            // true
                                            ?
                                            <div className='' onClick={() => handleOpen(3)} style={{ position: 'relative', display: 'flex' }}>
                                                <div className='' style={{ position: 'absolute', cursor: 'pointer', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: '16px' }}>
                                                    <PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '4rem', width: '4rem' }} />
                                                </div>
                                                <ImageBox
                                                    onClick={() => handleOpen(3)}
                                                    sx={{
                                                        height: '196px',
                                                        backgroundImage: "url('" + extractDataFromArray(images, [3, 'thumbnail_url'], '') + "')",
                                                    }}
                                                />
                                            </div>
                                            :
                                            <div className='' onClick={() => handleOpen(3)} style={{ position: 'relative', display: 'flex' }}>
                                                <div className='' style={{ position: 'absolute', cursor: 'pointer', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: '16px' }}>
                                                    <PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '4rem', width: '4rem' }} />
                                                </div>
                                                <CardMedia
                                                    component={'video'}
                                                    sx={{
                                                        height: '196px',
                                                        objectFit: 'cover',
                                                        borderRadius: '16px !important'
                                                    }}
                                                    image={extractDataFromArray(images, [3, 'url'], '')}

                                                />
                                            </div>
                                    }
                                </>
                            }
                            <Box
                                sx={{
                                    width: 'calc(100% - 0.5rem)',
                                    height: '196px',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    position: 'absolute',
                                    top: '0.5rem',
                                    zIndex: 2,
                                    background: "#0009",
                                    borderRadius: '1rem',
                                    cursor: 'pointer'
                                }}
                                onClick={() => handleOpen(0)}
                            >
                                <Typography color="white">({images.length}) View All</Typography>
                            </Box>
                        </Grid>
                    </Grid>
                </>
            }
        </Grid >
    )
}

export const ImagePreview = ({ open, handleClose, images, imageIndex, setSelectedImageIndex }) => {
    const [swiperInstance, setSwiperInstance] = useState(null);
    // console.log(swiperInstance?.activeIndex)

    const handleNext = () => {
        if (swiperInstance) {
            swiperInstance.slideNext();
        }
    };

    const handlePrev = () => {
        if (swiperInstance) {
            swiperInstance.slidePrev();
        }
    };

    const handleSlideChange = () => {
        const previousSlideIndex = imageIndex;
        const currentSlideIndex = swiperInstance?.realIndex || imageIndex;
        // console.log(currentSlideIndex)

        // Pause the video of the previous slide
        if (images[previousSlideIndex]?.type === 'VIDEO') {
            const previousVideoElement = document.getElementById(`video-${previousSlideIndex}`);
            if (previousVideoElement) {
                previousVideoElement.pause();
            }
        }

        // Play the video of the current slide
        if (images[currentSlideIndex]?.type === 'VIDEO') {
            const currentVideoElement = document.getElementById(`video-${currentSlideIndex}`);
            if (currentVideoElement) {
                currentVideoElement.play();
            }
        }

        // Update the imageIndex state
        setSelectedImageIndex(currentSlideIndex);
    };
    const handleKeyDown = (event) => {
        switch (event.key) {
            case 'ArrowRight':
                event.preventDefault(); // Prevents scrolling the page, for example
                handleNext();
                break;
            case 'ArrowLeft':
                event.preventDefault(); // Prevents scrolling the page, for example
                handlePrev();
                break;
            default:
                // If you have other keys to handle, add cases here
                break;
        }
    };


    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            className="authPopUpP"
            PaperProps={{
                sx: {
                    backgroundColor: '#0009'
                }
            }}
            onKeyDown={handleKeyDown}
        >
            <IconButton
                onClick={handleClose}
                sx={{
                    width: '40px',
                    borderRadius: '20px',
                    position: 'absolute',
                    top: '1rem',
                    right: '1rem',
                    zIndex: 2,
                    background: "#ddd",
                    "&:hover": {
                        background: '#ddd'
                    }
                }}
            >
                <Icon.CloseOutlined />
            </IconButton>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                <Swiper
                    initialSlide={imageIndex}
                    navigation
                    className="customSwiper2"
                    onSwiper={setSwiperInstance}
                    onSlideChange={handleSlideChange}
                    onSliderFirstMove={handleSlideChange}
                >
                    {images.map((image, index) => {
                        return (
                            <>
                                {image.type !== 'Unknown' && <SwiperSlide className="SwiperActiveSlide" key={index}>
                                    {
                                        image.type === 'IMAGE' ?
                                            <img
                                                src={image.url}
                                                alt={image.media_name}
                                                style={{
                                                    width: "100%",
                                                    maxHeight: "100vh",
                                                    objectFit: "contain",
                                                }}
                                            />
                                            :
                                            <CardMedia
                                                component={'video'}
                                                id={`video-${index}`}
                                                sx={{
                                                    width: '100%',
                                                    maxHeight: '100vh',
                                                    borderRadius: '16px !important'
                                                }}
                                                image={image.url}
                                                autoPlay={imageIndex === index}
                                                controls
                                            />
                                    }
                                </SwiperSlide>}
                            </>

                        )
                    })

                    }
                </Swiper>
            </Box>
        </Dialog>
    )
}

const ExpHeaderGallery = ({ type, seq, images, isMobileButtonClicked = false }) => {
    const [open, setOpen] = useState(false);
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);

    const handleClose = () => {
        setOpen(false);
        setSelectedImageIndex(null); // Reset selected image index when closing
    }

    const handleOpen = (index) => {
        setSelectedImageIndex(index); // Set the index of the clicked image
        setOpen(true);
    }
    const { isNineHundred } = CommonContext();

    const { user, authPopup, setAuthPopup } = UserAuth()

    const [wishlist, setWishlist] = useState(false)
    const handleWishlist = () => {
        if (empty(user)) {
            setAuthPopup({
                ...authPopup,
                type: type,
                open: true,
                callback: () => {
                    toggleWishlist()
                }
            })
        } else {
            toggleWishlist()
        }
    }

    const toggleWishlist = () => {
        if (!wishlist) {
            WishlistService.saveWishlist('E', seq, (data, errorMsg) => {
                if (data === true) {
                    // toast.success(errorMsg, {
                    //     theme: "colored",
                    // })
                    toastMessage({
                        message: errorMsg,
                        type: 'success'
                    })
                }
                setWishlist(true)
            })
        } else {
            WishlistService.removeFromWishlist('E', seq, (data, errorMsg) => {
                // toast.success(errorMsg, {
                //     theme: "colored",
                // })
                toastMessage({
                    message: errorMsg,
                    type: 'success'
                })
                setWishlist(false)
            })
        }
    }

    // This use effect is to refresh wishlist object on user login or logout status change
    useEffect(() => {
        if (!user) {
            setWishlist(false)
        }
    }, [user])


    useEffect(() => {
        if (config.module === 'guest' && user) {
            WishlistService.isWishlist(seq, 'E', (data) => {
                // console.log(data,'iswishlist data')
                setWishlist(data)
            })
        }
    }, [seq, type, user])

    return (
        <Box sx={{ position: 'relative' }}>
            {
                (isNineHundred && config.module === "guest") &&
                <Box sx={{ display: 'flex', gap: 1, position: "absolute", zIndex: 10, right: '0.5rem', top: '0.5rem' }}>
                    <Button variant="contained" color="grey300" onClick={handleWishlist} sx={{ color: '', minWidth: "34px ", width: '34px', p: 0, height: "34px" }} >
                        {wishlist ? <Icon.Favorite color="buttonRed" /> : <Icon.FavoriteBorderOutlined />}
                    </Button>
                    <ShareButton shareUrl={window.location.href} sx={{ minWidth: "34px ", width: '34px', p: 0, height: "34px", color: (theme) => theme.palette.secondary.main }} />
                </Box>
            }
            {isMobileButtonClicked && <Box sx={{ display: 'flex', gap: 1, position: "absolute", zIndex: 10, right: '0.5rem', top: '0.5rem', pointerEvents: 'none' }}>
                <Button variant="contained" sx={{ backgroundColor: 'var(--grey300)', minWidth: "34px ", width: '34px', p: 0, height: "34px" }} >
                    {<Icon.Favorite color="buttonRed" />}
                </Button>
                <ShareButton shareUrl={window.location.href} sx={{ minWidth: "34px ", width: '34px', p: 0, height: "34px", color: (theme) => theme.palette.secondary.main }} />
            </Box>}
            {
                (isNineHundred || isMobileButtonClicked) &&
                <Swiper
                    modules={[Pagination]}
                    pagination={{
                        dynamicBullets: true,
                    }}
                    spaceBetween={10}
                    slidesPerView={1}
                    navigation
                    onSlideChange={() => { }}
                    onSwiper={(swiper) => { }}
                    style={{ borderRadius: '16px', overflow: 'hidden', width: '100%' }}
                    className="mySwiper customSwiper"

                >
                    {
                        images.map((image, i) => {
                            return <SwiperSlide key={"images_" + i} style={{ borderRadius: '16px', width: '100%', overflow: 'hidden' }}>
                                {image.type === 'IMAGE' ?
                                    <Box
                                        onClick={() => { handleOpen(i) }}
                                        sx={{
                                            width: '100%',
                                            height: '250px',
                                            borderRadius: '16px',
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center center',
                                            backgroundImage: "url('" + (image.url) + "')"
                                        }} />
                                    :
                                    <>
                                        {
                                            extractDataFromArray(images, [i, 'thumbnail_url'], '') !== ''
                                                // true
                                                ?
                                                <div className='' onClick={() => handleOpen(i)} style={{ position: 'relative', display: 'flex' }}>
                                                    <div className='' style={{ position: 'absolute', cursor: 'pointer', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: '16px' }}>
                                                        <PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '4rem', width: '4rem' }} />
                                                    </div>
                                                    <ImageBox
                                                        onClick={() => handleOpen(0)}
                                                        sx={{
                                                            height: '250px',
                                                            backgroundImage: "url('" + extractDataFromArray(images, [i, 'thumbnail_url'], '') + "')",
                                                        }}
                                                    />
                                                </div>
                                                :
                                                <div className='' onClick={() => handleOpen(0)} style={{ position: 'relative', display: 'flex' }}>
                                                    <div className='' style={{ position: 'absolute', cursor: 'pointer', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', alignItems: 'center', justifyContent: 'center', zIndex: '1', borderRadius: '16px' }}>
                                                        <PlayCircleOutlinedIcon color="white" size={64} style={{ opacity: 0.8 }} sx={{ color: 'white', zIndex: '2', height: '4rem', width: '4rem' }} />
                                                    </div>
                                                    <CardMedia
                                                        component={'video'}
                                                        sx={{
                                                            height: '250px',
                                                            objectFit: 'cover',
                                                            borderRadius: '16px !important'
                                                        }}
                                                        image={extractDataFromArray(images, [i, 'url'], '')}

                                                    />
                                                </div>
                                        }
                                    </>
                                }

                            </SwiperSlide>
                        })
                    }
                </Swiper>

            }
            {
                (!isNineHundred && !isMobileButtonClicked) && <GalleryGrid images={images} handleOpen={handleOpen} />

            }
            <ImagePreview open={open} handleClose={handleClose} images={images} imageIndex={selectedImageIndex} setSelectedImageIndex={setSelectedImageIndex} />
        </Box>
    )
}

export default ExpHeaderGallery;