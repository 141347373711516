import React, { useState } from 'react';
import {
    Box,
    Typography,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Container,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';

// Sample FAQ data
const faqData = [
    {
        question: "How can I pay?",
        answer: `You can pay right through the app once you’ve downloaded it. We accept all major credit cards and payments through the Apple Store. Many of FarWide’s features are free. However, enhanced tools such as private property lines, 3D mapping technology, map tools, and drawing require a paid subscription. This can be completed by creating an account and paying the yearly subscription rate of only $19.99.`
    },
    {
        question: "What’s the cancellation policy?",
        answer: (
            <>
                You can cancel your subscription at any time by emailing us at{' '}
                <a href="mailto:hello@farwide.com" style={{ color: '#1a73e8', textDecoration: 'underline' }}>
                    hello@farwide.com
                </a>{' '}
                requesting cancellation. Keep in mind that your subscription is good for one full calendar year but will be terminated upon cancellation and you will no longer have access to the paid features within FarWide.
            </>
        ),
    },
    {
        question: "How extensive is your private boundary data?",
        answer: "Currently we cover the entire US with private land and boundary data. Land outside of the United States does not currently carry private land ownership data and we are unable to provide it at this point in time."
    },
    {
        question: "Does my payment choice allow for booking private access and experiences?",
        answer: "No, the purchase of paid features within the app is a separate charge. If you choose to book one of our private land and access experiences you will be asked to enter your chosen payment method information through our 3rd party payment system, Stripe. "
    },
];

const FAQSection = () => {
    const [expanded, setExpanded] = useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Container maxWidth="lg" sx={{ py: 8 }}>
            {/* Heading */}
            <Typography
                variant="h3"
                sx={{
                    color: '#1b1b1b',
                    textAlign: { xs: "left", sm: 'left', md: 'center' }
                }}
            >
                Frequently Asked Questions
            </Typography>
            <Typography
                variant="h6"
                sx={{
                    mb: 5,
                    fontWeight: 400,
                    color: '#747579',
                    textAlign: { xs: "left", sm: 'left', md: 'center' }
                }}
            >
                Find answers to common questions about our app and how it works.
            </Typography>

            {/* FAQ Cards */}
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
                {faqData.map((faq, index) => (
                    <Accordion
                        key={index}
                        expanded={expanded === `panel${index}`}
                        onChange={handleChange(`panel${index}`)}
                        sx={{
                            boxShadow: "0px 4px 4px 0px #00000040",
                            borderRadius: '16px !important',
                            '&:before': {
                                display: 'none',
                            },
                            '&.Mui-expanded': {
                                margin: 0,
                            }
                        }}
                    >
                        <AccordionSummary
                            expandIcon={expanded === `panel${index}` ?
                                <RemoveIcon sx={{ color: '#747579' }} /> :
                                <AddIcon sx={{ color: '#747579' }} />
                            }
                            sx={{
                                '& .MuiAccordionSummary-content': {
                                    margin: '16px 0',
                                },

                            }}
                        >
                            <Typography
                                variant="h6"
                                sx={{
                                    fontWeight: 600,
                                    color: '#000000',
                                    fontSize: { xs: '1rem', md: '1.125rem' }
                                }}
                            >
                                {faq.question}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{ pb: 2, pt: 0 }}>
                            <Typography
                                variant="body1"
                                sx={{
                                    color: '#747579',
                                    lineHeight: 1.7,
                                    "& .MuiAccordionDetails-root": {
                                        paddingTop: '0px !important'
                                    }
                                }}
                            >
                                {faq.answer}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                ))}
            </Box>
        </Container>
    );
};

export default FAQSection;