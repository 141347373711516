export const ROUTE_LISTER_HOME = '/landowner';
export const ROUTE_SEARCH = '/search';
export const ROUTE_DASHBOARD = '/dashboard';
export const ROUTE_LISTING = '/listings';
export const ROUTE_BOOKINGS = '/bookings';
export const ROUTE_PAYOUTDETAILS = '/payoutdetails';
export const ROUTE_CREATEREVIEW = '/createReview';
export const ROUTE_PROFILE = '/account';
export const ROUTE_WISHLIST = '/wishlist';
export const ROUTE_SUBMIT_REVIEW = '/review';
export const ROUTE_SETTINGS = '/settings';
export const ROUTE_PROPERTY_DETAILS = '/propertyDetails';
export const ROUTE_PROPERTY = '/property';
export const ROUTE_PACKAGE = '/package';
export const ROUTE_PACKAGE_DETAILS = '/packageDetails';
export const ROUTE_CHECKOUT = '/checkout';
export const ROUTE_CHAT = '/chat';
export const ROUTE_MANAGEBOOKINGS = '/managebookings';
export const ROUTE_CREATE_PROPERTY = '/createProperty';
export const ROUTE_VERIFY_ID = '/verifyID';
export const ROUTE_PAYMENT = '/payment';
export const USER_NAME = "username";
export const AUTH_TOKEN = "token";
export const ROUTE_TRANSACTIONS = '/transactions';
export const ROUTE_EXPERIENCE = '/experience';
export const ROUTE_EXPERIENCE_REVIEW = '/experiencereview';
export const ROUTE_REVIEW_SUCCEFULL = '/reviewsuccefull';
export const ROUTE_OURSTORY = '/ourstory';
export const ROUTE_APP = '/app';
export const ROUTE_ABOUTUS = '/aboutus';
export const ROUTE_CONSERVATION_TRUST = '/conservationtrust';
export const ROUTE_CONTACT_US = '/contactus';