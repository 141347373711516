import { Box, Grid, Typography, Skeleton, IconButton } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import HomePageService from "../../../services/home";
import { toast } from "react-toastify";
import { Swiper, SwiperSlide } from "swiper/react";
import * as Icon from "@mui/icons-material";
import { Navigation } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css';
import MediaCard from "../../../commonComponents/previewPageComponents/mediaCard";
import dayjs from "dayjs";
import { CommonContext } from "../../../store/context/commonContext";
import { SkeletonCard } from "../../../commonComponents/placeholders/SKeletonCard";
import { toastMessage } from "../../../utils/common";

const Blogs = () => {
    const [blogsData, setBlogsData] = useState([]);
    const [loading, setLoading] = useState(true);
    const swiperRef = useRef(null);
    const { screenWidth } = CommonContext();
    const [slideCount, setSlideCount] = useState(3);
    const [currentIndex, setCurrentIndex] = useState(0); // Track the current index

    useEffect(() => {
        if (screenWidth > 1000) {
            if (slideCount === 3) {
                return
            } else {
                setSlideCount(3)
            }
        } else if (screenWidth <= 1000 && screenWidth >= 670) {
            if (slideCount === 2) {
                return
            } else {
                setSlideCount(2)
            }
        } else {
            if (slideCount === 1) {
                return
            } else {
                setSlideCount(1)
            }
        }
    })

    useEffect(() => {
        HomePageService.getSampleBlogPosts(
            (data) => {
                setBlogsData(data);
                setLoading(false);
            },
            (errorMsg) => {
                // toast.error(errorMsg, { theme: "colored" });
                // toastMessage({
                //     message: errorMsg,
                //     type: 'error'
                // })
                console.log(errorMsg);
                setLoading(false);
            }
        );
    }, []);

    const handleSlideChange = (swiper) => {
        setCurrentIndex(swiper.activeIndex); // Update current index on slide change
    };

    if (loading) {
        return (
            <Grid container justifyContent="center" sx={{ mt: { xs: 6, md: 12 }, mb: { xs: 6, md: 12 } }}>
                {/* Render multiple SkeletonCard components */}
                {[1, 2, 3].map((_, index) => (
                    <SkeletonCard key={index} />
                ))}
            </Grid>
        );
    }

    if (blogsData.length === 0) {
        return null
    }

    return (
        <Grid container sx={{ mb: { xs: 6, md: 12 } }}>
            <Typography variant="h4" sx={{ textAlign: 'center', width: '100%', mb: 5 }}>
                Latest Updates From the Outdoor World
            </Typography>
            <Swiper
                ref={swiperRef}
                modules={[Navigation]}
                spaceBetween={50}
                slidesPerView={slideCount}
                onSlideChange={handleSlideChange} // Listen for slide changes
                className="custom-swiper homePageSwiper"
            >
                {blogsData.map((blog, index) => (
                    <SwiperSlide style={{ display: 'flex', justifyContent: 'center' }} key={index}>
                        <Box
                            onClick={() => window.open(blog.blog_url, '_blank')}
                            sx={{
                                minWidth: '336px',
                                maxWidth: '327px',
                                cursor: 'pointer'
                            }}>
                            <Box sx={{
                                mb: 2, display: 'flex',
                                justifyContent: screenWidth < 670 ? 'center' : 'unset',
                            }}>
                                <MediaCard
                                    imageObj={blog.media}
                                    borderRadius={'16px'}
                                    height={'253px'}
                                    width={'327px'}
                                    iconSize="70px"
                                />
                            </Box>
                            <Box sx={{
                                display: 'flex', gap: 1, mb: 1,
                            }}>
                                <Typography variant="caption" sx={{ color: 'var(--primary)' }}>
                                    {blog.blog_type}
                                </Typography>
                                <Typography variant="caption" sx={{ color: '#747579' }}>
                                    {dayjs(blog.published_date).format('MMM DD, YYYY')}
                                </Typography>
                            </Box>
                            <Typography variant="subtitle1">
                                {blog.caption}
                            </Typography>
                        </Box>
                    </SwiperSlide>
                ))}
            </Swiper>

            {/* Custom Navigation Buttons */}
            <Grid container justifyContent="center" sx={{ mt: 2, gap: 2 }}>
                <IconButton
                    variant="outlined"
                    onClick={() => swiperRef.current.swiper.slidePrev()}
                    disabled={currentIndex === 0} // Disable if first slide
                    sx={{ mx: 1, backgroundColor: currentIndex === 0 ? '#FDF2D2' : '#FDF2D2 !important' }}
                >
                    <Icon.ArrowBack color="#000000" />
                </IconButton>
                <IconButton
                    variant="outlined"
                    onClick={() => swiperRef.current.swiper.slideNext()}
                    disabled={currentIndex === blogsData.length - 1 - (slideCount - 1)} // Disable if last slide
                    sx={{ mx: 1, backgroundColor: currentIndex === blogsData.length - 1 - (slideCount - 1) ? '#FDF2D2' : '#FDF2D2 !important' }}
                >
                    <Icon.ArrowForward color="#000000" />
                </IconButton>
            </Grid>
        </Grid >
    );
};

export default Blogs;
