import React, { useState } from "react";
import { Box, IconButton, Popover, Paper, MenuItem } from "@mui/material";
import * as Icon from '@mui/icons-material';
import { toastMessage } from "../../utils/common";
import errorAndHelperText from "../CommonConstants/errorAndHelperText.json"

const ShareButton = ({
  shareUrl, sx
}) => {
  const [shareAnchorEl, setShareAnchorEl] = useState(null);

  const onShareClick = (event) => {
    setShareAnchorEl(event.currentTarget);
  };

  const onShareClose = () => {
    setShareAnchorEl(null);
  };

  const handleShare = (socialMedia) => {
    let shareUrlString = '';

    if (socialMedia === "facebook") {
      shareUrlString = `https://www.facebook.com/sharer/sharer.php?quote=Hey! I found this amazing place on FarWide. Check it out.&u=${encodeURIComponent(shareUrl)}`;
    } else if (socialMedia === "twitter") {
      shareUrlString = `https://twitter.com/intent/tweet?text=Hey! I found this amazing place on FarWide. Check it out.&url=${encodeURIComponent(shareUrl)}`;
    }
    onShareClose()

    // Open share dialog in a new window
    window.open(shareUrlString, "_blank", "width=600,height=400");
  };

  const handleShareEmail = () => {
    const emailSubject = "Hey, I took an outdoor trip to this amazing property. ";
    const emailBody = `I think you will love it too.:\n\n${shareUrl}`;

    const mailtoLink = `mailto:?subject=${encodeURIComponent(emailSubject)}&body=${encodeURIComponent(emailBody)}`;

    onShareClose()

    window.location.href = mailtoLink; // Opens default mail client
  };

  const handleCopyLink = () => {
    // Copy the URL to the clipboard
    navigator.clipboard.writeText(shareUrl);

    onShareClose()

    // toast.success("Link Copied!", { theme: "colored" });
    toastMessage(errorAndHelperText.link_copy.success)
  };

  return (
    <Box sx={{ display: "flex", gap: 1 }}>
      {/* Share Button */}
      <Box>
        <IconButton
          sx={{ background: "var(--grey300)", borderRadius: 1, ...sx }}
          onClick={onShareClick}
        >
          <Icon.Share />
        </IconButton>
      </Box>

      {/* Share Menu */}
      <Popover
        open={Boolean(shareAnchorEl)}
        anchorEl={shareAnchorEl}
        onClose={onShareClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        sx={{ marginLeft: "1rem" }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        <Paper
          elevation={3}
          sx={{
            pt: 1,
            pb: 1,
            boxShadow: "0 0 10px 0 rgba(0,0,0,0.15)",
          }}
        >
          <MenuItem onClick={onShareClose} sx={{ fontSize: "16px", fontWeight: 500 }}>
            Share
          </MenuItem>
          <MenuItem onClick={() => handleShare("facebook")}>
            <Icon.FacebookRounded />
            &nbsp; Facebook
          </MenuItem>
          <MenuItem onClick={() => handleShare("twitter")}>
            <Icon.X />
            &nbsp; X (formerly Twitter)
          </MenuItem>
          <MenuItem onClick={handleShareEmail}>
            <Icon.Email />
            &nbsp; Email
          </MenuItem>
          <MenuItem onClick={handleCopyLink}>
            <Icon.ContentCopyOutlined />
            &nbsp; Copy Link
          </MenuItem>
        </Paper>
      </Popover>
    </Box>
  );
};

export default ShareButton;
