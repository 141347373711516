import { Box, Divider, Typography } from "@mui/material";
import * as Icon from '@mui/icons-material';
import { empty, extractDataFromArray, isValueEqual } from "../../utils/common";
import { styled } from '@mui/material/styles';
import CustomTooltip from "../CustomTooltip";


const ColumnBox = styled(Box)(
    ({ theme }) => `
        display: flex;
        gap: 0.5rem;
        flex: 50%;
        ${theme.breakpoints.down("sm")} {
            flex: 100%;
          }
        width: 100%;
        margin-bottom: 0.5rem;
  `,
);


const AddOns = ({ title, icon, value, tooltip, titleDelimiter = ":" }) => {

    return (
        <>
            <ColumnBox sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }} >
                <Icon.ArrowForward />
                <Typography color="grey" sx={{}}> {title} </Typography>
                <Typography color="grey"> {value}</Typography>
                {
                    !empty(tooltip) && <CustomTooltip content={tooltip} />
                }

            </ColumnBox>

        </>
    )
}
const HuntingDetails = ({ packageData, activityData }) => {
    const isAmentitiesAvailable = isValueEqual(extractDataFromArray(activityData, ['amenities', 'tree_stands', 'available'], 'No'), 'Yes') &&
        isValueEqual(extractDataFromArray(activityData, ['amenities', 'ground_blinds', 'available'], 'No'), 'Yes') &&
        isValueEqual(extractDataFromArray(activityData, ['amenities', 'game_feeders', 'available'], 'No'), 'Yes') &&
        isValueEqual(extractDataFromArray(activityData, ['amenities', 'game_cameras', 'available'], 'No'), 'Yes')

    const isMethodAvailable = isValueEqual(extractDataFromArray(packageData, ['hunting_method', 'archery', 'available'], 'No'), 'Yes') &&
        isValueEqual(extractDataFromArray(packageData, ['hunting_method', 'firearms', 'available'], 'No'), 'Yes')

    const isSpeciesAvailable = !empty(extractDataFromArray(packageData, ['species'], []))

    if (!isAmentitiesAvailable && !isMethodAvailable && !isSpeciesAvailable) {
        return <></>
    }

    return (
        <>
            <Typography variant="h5" fontWeight={600}>
                Hunting Details
            </Typography>
            <Divider sx={{ mb: 3, mt: 2 }} />
            {
                isMethodAvailable &&

                <Box sx={{ mb: 1 }}>
                    <Typography sx={{ fontWeight: 700, mb: 1 }}>Hunting Method</Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        {
                            isValueEqual(extractDataFromArray(packageData, ['hunting_method', 'firearms', 'available'], 'No'), 'Yes') &&
                            <AddOns
                                title={"Firearms"}
                                tooltip={extractDataFromArray(packageData, ['hunting_method', 'firearms', 'additional_info'], '')}
                            />
                        }
                        {
                            isValueEqual(extractDataFromArray(packageData, ['hunting_method', 'archery', 'available'], 'No'), 'Yes') &&
                            <AddOns
                                title={"Archery"}
                                tooltip={extractDataFromArray(packageData, ['hunting_method', 'archery', 'additional_info'], '')}
                            />
                        }
                    </Box>
                </Box>
            }
            {
                isAmentitiesAvailable &&

                <Box sx={{ mb: 1 }}>
                    <Typography sx={{ fontWeight: 700, mb: 1 }}>Hunting Amenities</Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                        {
                            isValueEqual(extractDataFromArray(activityData, ['amenities', 'tree_stands', 'available'], 'No'), 'Yes') &&
                            <AddOns
                                title={"Tree Stands"}
                                value={extractDataFromArray(activityData, ['amenities', 'tree_stands', 'total'], [])}
                                tooltip={extractDataFromArray(activityData, ['amenities', 'tree_stands', 'additional_info'], '')}
                            />
                        }
                        {
                            isValueEqual(extractDataFromArray(activityData, ['amenities', 'ground_blinds', 'available'], 'No'), 'Yes') &&
                            <AddOns
                                title={"Ground Blinds"}
                                value={extractDataFromArray(activityData, ['amenities', 'ground_blinds', 'total'], [])}
                                tooltip={extractDataFromArray(activityData, ['amenities', 'ground_blinds', 'additional_info'], '')}
                            />
                        }
                        {
                            isValueEqual(extractDataFromArray(activityData, ['amenities', 'game_feeders', 'available'], 'No'), 'Yes') &&
                            <AddOns
                                title={"Game Feeders"}
                                value={extractDataFromArray(activityData, ['amenities', 'game_feeders', 'total'], [])}
                                tooltip={extractDataFromArray(activityData, ['amenities', 'game_feeders', 'additional_info'], '')}
                            />
                        }
                        {
                            isValueEqual(extractDataFromArray(activityData, ['amenities', 'game_cameras', 'available'], 'No'), 'Yes') &&
                            <AddOns
                                title={"Game Cameras"}
                                value={extractDataFromArray(activityData, ['amenities', 'game_cameras', 'total'], [])}
                                tooltip={extractDataFromArray(activityData, ['amenities', 'game_cameras', 'additional_info'], '')}
                            />
                        }
                    </Box>
                </Box>
            }
            {
                isSpeciesAvailable &&

                <Box sx={{ mb: 1 }}>
                    <Typography sx={{ fontWeight: 700, mb: 1 }}>Included species</Typography>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', color: 'var(--grey900)' }}>
                        {
                            extractDataFromArray(packageData, ['species'], []).map((sp, i) => {
                                return <AddOns key={"species_" + i} title={sp} titleDelimiter={""} />
                            })
                        }
                    </Box>
                </Box>
            }
        </>
    )
}

export default HuntingDetails;
