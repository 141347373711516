import { Box, Grid, Typography } from "@mui/material"
import ConservationTrustData from './conservationData.json'
import { decodeHTML, extractDataFromArray } from "../../utils/common";
import MediaCard from "../../commonComponents/previewPageComponents/mediaCard";
import { RESPONSIVE_SPACING } from "../../commonComponents/CommonConstants/commonKeys";
import { CommonContext } from "../../store/context/commonContext";
import { useEffect, useState } from "react";

const ConservationTrust = () => {
    const { screenWidth } = CommonContext()
    const [height, setHeight] = useState('477px')
    useEffect(() => {
        if (screenWidth <= 900 && screenWidth > 768) {
            setHeight('300px')
        } else if (screenWidth <= 768) {
            setHeight('200px')
        } else {
            setHeight('477px')
        }
    }, [screenWidth])

    return (
        <>
            <Box
                className="home_wrapper"
                style={{
                    backgroundImage: "url('../assets/images/homepage/homepage_bg.png')",
                }}
                sx={{ pt: '170px', backgroundColor: 'white !important', backgroundRepeat: 'no-repeat !important', backgroundSize: "cover !important", display: 'flex', justifyContent: 'center' }}
            >
                <Box sx={{
                    width: '100%',
                    display: "flex",
                    flexDirection: 'column',
                    justifyContent: 'center',
                    gap: 6,
                    px: RESPONSIVE_SPACING,
                    maxWidth: 'lg'
                }}>
                    <Typography variant="h3" sx={{ textAlign: 'center' }}>
                        {/* {extractDataFromArray(ConservationTrustData, ['featured', 'title'], '')} */}
                        {/* not using above line as it needs customisation (line break)  */}
                        What is the <br />
                        FarWide Conservation Trust?
                    </Typography>
                    <Box sx={{
                        width: '100%',
                        display: "flex",
                        justifyContent: 'center',

                    }}>
                        <MediaCard
                            imageObj={extractDataFromArray(ConservationTrustData, ['featured', 'media'], {})}
                            width={'100%'}
                            height={height}
                            borderRadius={'16px'}
                            iconSize={'6rem'}
                            preview={true}
                        />
                    </Box>
                    <Typography variant="h6" color="#000000" sx={{ textAlign: 'center', fontWeight: 400 }}>{extractDataFromArray(ConservationTrustData, ['featured', 'caption'], '')}</Typography>
                </Box>
            </Box>
            <Box sx={{ px: RESPONSIVE_SPACING, display: 'flex', justifyContent: 'center', mb: 6 }}>
                <Box sx={{
                    maxWidth: 'lg',
                    width: '100%'
                }}>
                    <Grid container spacing={3}>
                        {
                            extractDataFromArray(ConservationTrustData, ['conservations', 'items'], []).map((item, index) => (
                                <>
                                    <Grid item xs={12} sm={6} md={4} key={index}>
                                        <MediaCard
                                            imageObj={item.media}
                                            height={'15.75rem'}
                                            width={'100%'}
                                            borderRadius={'1rem'}
                                            iconSize="4.5rem"
                                            preview={true}
                                        />
                                        <Typography
                                            variant="h5"
                                            sx={{
                                                color: '#1B1B1B',
                                                fontWeight: "bold",
                                                mb: 1.5,
                                                mt: 4,
                                            }}
                                        >
                                            {item.caption}
                                        </Typography>
                                        <Typography
                                            variant="h6"
                                            sx={{
                                                color: "#1B1B1B",
                                                fontWeight: 400,
                                                fontSize: '1rem'
                                            }}
                                            dangerouslySetInnerHTML={{ __html: item.description }}
                                        />

                                    </Grid>
                                </>
                            ))
                        }
                    </Grid>
                </Box>
            </Box>

        </>
    )
}

export default ConservationTrust;