import { Dialog } from "@mui/material";
import ApprovalContent from "./ApprovePopup";
import DeclineContent from "./DeclinePopup";
import SpamContent from "./SpamPopup";
import MoveToPendingContent from "./MoveToPendingPopup";

const AdminListingPopup = ({ open, handleClose, type, listing_seq, listing_type, setCurrentStatus }) => {
    return (
        <Dialog open={open} onClose={handleClose} maxWidth="lg">

            {
                type === 'V' && <ApprovalContent handleClose={handleClose} listing_seq={listing_seq} listing_type={listing_type} setCurrentStatus={setCurrentStatus} />
            }
            {
                type === 'D' && <DeclineContent handleClose={handleClose} listing_seq={listing_seq} listing_type={listing_type} setCurrentStatus={setCurrentStatus} />       
            }
            {
                type === 'S' && <SpamContent handleClose={handleClose} listing_seq={listing_seq} listing_type={listing_type} setCurrentStatus={setCurrentStatus}/>
            }
            {
                type === 'P' && <MoveToPendingContent handleClose={handleClose} listing_seq={listing_seq} listing_type={listing_type} setCurrentStatus={setCurrentStatus}/>
            }

        </Dialog>
    )
}


export default AdminListingPopup;