import React, { useEffect, useState } from "react";
import { Box, Button, Chip, Divider, Typography, Table, TableContainer, TableHead, TableRow, TableCell, TableBody, Paper, Skeleton } from "@mui/material";
import * as Icon from "@mui/icons-material";
import { capitalizeFirstLetter, empty, extractDataFromArray, isEmpty } from "../../utils/common";
import TransactionService from "../../services/transaction";
import { useNavigate, useParams } from "react-router-dom";
import ReactToPrint from 'react-to-print';
import { Link } from "react-router-dom";
import moment from "moment";
import { generatePhrase } from "../../utils/common";
import { CommonContext } from "../../store/context/commonContext";
import { DEFAULT_LOGO_SIZE, SMALL_LOGO_SIZE } from "../CommonConstants/commonKeys";
import CreateChip from "../CreateChip";


const Transactionsdetails = () => {
  const { transaction_seq } = useParams();
  const [transaction, setTransaction] = useState([]);
  const [loading, setLoading] = useState(true)
  const [ownerResultString, setOwnerResultString] = useState('');
  const [resultString, setResultString] = useState('');
  const navigate = useNavigate()
  const [packageType, setPackageType] = useState('')
  const [addons, setAddons] = useState([])
  const { isMobile, isTablet, screenWidth } = CommonContext();

  const handleDownloadpdf = () => {
    // console.log("clicked")
    TransactionService.DownloadInvoice(transaction_seq, (data) => {
      try {
        const blob = new Blob([data.data], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);

        // Create a temporary anchor element
        const a = document.createElement('a');
        a.href = url;
        a.download = `invoice_${transaction_seq}.pdf`; // Set the download attribute with desired filename
        a.click(); // Simulate click to trigger download
        URL.revokeObjectURL(url); // Release the object URL
      } catch (error) {
        console.error('Error downloading PDF:', error);
      }
    }).catch(error => {
      console.error('Error fetching PDF data:', error);
    });
  }


  useEffect(() => {

    if (transaction.length === 0) {
      if (!empty(extractDataFromArray(transaction, ['transaction_seq'], transaction_seq))) {
        TransactionService.getTransactionDetails(extractDataFromArray(transaction, ['transaction_seq'], transaction_seq), (data) => {
          setTransaction(data);
          setAddons(extractDataFromArray(data, ["records", 'addons'], []))

          setLoading(false)
        }, (errorMessage) => {
          navigate('/unauthorized', { state: { errorMessage } });
        });

      }
    }

  }, [transaction_seq, transaction]);

  const printRef = React.createRef();

  const generateOwnerAddress = () => {
    if (transaction && transaction.records) {
      const ownerAddressLine1 = extractDataFromArray(transaction, ["records", "owner_address_line1"], "");
      const ownerAddressLine2 = extractDataFromArray(transaction, ["records", "owner_address_line2"], "");
      const ownerCity = extractDataFromArray(transaction, ["records", "owner_city"], "");
      const ownerState = extractDataFromArray(transaction, ["records", "owner_state"], "");

      const parts = [];

      if (ownerAddressLine1 !== "") {
        parts.push(ownerAddressLine1);
      }

      if (ownerAddressLine2 !== "") {
        parts.push(ownerAddressLine2);
      }

      if (ownerCity !== "") {
        parts.push(ownerCity);
      }

      if (ownerState !== "") {
        parts.push(ownerState);
      }

      return parts.join(', ');

    }
  };


  useEffect(() => {
    // Call the function when the transaction data changes
    // console.log("transaction", transaction);
    const newOwnerResultString = generateOwnerAddress();
    setOwnerResultString(newOwnerResultString);
    const newResultString = generateBillingAddress();
    setResultString(newResultString);
    setPackageType(extractDataFromArray(transaction, ["records", 'package_type'], ''))

  }, [transaction]);



  const generateBillingAddress = () => {
    if (transaction && transaction.records) {
      const billingAddressLine1 = extractDataFromArray(transaction, ["records", "billing_address_line1"], "");
      const billingAddressLine2 = extractDataFromArray(transaction, ["records", "billing_address_line2"], "");
      const billingCity = extractDataFromArray(transaction, ["records", "billing_city"], "Pune");
      const billingState = extractDataFromArray(transaction, ["records", "billing_state"], "");

      const parts = [];
      if (billingAddressLine1 !== "") {
        parts.push(billingAddressLine1);
      }

      if (billingAddressLine2 !== "") {
        parts.push(billingAddressLine2);
      }

      if (billingCity !== "") {
        parts.push(billingCity);
      }

      if (billingState !== "") {
        parts.push(billingState);
      }
      return parts.join(', ');
    }
  };



  const parsedDate = moment.utc(extractDataFromArray(transaction, ["records", "invoice_date"], ""), "YYYY-MM-DD HH:mm:ss").local();
  const formattedInvoiceDate = parsedDate.isValid() ? parsedDate.format("DD MMM, YYYY h:mmA") : '--/--/----';
  const days = extractDataFromArray(transaction, ["records", 'package_duration'], '')
  const guests = extractDataFromArray(transaction, ["records", 'guest_count'], '')
  const billingCountry = extractDataFromArray(transaction, ["records", "billing_country"], "")
  const paymentStatusCode = extractDataFromArray(transaction, ['records', 'payment_status'], '')
  const paymentStatusText = extractDataFromArray(transaction, ['records', 'payment_status_caption'], '')

  // console.log("country", billingCountry)

  const getChipStyles = (paymentStatusCode) => {
    // console.log("staussss", paymentStatus)
    switch (paymentStatusCode) {
      case 'S':
        return {
          backgroundColor: "#26C77A",
          color: "white",
        };
      case 'F':
        return {
          backgroundColor: "#e94a4a",
          color: "white",
        };
      case 'R':
        return {
          // label: paymentStatusText,
          backgroundColor: "#FFFFFF",
          color: "#26C77A",
          border: "1px solid #26C77A",
        };
      case 'C':
        return {
          // label: paymentStatusText,
          backgroundColor: "#FFFFFF",
          color: "#26C77A",
          border: "1px solid #26C77A",
        };
      case 'RC':
        return {
          // label: paymentStatusText,
          // backgroundColor: "#FFFFFF", 
          // color: "#26C77A",  
          // border: "1px solid #26C77A",
        };
      case 'RF':
        return {
          // label: paymentStatusText,
          // backgroundColor: "#FFFFFF", 
          // color: "#26C77A",  
          // border: "1px solid #26C77A",
        };
      case 'RP':
        return {
          // label: paymentStatusText,
          // backgroundColor: "#FFFFFF", 
          // color: "#26C77A",  
          // border: "1px solid #26C77A",
        };
      case 'RA':
        return {
          // label: paymentStatusText,
          // backgroundColor: "#FFFFFF", 
          // color: "#26C77A",  
          // border: "1px solid #26C77A",
        };
      case 'I':
        return {
          backgroundColor: "#FFFFFF",
          color: "#FF962D",
          border: "1px solid #FF962D"
        };
      case 'A':
        return {
          backgroundColor: "#FF962D",
          color: "#FFFFFF",
        };
      default:
        return null;
    }
  };


  const chipStyles = getChipStyles(paymentStatusCode);

  const findAddonPrice = (addonId, priceBreakdown) => {
    const matchingAddon = priceBreakdown.addons.find((item) => item.id === addonId);
    return matchingAddon ? matchingAddon.price : 0;  // Return the price if found, otherwise 0
  };

  const calculateSubtotal = (priceBreakdown) => {
    // Extract package amount from price breakdown
    const packageAmount = parseFloat(priceBreakdown.package_amount) || 0;

    // Calculate total addon prices, if addons exist and are not empty
    const addonsTotal = priceBreakdown.addons && priceBreakdown.addons.length > 0
      ? priceBreakdown.addons.reduce((total, addon) => total + parseFloat(addon.price || 0), 0)
      : 0;

    // Calculate subtotal by adding package amount and total addons price
    const subtotal = packageAmount + addonsTotal;

    return subtotal.toFixed(2); // Return subtotal as a string with 2 decimal places
  };


  const subtotal = calculateSubtotal(extractDataFromArray(transaction, ["records", "price_breakdown"]));
  // console.log("Subtotal: $", subtotal);
  // console.log("paymentStatusCode :- ", paymentStatusCode);
  // console.log("paymentStatusText :- ", paymentStatusText);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          border: "1px solid #fff",
          gap: '16px',
          alignItems: "center",
          mb: 2
        }}
      > <Link to={`/transactions`} style={{ height: '24px' }} >
          <Icon.ArrowBack sx={{ color: "#000" }} /></Link>
        <Typography variant="h4">
          Transactions Details
        </Typography>
      </Box>
      {loading ?
        <Skeleton variant="rectangular" sx={{ borderRadius: theme => `${theme.card.borderRadius}`, height: 800 }} />
        :
        <Box

          sx={{
            border: "1px solid #E5E7EB",
            borderRadius: "16px",
            width: "100%",
            position: "relative",
            marginBottom: 10
          }}
        >
          <Box sx={{ height: "100%", pl: 3, pr: 3, pt: 3, pb: 8 }} ref={printRef}  >
            <Box sx={{ display: "flex" }}>
              <Box >
                <img
                  src="../../assets/images/farwide-new-logo.svg"
                  alt="logo"
                  style={{
                    ... (isTablet ? SMALL_LOGO_SIZE : DEFAULT_LOGO_SIZE),
                    marginBottom: "24px",
                  }}
                />
              </Box>
              <Box
                sx={{ marginLeft: "auto", textAlign: "right", color: "#1B1B1B" }}
              >
                Invoice    {extractDataFromArray(transaction, ["records", "invoice_no"], "")}
                <br />
                {
                  chipStyles ?
                    <>
                      {/* <Chip
                        size="small"
                        label={paymentStatusText}
                        style={chipStyles}
                      /> */}
                      <CreateChip chipType={paymentStatusCode} caption={paymentStatusText} sx={{ mb: 1 }} />
                    </>
                    : ""
                }

              </Box>
            </Box>
            <Box>
              <Typography sx={{ color: "grey" }}>
                {extractDataFromArray(transaction, ['records', 'owner_fname'], '') + " " + extractDataFromArray(transaction, ['records', 'owner_lname'], '')}
              </Typography>
              <Typography sx={{ color: "grey" }}>
                {ownerResultString}{ownerResultString ? "," : ""}
              </Typography>
              <Typography sx={{ color: "var(--textGrey)" }}>
                {extractDataFromArray(transaction, ["records", "owner_country"], "")}{ownerResultString ? "." : ""}
              </Typography>
              <Typography sx={{ color: "var(--textGrey)" }}>
                Phone :   {extractDataFromArray(transaction, ["records", "owner_phone"], "")}
              </Typography>
            </Box>
            <Divider sx={{ mb: 3, mt: 3 }} />
            <Box sx={{ display: "flex", mb: 6, flexDirection: screenWidth < 835 ? 'column' : 'row' }}>
              <Box>
                <Typography sx={{ color: "#1B1B1B", fontWeight: "600", mb: 1 }}>
                  Billed To:
                </Typography>
                <Typography sx={{ color: "grey" }}>
                  {extractDataFromArray(transaction, ['records', 'billing_name'], '')}
                </Typography>
                <Typography sx={{ color: "grey" }}>
                  {resultString}
                </Typography>
                <Typography sx={{ color: "var(--textGrey)" }}>
                  {extractDataFromArray(transaction, ["records", "billing_country"], "")} {billingCountry ? "." : ""}
                </Typography>
                <Typography sx={{ color: "var(--textGrey)" }}>
                  Phone :   {extractDataFromArray(transaction, ["records", "billing_phone"], "")}
                </Typography>
                <Typography sx={{ color: "#85878A", wordWrap: 'break-word' }}>
                  Email :    {extractDataFromArray(transaction, ["records", "billing_email"], "")}
                </Typography>
              </Box>
              <Box sx={{ marginLeft: screenWidth < 835 ? '' : "auto", textAlign: screenWidth < 835 ? 'left' : "right", mt: screenWidth < 835 ? 3 : '' }}>
                <Typography sx={{ color: "#85878A" }}>Invoice Date:</Typography>
                <Typography sx={{ color: "#1B1B1B", mb: 3 }}>
                  {formattedInvoiceDate}
                </Typography>

                <Typography sx={{ color: "#85878A" }}>Order No:</Typography>
                <Typography sx={{ color: "#1B1B1B", mb: 3 }}> {extractDataFromArray(transaction, ["records", "order_id"], "")}

                </Typography><Typography sx={{ color: "#85878A" }}>Booking ID</Typography>
                <Typography sx={{ color: "#1B1B1B", wordWrap: "break-word" }}> {extractDataFromArray(transaction, ["records", "booking_id"], "")}</Typography>

                <Box></Box>
              </Box>
            </Box>
            <Typography
              sx={{ color: "#1B1B1B", fontWeight: "700" }}
            >
              Order Summary
            </Typography>
            <TableContainer
              sx={{ border: "1px solid #fff", boxShadow: "none" }}
              component={Paper}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: "900" }}>No</TableCell>
                    <TableCell sx={{ fontWeight: "900" }}>Item</TableCell>
                    <TableCell sx={{ fontWeight: "900", textAlign: 'end' }}>Price</TableCell>
                    <TableCell sx={{ fontWeight: "900", textAlign: 'end' }}>Quantity</TableCell>
                    <TableCell sx={{ fontWeight: "900", textAlign: 'end' }}>Total</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell>
                      <Typography>01</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ color: "#1B1B1B", fontWeight: "500" }}>
                        {extractDataFromArray(transaction, ["records", "package_name"], "")}
                      </Typography>
                      <Typography sx={{ color: "#85878A" }}>
                        {/* {`${days} ${days == 1 ? 'Day' : 'Days'}` + " x " + `${guests} ${guests == 1 ? 'Guest' : 'Guests'}`} */}
                        {`${generatePhrase(days, 'Day')}` + " x " + `${generatePhrase(guests, "Guest")}`}
                      </Typography>
                      {
                        packageType &&
                        // <Chip variant="outlined" size="small" label={capitalizeFirstLetter(packageType)} sx={{ borderColor: '#979797', color: '#747579' }} />
                        <CreateChip size="small" chipType={capitalizeFirstLetter(packageType)} caption={capitalizeFirstLetter(packageType)} sx={{ backgroundColor: 'white', border: '1px solid #979797', color: '#747579' }} />
                      }
                    </TableCell>
                    <TableCell>
                      <Typography sx={{ textAlign: 'end' }}> ${parseFloat(extractDataFromArray(transaction, ["records", "price_breakdown", "package_amount"], "")).toFixed(2)}  </Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: 'end' }}>
                      {" "}
                      <Typography >{extractDataFromArray(transaction, ["records", "quantity"], "")} </Typography>
                    </TableCell>
                    <TableCell>
                      {" "}
                      <Typography sx={{ textAlign: 'end' }}> ${parseFloat(extractDataFromArray(transaction, ["records", "price_breakdown", "package_amount"], "")).toFixed(2)}  </Typography>
                    </TableCell>
                  </TableRow>
                  {
                    packageType === 'experience' && !isEmpty(addons) &&
                    <TableRow

                      sx={{
                        borderBottom: 'none',
                      }}
                    >
                      <TableCell sx={{ borderBottom: 'none', paddingTop: '16px', paddingBottom: '0px' }} >
                        <Typography></Typography>
                      </TableCell>
                      <TableCell sx={{ borderBottom: 'none', paddingTop: '16px', paddingBottom: '0px' }} >
                        <Typography sx={{ color: "#1B1B1B", fontWeight: "500" }}>
                          Add-on
                        </Typography>
                        <Typography sx={{ color: "#85878A" }}>

                        </Typography>
                      </TableCell>
                      <TableCell sx={{ borderBottom: 'none', paddingTop: '16px', paddingBottom: '0px' }}>
                        <Typography sx={{ textAlign: 'end' }}> </Typography>
                      </TableCell>
                      <TableCell sx={{ textAlign: 'end', borderBottom: 'none', paddingTop: '16px', paddingBottom: '0px' }}>
                        {" "}
                        <Typography > </Typography>
                      </TableCell>
                      <TableCell sx={{ borderBottom: 'none', paddingTop: '16px', paddingBottom: '0px' }} >
                        {" "}
                        <Typography sx={{ textAlign: 'end' }}> </Typography>
                      </TableCell>
                    </TableRow>
                  }
                  {
                    packageType === 'experience' && !isEmpty(addons) &&

                    addons.map((addon, index) =>

                      <TableRow
                        key={addon.id}
                        sx={{
                          borderBottom: index === addons.length - 1 ? '1px solid rgba(224, 224, 224, 1)' : 'none',

                        }}
                      >
                        <TableCell sx={{ borderBottom: 'none', paddingBlock: '0px', paddingBottom: index === addons.length - 1 ? '16px' : '0px' }} >
                          <Typography></Typography>
                        </TableCell>
                        <TableCell sx={{ borderBottom: 'none', paddingBlock: '0px', paddingBottom: index === addons.length - 1 ? '16px' : '0px' }} >
                          <Typography sx={{ color: "#1B1B1B", fontWeight: "500" }}>

                          </Typography>
                          <Typography sx={{ color: "#85878A", textWrap: 'nowrap' }}>
                            {addon.title}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ borderBottom: 'none', paddingBlock: '0px', paddingBottom: index === addons.length - 1 ? '16px' : '0px' }}>
                          <Typography sx={{ textAlign: 'end' }}> ${parseFloat(extractDataFromArray(addon, ["price"], "")).toFixed(2)}  </Typography>
                        </TableCell>
                        <TableCell sx={{ textAlign: 'end', borderBottom: 'none', paddingBlock: '0px', paddingBottom: index === addons.length - 1 ? '16px' : '0px' }}>
                          {" "}
                          <Typography >{extractDataFromArray(addon, ["selected_quantity"], "")} </Typography>
                        </TableCell>
                        <TableCell sx={{ borderBottom: 'none', paddingBlock: '0px', paddingBottom: index === addons.length - 1 ? '16px' : '0px' }} >
                          {" "}
                          <Typography sx={{ textAlign: 'end' }}> ${parseFloat(findAddonPrice(addon.id, transaction.records.price_breakdown)).toFixed(2)}  </Typography>
                        </TableCell>
                      </TableRow>

                    )

                  }
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    <TableCell sx={{ textAlign: 'end' }}>
                      {" "}
                      <Typography sx={{ textWrap: 'nowrap' }} > Sub Total</Typography>
                      {/* <Typography>Discount </Typography>
                      <Typography>Tax </Typography> */}
                      <Typography sx={{ textWrap: 'nowrap' }} >Platform Fee (5%)</Typography>
                    </TableCell>
                    <TableCell sx={{ textAlign: 'end' }}>
                      {" "}
                      <Typography>${subtotal}  </Typography>
                      {/* <Typography>${parseFloat(extractDataFromArray(transaction, ["records", "discount"], "")).toFixed(2)} </Typography>
                      <Typography>${parseFloat(extractDataFromArray(transaction, ["records", "tax"], "")).toFixed(2)}</Typography> */}
                      <Typography>${parseFloat(extractDataFromArray(transaction, ["records", "price_breakdown", "booking_fee"], "")).toFixed(2)} </Typography>
                    </TableCell>
                  </TableRow>
                  <TableRow sx={{ borderBottom: "1px solid red !important" }}>
                    <TableCell sx={{ borderBottom: "1px solid #fff" }}></TableCell>
                    <TableCell sx={{ borderBottom: "1px solid #fff" }}></TableCell>
                    <TableCell sx={{ borderBottom: "1px solid #fff" }}></TableCell>
                    <TableCell sx={{ textAlign: 'end', borderBottom: "1px solid #fff" }}>
                      {" "}
                      <Typography sx={{ color: "#1B1B1B", fontWeight: "500" }}>
                        Total{" "}
                      </Typography>
                    </TableCell>
                    <TableCell sx={{ borderBottom: "1px solid #fff" }}>
                      {" "}
                      <Typography
                        variant="h6"
                        sx={{
                          color: "#1B1B1B",
                          fontWeight: "700",
                          textWrap: 'nowrap',
                          textAlign: 'end'
                        }}
                      >
                        $ {parseFloat(extractDataFromArray(transaction, ["total"], "")).toFixed(2)}
                      </Typography>

                    </TableCell>

                  </TableRow>


                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end", // To horizontally right-align the button
              textAlign: 'right',
              margin: 'auto',
              position: "absolute",
              bottom: 0,
              right: "24px",
              paddingBottom: 2
            }}
          >

            <ReactToPrint
              trigger={() => <Button

                variant="contained"
                size="small"
                sx={{ borderRadius: "4px" }}
              >
                Print
              </Button>}
              content={() => printRef.current}
            />
            {/* <Button variant="light" startIcon={<Icon.SimCardDownloadOutlined />} onClick={() => { handleDownloadpdf() }}>Download Invoice</Button> */}
          </Box>


        </Box>
      }

    </>
  );
};

export default Transactionsdetails;



