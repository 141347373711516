import axios from "axios";
import config from "../config";
import { empty, extractDataFromArray, parseResponseAxios, toastMessage } from "../utils/common";
import errorAndHelperText from "../commonComponents/CommonConstants/errorAndHelperText.json"

const Profile = {

	getInterestDetails: async (callback = () => { }) => {
		await axios.get(config.apiUrl + "/services/api", {
			params: {
				cmd: "getInterestDetails"
			}
		}).then((response) => {
			response = parseResponseAxios(response)
			if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
				callback(extractDataFromArray(response, ['data'], []))
			}
		});
	},

	getProfileDetails: async (callback = () => { }) => {
		await axios
			.get(config.apiUrl + "/services/api", {
				params: {
					cmd: "getProfileDetails",
				},
			})
			.then((response) => {
				response = parseResponseAxios(response);
				if (extractDataFromArray(response, ["errorCode"], 0) === 0) {
					callback(extractDataFromArray(response, ["data"], []));
				}
			});
	},

	saveProfile: async (
		guest_seq,
		fname,
		lname,
		phone,
		emergency_contact,
		email,
		is_like_to_rent_land,
		bio,
		address_line1,
		address_line2,
		city,
		state,
		country,
		zipcode,
		callback = () => { }
	) => {

		const formData = new FormData();
		formData.append("guest_seq", guest_seq);
		formData.append("fname", fname);
		formData.append("lname", lname);
		formData.append("phone", phone);
		formData.append("emergency_contact", emergency_contact);
		formData.append("email", email);
		formData.append("is_like_to_rent_land", is_like_to_rent_land);
		formData.append("bio", bio);
		formData.append("address_line1", address_line1);
		formData.append("address_line2", address_line2);
		formData.append("city", city);
		formData.append("state", state);
		formData.append("country", country);
		formData.append("zipcode", zipcode);

		// formData.append("listing_images", listing_images);

		await axios
			.post(config.apiUrl + "/services/api?cmd=saveProfile", formData)

			.then((response) => {
				response = parseResponseAxios(response);

				if (extractDataFromArray(response, ["errorCode"], 0) === 0) {
					const data = extractDataFromArray(response, ["data"], []);

					callback(data);
				}
			});
	},
	updateProfile: async (fname, lname, phone, emergency_contact, email, is_like_to_rent_land, bio, address_line1, address_line2, city, state, country, zipcode, profile_pic, stateCode, countryCode, callback = () => { }
	) => {
		const formData = new FormData();

		formData.append("fname", fname);
		formData.append("lname", lname);
		formData.append("phone", phone);
		formData.append("emergency_contact", emergency_contact);
		formData.append("email", email);
		formData.append("bio", bio);
		formData.append("is_like_to_rent_land", is_like_to_rent_land);
		formData.append("address_line1", address_line1);
		formData.append("address_line2", address_line2);
		formData.append("city", city);
		formData.append("state", state);
		formData.append("country", country);
		formData.append("zipcode", zipcode);
		formData.append("profile_pic", profile_pic);
		formData.append("country_code", countryCode);
		formData.append("state_code", stateCode);

		// formData.append("listing_images", listing_images);

		await axios
			.post(config.apiUrl + "/services/api?cmd=updateProfile", formData)
			.then((response) => {
				response = parseResponseAxios(response);

				if (extractDataFromArray(response, ["errorCode"], 0) === 0) {
					const data = extractDataFromArray(response, ["data"], []);

					callback(data);
				} else {
					// toast.error("Failed to update profile", {
					// 	theme: "colored",
					// });
					toastMessage(errorAndHelperText.profile_update.error);
				}
			});
	},

	deleteProfile: async (callback = () => { }) => {
		await axios
			.post(config.apiUrl + "/services/api?cmd=deleteProfile")
			.then((response) => {

				response = parseResponseAxios(response);
				if (extractDataFromArray(response, ["errorCode"], 0) === 0) {
					callback(extractDataFromArray(response, ["data"], []));
				}
			});
	},

	saveInterests: async (seq, interested_activities, hunting_states, choosen_weapons, hunting_species,
		callback = () => { }) => {


		const formData = new FormData();
		formData.append('seq', seq);
		formData.append('interested_activities', JSON.stringify(interested_activities));
		formData.append('hunting_states', JSON.stringify(hunting_states));
		formData.append('choosen_weapons', JSON.stringify(choosen_weapons));
		formData.append('hunting_species', JSON.stringify(hunting_species));


		// formData.append("listing_images", listing_images);

		await axios.post(config.apiUrl + "/services/api?cmd=saveInterests", formData
		)

			.then((response) => {

				response = parseResponseAxios(response);

				if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
					const data = extractDataFromArray(response, ['data'], []);

					callback(data);
				}
			});
	},

	updateInterests: async (interested_activities = null, hunting_states = null, choosen_weapons = null, hunting_species = null,
		callback = () => { }, errCallback = () => { }) => {


		const formData = new FormData();
		!empty(interested_activities) && formData.append('interested_activities', JSON.stringify(interested_activities));
		!empty(hunting_states) && formData.append('hunting_states', JSON.stringify(hunting_states));
		!empty(choosen_weapons) && formData.append('choosen_weapons', JSON.stringify(choosen_weapons));
		!empty(hunting_species) && formData.append('hunting_species', JSON.stringify(hunting_species));


		// formData.append("listing_images", listing_images);

		await axios.post(config.apiUrl + "/services/api?cmd=saveInterests", formData
		)

			.then((response) => {

				response = parseResponseAxios(response);

				if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
					const data = extractDataFromArray(response, ['data'], []);
					callback(data);
				} else if (extractDataFromArray(response, ['errorCode'], 1) === 1) {
					const data = extractDataFromArray(response, ['errorMsg'], []);
					errCallback(data);
				}
			});
	},


	getCards: async (callback = () => { }) => {
		await axios.get(config.apiUrl + "/services/api", {
			params: {
				cmd: "getCards"
			},
		}).then((response) => {
			response = parseResponseAxios(response)
			if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
				callback(extractDataFromArray(response, ['data'], []))
			}
		});
	},
	getAllRegions: async (callback = () => { }) => {
		await axios.get(config.apiUrl + "/services/openapi", {
			params: {
				cmd: "getAllRegions"
			},
		}).then((response) => {
			response = parseResponseAxios(response)
			if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
				callback(extractDataFromArray(response, ['data'], []))
			}
		});
	},


};

export default Profile;