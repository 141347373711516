import React, { useState } from "react";
import {
	Box,
	Divider,
	FormGroup,
	Grid,
	IconButton,
	InputAdornment,
	MenuItem,
	Select,
	Tab,
	Tabs,
	TextField,
	Typography,
} from "@mui/material";
import * as Icon from "@mui/icons-material";
import { CommonContext } from "../../store/context/commonContext";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import AccountVerification from "../../commonComponents/AccountVerification";
import FeatureSectionCommon from "../home/section/FeatureSectionCommon";
import { ACCESS, ACCOUNT_VERIFICATION_TEXTS, EXPERIENCE } from "../../commonComponents/CommonConstants/commonKeys";
import activityData from '../home/section/titleSection.json'

const Dashboard = () => {
	const navigate = useNavigate();
	const { searchFilters, setSearchFilters } = CommonContext();
	const { isTablet } = CommonContext();

	const { setClikedBanner } = CommonContext();
	const [searchFiltersHome, setSearchFiltersHome] = useState(
		{
			activity: 'access', // Changed it from "A" to "activitySelected" while working on new search drop down menu
			kw: ''
		}
	);
	const [tabIndex, setTabIndex] = useState(0);
	const [selectedTab, setSelectedTab] = useState('access')

	const handleChange = (e) => {
		// console.log("e.target.value", e.target.value);
		setSearchFiltersHome({ ...searchFiltersHome, 'activity': e.target.value });
	};

	const handleTabChange = (event, newValue) => {
		setTabIndex(newValue);
		const tabs = ['access', 'experience', 'academy'];
		const newSelectedTab = tabs[newValue];
		setSelectedTab(newSelectedTab);

		// Update the searchFiltersHome.activity based on the selected tab
		setSearchFiltersHome(prevFilters => ({
			...prevFilters,
			activity: newSelectedTab
		}));
	};

	return (
		<>
			<AccountVerification
				verification_title={ACCOUNT_VERIFICATION_TEXTS.guest_common}
				sx={{ mb: 6 }} />
			<Box
				sx={{

					bgcolor: 'var(--grey300)',
					borderRadius: '8px',
					pb: 6,
					mb: '62px',
				}}
			>
				<Typography
					variant="h6"
					fontWeight="bold"
					fontSize='34px'
					textAlign="center"
					sx={{
						py: { xs: 2, sm: 3, md: 3, lg: 3 },
						// mt: '18px',
					}}

				>
					Search Properties
				</Typography>
				<Grid container sx={{ p: { xs: 0, md: 0 } }}>
					<Grid item xs={12} sm={12} md={12} sx={{ m: "auto", }}>
						<Box sx={{ display: 'flex', justifyContent: 'center', width: '100%', mb: 5 }}>
							<Box className="homePageTabs">
								<Tabs variant="scrollable" scrollButtons="auto" value={tabIndex} onChange={handleTabChange}>
									<Tab icon={<Box>
										<img src="/assets/images/icon_images/access.svg" />
									</Box>} aria-label="Access" label={"Access"} iconPosition={isTablet ? "top" : "start"} />
									<Tab icon={<Box>
										<img src="/assets/images/icon_images/experience.svg" />
									</Box>} aria-label="Experience" label={"Experience"} iconPosition={isTablet ? "top" : "start"} />
									{/* <Tab icon={<Box>
                                    <img src="/assets/images/icon_images/academy.svg" />
                                </Box>} aria-label="Academy" label={"Academy"} iconPosition={isTablet ? "top" : "start"} /> */}
								</Tabs>
								<Divider />
							</Box>
						</Box>
						<>
							<Box sx={{ margin: 'auto', width: '100%', display: 'flex', justifyContent: 'center' }}>
								<Box sx={{ mx: 2, width: '100%' }}>
									<FormGroup sx={{ alignItems: 'center', flexWrap: 'nowrap', flexDirection: 'row', justifyContent: 'center', border: 'solid 1px #C5C5C7', background: '#fff', borderRadius: '16px', maxWidth: '680px', m: 'auto', }}>
										<Select
											name="listing_sort"
											size="large"
											value={searchFiltersHome.activity}
											onChange={handleChange}
											IconComponent={Icon.KeyboardArrowDownOutlined}
											sx={{ border: 'none', "& fieldset": { border: 'none' }, m: 0, pt: 0, width: "19ch", mb: 0, mt: "0px", display: { xs: 'none', sm: 'none', md: 'block' } }}
										>
											{/* <MenuItem value={"A"}>All Activities</MenuItem> */}
											{Object.keys(activityData[selectedTab].activities).map((key, index) => {
												const value = activityData[selectedTab].activities[key].label;
												return (
													<MenuItem key={key} value={key}>{activityData[selectedTab].activities[key].label}</MenuItem>
												);
											})}
										</Select>
										<Divider orientation="vertical" variant="middle" flexItem sx={{ mt: 0, mb: 0, display: { xs: 'none', sm: 'none', md: 'block' } }} />
										<TextField
											className='top_hdr_search_input'
											placeholder={isTablet ? 'Search..' : 'Where would you like to go?'}
											variant="outlined"
											InputProps={{
												endAdornment: (
													<InputAdornment position="end">
														<IconButton variant="contained" style={{ background: "var(--primary)", color: "#fff" }} onClick={() => {
															window.location.href = window.location.origin + "/search?kw=" + (searchFiltersHome.kw || "") + "&at=" + (searchFiltersHome.activity === "A" ? '' : searchFiltersHome.activity || "") + "&type=" + activityData[selectedTab].label.toLowerCase()
														}}>
															<Icon.SearchOutlined />
														</IconButton>
													</InputAdornment>
												),
											}}
											sx={{ border: 'none', "& fieldset": { border: 'none' }, m: 0, pr: '8px', width: { xs: '100%', md: '520px', }, "& .MuiInputBase-root": { paddingRight: '0px' } }}
											size='large'
											value={searchFiltersHome.kw || ""}
											onInput={(e) => { setSearchFiltersHome({ ...searchFiltersHome, 'kw': e.target.value }); setSearchFilters({ ...searchFilters, 'kw': e.target.value }) }}
											onKeyDown={(e) => {
												if (e.key === 'Enter') {
													if (window.location.pathname !== "/search") {
														window.location.href = window.location.origin + "/search?kw=" + (searchFiltersHome.kw || "") + "&at=" + (searchFiltersHome.activity === "A" ? '' : searchFiltersHome.activity || "")
													}
												}
											}}
										/>
									</FormGroup>
									<Box sx={{ maxWidth: '680px', textAlign: 'right', margin: 'auto', mb: 5 }}>
										<Link to={`/search?at=${activityData[selectedTab].label.toLowerCase()}&type=${activityData[selectedTab].label.toLowerCase()}`}
											onClick={() => { setClikedBanner(true); setSearchFilters({ 'activity': activityData[selectedTab].label.toLowerCase() }); }}
											style={{ color: 'var(--black)', fontSize: '12px', borderBottom: 'solid 1px var(--black)', textDecoration: 'none' }}
										>
											Explore All
										</Link>
									</Box>
								</Box>
							</Box>
							<Box sx={{ overflow: 'auto', display: 'flex', pl: 2 }}>
								<Box sx={{ margin: 'auto', display: 'flex', gap: 2, justifyContent: { xs: 'initial', md: 'center' }, mb: 1 }}>
									{Object.keys(activityData[selectedTab].activities).map((key) => {
										if (key === 'access' || key === 'experience' || key === 'academy') return (
											null
										);

										return (
											<Box key={key} className="search_img_link" sx={{ textAlign: 'center' }}>
												<Link to={`/search?at=${key}&type=${activityData[selectedTab].label.toLowerCase()}`}
													onClick={() => { setClikedBanner(true); setSearchFilters({ 'activity': key }); }}

												>
													<Box className="search_img">
														<img src={activityData[selectedTab].activities[key].thumbnail_url} style={{ maxWidth: '100%' }} />
													</Box>
													<Typography>{activityData[selectedTab].activities[key].label}</Typography>
												</Link>
											</Box>
										)
									})}
								</Box>
							</Box>
						</>
					</Grid>
				</Grid>
			</Box>
			<Box sx={{ mb: '62px' }}>
				<FeatureSectionCommon type={ACCESS.value} />
			</Box>
			<Box>
				<FeatureSectionCommon type={EXPERIENCE.value} />
			</Box>
		</>
	);
};

export default Dashboard;
