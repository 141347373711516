
import * as Icon from "@mui/icons-material";
import { Box, Button, Grid, IconButton, InputAdornment, Link, TextField, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import config from "../../../config";
import { isEmpty } from "../../../utils/common";


const FarwideSearch = (props) => {
    const [search, setSearch] = useState("")
    return (
        <>
            <Grid container sx={{ 
                // mt: { xs: 6, md: 12 },
                 mb: { xs: 6, md: 12 }
                  }}>
                <Grid item xs={12} sm={12} md={12}>
                    <Box sx={{ backgroundImage: 'url(../assets/images/homepage/bg_farwide_search.jpg)', minHeight: '336px', p: 4, borderRadius: 4, color: '#fff', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Box sx={{ maxWidth: '634px', width: '100%' }}>
                            <Typography variant="h4" color='#fff' sx={{ mb: 1 }}>Get Informed. Get Outside.</Typography>
                            <Typography variant="body1" color='#fff' sx={{ mb: 2 }}>A Search Engine for Hunters, Anglers and Outdoor Enthusiasts.</Typography>
                            <TextField
                                placeholder='Search Location'
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton variant="contained" style={{ background: "var(--grey300)", color: "var(--primary)" }} onClick={() => {
                                                if (isEmpty(search)) {
                                                    window.location.href = config.searchURL + "Missouri&isExplore=true&fetchLR=true&lat=38.3046615&lon=-92.437099&zl=4"
                                                } else {
                                                    window.location.href = config.searchURL + search
                                                }
                                            }}>
                                                <Icon.SearchOutlined />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{ width: '100%', border: 'none', "& fieldset": { border: 'none' }, background: '#fff', borderRadius: 2, }}
                                value={search}
                                onInput={(e) => { setSearch(e.target.value) }}
                                onKeyDown={(e) => {
                                    if (e.key === 'Enter') {
                                        if (window.location.pathname !== "/search") {
                                            if (isEmpty(search)) {
                                                window.location.href = config.searchURL + "Missouri&isExplore=true&fetchLR=true&lat=38.3046615&lon=-92.437099&zl=4"
                                            } else {
                                                window.location.href = config.searchURL + search
                                            }
                                        }
                                    }
                                }}

                            >
                            </TextField>
                            <Box sx={{
                                display: 'flex', gap: 2, alignItems: 'center', justifyContent: 'center', mt: 2,
                                flexDirection: { xs: 'column-reverse', md: 'unset', lg: 'unset' }
                            }}>
                                <Link href="https://apps.apple.com/in/app/farwide-hunt-fish-explore/id1383297776" target="_blank"><img src="../assets/images/homepage/appstore_1.png" style={{ display: 'block' }} /></Link>
                                <Button variant="contained" size="medium" href={config.searchURL + "Missouri&isExplore=true&fetchLR=true&lat=38.3046615&lon=-92.437099&zl=4"}>
                                    Explore The Map
                                </Button>
                            </Box>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    );
};

export default FarwideSearch;
