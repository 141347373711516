import { Chip } from "@mui/material";

const MessageChip = ({ messageType }) => {
  let chipProps = {
    label: '',
    sx: {
      fontSize: '12px',
      borderRadius: 2,
      width: 'fit-content',
      height: 20,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  };

  switch (messageType) {
    case 'booking_request':
      chipProps.variant = 'filled';
      chipProps.label = 'Booking Request';
      chipProps.sx.backgroundColor = '#1B1B1B';
      chipProps.sx.color = '#FFFFFF';
      break;
    case 'enquiry':
      chipProps.variant = 'outlined';
      chipProps.label = 'Enquiry';
      chipProps.sx.backgroundColor = '#FFFFFF';
      chipProps.sx.color = '#1B1B1B';
      chipProps.sx.border = '1px solid #1B1B1B';
      break;
    case 'booking_confirmed':
      chipProps.variant = 'filled';
      chipProps.label = 'Booking Confirmed';
      chipProps.sx.backgroundColor = '#26c77a';
      chipProps.sx.color = '#fff';
      chipProps.sx.border = '1px solid ';
      break;
    case 'booking_declined':
      chipProps.variant = 'outlined';
      chipProps.label = 'Booking Declined';
      chipProps.sx.backgroundColor = '#FFFFFF';
      chipProps.sx.color = '#E94A4A';
      chipProps.sx.border = '1px solid #E94A4A';
      break;
    case 'booking_cancelled':
      chipProps.variant = 'outlined';
      chipProps.label = 'Booking Cancelled';
      chipProps.sx.backgroundColor = '#FFFFFF';
      chipProps.sx.color = '#E94A4A';
      chipProps.sx.border = '1px solid #E94A4A';
      break;
    case 'booking_completed':
      chipProps.variant = 'outlined';
      chipProps.label = 'Trip Completed';
      chipProps.sx.backgroundColor = '#FFFFFF';
      chipProps.sx.color = '#26C77A';
      chipProps.sx.border = '1px solid #26C77A';
      break;
    case 'review_received':
      chipProps.variant = 'outlined';
      chipProps.label = 'Booking Reviewed';
      chipProps.sx.backgroundColor = '#FFFFFF';
      chipProps.sx.color = '#FD9933';
      chipProps.sx.border = '1px solid #FD9933';
      break;
    case 'listing_declined':
      chipProps.variant = 'outlined';
      chipProps.label = 'Listing Declined';
      chipProps.sx.backgroundColor = '#FFFFFF';
      chipProps.sx.color = '#E94A4A';
      chipProps.sx.border = '1px solid #E94A4A';
      break;
    case 'listing_approved':
      chipProps.variant = 'filled';
      chipProps.label = 'Listing Approved';
      chipProps.sx.backgroundColor = '#26c77a';
      chipProps.sx.color = '#fff';
      chipProps.sx.border = '1px solid ';
      break;
    case 'package_approve':
      chipProps.variant = 'filled';
      chipProps.label = 'Listing Approved';
      chipProps.sx.backgroundColor = '#26c77a';
      chipProps.sx.color = '#fff';
      chipProps.sx.border = '1px solid ';
      break;
    case 'package_decline':
      chipProps.variant = 'outlined';
      chipProps.label = 'Listing Declined';
      chipProps.sx.backgroundColor = '#FFFFFF';
      chipProps.sx.color = '#E94A4A';
      chipProps.sx.border = '1px solid #E94A4A';
      break;
    default:
      return null;
  }

  return <Chip {...chipProps} />;
};

export default MessageChip;