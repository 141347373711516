import { Box, Grid, Skeleton } from "@mui/material";

// SkeletonCard component
export const SkeletonCard = () => (
    <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
        {/* Skeleton for image */}
        <Skeleton variant="rectangular" width={327} height={253} sx={{ borderRadius: 2 }} />
        {/* Skeleton for title */}
        <Box sx={{ mt: 2 }}>
            <Skeleton variant="text" width="80%" sx={{ mb: 1 }} />
            <Skeleton variant="text" width="60%" />
        </Box>
        {/* Skeleton for blog type and date */}
        <Box sx={{ mt: 2 }}>
            <Skeleton variant="text" width="50%" sx={{ mb: 1 }} />
        </Box>
    </Grid>
);