import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Box, Typography } from '@mui/material';
import { FreeMode, Navigation, Keyboard } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/keyboard'; // Import keyboard CSS

const AppScreenshotsSlider = ({ screenshots }) => {
    const [swiper, setSwiper] = useState(null);
    const [activeIndex, setActiveIndex] = useState(1); // Center slide

    const handleSlideClick = (index) => {
        if (swiper) {
            const targetIndex = index;
            swiper.slideToLoop(targetIndex, 500);
        }
    };

    return (
        <>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: { xs: "left", sm: 'left', md: 'center' },
                    paddingInline: { xs: 3, sm: 3, md: 4 },
                    pt: { xs: 9, sm: 9, md: 9 },
                    gap: 3
                }}
            >
                <Typography variant="h3" sx={{ textAlign: { xs: "left", sm: 'left', md: 'center' } }} >Created by and for Outdoorsmen</Typography>
                <Typography variant="h6" color='var(--grey900)' sx={{ textAlign: { xs: "left", sm: 'left', md: 'center' } }} >Download the FarWide App now to access and plan your next adventure</Typography>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    maxWidth: '100vw',  // Ensures the slider does not exceed viewport width
                    overflow: 'hidden',  // Prevents content from overflowing out of the window
                    position: 'relative',
                    py: { xs: 3, md: 4 },
                    // Ensure container is wide enough to show half slides
                    '& .swiper': {
                        overflow: 'visible',
                        padding: '20px 0',
                    },
                    // Basic slide styles
                    '& .swiper-slide': {
                        transition: 'all 0.3s ease',
                        transform: 'scale(0.85)',
                        opacity: 0.6,
                        border: 'none', // Remove border for non-active slides
                    },
                    // Center slide styles with red border
                    '& .swiper-slide-active': {
                        transform: 'scale(1)',
                        opacity: 1,
                        border: '3px solid #1B1B1B', // Black border for active slide
                        transition: 'all 0.3s ease',
                        borderRadius: '16px'
                    },
                    // Navigation buttons
                    '& .swiper-button-next, & .swiper-button-prev': {
                        display: 'none',
                    },
                }}
            >
                <Swiper
                    modules={[FreeMode, Navigation, Keyboard]}
                    centeredSlides={true}
                    loop={true} // Loop enabled for infinite feel
                    freeMode={{
                        enabled: true,
                        sticky: false,
                        momentum: true,
                    }}
                    navigation={true}
                    keyboard={{ enabled: true }}
                    initialSlide={0}
                    onSwiper={setSwiper}
                    slidesPerView="auto"
                    onSlideChange={(swiper) => setActiveIndex(swiper.realIndex)}
                    loopAdditionalSlides={5}
                    loopedSlides={screenshots.length} // This preloads more slides for smoother looping
                    loopFillGroupWithBlank={true}
                    preloadImages={true} // Preload images to reduce delay
                    lazy={false} // Disable lazy loading for instant image display
                    breakpoints={{
                        320: {
                            slidesPerView: 1.5,
                            spaceBetween: 10,
                        },
                        375: {
                            slidesPerView: 2.5,
                            spaceBetween: 15,
                        },
                        768: {
                            slidesPerView: 3.5,
                            spaceBetween: 20,
                        },
                        1024: {
                            slidesPerView: 4.5,
                            spaceBetween: 25,
                        },
                        1280: {
                            slidesPerView: 5.5,
                            spaceBetween: 30,
                        }, 1400: {
                            slidesPerView: 6.5,
                            spaceBetween: 30,
                        },
                    }}
                >
                    {screenshots.map((screenshot, index) => (
                        <SwiperSlide
                            key={index}
                            onClick={() => handleSlideClick(index)}
                            style={{ // Added this style tag and css to solve active slide not being bigger than rest issue on mobile devices
                                transform: index === activeIndex ? 'scale(1)' : 'scale(0.85)',
                                opacity: index === activeIndex ? 1 : 0.6,
                                border: index === activeIndex ? '3px solid rgb(27, 27, 27)' : 'none',
                                borderRadius: '28px',
                                transition: 'transform 0.3s ease, opacity 0.3s ease',
                            }}
                        >
                            <Box
                                sx={{
                                    cursor: 'pointer',
                                    width: '100%',
                                    position: 'relative',
                                    backgroundColor: '#fff',
                                    borderRadius: '26px',
                                    overflow: 'hidden',
                                    boxShadow: '0 4px 12px rgba(0,0,0,0.1)',
                                    transition: 'all 0.3s ease',
                                    '&::before': {
                                        content: '""',
                                        display: 'block',
                                        paddingTop: '200%',
                                    },
                                    '&:hover': {
                                        boxShadow: '0 6px 16px rgba(0,0,0,0.15)',
                                    },
                                }}
                            >
                                <img
                                    src={screenshot.url}
                                    alt={screenshot.alt || `App Screenshot ${index + 1}`}
                                    style={{
                                        position: 'absolute',
                                        top: 0,
                                        left: 0,
                                        width: '100%',
                                        height: '100%',
                                        objectFit: 'fill',
                                        borderRadius: '16px',
                                    }}
                                />
                            </Box>
                        </SwiperSlide>
                    ))}
                    <Typography
                        variant="body2"
                        align="center"
                        sx={{ mt: 2, color: '#26C77A', fontSize: '1.3rem', paddingInline: '16px' }} // Adds margin-top
                    >
                        {screenshots[activeIndex]?.alt || ''}
                    </Typography>
                </Swiper>
            </Box>
        </>
    );
};

// Props validation
AppScreenshotsSlider.defaultProps = {
    screenshots: [],
};

export default AppScreenshotsSlider;