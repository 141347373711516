import React, { useEffect, useState } from 'react';
import { Button, Typography } from '@mui/material';
import config from '../../config';

const GoogleAuth = ({
    onSuccess,
    buttonText = 'Sign in with Google',
    variant = 'contained',
}) => {

    const [tokenClient, setTokenClient] = useState(null)
    useEffect(() => {
        // Load the Google API script
        const script = document.createElement('script');
        script.src = 'https://accounts.google.com/gsi/client';
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);


        script.onload = () => {
            if (window.google && window.google.accounts) {
                let tc = window.google.accounts.oauth2.initTokenClient({
                    client_id: config.clientId,
                    scope: "email profile openid ", // Add additional scopes as needed
                    callback: handleGoogleLogin
                });
                setTokenClient(tc)
            }
        };
        return () => {
            document.body.removeChild(script);
        };
    }, []);

    const handleGoogleLogin = async (response) => {
        console.log(response);
        if(response.access_token){
            onSuccess({
                idToken: response.access_token,
            });
        }
    };

    return (
        <Button
            // onClick={handleGoogleLogin}
            variant={variant}
            startIcon={<img style={{ width: '24px', height: '24px' }} src="../assets/images/googleColorIcon.svg" alt="" />}
            fullWidth
            size='large'
            onClick={()=>{
                if(tokenClient)
                    tokenClient.requestAccessToken()
            }}
            sx={{
                textTransform: 'none',
                backgroundColor: variant === 'contained' ? '#E5E7EB' : 'transparent',
                color: '#1B1B1B',
                '&:hover': {
                    backgroundColor: variant === 'contained' ? '#E5E7EB' : 'rgba(66, 133, 244, 0.04)',
                },
                borderRadius: 1.5,
                // paddingBlock:'10px'
            }}
        >
            <Typography variant='body2' sx={{ color: '#1B1B1B', fontWeight: '600' }}>
                {buttonText}
            </Typography>
        </Button>
    );
};

export default GoogleAuth;