import * as React from "react";

import { Avatar, Card, CardContent, Grid, Typography } from "@mui/material";

const Cards = (props) => {
  const cardsData = [
    {
      avatarSrc: "../assets/images/icon_images/hunting_grey.svg",
      text: "Hunting",
    },
    {
      avatarSrc: "../assets/images/icon_images/fishing_grey.svg",
      text: "Fishing",
    },
    {
      avatarSrc: "../assets/images/icon_images/farm_grey.svg",
      text: "Farm and Ranch",
    },
  ];
  return (
    <Grid container spacing={3} className="mobmarginbottom" sx={{ mb: 4 }}>
      {cardsData.map((card, index) => (
        <Grid key={index} item xs={12} sm={6} md={4}>
          <Card
            sx={{ borderRadius: 4, backgroundColor: "#1B1B1B", padding: 2 }}
          >
            <CardContent style={{ display: "flex", alignItems: "center" }}>
              <Avatar
                src={card.avatarSrc}
                style={{
                  backgroundColor: "#ffff",
                  height: 72,
                  width: 72,
                  padding: 15,
                }}
              />
              <Typography
                variant="h5"
                sx={{ marginLeft: 3, color: "#fff !important", fontWeight: "bold" }}
              >
                {card.text}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};

export default Cards;
