import ListingService from "../../services/listing"
import { extractDataFromArray } from "../../utils/common"

export const getDashboardData = (setTableNoDataValues) => {
    ListingService.getDashboardData((data) => {
        // console.log('publishedlisting',parseInt(extractDataFromArray(data, ['published_listing_count'], 0)));
        // console.log('incompletelisting',parseInt(extractDataFromArray(data, ['is_incomplete_listing'], 0)));
        if (parseInt(extractDataFromArray(data, ['published_listing_count'], 0)) === 0) {
            if (parseInt(extractDataFromArray(data, ['is_incomplete_listing'], 0)) === 0) {
                setTableNoDataValues({
                    link: "",
                    buttonText: 'Create Listing Now',
                    setIsPopupOpen: true
                })
            } else {
                // if there's any incompleteListing the show Complete listing button
                setTableNoDataValues({
                    link: "/listings",
                    buttonText: 'Complete Your Listing',
                    setIsPopupOpen: false
                })
            }
        } else {
            setTableNoDataValues({
                link: "/listings",
                buttonText: 'Review Listings',
                setIsPopupOpen: false
            })
        }
    })
}