import { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Paper, Snackbar, TextField, Typography } from "@mui/material"
import * as Icon from "@mui/icons-material";
import DropZone from "./DropZone";
import { empty, exponentialBackoff, extractDataFromArray, toastMessage } from "../../../utils/common";
import FileManagerService from "../../../services/fileManager";
import FileManagerButton from "..";
import loadImage from "blueimp-load-image";
import { useLocation } from "react-router";
import PlayCircleFilledWhiteOutlinedIcon from '@mui/icons-material/PlayCircleFilledWhiteOutlined';
import errorAndHelperText from "../../CommonConstants/errorAndHelperText.json"


const UploadedFile = ({ title, imgSrc, removeFile, status, type }) => {
    let statusIcon = <></>
    switch (status) {
        case "uploading":
            statusIcon = <CircularProgress size={30} color="success" />
            break
        case "uploaded":
            statusIcon = <Icon.CheckCircle fontSize="large" color="success" />
            break
        case "conflict":
            statusIcon = <></>
            break
        default:
            statusIcon = <IconButton onClick={removeFile}><Icon.Close /></IconButton>
            break
    }
    statusIcon = <Box className="uploaded_file_action">{statusIcon}</Box>

    return (
        <Paper variant="outlined" sx={{ display: 'flex', p: 1, mt: 1, alignItems: 'center', borderRadius: theme => `${theme.card.borderRadiusMd} ` }}>
            {
                type.startsWith('video') ?
                    <PlayCircleFilledWhiteOutlinedIcon sx={{ borderRadius: theme => `${theme.card.borderRadiusMd} `, height: '36px', width: '36px', mr: 2, color: 'var(--primary)' }} />
                    :
                    <Box className="uploaded_file" sx={{ borderRadius: theme => `${theme.card.borderRadiusMd} ` }} style={{ backgroundImage: 'url(' + imgSrc + ')' }}></Box>
            }
            <Box className="uploaded_file_name">
                <Box sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '100%' }}>{title}</Box>
                {
                    status === 'conflict' ?
                        <Box sx={{ marginTop: 1 }}>
                            <Button variant="contained" color="primary" sx={{ mr: 1, borderRadius: theme => `${theme.card.borderRadiusMd} ` }} size="small">Replace</Button>
                            <Button variant="outlined" color="primary" size="small" sx={{ borderRadius: theme => `${theme.card.borderRadiusMd} ` }}>Keep Both Files</Button>
                            <Button variant="text" color="secondary" sx={{ ml: 1, borderRadius: theme => `${theme.card.borderRadiusMd} ` }} size="small">Skip</Button>
                        </Box> : <></>
                }

            </Box>
            {statusIcon}

        </Paper>
    )
}

const UploadPopupContent = ({ type, files, handleFileUpload, removeFile, limit, reqFileType = '' }) => {
    const location = useLocation()
    let fileType = location.pathname === "/account" || location.pathname === "/review" || location.pathname === "/experiencereview" ? "image/*" : "image/*, video/*"
    if (reqFileType === 'IMAGE') {
        fileType = "image/*"
    }


    return (
        <>
            {type === "file" ? <></> :
                <TextField fullWidth id="" label="Folder Name" variant="outlined" />
            }
            <DropZone dropCallback={handleFileUpload} accept={fileType} limit={limit} />
            {
                files.map((file, index) => {
                    let image = extractDataFromArray(file, ["url"], "")
                    if (empty(image)) {

                        image = window.URL.createObjectURL(file)
                        return (
                            <UploadedFile key={"uploaded_file_" + index} title={file.name} imgSrc={image} removeFile={() => { removeFile(index) }} status={extractDataFromArray(file, ['status'], 'init')} type={file.type} />
                        )
                    }
                })
            }
        </>
    )
}


const UploadPopup = ({ onClose, popupOpen, type, showFilemanageButton = false, selectedCallback, limit, buttonText, reqFileType = '' }) => {
    const [files, setFiles] = useState([])

    const handleFileUpload = (uploadFiles) => {
        const currentFiles = files
        setFiles(currentFiles.concat(uploadFiles))
    }


    const [conflict, setConflict] = useState({ state: false, action: '' })
    const [uploading, setUploading] = useState(false)
    const [hideFM, setHideFM] = useState(false)

    const removeFile = (index) => {
        const currentFiles = files.slice()
        currentFiles.splice(index, 1)
        setFiles(currentFiles)
    }

    const uploadFiles = async () => {
        if (!empty(files)) {

            await updateAllStateToUploading((currentFiles) => {
                setFiles(currentFiles)
                setUploading(true)
                callUploadFilesAPI()
            })

        }
    }

    let [showSuccessToast, setShowSuccessToast] = useState(false)
    const callUploadFilesAPI = () => {
        let uploadedFiles = [];
        let errorFiles = [];
        // // setShowSuccessToast(true)

        const uploadImage = (file, index) => {
            file.status = 'uploading';
            // console.log(file);

            if (file.conflict === true && file.action === 'skip') {
                // toast.warning(`Note: ${file.name} skipped during upload`, {
                //     theme: 'colored'
                // })
                toastMessage({
                    message: `Note: ${file.name} skipped during upload`,
                    type: 'warning'
                })
                file.status = 'error';
                errorFiles.push(file)
            }

            // console.log('upload video file type',file)
            !(file.conflict === true && file.action === 'skip') && FileManagerService.uploadAsset(
                file,
                showFilemanageButton ? 'keep' : file.conflict ? file.action : '',
                0,
                (data) => {
                    // console.log(data)
                    file.status = 'uploaded';
                    let currentFiles = files;
                    data.type = data.file_type;
                    currentFiles[index] = data;
                    setFiles(currentFiles);
                    uploadedFiles.push(data);
                },
                (err) => {
                    // toast.error(err, {
                    //     theme: "colored",
                    // });
                    toastMessage({
                        message: err,
                        type: 'error'
                    })
                    file.status = 'error';
                    errorFiles.push(file);
                }
            );
        };

        files.forEach((file, index) => {
            // console.log(file.type);
            // Check if the file is an image
            if (file.type.startsWith('image/')) {
                file.status = 'uploading';
                // Use loadImage to correct image orientation before uploading
                loadImage(
                    file,
                    function (img, data) {
                        if (data?.imageHead && data?.exif) {
                            // Reset Exif Orientation data:
                            loadImage.writeExifData(data.imageHead, data, 'Orientation', 1);
                            img.toBlob(function (blob) {
                                loadImage.replaceHead(blob, data.imageHead, function (newBlob) {
                                    // Create a new File object with corrected orientation data
                                    const correctedFile = new File([newBlob], file.name, { type: 'image/jpeg' });
                                    // Proceed with uploading the corrected image file
                                    uploadImage(correctedFile, index);
                                });
                            }, 'image/jpeg');
                        } else {
                            // If no orientation correction needed, upload the file directly
                            uploadImage(file, index);
                        }
                    },
                    { meta: true, orientation: true, canvas: true, maxWidth: 800 }
                );
            } else {
                // If the file is not an image, call uploadImage directly
                uploadImage(file, index);
            }
        });




        exponentialBackoff(() => {
            return (uploadedFiles.length + errorFiles.length) === files.length
        }, files.length * 5 * 100, 1000, () => {
            setUploading(false)
            // if(showSuccessToast){
            if (errorFiles.length === 0) {
                // toast.success("Successfully uploaded", {
                //     theme: 'colored'
                // })
                toastMessage(errorAndHelperText.file_upload.success)
            } else {
                let skipped = false;
                errorFiles.forEach((file) => {
                    if (file.action === 'skip') {
                        skipped = true;
                    }
                })
                if (skipped === true) {
                    // console.log('some files skipped');
                    // toast.warning(`A few files were skipped.`, {
                    //     theme: 'colored'
                    // })
                    toastMessage(errorAndHelperText.file_upload.skipped)
                } else {
                    if (uploadedFiles.length > 0) {
                        // toast.error("Error occured while uploading one or more files. Please check and try again.", {
                        //     theme: 'colored'
                        // })
                        toastMessage(errorAndHelperText.file_upload.error)
                    } else {
                        // toast.error("Error occured while uploading. Please check and try again.", {
                        //     theme: 'colored'
                        // })
                        toastMessage(errorAndHelperText.file_upload.error)
                    }
                }
            }
            // if (showFilemanageButton || typeof se) {

            selectedCallback(uploadedFiles)
            // }
            setHideFM(false)
            // setShowSuccessToast(false)
            // }
            setFiles([])
            onClose()
        })
    }
    const removeAllFiles = () => {
        setFiles([])
        setConflict({ state: false })
        setUploading(false)
    }


    const updateAllStateToUploading = async (callback = () => { }) => {
        let currentFiles = []
        let conflictExists = false
        files.forEach(async (file) => {
            file.conflict = false
            await FileManagerService.checkIfFileExists(file.name, file.folder_seq, async (exists) => {
                if (exists) {
                    conflictExists = true;
                    file.conflict = true;
                }
                currentFiles.push(file)
                // console.log(currentFiles);
                if (currentFiles.length === files.length) {
                    if (conflictExists) {
                        setConflict({ state: true })

                    } else {
                        callback(currentFiles)
                    }
                }
            })
        })

    }

    const setConflictAction = (action) => {
        let selectedFiles = []
        files.forEach((file) => {
            if (file.conflict === true) {
                file.action = action;
            }
            selectedFiles.push(file)
        })

        setFiles(selectedFiles)
        setConflict({ state: false, action })
        callUploadFilesAPI()
        // onClose()
    }

    const handleClose = () => {
        onClose();
    };



    useEffect(() => {
        if (showFilemanageButton && files.length > 0) {
            setHideFM(true)
            setUploading(true)
            callUploadFilesAPI()
        }
    }, [files])

    return (
        <>
            <Dialog onClose={handleClose} open={popupOpen} sx={{ zIndex: 999998 }} >
                <DialogTitle sx={{ display: 'flex', justifyContent: 'space-between' }}><Typography variant="h6" sx={{ fontWeight: 700 }}>{type === "file" ? "Upload Files" : "New Folder"}</Typography><IconButton onClick={handleClose}><Icon.CloseOutlined /></IconButton></DialogTitle>
                <DialogContent dividers={true} sx={{ borderTop: 0, borderBottom: 0, paddingTop: 0, paddingBottom: 0 }}>
                    <UploadPopupContent
                        type={type}
                        files={files}
                        limit={limit}
                        handleFileUpload={handleFileUpload}
                        removeFile={removeFile}
                        reqFileType={reqFileType}
                    />

                </DialogContent>
                <DialogActions sx={{ flexDirection: 'column', py: 3 }}>
                    {
                        conflict.state && !showFilemanageButton ?
                            <Box sx={{ border: "1px solid var(--grey700);", padding: 2, mx: 2, borderRadius: theme => `${theme.card.borderRadiusMd} ` }} >
                                <Typography>
                                    Some of these files already exist in this location. Do you want to replace them existing with a new name or keep both?                                </Typography>
                                <Box sx={{ paddingTop: 2 }}>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        sx={{ mr: 1, borderRadius: theme => `${theme.card.borderRadiusMd} ` }}
                                        size="small"
                                        onClick={() => { setConflictAction("replace") }}
                                    >
                                        Replace
                                    </Button>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        size="small"
                                        sx={{ borderRadius: theme => `${theme.card.borderRadiusMd} ` }}
                                        onClick={() => { setConflictAction("keep") }}
                                    >
                                        Keep Both Files
                                    </Button>
                                    <Button
                                        variant="text"
                                        color="secondary"
                                        sx={{ ml: 1, borderRadius: theme => `${theme.card.borderRadiusMd} ` }}
                                        size="small"
                                        onClick={() => { setConflictAction("skip") }}
                                    >
                                        Skip
                                    </Button>
                                </Box>
                            </Box> : <></>
                    }
                    {
                        showFilemanageButton && !hideFM &&
                        <Box sx={{ width: '100%', p: '0 1rem' }}>
                            <FileManagerButton buttonText={buttonText} fullWidth={true} buttonVariant="outlined" buttonColor="secondary" reqFileType={reqFileType} label={<><Icon.GridViewOutlined sx={{ mr: 1 }} />Select From File Manager</>}
                                selectedCallback={selectedCallback}
                                handleModalClose={handleClose}
                            />
                        </Box>
                    }
                    {
                        !showFilemanageButton &&
                        <Box sx={{ display: "flex", width: "100%", justifyContent: "end", marginTop: 2, mr: 3 }}>
                            <SpinningButton loading={uploading} variant="contained" startIcon={<Icon.CloudUploadOutlined />} sx={{ mr: 1 }} onClick={uploadFiles}>
                                Upload
                            </SpinningButton>
                            <Button variant="outlined" color="secondary" onClick={removeAllFiles}>
                                Remove All
                            </Button>
                            {type === "folder" ?
                                <Button variant="outlined" color="primary" sx={{ ml: 1 }}>
                                    Create Folder
                                </Button> : <></>
                            }
                        </Box>
                    }
                </DialogActions>
            </Dialog >
        </>
    );
}

const SpinningButton = ({ loading, variant, onClick, children, sx, startIcon }) => {
    if (loading) {
        startIcon = <CircularProgress
            size={24}

        />
    }
    return (
        <Button
            variant={variant}
            disabled={loading}
            onClick={onClick}
            startIcon={startIcon}
            sx={sx}
        >
            {children}
        </Button>
    )
}

export default UploadPopup;