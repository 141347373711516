import { styled } from '@mui/material/styles';
import { Box, Container, Grid, Link, Typography } from "@mui/material";
import { CommonContext } from '../../../store/context/commonContext';

const ImageObject = {
    "name": "app_image_0.png",
    "alt_name": null,
    "file_type": "IMAGE",
    "size": "88832",
    "create_time": "2024-10-23 07:22:12",
    "update_time": "2024-10-23 07:22:12",
    "added_by": "2221",
    "folder_seq": "0",
    "tags": null,
    "url": "//farwide.s3.amazonaws.com/asset/qWlXlxmzMF-7ijM5UF-D_3Zc/2221/6718a4241a2131729668132.png",
    "thumbnail_url": ""
}


// Styled components for tilted images
const TiltedImageContainer = styled(Box)(({ direction, marginBottom }) => ({
    borderRadius: 24,
    maxWidth: 240,
    margin: '0 auto',
    '& img': {
        width: '100%',
        borderRadius: 16,
    },
    // [theme => theme.breakpoints.up('md')]: {
    transform: `rotate(${direction === 'right' ? '8deg' : '-8deg'})`,
    marginBottom
    // '&:hover': {
    //     transform: `rotate(${direction === 'right' ? '15deg' : '-15deg'})`,
    // }
    // }
}));

const AppBanner = () => {
    const { isNineHundred } = CommonContext();

    const calculateMarginBottom = (direction) => {
        if (direction === 'left') {
            return isNineHundred ? '86px' : '126px';
        }
        return 0; // No margin for other directions
    };
    return (
        <>
            <Box sx={{
                width: '100%',
                py: { xs: 4, md: 8 },
                px: { xs: 4, sm: 4 }
            }}>
                <Container maxWidth="lg">
                    <Grid container spacing={{ xs: 6, md: 4 }} alignItems="center">
                        {/* Left Mobile Preview */}
                        {
                            !isNineHundred &&
                            <Grid item xs={0} md={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                                <TiltedImageContainer direction="right" marginBottom={calculateMarginBottom('right')} >
                                    <img
                                        src={`//farwide.s3.amazonaws.com/asset/9Ae46xh9r8oaKUvtEar-YFZA/2221/677b849e993fc1736148126.webp`}
                                        alt="Mobile preview 1"
                                    />
                                </TiltedImageContainer>
                            </Grid>
                        }

                        {/* Center Content */}
                        <Grid item xs={12} md={6} sx={{ alignSelf: 'flex-start' }}>
                            <Box sx={{ display: 'flex', flexDirection: "column", alignItems: 'center', marginTop: 6 }}>
                                <Typography
                                    variant="h3"
                                    sx={{
                                        color: '#1B1B1B',
                                        fontWeight: 700,
                                        textAlign: 'center',
                                        mb: 2.5
                                    }}
                                >
                                    The <br></br>FarWide App
                                </Typography>
                                {/* <Typography
                                    variant="h3"
                                    sx={{
                                        color:'#1B1B1B',
                                        fontWeight: 700,
                                        mb: 2.5
                                    }}
                                >
                                    Hunting App
                                </Typography> */}
                                <Typography
                                    variant="h6"
                                    sx={{
                                        mb: 4,
                                        textAlign: 'center',
                                        color: '#747579',
                                        fontWeight: 400
                                    }}
                                >
                                    FarWide is your ultimate outdoor companion that simplifies planning and lets you focus on the immediate experience. Use the app for its intuitive mapping tools, access points, and map layers. And now you can book exclusive properties or access for your next outdoor adventure! Download for free today and plan your next outing.
                                </Typography>
                                <Link href="https://apps.apple.com/in/app/farwide-hunt-fish-explore/id1383297776" target="_blank"><img src="../assets/images/homepage/appdownload.png" style={{ display: 'block' }} /></Link>
                            </Box>
                        </Grid>

                        {/* Right Mobile Preview */}
                        <Grid item xs={12} md={3} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <TiltedImageContainer direction="left" marginBottom={calculateMarginBottom('left')}>
                                <img
                                    src={`//farwide.s3.amazonaws.com/asset/_akug88qeYCba_KYRtAVAyDX/2221/677b8455033921736148053.webp`}
                                    alt="Mobile preview 1"
                                />
                            </TiltedImageContainer>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    )
};

export default AppBanner;