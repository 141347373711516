import { Box, Link, Typography } from "@mui/material";

const ImageObject = {
    "name": "app_download_mobile.png",
    "alt_name": null,
    "file_type": "IMAGE",
    "size": "290863",
    "create_time": "2024-10-24 09:15:58",
    "update_time": "2024-10-24 09:15:58",
    "added_by": "2221",
    "folder_seq": "0",
    "tags": null,
    "url": "//farwide.s3.amazonaws.com/asset/WNF6oH0zGKX6yOVsioKg-tEo/2221/677b81d4044321736147412.webp",
    "thumbnail_url": ""
}

const DownloadApp = () => {
    return (
        <>
            <Box
                sx={{
                    width: '100%',
                    marginTop: 20,
                    paddingInline: { xs: 3, sm: 3, md: 4 }
                }}>
                <Box
                    sx={{
                        backgroundColor: '#26C77A',
                        borderRadius: 4,
                        top: 110,
                        width: '100%',
                        display: 'flex',
                        padding: { xs: 7, sm: 7, md: 11.5 },
                        flexDirection: { xs: 'column-reverse', sm: 'column-reverse', md: 'row' },
                        alignItems: { xs: 'center', sm: 'center', },
                        gap: { xs: 3, sm: 3, md: 7.5 },
                    }}
                >
                    <Box
                        sx={{
                            // px: { xs: 5, sm: 5, md: 0 },
                            display: { xs: 'flex', sm: 'flex', md: 'block' },
                            flexDirection: { xs: 'column', sm: 'column' },
                            alignItems: { xs: 'center', sm: 'center' },
                            alignSelf: { xs: 'center', sm: 'center', md: 'flex-start' }
                        }}
                    >
                        <Typography
                            variant="h3"
                            color="#FFFFFF"
                            sx={{
                                mb: { xs: 2.5, sm: 2.5, md: 4 },
                                textAlign: { xs: "center", sm: "center", md: 'left' }
                            }}
                        >
                            Download FarWide from the Apple Store today!
                        </Typography>
                        {/* <Typography
                            variant="body1"
                            color="#FFFFFF"
                            sx={{
                                mb: { xs: 4.5, sm: 4.5, md: 6 },
                                textAlign: { xs: "center", sm: "center", md: 'left' }
                            }}
                        >
                            Instant free download from apple and play store orem Ipsum is simply dummy text of the printing. and typese tting indus orem Ipsum has beenthe standard
                        </Typography> */}
                        <Link
                            href="https://apps.apple.com/in/app/farwide-hunt-fish-explore/id1383297776"
                            target="_blank"
                            sx={{ my: { xs: "auto", sm: "auto", md: '0px' } }}
                        >
                            <img src="../assets/images/homepage/appdownload.png" style={{ display: 'block' }} />
                        </Link>
                    </Box>
                    <Box
                        sx={{
                            width: { xs: '226px ', sm: '320px', md: '100%' },
                            marginTop: { xs: -12, sm: -17, md: -24 }
                            // position: 'absolute',
                        }}
                    >
                        <img
                            src={`//farwide.s3.amazonaws.com/asset/WNF6oH0zGKX6yOVsioKg-tEo/2221/677b81d4044321736147412.webp`}
                            alt="Mobile preview 1"
                            style={{
                                width: 'auto',        // Use 'auto' for natural width
                                height: 'auto',       // Use 'auto' for natural height
                                maxWidth: '100%',     // Ensures responsiveness without exceeding the box
                                display: 'block',   // Removes bottom spacing
                            }}
                        />
                    </Box>
                </Box>
            </Box>
        </>
    )
};

export default DownloadApp;