import axios from "axios";
import config from "../config";
import { extractDataFromArray, parseResponseAxios, toastMessage } from "../utils/common";
import { toast } from "react-toastify";

const BookingService = {
    getBookings: async (booking_status, start, limit, searchKeyWord, type, callback = () => { }) => {
        await axios.get(config.apiUrl + "/services/api", {
            params: {
                cmd: "getBookings",
                booking_status,
                start,
                limit,
                kw: searchKeyWord,
                type: type,
            }
        }).then((response) => {
            response = parseResponseAxios(response)
            if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
                callback(extractDataFromArray(response, ['data'], []))
            }
        });
    },

    getBookingDetails: async (booking_id, successCB = () => { }, errCallback = () => { }) => {
        await axios.get(config.apiUrl + "/services/api", {
            params: {
                cmd: "getBookingDetails",
                booking_id: booking_id,
            }
        }).then((response) => {
            response = parseResponseAxios(response);
            if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
                const data = extractDataFromArray(response, ['data'], []);
                successCB(data);
            } else {
                errCallback(extractDataFromArray(response, ['errorMsg'], ''))
            }
        });
    },
    getCancellationReasons: async (successCB = () => { }, errorCB = () => { }) => {
        await axios.get(config.apiUrl + "/services/api", {
            params: {
                cmd: "getCancellationReasons"
            }
        }).then((response) => {
            response = parseResponseAxios(response);
            if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
                const data = extractDataFromArray(response, ['data'], []);
                successCB(data);
            } else {
                errorCB(extractDataFromArray(response, ['errorMsg'], ''))
            }
        });
    },
    getCancelationData: async (booking_id, successCB = () => { }, errorCB = () => { }) => {
        await axios.get(config.apiUrl + "/services/api", {
            params: {
                booking_id: booking_id,
                cmd: "getCancelationData"
            }
        }).then((response) => {
            response = parseResponseAxios(response);
            if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
                const data = extractDataFromArray(response, ['data'], []);
                successCB(data);
            } else {
                errorCB(extractDataFromArray(response, ['errorMsg'], ''))
            }
        });
    },
    DownloadInvoice: async (booking_id, successCB = () => { }) => {
        await axios.get(config.apiUrl + "/services/api", {
            params: {
                booking_id: booking_id,
                cmd: "downloadInvoice"
            },
            responseType: 'blob' // Set the response type to 'blob'
        }).then((response) => {
            // response = parseResponseAxios(response);
            successCB(response);
        });
    },
    cancelBooking: async (booking_id, cancellation_reason, custom_cancellation_reason, cancellation_message, setLoaderDeclineButton, getBookingDetails, successCB = () => { }, errorCB = () => { }) => {
        let data = new FormData()
        data.append("cmd", "cancelBooking")
        data.append("booking_id", booking_id)
        data.append("cancellation_reason", cancellation_reason)
        data.append("custom_cancellation_reason", custom_cancellation_reason)
        data.append("cancellation_message", cancellation_message)
        await axios.post(config.apiUrl + "/services/api", data).then((response) => {
            response = parseResponseAxios(response);
            if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
                const data = extractDataFromArray(response, ['data'], []);
                successCB(data);
            } else {
                // toast.error(extractDataFromArray(response, ['errorMsg'], ''), {
                //     theme: "colored",
                // });     
                toastMessage({
                    message: extractDataFromArray(response, ['errorMsg'], ''),
                    type: 'error',
                });
                errorCB(extractDataFromArray(response, ['errorMsg'], ''))
                setLoaderDeclineButton(false)
            }
        });
    },
    unlockBooking: async (payment_id, successCB = () => { }, errorCB = () => { }) => {
        let data = new FormData()
        data.append("cmd", "unlockBooking")
        data.append("payment_id", payment_id)
        await axios.post(config.apiUrl + "/services/api", data).then((response) => {
            response = parseResponseAxios(response);
            if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
                const data = extractDataFromArray(response, ['data'], []);
                successCB(data);
            } else {  
                toastMessage({
                    message: extractDataFromArray(response, ['errorMsg'], ''),
                    type: 'error',
                });
                errorCB(extractDataFromArray(response, ['errorMsg'], ''))
            }
        });
    },
}

export default BookingService;