

import React, { useEffect, useState } from 'react';
import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControlLabel,
    Grid,
    IconButton,
    Typography
} from "@mui/material";
import * as Icon from '@mui/icons-material';
import { capitalizeFirstLetter, empty, hasEmptyStrings } from '../../utils/common';
import { CommonContext } from '../../store/context/commonContext';

const ExpAcitvitiesFilterPopUp = ({ filterData = {}, type, value, setValue, open, handleClose, selectedFilters, setSelectedFilters, handleFilterChange, setOpenExpActivity, count, setSelectedActivities, selectedActivities, removeFilterFromUrlParams, setCallGetCount, setShowClearFilterButton }) => {

    const { screenWidth } = CommonContext()

    // console.log("selectedActivities", selectedActivities);
    const isFilterApplied = () => {
        const url = new URL(window.location.href);
        const eat = url.searchParams.get('eat');
        return eat !== null && eat !== '';
    };

    useEffect(() => {
        setSelectedActivities(() => {
            const url = new URL(window.location.href);
            const eat = url.searchParams.get('eat');
            return eat ? eat.split(',') : [];
        })
    }, [])



    const handleCheckboxChange = (index) => {
        setCallGetCount(true)
        setSelectedActivities(prev => {
            const updatedActivities = prev.includes(index)
                ? prev.filter(item => item !== index)
                : [...prev, index];
            setCallGetCount(true);
            return updatedActivities;
        });
        // setSelectedFilters(updatedFilters);
    };

    const handleApplyFilter = (action) => {
        // console.log("selectedActivities.length", selectedActivities);
        const isFilterApplied = !empty(selectedActivities);  // Check if any activities are selected

        // Handle 'apply' action
        if (action === 'apply') {
            // If activities were manually unselected (selectedActivities is empty), trigger API call with empty filter
            if (isFilterApplied || selectedActivities.length === 0) {
                const selectedFiltersString = selectedActivities.join(',') || '';  // Send empty string if no activities
                handleFilterChange(selectedFiltersString);  // API call with selected activities or empty data
                addFilterToUrlParams();  // Update URL with selected filters
            }
            setOpenExpActivity(false);  // Close the popup
        }
        // Handle 'clear' action
        else if (action === 'clear') {
            const url = new URL(window.location.href);
            const eat = url.searchParams.get('eat');

            // Only clear filters and trigger API call if filters are present in URL or activities selected
            if (eat || isFilterApplied) {
                handleFilterChange('');  // API call to clear filters
                removeFilterFromUrlParams();  // Remove filters from URL
            }
            setSelectedActivities([]);  // Clear all selected activities
            setOpenExpActivity(false);  // Close the popup
        }
    };

    const addFilterToUrlParams = (filterType) => {
        let url = ''
        url = new URL(window.location.href);

        url.searchParams.set('eat', selectedActivities.join(','))
        // url.searchParams.set('mxd', maxValue)


        window.history.pushState({}, '', url);
    }

    return (
        <Dialog
            open={open}
            onClose={() => { setOpenExpActivity(false) }}
        >
            <DialogTitle id='customized-dialog-title' sx={{ py: 1, px: 3, justifyContent: "space-between", display: "flex", alignItems: 'center' }}>
                <Typography variant='h6' component='span' fontWeight='700'>
                    Filter by Activities
                </Typography>
                <IconButton onClick={() => { setOpenExpActivity(false) }}><Icon.Close /></IconButton>
            </DialogTitle>
            <Divider />
            <DialogContent>
                <Typography variant="body" sx={{ fontWeight: '600' }}>Activity Type</Typography>
                <Box sx={{ mt: 1 }}>
                    <Grid container>
                        {
                            Object.entries(filterData).map(([index, val]) => (
                                <Grid item xs={12} sm={6} md={6} key={index}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={selectedActivities.includes(index)}
                                                onChange={() => handleCheckboxChange(index)}
                                            />
                                        }
                                        label={`${capitalizeFirstLetter(index)} (${val})`}
                                    />
                                </Grid>
                            ))
                        }
                    </Grid>
                </Box>
            </DialogContent>
            <DialogActions sx={{ display: 'flex', justifyContent: 'space-between', p: 3 }}>
                <Button
                    variant="outlined" sx={{ color: 'var(--black)', borderColor: '1px solid var(--black)', "&:hover": { backgroundColor: 'white', borderColor: '1px solid var(--black)' } }}
                    onClick={() => {
                        if (isFilterApplied()) {
                            setSelectedActivities([]);
                            handleApplyFilter('clear');
                            removeFilterFromUrlParams('activities');
                        } else {
                            setSelectedActivities([]);
                            setOpenExpActivity(false);
                            removeFilterFromUrlParams('activities');
                        }
                    }}
                >
                    Clear
                </Button>
                <Button variant="contained"
                    onClick={() => {
                        handleApplyFilter('apply')
                        // setShowClearFilterButton(true)
                        // addFilterToUrlParams('activities')
                        // setOpenExpActivity(false)
                    }}
                    sx={{
                        paddingInline: screenWidth < 398 ? '16px !important' : '22px !important'
                    }}
                >
                    Show Results
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ExpAcitvitiesFilterPopUp;