import React, { useRef, useEffect, useState } from 'react';
import * as Icon from "@mui/icons-material"
import mapboxgl from 'mapbox-gl';
import "mapbox-gl/dist/mapbox-gl.css";
import { empty, extractDataFromArray, generatePhrase, getPlaceName, toastMessage } from '../../utils/common';
import WishlistService from '../../services/wishlist';
import { UserAuth } from '../../store/context/userContext';
import { toast } from 'react-toastify';
import { Box, Button, Dialog, Divider, Typography } from '@mui/material';
import { string } from 'yup';
import { CommonContext } from '../../store/context/commonContext';
import zIndex from '@mui/material/styles/zIndex';

const defaultLat = 40.489627;
const defaultLng = -101.592734;

const SearchMap = ({ results, listingSequence, wishlistType }) => {
	// console.log("resuls", results);
	const [isWishlist, setIsWishlist] = useState(false)
	const [toggle, setToggle] = useState(false)
	const [iconUrl, setIconUrl] = useState('/assets/images/icon_images/favorite_border_icon.svg')
	const [listingSeq, setListinSeq] = useState('')
	const mapContainer = useRef(null);
	const { user, authPopup, setAuthPopup } = UserAuth()
	const [openRemoveDialog, setOpenRemoveDialog] = useState(false);
	const [checkIsWishlisted, setCheckIsWishlisted] = useState(false);
	const [isUser, setIsUser] = useState(false)
	const { isMobile, showSearchType, setShowSearchType } = CommonContext()
	const [latitue, setLatitue] = useState('')
	const [longitude, setLongitude] = useState('')
	const [experienceType, setExperienceType] = useState('')


	const handleRemoveConfirm = (event) => {

		event.stopPropagation();
		setOpenRemoveDialog(false);
		toggleWishlist(listingSeq);
	};


	const handleRemoveCancel = (event) => {
		event.stopPropagation();
		setOpenRemoveDialog(false);
	};



	const map = useRef(null);
	let activeMarker = null;
	let activePopup = null;

	useEffect(() => {

	}, [iconUrl, toggle])

	const toggleWishlist = (listingSeq) => {
		const type = 'L'
		if (checkIsWishlisted === false) {
			setCheckIsWishlisted(true)
			WishlistService.saveWishlist(wishlistType, listingSeq, (data, errorMsg) => {
				if (data === false) {
					setOpenRemoveDialog(true)
				} else {
					// toast.success(errorMsg, {
					// 	theme: "colored",
					// })
					toastMessage({
						message: errorMsg,
						type: 'success',
					});
					document.getElementById("mb_fav_icon").style.backgroundImage = 'url(/assets/images/icon_images/favorite_icon.svg)';
					setCheckIsWishlisted(true)
					setToggle(true)
				}
			})
		} else {
			WishlistService.removeFromWishlist(wishlistType, listingSeq, (data, errorMsg) => {
				if (!data) {
					// toast.warning("Something Went Wrong.", {
					//     theme: "colored",
					// })
				} else {
					// toast.success(errorMsg, {
					// 	theme: "colored",
					// })
					toastMessage({
						message: errorMsg,
						type: 'success',
					});
					document.getElementById("mb_fav_icon").style.backgroundImage = 'url(/assets/images/icon_images/favorite_border_icon.svg)';
				}
			})
		}
	}

	// const { user } = UserAuth()


	// CSS styles (you might want to move this to your main CSS file)
	const chipStyles = `
.mb_chip_container {
  display: flex;
  gap: 8px;
}

.mb_chip {
  display: flex;
  align-items: center;
  background-color: #f0f0f0;
  border-radius: 16px;
  padding: 4px 8px;
  font-size: 14px;
}

.mb_chip img {
  height: 14px;
  width: 14px;
  margin-bottom: 0px;
}

.mb_activity_type {
  margin: 0px;
}
`;

	// Function to create the chip HTML
	function createExperienceChip(type, duration, experienceType, experienceTypeKey) {
		// Early return if type is not "experience"
		if (type !== "experience") {
			return '';
		}

		// Determine icon path based on experienceType
		let iconPath;
		if (experienceTypeKey === "RE") {
			iconPath = '/assets/images/icon_images/event_exp_icon.svg';
		} else if (experienceTypeKey === "GT") {
			iconPath = '/assets/images/icon_images/tour_guide_exp_icon.svg';
		} else if (experienceTypeKey === "OA") {
			iconPath = '/assets/images/icon_images/outdoor_activity_exp_icon.svg';
		} else if (experienceTypeKey === "IP") {
			iconPath = '/assets/images/icon_images/in_person.svg';
		} else {
			iconPath = '/assets/images/icon_images/default_icon.svg'; // Default icon path
		}

		// Create and return the chip HTML
		return `
    <div class="mb_chip_container">
      <div class="mb_chip">
        <img src="/assets/images/icon_images/time.svg" style="height: 14px; width: 14px; margin-bottom: 0px;" alt="Duration" />
        &nbsp;<p style="margin:0px" class='mb_activity_type'>${duration}</p>
      </div>
      <div class="mb_chip" style="font-size:14px">
        <img src="${iconPath}" style="height: 14px; width: 14px; margin-bottom: 0px;" alt="${experienceType}" />
        &nbsp;<p style="margin:0px" class='mb_activity_type'>${experienceType}</p>
      </div>
    </div>
  `;
	}

	// Function to set active background image 
	function getActiveBackgroundImageUrl(showSearchType, extractedExperienceType, extractedExperienceTypeKey) {
		if (showSearchType === 'access') {
			return 'url("/assets/images/property_detail_page/map_pointer_active.svg")';
		}

		switch (extractedExperienceTypeKey) {
			case 'GT':
				return 'url(/assets/images/guided_tour_icon_active.svg)';
			case 'RE':
				return 'url(/assets/images/event_icon_active.svg)';
			case 'IP':
				return 'url(/assets/images/in_person_icon_active.svg)';
			default:
				return 'url(/assets/images/outdoor_activity_icon_active.svg)';
		}
	}

	function getBackgroundImageUrl(showSearchType, extractedExperienceType, extractedExperienceTypeKey) {
		if (showSearchType === 'access') {
			return 'url("/assets/images/property_detail_page/map_pointer.svg")';
		}

		switch (extractedExperienceTypeKey) {
			case 'GT':
				return 'url(/assets/images/guided_tour_icon.svg)';
			case 'RE':
				return 'url(/assets/images/event_icon.svg)';
			case 'IP':
				return 'url(/assets/images/in_person_icon.svg)';
			default:
				return 'url(/assets/images/outdoor_activity_icon.svg)';
		}

	}



	const addPopupToMarker = (marker, map, result, checkIsWishlisted) => {
		const popupContent = document.createElement('div');

		let type = extractDataFromArray(result, ['type'], '')

		let data;
		let photoList = []
		let mediaType = ''
		let mediaURL = ''
		let title = ''
		let address = ''
		let lat = 0
		let lon = 0
		let slug = ''
		let starting_price = 'N/A'
		let durationObj = {}
		let experienceType = ''
		let experienceTypeKey = ''
		let availability_id = ''
		let link = ''
		let rating = ''
		let reviewCount = ''
		let iconPath = ''
		let duration = ''
		if (type === "experience") {
			data = result.experience_data
			experienceType = result.experience_type
			experienceTypeKey = result.experience_type_key
			for (let key in data) {
				if (data[key].type === 'photos') {
					photoList = data[key].photo_list;
				}
				else if (data[key].type === 'general_info') {
					title = data[key].title;
					slug = data[key].slug
				}
				else if (data[key].type === 'location') {
					address = `Nearest Town, ${data[key].city}`;
					lat = data[key].lat;
					lon = data[key].lon;
				}
				else if (data[key].type === 'pricing') {
					durationObj = data[key].package_duration
					const slotList = data[key].slot_list;

					if (slotList.length === 0) {
						starting_price = 'N/A';
					} else {
						starting_price = Math.min(...slotList.map(slot => parseInt(slot.price, 10)));
					}
					const unit = extractDataFromArray(durationObj, ['unit'], 'hours').toLowerCase();
					duration = generatePhrase(
						parseInt(extractDataFromArray(durationObj, ['value'], 0)),
						(['hours', 'hrs'].includes(unit) ? 'Hr' : 'Day')
					)
				}
				else if (data[key].type === 'availability') {
					availability_id = data[key].id
				}
			}
			link = `/experience/${slug}-${result.experience_seq}`
			rating = extractDataFromArray(result, ['average_rating'], '')
			reviewCount = extractDataFromArray(result, ['review_count'], '')
			mediaType = extractDataFromArray(photoList, [0, 'type'])

			if (mediaType === 'VIDEO') {

				mediaURL = extractDataFromArray(photoList, [0, 'thumbnail_url'], '')
			} else if (mediaType === 'IMAGE') {

				mediaURL = extractDataFromArray(photoList, [0, 'url'], '')
			}


			if (experienceType === "Recreational Event") {
				iconPath = '/assets/images/icon_images/event_exp_icon.svg'
			} else if (experienceType === "Guided Tour") {
				iconPath = '/assets/images/icon_images/tour_guide_exp_icon.svg'
			} else if (experienceType === "Outdoor Activity") {
				iconPath = '/assets/images/icon_images/outdoor_activity_exp_icon.svg'
			} else if (experienceType === "In-Person Training") {
				iconPath = '/assets/images/icon_images/in_person.svg'
			}

		} else if (type === 'listing') {
			title = extractDataFromArray(result, ['listing', 'title'], 'property')
			rating = extractDataFromArray(result, ['listing', 'rating'], '')
			reviewCount = extractDataFromArray(result, ['listing', 'review_count'], 0)
			starting_price = Math.round(extractDataFromArray(result, ['listing', 'starting_price'], '1'))
			mediaType = extractDataFromArray(result, ['listing', 'image', 'type'], '')

			if (mediaType === 'VIDEO') {
				mediaURL = extractDataFromArray(result, ['listing', 'image', 'thumbnail_url'], '/landowner/assets/images/property_detail_page/blank_property_image.png')
			} else if (mediaType === 'IMAGE') {
				mediaURL = extractDataFromArray(result, ['listing', 'image', 'url'], '/landowner/assets/images/property_detail_page/blank_property_image.png')
			}
		}


		setListinSeq(showSearchType === 'access' ? result.listing.seq : extractDataFromArray(result, ['experience_seq']))
		popupContent.className = 'pop-up-content'
		popupContent.onclick = () => {
			let locationToOpen = isMobile ? "_self" : "_blank"
			if (type === 'listing') {
				window.open(`/property/${extractDataFromArray(result, ['listing', 'slug'], 'propery')}-${result.listing.seq}`, locationToOpen);
			} else if (type === 'experience') {
				window.open(`${link}`, locationToOpen);
			}
		};

		const showRating = type === 'listing'
			? !(extractDataFromArray(result, ['listing', 'review_count'], 0) == 0 || extractDataFromArray(result, ['listing', 'rating'], '') == 0)
			: !(extractDataFromArray(result, ['review_count'], 0) == 0 || extractDataFromArray(result, ['average_rating'], '') == 0);


		const contentDiv = document.createElement('div'); // Added this to wrap content below image in one div for new pop up design
		// contentDiv.style.width = '249px';
		// contentDiv.style.padding = '24px';
		contentDiv.className = 'data-wrapper'

		// Previously it was popupContent.innerHTML
		contentDiv.innerHTML = `

		<p class="mb_property_title">${title}</p>
		${type === 'listing' ?
				`<p class="mb_property_address">
		${extractDataFromArray(result, ['listing', 'area'], '1')} Acres, 
		${extractDataFromArray(result, ['listing', 'address'], '') === '-'
					?
					`near ${extractDataFromArray(result, ['listing', 'nearest_town'], '')}`
					:
					`${extractDataFromArray(result, ['listing', 'address'], '')}`
				}
		</p>` : `<p class="mb_property_address">${address} </p>`
			}
		${createExperienceChip(type, duration, experienceType, experienceTypeKey)}
		${showRating ?
				`<p class="mb_property_review_card">
			<img src="/assets/images/icon_images/star-solid.svg" style="height: 16px; width: 16px;" />
			<span class="mb_rating">&nbsp;${rating}</span>${reviewCount} reviews
			</p>`
				:
				''
			}
	  
      <p style="margin: 0">
        <span style="color: #1B1B1B; font-size: 14px; font-weight: bold;">$${starting_price}</span>
        <span style=" font-size: 14px; ">/Starting Price</span>
      </p>
    `;
		// Created 2 new div for wishlist icon logic
		const imageDiv = document.createElement("div");
		const videoPreview = document.createElement("div");
		const overlay = document.createElement("div");

		imageDiv.className = "mb_property_image";

		if (mediaType === 'VIDEO') {
			imageDiv.style.backgroundImage = "url(" + mediaURL + ")";
			videoPreview.style.backgroundImage = "url('/assets/images/icon_images/play-button-svgrepo-com.svg')";
			videoPreview.style.height = "40px";
			videoPreview.style.width = "40px";
			videoPreview.style.zIndex = 1000;
			videoPreview.style.margin = "auto";
			videoPreview.style.position = "absolute";
			videoPreview.style.padding = "16px";
			videoPreview.style.backgroundPosition = "center center";
			videoPreview.style.backgroundRepeat = "no-repeat";
			videoPreview.style.borderRadius = "4px";
			videoPreview.style.backgroundSize = "40px";
			videoPreview.style.top = '0px';
			videoPreview.style.left = '0px';
			videoPreview.style.right = '0px';
			videoPreview.style.bottom = '0px';

			// Adding overlay to imageDiv
			overlay.style.position = "relative";
			overlay.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
			overlay.style.position = "rgba(0, 0, 0, 0.5)";
			overlay.style.borderTopRightRadius = "16px";
			overlay.style.borderTopLeftRadius = "16px";
			overlay.style.width = "100%";
			overlay.style.height = "100%";
		} else if (mediaType === 'IMAGE') {
			imageDiv.style.backgroundImage = "url(" + mediaURL + ")";
		}

		// Append videoPreview to imageDiv
		imageDiv.appendChild(videoPreview);


		imageDiv.style.backgroundImage = "url(" + (extractDataFromArray(result, ['listing', 'image'],) || photoList) && '/landowner/assets/images/property_detail_page/blank_property_image.png' + ")"



		const favoriteIcon = document.createElement("div")
		favoriteIcon.id = "mb_fav_icon"
		favoriteIcon.style.backgroundImage = "url(" + iconUrl + ")"
		favoriteIcon.style.backgroundColor = "white"
		favoriteIcon.style.height = "34px"
		favoriteIcon.style.width = "34px"
		favoriteIcon.style.zIndex = 1000
		favoriteIcon.style.position = "absolute"
		favoriteIcon.style.padding = "16px"
		favoriteIcon.style.backgroundPosition = "center center"
		favoriteIcon.style.backgroundRepeat = "no-repeat"
		favoriteIcon.style.borderRadius = "4px"
		favoriteIcon.style.backgroundSize = "24px"
		favoriteIcon.style.top = "8px"
		favoriteIcon.style.right = "8px"

		imageDiv.append(favoriteIcon)
		imageDiv.append(videoPreview)
		imageDiv.append(overlay)
		popupContent.prepend(imageDiv);

		// popupContent.prepend(videoPreview);


		// if(!empty(user)) {
		// 	if(checkIsWishlisted) {

		// 	} else {
		// 		document.getElementById("mb_fav_icon").style.backgroundImage = 'url(/assets/images/icon_images/favorite_border_icon.svg)';
		// 	}
		// }


		favoriteIcon.addEventListener("click", (e) => {
			e.stopImmediatePropagation()
			e.stopPropagation()

			if (empty(user)) {
				setAuthPopup({
					...authPopup,
					type: 'L',
					open: true,
					callback: () => {
						if (checkIsWishlisted) {
							setOpenRemoveDialog(false);
						} else {
							let sequence = showSearchType === 'access' ? result.listing.seq : extractDataFromArray(result, ['experience_seq'])
							toggleWishlist(sequence);
						}
					}
				});
			} else {
				if (checkIsWishlisted) {
					// console.log(checkIsWishlisted, 'in lin 174')
					let sequence = showSearchType === 'access' ? result.listing.seq : extractDataFromArray(result, ['experience_seq'])
					WishlistService.isWishlist(sequence, wishlistType, (data) => {
						if (data) {
							setOpenRemoveDialog(true);
						} else {
							WishlistService.saveWishlist(wishlistType, sequence, (data, errorMsg) => {
								if (data) {
									// toast.success(errorMsg, {
									// 	theme: "colored",
									// })
									toastMessage({
										message: errorMsg,
										type: 'success',
									});
									document.getElementById("mb_fav_icon").style.backgroundImage = 'url(/assets/images/icon_images/favorite_icon.svg)';
									setCheckIsWishlisted(true)
									setToggle(true)
								}
							});
						}
					})
				} else {
					let sequence = showSearchType === 'access' ? result.listing.seq : extractDataFromArray(result, ['experience_seq'])
					toggleWishlist(sequence);
				}
			}
		})

		popupContent.appendChild(imageDiv); // Added these two appends for new pop up design , it was not here before
		popupContent.appendChild(contentDiv);

		const popup = new mapboxgl.Popup({ closeButton: false, offset: 25 }).setDOMContent(popupContent);

		popup.setLngLat(marker.getLngLat()).addTo(map.current);

		if (activeMarker && activeMarker !== marker) {

			activeMarker.getElement().style.backgroundImage = '/assets/images/property_detail_page/map_pointer.svg';
		}

		const extractedExperienceType = extractDataFromArray(result, ['experience_type'], '');
		const extractedExperienceTypeKey = extractDataFromArray(result, ['experience_type_key'], '');
		const activeBackgroundImageUrl = getActiveBackgroundImageUrl(showSearchType, extractedExperienceType, extractedExperienceTypeKey);

		marker.getElement().style.backgroundImage = activeBackgroundImageUrl;
		activeMarker = marker;
		marker.setPopup(popup);
		map.current.on('click', (event) => {
			// Check if the click is not on a custom marker
			const clickedMarker = event.originalEvent.target.closest('.custom-marker');
			if (!clickedMarker) {
				// Remove active style from the previous active marker
				if (activeMarker) {

					let currentBgImage = activeMarker.getElement().style.backgroundImage;

					// Remove the quotes around the URL
					currentBgImage = currentBgImage.replace(/['"]+/g, '');

					// Remove 'url(' from the start and ')' from the end
					currentBgImage = currentBgImage.slice(4, -1);

					// Replace '_active' with '' to get the inactive image URL
					let inactiveBgImage = currentBgImage.replace('_active', '');

					activeMarker.getElement().style.backgroundImage = `url("${inactiveBgImage}")`;
					activeMarker.getElement().style.zIndex = 0;
					activeMarker = null;

					// Close any open popups
					if (activePopup) {
						activePopup.remove();
						activePopup = null;
					}
				}
			}
		});
	};

	useEffect(() => {
		const coordinatesSet = new Set();
		const coordinates = results.length > 0 ? results.reduce((acc, result) => {
			let lat;
			let lon;

			if (result) {
				if (result.type === 'listing' && result.listing) {
					lat = parseFloat(result.listing.lat) || null;
					lon = parseFloat(result.listing.lon) || null;
					if (showSearchType === 'access') {
						setLatitue(result.listing.lat || '');
						setLongitude(result.listing.lon || '');
					}
				} else if (result.type === 'experience' && result.experience_data && result.experience_data.location_03cd) {
					lat = parseFloat(extractDataFromArray(result, ['experience_data', 'location_03cd', 'lat'], '')) || null;
					lon = parseFloat(extractDataFromArray(result, ['experience_data', 'location_03cd', 'lon'], '')) || null;
					if (showSearchType === 'experience') {
						setLatitue(extractDataFromArray(result, ['experience_data', 'location_03cd', 'lat'], '') || '');
						setLongitude(extractDataFromArray(result, ['experience_data', 'location_03cd', 'lon'], '') || '');
					}
				}
			}

			if (!isNaN(lat) && !isNaN(lon)) {
				const latLonString = `${lat}_${lon}`;
				if (!coordinatesSet.has(latLonString)) {
					coordinatesSet.add(latLonString);
					acc.push({ lon, lat });
				}
			}
			return acc;
		}, []) : null;

		// Initialize map only if it doesn't exist
		if (!map.current) {
			mapboxgl.accessToken = 'pk.eyJ1IjoiZmFyd2lkZSIsImEiOiJjajloOHc4MGozNWFnMnFsc2FueXJvbnZ3In0.7Cek0jLTdXkikf2ioduEvg';

			map.current = new mapboxgl.Map({
				container: mapContainer.current,
				style: 'mapbox://styles/mapbox/streets-v12',
				center: [defaultLng, defaultLat],
				zoom: 4,
				attributionControl: false,
			});

			const nav = new mapboxgl.NavigationControl();
			map.current.addControl(nav, 'top-left');
		}

		// Update map view based on results
		if (map.current) {
			// Remove existing markers
			const existingMarkers = document.querySelectorAll('.custom-marker');
			existingMarkers.forEach(marker => {
				marker.remove();
			});

			if (results.length === 0) {
				map.current.flyTo({
					center: [defaultLng, defaultLat],
					zoom: 4,
					duration: 1000,
					essential: true
				});
			} else if (coordinates) {
				if (coordinates.length === 1) {
					map.current.flyTo({
						center: coordinates[0],
						zoom: 9,
						duration: 1000,
						essential: true
					});
				} else {
					// Calculate bounds to fit all result coordinates
					const bounds = coordinates.reduce(
						(bounds, coord) => bounds.extend(coord),
						new mapboxgl.LngLatBounds(coordinates[0], coordinates[0])
					);

					map.current.fitBounds(bounds, {
						padding: 50,
						duration: 1000,
						easing: t => t * (2 - t)
					});
				}

				// Add markers for results
				results.forEach((result) => {
					const extractedExperienceType = extractDataFromArray(result, ['experience_type'], '');
					const extractedExperienceTypeKey = extractDataFromArray(result, ['experience_type_key'], '');

					switch (showSearchType) {
						case 'access':
							setExperienceType('property');
							break;
						case 'experience':
							setExperienceType(extractedExperienceType);
							break;
						default:
							break;
					}

					const backgroundImageUrl = getBackgroundImageUrl(showSearchType, extractedExperienceType, extractedExperienceTypeKey);

					const customMarker = document.createElement('div');
					customMarker.className = 'custom-marker';
					customMarker.style.backgroundImage = backgroundImageUrl;
					customMarker.style.width = '50px';
					customMarker.style.height = '50px';
					customMarker.style.backgroundRepeat = 'no-repeat';
					customMarker.style.cursor = 'pointer';

					const markerCoordinates = showSearchType === 'access'
						? (result?.listing?.lon && result?.listing?.lat)
							? [result.listing.lon, result.listing.lat]
							: null
						: (extractDataFromArray(result, ['experience_data', 'location_03cd', 'lon'], '') &&
							extractDataFromArray(result, ['experience_data', 'location_03cd', 'lat'], ''))
							? [
								extractDataFromArray(result, ['experience_data', 'location_03cd', 'lon'], ''),
								extractDataFromArray(result, ['experience_data', 'location_03cd', 'lat'], '')
							]
							: null;

					let marker;
					if (markerCoordinates) {
						marker = new mapboxgl.Marker({ element: customMarker })
							.setLngLat(markerCoordinates)
							.addTo(map.current);
					}

					const activeBackgroundImageUrl = getActiveBackgroundImageUrl(showSearchType, extractedExperienceType, extractedExperienceTypeKey);

					marker && marker.getElement().addEventListener('click', () => {
						if (activeMarker) {
							let currentBgImage = activeMarker.getElement().style.backgroundImage;
							currentBgImage = currentBgImage.replace(/['"]+/g, '');
							currentBgImage = currentBgImage.slice(4, -1);
							let inactiveBgImage = currentBgImage.replace('_active', '');
							activeMarker.getElement().style.backgroundImage = `url("${inactiveBgImage}")`;
							activeMarker.getElement().style.zIndex = 0;
							activeMarker.getPopup().remove();
						}

						customMarker.style.backgroundImage = activeBackgroundImageUrl;
						activeMarker = marker;
						activeMarker.getElement().style.zIndex = 1000;

						let user1 = JSON.parse(localStorage.getItem("userData")) || {};

						if (!empty(user1) || user1['loggedIn'] === "Y") {
							WishlistService.isWishlist(
								showSearchType === 'access' ? result.listing.seq : extractDataFromArray(result, ['experience_seq']),
								wishlistType,
								(data) => {
									setIsWishlist(data);
									if (data === true) {
										document.getElementById("mb_fav_icon").style.backgroundImage = 'url(/assets/images/icon_images/favorite_icon.svg)';
										setCheckIsWishlisted(true);
									} else {
										document.getElementById("mb_fav_icon").style.backgroundImage = 'url(/assets/images/icon_images/favorite_border_icon.svg)';
										setCheckIsWishlisted(false);
									}
								}
							);
						}

						addPopupToMarker(marker, map, result, checkIsWishlisted);
					});
				});
			}
		}

		return () => {
			// Cleanup function - don't remove the map, just clean up markers if needed
			const existingMarkers = document.querySelectorAll('.custom-marker');
			existingMarkers.forEach(marker => {
				marker.remove();
			});
		};
	}, [results]);


	useEffect(() => {
		// Cleanup function to remove the element immediately when listingSequence becomes empty
		// Remove all existing marker elements
		const existingMarkers = document.querySelectorAll('.custom2-marker');
		existingMarkers.forEach(marker => marker.remove());

		// If listingSequence is not empty, create new marker elements with unique IDs
		if (listingSequence !== "") {
			const resultsToDisplay = results.length > 0 ? results : [];

			resultsToDisplay.forEach((result) => {

				const extractedExperienceType = extractDataFromArray(result, ['experience_type'], '');
				const extractedExperienceTypeKey = extractDataFromArray(result, ['experience_type_key'], '');

				switch (showSearchType) {
					case 'access':
						// console.log("in firsttttt");
						setExperienceType('property')
						break;
					case 'experience':
						// console.log("in second", extractDataFromArray(result, ['experience_data', 'general_info_01ab', 'experience_type'], ''));
						setExperienceType(extractedExperienceType)
						break;

					default:
						break;
				}

				if ((showSearchType === 'access' ? result.listing.seq : extractDataFromArray(result, ['experience_seq'], '')) === listingSequence) {
					const activeBackgroundImageUrl = getActiveBackgroundImageUrl(showSearchType, extractedExperienceType, extractedExperienceTypeKey);

					const customMarker = document.createElement('div');
					customMarker.id = `custom-marker-${showSearchType === 'access' ? result.listing.seq : extractDataFromArray(result, ['experience_seq'], '')}`;
					customMarker.className = 'custom2-marker';
					customMarker.style.backgroundImage = activeBackgroundImageUrl// Set default background image
					customMarker.style.width = '50px';
					customMarker.style.height = '50px'; // it was 100px before code fix
					customMarker.style.backgroundRepeat = 'no-repeat';
					customMarker.style.cursor = 'pointer';
					// customMarker.style.marginTop = '-25px'; commented for accurate pin location
					customMarker.style.zIndex = 10; // Set default zIndex

					// Add the marker to the map
					const marker = new mapboxgl.Marker({ element: customMarker }).setLngLat(showSearchType === 'access' ? [result.listing.lon, result.listing.lat] : [extractDataFromArray(result, ['experience_data', 'location_03cd', 'lon'], ''), extractDataFromArray(result, ['experience_data', 'location_03cd', 'lat'], '')]).addTo(map.current);

					// Remove the element and marker when component unmounts
					return () => {
						customMarker.remove();
						marker.remove();
					};
				}
			});
		}
	}, [listingSequence]);

	return (

		<>
			<div ref={mapContainer} className="map-container" />
			<Dialog open={openRemoveDialog} onClose={handleRemoveCancel} >
				<Box >
					<Box sx={{ p: 2.5 }}>
						<Typography variant="h6" sx={{ fontWeight: 700 }}>Confirm the action</Typography>
					</Box>
					<Divider />
					<Box sx={{ p: 2.5 }}>
						<Typography>Are you sure you want to remove this property from your wishlist?</Typography>
					</Box>
					<Box sx={{ display: 'flex', justifyContent: 'end', gap: 1, p: 2.5, width: '100%' }}>
						<Button variant="contained" color="error" onClick={handleRemoveConfirm}>REMOVE</Button>
						<Button variant="text" color="secondary" onClick={(event) => handleRemoveCancel(event)}>CANCEL</Button>
					</Box>
				</Box>
			</Dialog>
		</>
	)
};

export default SearchMap;
