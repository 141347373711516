import React, { useState, useEffect } from 'react';
import { AppBar, Box, Toolbar, Typography } from '@mui/material';
import { useLocation } from 'react-router';
import { UserAuth } from '../../store/context/userContext';

const ImpersonationHeader = () => {
    const [isCollapsed, setIsCollapsed] = useState(false);
    const [isManaged, setIsManaged] = useState(false);
    const [userName, setUserName] = useState('Unknown User');
    const [adminName, setAdminName] = useState('Admin')
    const { user } = UserAuth();
    const location = useLocation();

    useEffect(() => {
        // Utility function to get a specific cookie value
        const getCookie = (cookieName) => {
            const cookies = document.cookie.split(';');
            for (let cookie of cookies) {
                const [name, value] = cookie.trim().split('=');
                if (name === cookieName) {
                    return decodeURIComponent(value);
                }
            }
            return null;
        };

        // Set state based on cookies
        const managedCookie = getCookie('u-is_imp');
        const nameCookie = getCookie('u-name');
        const adminName = getCookie('u-imp_admin_em');

        setIsManaged(managedCookie === 'Y');
        setUserName(nameCookie || 'Unknown User');
        setAdminName(adminName || "Admin")

    }, [user]); // Rerun if `user` changes

    if (!isManaged || !user) {
        return null; // Do not render if the cookie is not present
    }

    return (
        <AppBar
            position={location.pathname === '/' ? 'sticky' : 'absolute'}
            sx={{
                top: 0,
                left: 0,
                bgcolor: '#001A6E',
                color: 'white',
                height: 20,
                width: isCollapsed ? '20px' : '100%',
                transition: 'width 0.3s ease',
                overflow: 'hidden',
                zIndex: (theme) => theme.zIndex.drawer + 2,
                display: 'flex',
                alignItems: 'center',
            }}
        >
            <Toolbar
                sx={{
                    minHeight: '20px !important',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                    <Typography
                        variant="body2"
                        sx={{
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            display: isCollapsed ? 'none' : 'inline-block',
                        }}
                    >
                        You are impersonating {userName}’s account. (By Admin : {adminName})
                    </Typography>
                    {/* icon button for collapsing/expanding */}
                    {/* <IconButton
                        size="small"
                        color="inherit"
                        onClick={() => setIsCollapsed(!isCollapsed)}
                    >
                        {isCollapsed ? <ArrowForwardIos sx={{ height: 20, width: 20 }} /> : <ArrowBackIosNew sx={{ height: 20, width: 20 }} />}
                    </IconButton> */}
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default ImpersonationHeader;
