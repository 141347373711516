import React, { useState, useEffect } from 'react';
import { Button, Typography, Card, CardContent, Select, MenuItem, Dialog, Box, Grid, TextField, IconButton } from "@mui/material";
import * as Icon from "@mui/icons-material";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { SingleInputDateRangeField } from '@mui/x-date-pickers-pro/SingleInputDateRangeField';
import { empty, toastMessage } from "../../utils/common";
import { LicenseInfo } from '@mui/x-date-pickers-pro';
import dayjs from "dayjs";
import '../EnquiryPopUp/style.css';
import { extractDataFromArray } from '../../utils/common';
import errorAndHelperText from "../../commonComponents/CommonConstants/errorAndHelperText.json"
import { UserAuth } from '../../store/context/userContext';
import { MessagesApi } from '../../services/messagesApi';
import GreyLabel from '../formElement/GreyLabel';
import config from '../../config';
import { useLocation, useNavigate } from 'react-router';

// Assuming you have an array of MUI numbers
LicenseInfo.setLicenseKey('370e579ab4fef48a1739afecb9c68d3bTz04OTIyMyxFPTE3NDU3NTAwNTUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y')

const ExpEnquiryPopUp = ({ open, handleClose, packages, list, experienceData, minDays, maxDays, setOpenExpEnquiryPopup
}) => {
    const [listerSeq, setListerSeq] = useState(extractDataFromArray(experienceData, ['lister_seq'], ''))
    const location = useLocation();
    const [toggle, setToggle] = useState(false)
    const defaultStartDate = null;
    const defaultEndDate = null;
    const [defaultDateRange, setDefaultDateRange] = useState([
        defaultStartDate,
        defaultEndDate,
    ]);
    const [isExperiencePage, setIsExperiencePage] = useState(false)
    const minGuests = 1;
    const maxGuests = 99;
    const [guests, setGuests] = useState(1);
    const { user, authPopup, setAuthPopup } = UserAuth();
    const navigate = useNavigate()


    // date picker variables
    const [dateRange, setDateRange] = useState(null)
    const [openDate, setOpenDate] = useState(false)
    const [dateSelectText, setDateSelectText] = useState('')
    const [errorText, setErrorText] = useState('')
    const [dateError, setDateError] = useState(false)

    const openAuthPopup = (type) => {
        setAuthPopup({
            ...authPopup,
            type: type,
            open: true,
            callback: () => {
                submitData();
            },
        });
    };

    const handleGuestChange = (event) => {
        setGuests(event.target.value);
        setData((prevData) => ({
            ...prevData,
            guest_count: event.target.value
        }));
    };

    // const handleDateChange = (date) => {
    //     setData((prevData) => ({
    //         ...prevData,
    //         check_in_date: dayjs(date[0]).format('YYYY-MM-DD'),
    //         check_out_date: dayjs(date[1]).format('YYYY-MM-DD')
    //     }));
    // };

    const handleDateChange = (date) => {
        console.log(date, dateRange);
        if (dateRange) {
            if (dateRange[0] && dateRange[0].format("YYYY-MM-DD") !== "Invalid Date") {
                if (dateRange[1] && dateRange[1].format("YYYY-MM-DD") !== "Invalid Date") {
                    date[1] = null
                    setOpenDate(true)
                }
            }
        }

        setData((prevData) => ({
            ...prevData,
            check_in_date: dayjs(date[0]).format('YYYY-MM-DD'),
            check_out_date: dayjs(date[1]).format('YYYY-MM-DD')
        }));

        setDateRange(date)
    }

    const handleMessageChange = (event) => {
        const message = event.target.value;
        setData((prevData) => ({
            ...prevData,
            message: message
        }));
    };

    const [data, setData] = useState({
        package_seq: '',
        lister_seq: listerSeq,
        guest_count: 1,
        check_in_date: null,
        check_out_date: null,
        message: '',
        package_type: 'experience'
    })

    useEffect(() => {

        setData({
            package_seq: experienceData.experience_seq,
            lister_seq: listerSeq,
            guest_count: 1,
            check_in_date: null,
            check_out_date: null,
            message: '',
            package_type: 'experience'
        })

    }, [])

    const redirectToChat = () => {
        if (config.module === "guest") {
            navigate('/chat/' + (extractDataFromArray(experienceData, ['lister', 'fname'], "")) + '/' + (extractDataFromArray(experienceData, ['lister_seq'])))
        } else {
            navigate('/landowner/chat/' + (extractDataFromArray(experienceData, ['lister', 'fname'])) + '/' + (extractDataFromArray(experienceData, ['lister_seq'])))
        }
    }

    const [errors, setErrors] = useState({
        message: '',
        check_in_date: '',
        check_out_date: ''
    });

    const validateForm = () => {
        let valid = true;
        const newErrors = {
            message: '',
            check_in_date: '',
            check_out_date: ''
        };

        // Make sure we're accessing the actual string message from the helperTextsObj
        if (data.message.trim() === "") {
            newErrors.message = errorAndHelperText.helperTextsObj.required_message.message;  // Note the .message here
            valid = false;
        }

        if (data.check_in_date === "Invalid Date" || data.check_in_date === null) {
            newErrors.check_in_date = errorAndHelperText.helperTextsObj.check_in_date_required.message;  // Note the .message here
            valid = false;
        }

        if (data.check_out_date === "Invalid Date" || data.check_out_date === null) {
            newErrors.check_out_date = errorAndHelperText.helperTextsObj.check_out_date_required.message;  // Note the .message here
            valid = false;
        }

        setErrors(newErrors);
        return valid;
    };

    useEffect(() => {
        if (data.message != "") {
            validateForm()
        }
    }, [data.message])


    const submitData = () => {

        const isValid = validateForm();

        // Set errors even if the form is not submitted
        if (!isValid) {
            // Additional logic for date validation
            if (data.check_out_date === "Invalid Date" || data.check_out_date === null) {
                setToggle(true);
            }
            if (data.check_in_date === "Invalid Date" || data.check_in_date === null) {
                setToggle(true);
            }
        }

        // If form is not valid, do not submit
        if (!isValid) {
            return;
        }
        // console.log("user", user)
        if (empty(user)) {
            openAuthPopup("signin");
            return;
        }
        // console.log("data to send", data);
        MessagesApi.sendEnquiry(
            data,
            (res) => {
                if (res != '') {
                    redirectToChat()
                    setOpenExpEnquiryPopup(false)
                }
            },
            (err) => {
                // toast.error(err, {
                //     theme: 'colored'
                // })
                toastMessage({
                    message: err,
                    type: 'error'
                })

            }
        );
    };

    const [selectedDays, setSelectedDays] = useState(0);


    useEffect(() => {

        if ((data.check_in_date != "Invalid Date") && (data.check_out_date != "Invalid Date")) {
            setToggle(false)
        }
    }, [data.check_in_date, data.check_out_date])


    if (isNaN(selectedDays)) {
        selectedDays = 0;
    }



    useEffect(() => {


        if (location.pathname.includes('/experience')) { //added it to check if it is property page or not deending on which we either redirect user to property page or close the popup if alread on property page
            setIsExperiencePage(true)
        } else {
            setIsExperiencePage(false)
        }

    }, [])

    useEffect(() => {
        if (!dateRange) {
            setDateSelectText('Select Dates')
            return
        }

        if (dateRange[0] == null || dateRange[1] == null) {
            setDateSelectText('Select Dates')
        } else if (dateRange[0].format('YYYY-MM-DD') == dateRange[1].format('YYYY-MM-DD')) {
            const invalidCheck = dateRange[0].format('MMM DD, YYYY')
            if (invalidCheck.includes('Invalid')) {
                setDateRange([null, null])
                return
            }
            setDateSelectText(`${dateRange[0].format('MMM DD, YYYY')}`)
        } else {
            const invalidCheck = `${dateRange[0].format('MMM DD, YYYY')}${dateRange[1].format('MMM DD, YYYY')}`
            if (invalidCheck.includes('Invalid')) {
                setDateRange([null, null])
                return
            }
            setDateSelectText(`${dateRange[0].format('MMM DD, YYYY')} - ${dateRange[1].format('MMM DD, YYYY')}`)
        }


    }, [dateRange, guests]);


    return (

        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth="lg"
            sx={{
                "& .MuiPaper-root": {
                    maxWidth: 400,
                    width: '100%'
                }
            }}
        >
            <Box
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                p={2}
                sx={{ borderBottom: "1px solid #C5C5C7", backgroundColor: "#FFFFFF", zIndex: 2 }}
            >
                <Typography variant="h6" sx={{
                    fontWeight: "bold"
                }}>{"Contact " + extractDataFromArray(experienceData, ['lister', 'fname'], '')} </Typography>
                <IconButton onClick={handleClose}>
                    <Icon.Close />
                </IconButton>
            </Box>


            <Box
                p={2}
                sx={{ maxWidth: 400 }}
            >


                <Box
                    sx={{
                        border: "1px solid var(--grey700)",
                        borderRadius: (theme) => `${theme.card.borderRadiusMd} `,
                        mb: 3,
                        display: "flex",
                        flexDirection: "column", gap: 2,
                        pt: 3, pb: 3
                    }}
                >
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                        <Box sx={{ display: "flex", pl: 3, pr: 3, alignItems: "center" }}>
                            <Box sx={{ display: "flex", flexDirection: "row", gap: 0.5 }}>

                                <Icon.PersonOutlineOutlined fontSize='small' sx={{ color: "var(--grey900)" }} />


                                {/* <Typography>
                      {guests === 1 ? "1 Guest" : guests + " Guests"}
                    </Typography>  */}
                                <Typography variant='body1' sx={{ color: "var(--grey900)" }}>
                                    Guest
                                </Typography>

                            </Box>
                        </Box>
                        <Box sx={{ pl: 3, pr: 3, }}>
                            <Select
                                fullWidth
                                displayEmpty
                                value={data.guest_count}
                                IconComponent={Icon.KeyboardArrowDownOutlined}
                                onChange={handleGuestChange}
                                sx={{
                                    height: "42px",
                                }}

                                MenuProps={{
                                    PaperProps: {
                                        sx: {
                                            maxHeight: {
                                                xs: 200,
                                                sm: 180,
                                                md: 250,
                                                lg: 470,
                                                xl: 400
                                            }
                                        }
                                    },
                                    MenuListProps: { style: { width: '100%' } }
                                }}
                            >
                                {Array.from({ length: maxGuests - minGuests + 1 }, (_, index) => (
                                    <MenuItem
                                        key={index + parseInt(minGuests)}
                                        value={index + parseInt(minGuests)}
                                    >
                                        {index + parseInt(minGuests)}
                                    </MenuItem>
                                ))}
                            </Select>
                        </Box>
                    </Box>
                    <Box>

                        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                            <Box sx={{ display: "flex", pl: 3, pr: 3, alignItems: "center", gap: 0.5, color: "var(--grey900)" }}>

                                <Icon.CalendarTodayOutlined fontSize='small' sx={{ color: "var(--grey900)" }} />



                                <Typography variant='body1' sx={{ color: "var(--grey900)" }}>Dates</Typography>



                            </Box>
                            <Box sx={{ pl: 3, pr: 3, alignItems: "center" }}>

                                <Box sx={{ height: 0 }} >
                                    <LocalizationProvider fullWidth dateAdapter={AdapterDayjs}>
                                        <DateRangePicker
                                            open={openDate}
                                            calendars={1}
                                            clearable={true}
                                            slots={{ field: SingleInputDateRangeField }}
                                            slotProps={{
                                                textField: {
                                                    size: "small",
                                                    placeholder: "Select Dates",
                                                    InputProps: { endAdornment: <Icon.CalendarMonth /> },
                                                },
                                                actionBar: { actions: ["clear", "accept"] },
                                                dialog: {
                                                    maxWidth: 'md'
                                                },
                                                popper: { // Added this to show clear and ok button on smaller screen
                                                    sx: {
                                                        '& .MuiPickersPopper-paper': {
                                                            height: '269px !important',
                                                            maxHeight: '269px !important',
                                                            overflowY: 'auto !important',
                                                        },
                                                        '& .MuiPickersSlideTransition-root.MuiDayCalendar-slideTransition': {
                                                            minHeight: '212px !important',
                                                            // Add any other styles you want to apply to this class
                                                        },
                                                        "& .MuiPickersCalendarHeader-root": {
                                                            marginTop: '4px !important',
                                                            marginBottom: '4px !important'
                                                        }
                                                    },
                                                }
                                            }}
                                            sx={{ minWidth: "unset !important" }}
                                            format="DD MMM"
                                            value={defaultDateRange}
                                            onOpen={() => { setOpenDate(true) }}
                                            onClose={() => { setOpenDate(false) }}
                                            onChange={(date) => handleDateChange(date)}

                                            disablePast
                                            disableHighlightToday
                                            closeOnSelect
                                        // shouldDisableDate={isDisabledDate}
                                        />
                                    </LocalizationProvider>
                                </Box>
                                <TextField
                                    sx={{
                                        zIndex: '99',
                                        borderRadius: '6px',
                                        // transition: 'border-color 0.3s', // Add transition for smooth effect
                                        color: 'var(--black)',
                                        '& :hover': {
                                            cursor: 'pointer'
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            backgroundColor: 'white',
                                        },
                                        "&. MuiInputBase-root": {
                                            display: 'none',
                                        },
                                        "& .MuiFormControl-root": {
                                            display: 'none'
                                        }, "& .MuiFormHelperText-root": {
                                            ml: 0,
                                            color: 'var(--primary)'
                                        }, "& .MuiInputBase-input": {
                                        }, "& .Mui-error": {
                                            color: '#d32f2f'
                                        }
                                    }}
                                    InputProps={{
                                        endAdornment: <Icon.CalendarToday />,
                                        style: { color: 'black' },
                                        inputProps: { style: { color: 'black' } }
                                    }}
                                    fullWidth
                                    autoComplete="off"
                                    size="small"
                                    value={dateSelectText}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setOpenDate(!openDate)
                                    }}
                                    onKeyDown={(e) => e.preventDefault()} // Prevent typing inside TextField
                                />
                                {toggle && <Typography variant='caption' color="error">Please select dates</Typography>}
                            </Box>
                        </Box>
                    </Box>
                </Box>

                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                    <GreyLabel label='Message' required variant="body1" sx={{ color: "var(--textGrey)", mb: 0 }}>

                    </GreyLabel>
                    <TextField
                        fullWidth
                        placeholder="Enter your message"
                        maxRows={2}
                        multiline
                        value={data.message}
                        onChange={handleMessageChange}
                        error={Boolean(errors.message)}
                        helperText={errors.message || ''}
                    >
                    </TextField>
                </Box>

                <Button variant="contained" color="primary" sx={{ mt: 3, borderRadius: "8px", height: "42px", fontSize: "1rem" }} fullWidth onClick={() => {
                    submitData();
                }}>
                    Send Message
                </Button>


            </Box>
        </Dialog>
    );
}

export default ExpEnquiryPopUp;
