import moment from "moment";
import UserService from "../services/user";
import { toast } from "react-toastify";
import { ROUTE_ABOUTUS, ROUTE_APP, ROUTE_CONSERVATION_TRUST, ROUTE_CONTACT_US, ROUTE_EXPERIENCE, ROUTE_PACKAGE, ROUTE_PROPERTY, ROUTE_SEARCH } from "../constants/page";

const handleLogout = (callback = null) => {
    window.localStorage.removeItem('userData');
    UserService.logoutUser();

    // Retrieve the current page from the URL (excluding the main path)
    const currentPath = window.location.pathname;
    const currentQuery = window.location.search;

    // Construct the redirect URL with the current page
    const redirectUrl = `/login?done=${encodeURIComponent(currentPath)}${currentQuery}`;

    // Redirect to the constructed URL after logout
    window.location.href = redirectUrl;

    // Optionally, you can still invoke a callback after the redirect
    if (callback && typeof callback === 'function') {
        callback();
    }
};


export function deepCopy(aObject) {
    if (!aObject) {
        return aObject;
    }
    if (typeof aObject == "object") //the type of array too is object, but just in case
    {
        let v;
        let bObject = Array.isArray(aObject) ? [] : {};
        for (const k in aObject) {
            if (aObject.hasOwnProperty(k)) {
                v = aObject[k];
                bObject[k] = (typeof v === "object") ? deepCopy(v) : v;
            }
        }
        return bObject;
    }
    return aObject;
}

export function extractDataFromArray(dataArr, paramsArr, defaultRetVal = '') {
    var data = defaultRetVal;
    try {
        if (!isEmpty(dataArr) && !isUndefined(paramsArr)) {
            data = dataArr;
            paramsArr = typeof paramsArr === "string" ? paramsArr.split(".") : paramsArr
            var len = paramsArr.length;
            for (var i = 0; i < len; ++i) {
                if (!isUndefined(paramsArr[i])) {
                    data = data[paramsArr[i]];
                }
            }
        }
        if (isUndefined(data)) {
            data = defaultRetVal;
        }
    }
    catch (e) {
        data = defaultRetVal;
    }
    return data;
}

export function setDataToArray(dataArr, paramsArr, value = '') {
    if (typeof paramsArr == 'string') {
        paramsArr = paramsArr.split(".")
    }
    try {
        if (paramsArr.length === 1) {
            dataArr[paramsArr[0]] = value;
        } else {
            if (typeof dataArr[paramsArr[0]] === "undefined") {
                dataArr[paramsArr[0]] = {}
            }
            let key = paramsArr.splice(0, 1)
            setDataToArray(dataArr[key[0]], paramsArr, value)
        }
    }
    catch (e) {

    }
}

// function extractEscapeHtmlDataFromArray(dataArr, paramsArr, defaultRetVal = '') {
//     return escapeHTML(extractDataFromArray(dataArr, paramsArr, defaultRetVal));
// }

// function extractUnescapeHtmlDataFromArray(dataArr, paramsArr, defaultRetVal = '') {
//     return unescapeHTML(extractDataFromArray(dataArr, paramsArr, defaultRetVal));
// }

export function setValueIfEmpty(variable, value) {
    if (empty(variable)) {
        variable = value
    }
    return variable;
}

export function isEmpty(str) {
    if (isUndefined(str)) return true;

    if (!empty(str)) {
        str += '';
        if (str === 'undefined') return true;
        str.replace(/^\s+|\s+$/g, '');
        return (str === '' || str === 0);
    }
    return true;
}

export function isUndefined(mixed_var) {
    if (typeof mixed_var == "undefined" || mixed_var === undefined || mixed_var === null) {
        return true;
    }
    mixed_var += '';
    if (mixed_var === 'undef' || mixed_var === 'undefined') return true;
    return false;
}

export function isDefined(mixed_var) {
    return !isUndefined(mixed_var);
}

export function empty(mixed_var) {
    // Checks if the argument variable is empty
    // undefined, null, false, number 0, empty string,
    // string "0", objects without properties and empty arrays
    // are considered empty
    var undef, key, i, len;
    var emptyValues = [undef, undefined, null, false, 0, "", "0"];

    for (i = 0, len = emptyValues.length; i < len; i++) {
        if (mixed_var === emptyValues[i]) {
            return true;
        }
    }

    if (typeof mixed_var === "object") {
        for (key in mixed_var) {
            // TODO: should we check for own properties only?
            //if (mixed_var.hasOwnProperty(key)) {
            return false; //non-empty object or array
            //}
        }
        return true;
    }

    return false;
}

export function parseResponse(response) {
    if (response === undefined || response == null || typeof response != "object") //beware null typeof is object
    {
        response = { errorCode: "-1", errorMsg: "Unknown Error, malformed response received.", html: "" };
    }

    if (extractDataFromArray(response, ['errorMsg'], '') === "RCP") {
        //Handle RCP
        handleLogout()
    }
    return response;
}

export function parseResponseAxios(response) {
    if (response === undefined || response == null || typeof response != "object") //beware null typeof is object
    {
        response = { errorCode: "-1", errorMsg: "Unknown Error, malformed response received.", html: "" };
    }
    response = extractDataFromArray(response, ['data'], [])


    if (extractDataFromArray(response, ['errorMsg'], '') === "RCP") {
        //Handle RCP
        handleLogout()
    }
    return response;
}

export function getUrlParamByName(name) {
    const queryParameters = new URLSearchParams(window.location.search)
    return queryParameters.get(name)
}

export function formatBytes(bytes, decimals = 2) {
    if (!+bytes) return '0 Bytes'

    const k = 1024
    const dm = decimals < 0 ? 0 : decimals
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

    const i = Math.floor(Math.log(bytes) / Math.log(k))

    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

export function exponentialBackoff(toTry, max, delay, callback, onFail = () => { }) {
    var result = toTry();

    if (result) {
        callback();
    } else {
        if (max > 0) {
            setTimeout(function () {
                var updatedDelay = delay / 2 < 1000 ? 1000 : delay / 2;
                exponentialBackoff(toTry, --max, updatedDelay, callback);
            }, delay);

        } else {
            onFail()
        }
    }
}

export function ucFirst(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function isValueEqual(data, value) {
    return data === value
}

export function getDateToString(date) {
    return empty(date) ? '' : date.format('YYYY-MM-DD');
}


export function isNumber(num) {
    var regex = /^-?\d+(\.\d*)?$/
    return regex.test(num)
}
export function isSet(obj, key) {
    return typeof obj[key] != "undefined"
}


function getDefaultValue(type) {
    switch (type) {
        case 'object':
            return {}
        case 'array':
            return []
        default:
            return '';
    }
}
export function checkIfJsonHasNonEmptyValues(obj) {
    let errorExists = false
    let keys = Object.keys(obj)

    for (let i = 0; i < keys.length; i++) {
        if (!empty(obj[keys[i]])) {
            if (typeof obj[keys[i]] === 'object') {
                errorExists = checkIfJsonHasNonEmptyValues(obj[keys[i]])
                if (errorExists) {
                    return true;
                }
            } else {
                return true
            }
        }
    }
    return errorExists
}

export function validateJSON(data, validationSchema) {
    let error = {}
    if (empty(validationSchema)) {
        return {}
    }
    Object.keys(validationSchema).forEach((key, i) => {
        const schema = validationSchema[key]
        const type = extractDataFromArray(schema, ['type'], false);

        let value;
        if (!isSet(data, key)) {
            data[key] = extractDataFromArray(schema, ['default'], getDefaultValue(type))
        }


        if (isSet(schema, 'checkIf') && !empty(extractDataFromArray(schema, 'checkIf', {}))) {
            let checkIfConfig = extractDataFromArray(schema, 'checkIf', {})
            let condition = extractDataFromArray(checkIfConfig, ['is'], '')
            let fieldValue = extractDataFromArray(data, extractDataFromArray(checkIfConfig, ['field'], ''), '')
            if (typeof condition === 'function') {
                if (!condition(fieldValue, data)) {
                    return
                }
            } else {
                if (condition !== fieldValue)
                    return
            }
        }

        value = extractDataFromArray(data, [key])

        const required = extractDataFromArray(schema, ['required'], false);
        const max = extractDataFromArray(schema, ['max'], false);
        const min = extractDataFromArray(schema, ['min'], false);

        switch (type) {
            case 'string':
                value = value.toString().trim()
                if (required && empty(value)) {
                    error[key] = extractDataFromArray(schema, ['requiredMsg'], "Value required")
                    break;
                } else if (max !== false && value.length > max) {
                    error[key] = extractDataFromArray(schema, ['maxMsg'], false)
                    break;
                } else if (min !== false && value.length < min) {
                    error[key] = extractDataFromArray(schema, ['minMsg'], false)
                    break;
                }
                break;
            case 'array':
                if (required && empty(value)) {
                    error[key] = extractDataFromArray(schema, ['requiredMsg'], "Value required")
                    break;
                } else if (min !== false && value.length < min) {
                    error[key] = extractDataFromArray(schema, ['minMsg'], false)
                    break;
                } else if (max !== false && value.length > max) {
                    error[key] = extractDataFromArray(schema, ['maxMsg'], false)
                    break;
                }
                break;
            case 'number':
                value = parseFloat(value)
                const positive = extractDataFromArray(schema, ['positive'], false);
                if (!empty(value) && !isNumber(value)) {
                    error[key] = "Enter a valid number";
                    break;
                } else if (required && empty(value)) {
                    error[key] = extractDataFromArray(schema, ['requiredMsg'], "Value required")
                    break;
                } else if (max !== false && value > max) {
                    error[key] = extractDataFromArray(schema, ['maxMsg'], false)
                    break;
                } else if (positive && value < 0) {
                    error[key] = "Enter a positive number";
                    break;
                } else if (min !== false && value < min) {
                    error[key] = extractDataFromArray(schema, ['minMsg'], false)
                    break;
                }
                break;
            case 'object':
                if (!empty(extractDataFromArray(schema, ['objectFields'], {}))) {
                    error[key] = validateJSON(data[key], extractDataFromArray(schema, ['objectFields'], {}))
                }
                break;
            default:
        }

        const isValid = extractDataFromArray(schema, ['isValid'], null)
        if (!empty(isValid) && empty(error[key])) {
            let isValidMsg = isValid(data, value)
            if (!empty(isValidMsg)) {
                error[key] = isValidMsg
            }
        }

    })

    return error;

}

export function getImageUrl(url, type = 'l') {
    if (url.indexOf("farwide.s3") > -1 && type !== "l") {
        url = url.split(".")
        url[url.length - 2] = url[url.length - 2] + "_" + type
        url = url.join(".")
    }
    return url;
}

export function decodeHTML(html) {
    var txt = document.createElement('textarea');
    txt.innerHTML = html.replace(/&amp;/g, '&');
    return txt.value;
}

export function convertTo24HourFormat(timestamp) {
    // Check if the timestamp is already in hours format
    if (/^\d{2}:\d{2}:\d{2}$/.test(timestamp)) {
        // If it is, return it as is
        return timestamp;
    }

    // If not, assume it's in 24-hour format and may not include seconds
    var [timePart, ampmPart] = timestamp.split(' ');
    var [hours, minutes, seconds] = (timePart.split(':').map(Number));

    // Handle missing AM/PM information
    if (!ampmPart) {
        // If no AM/PM, assume 24-hour format and set seconds to 00 if undefined
        seconds = seconds || 0;
    } else {
        // Adjust hours for PM time (except for 12:00 PM, which remains unchanged)
        if (ampmPart.toUpperCase() === 'PM' && hours < 12) {
            hours += 12;
        }

        // Adjust hours for 12:00 AM (midnight)
        if (ampmPart.toUpperCase() === 'AM' && hours === 12) {
            hours = 0;
        }
    }

    // Ensure seconds are defined and convert to 24-hour format
    var formattedSeconds = (isNaN(seconds) ? '00' : (seconds < 10) ? '0' + seconds : seconds);
    var formattedMinutes = (minutes < 10) ? '0' + minutes : minutes;
    var formattedHours = (hours < 10) ? '0' + hours : hours;

    return formattedHours + ':' + formattedMinutes + ':' + formattedSeconds;
}

export function showZendeskLauncher(show = true) {
    exponentialBackoff(() => {
        return document.getElementById("launcher") !== null
    }, 10, 200, () => {
        document.getElementById("launcher").style.display = show ? "" : "none"
    })
}

export function convertUtcToLocal(utcTimeString) {
    let local_date = moment.utc(utcTimeString).local().format('ddd D MMM YYYY, hh:mm A');
    return local_date
}


export function generatePhrase(num, word) {
    if (num == 1 || num == 0) {
        return num + ' ' + word;
    } else {
        return num + ' ' + word + 's';
    }
}

export function generatePhraseReturnText(num, word) {
    if (num == 1 || num == 0) {
        return word;
    } else {
        return word + 's';
    }
}

export function generatePhraseAdvanced(num, word) {
    if (num == 1 || num == 0) {
        // Remove 's' at the end if present for singular numbers
        return num + ' ' + (word.endsWith('s') ? word.slice(0, -1) : word);
    } else {
        // Check if the word already ends with 's'
        if (word.endsWith('s')) {
            return num + ' ' + word;
        } else {
            return num + ' ' + word + 's';
        }
    }
}

export function getActivityType(type) {
    switch (type) {
        case 'H':
            return "Hunting"
        case 'F':
            return "Fishing"
        case 'FR':
            return "Farm"
        case 'BW':
            return "Bird Watching"
        default:
            return ""
    }
}

export function capitalizeWords(str) {
    return str.split(' ')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
        .join(' ');
}


export const getPlaceName = (address) => {
    if (typeof address === 'object' && address !== null && 'place_name' in address) {
        return address.place_name;
    }
    return address;
};

export function changeTimeFormat(time24h) {
    if (!time24h) {
        return null
    }
    // Split the time string into hours and minutes
    const [hours, minutes] = time24h.split(':').map(Number);

    // Determine AM or PM
    const period = hours < 12 ? 'AM' : 'PM';

    // Convert hours to 12-hour format
    let hours12 = hours % 12;
    hours12 = hours12 ? hours12 : 12; // If hours12 is 0, set it to 12

    // Format the result
    return `${hours12.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')} ${period}`;
}


export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}


export function findExperienceDataByType(experienceJson, targetType) {
    // Access the experience_data object
    const experienceData = experienceJson.experience_data;

    // Iterate through the keys of experience_data
    for (let key in experienceData) {
        if (experienceData.hasOwnProperty(key)) {
            const dataObject = experienceData[key];

            // Check if the type matches the targetType
            if (dataObject.type === targetType) {
                return dataObject; // Return the matching object
            }
        }
    }

    // If no matching type is found, return null or handle the case accordingly
    return null;
}

export function hasKey(obj, key) {
    return obj.hasOwnProperty(key);
    // Alternatively, you can use the 'in' operator:
    // return key in obj;
}


export function hasEmptyStrings(arr) {
    return arr.some(value => value === '');
}
export function hasStrings(arr) {
    return arr.every(value => typeof value === 'string');
}

export function roundNumber(num) {
    // Convert to a number if the input is a string
    if (typeof num === 'string') {
        num = parseFloat(num);
    }

    // If the number is an integer, or has .00 as decimals, return the rounded number
    if (num % 1 === 0) {
        return num;
    }

    // Convert the number to a string and split at the decimal point
    let numParts = num.toString().split('.');

    // If the decimal part is all zeros (like 34.00), round it
    if (parseFloat('0.' + numParts[1]) === 0) {
        return Math.round(num);
    }

    // If it has non-zero decimal values (like 34.546), return as is
    return num;
}

export const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => func(...args), delay);
    };
};

export const toastMessage = ({
    message,
    time,
    type,
    theme = 'colored',
    dismissAllPrevious = true
}) => {
    // Dismiss all previous toasts if the flag is true
    if (dismissAllPrevious) {
        toast.dismiss();
    }

    // Default time based on the length of the message if no time is provided
    const defaultTime = message.length * 100; // 100ms per character
    const displayTime = time || defaultTime;

    // Set the toast options including the theme
    const toastOptions = {
        autoClose: displayTime,
        theme: theme, // 'colored', 'light', 'dark'
    };

    // Set the toast type based on the provided "type"
    switch (type) {
        case 'error':
            toast.error(message, toastOptions);
            break;
        case 'success':
            toast.success(message, toastOptions);
            break;
        case 'info':
            toast.info(message, toastOptions);
            break;
        case 'warning':
            toast.warn(message, toastOptions);
            break;
        default:
            toast(message, toastOptions);
            break;
    }
};

export const isOpenPage = () => {
    const OpenPageArr = [
        ROUTE_SEARCH,
        ROUTE_PACKAGE,
        ROUTE_PROPERTY,
        ROUTE_EXPERIENCE,
        ROUTE_APP,
        ROUTE_ABOUTUS,
        ROUTE_CONSERVATION_TRUST,
        ROUTE_CONTACT_US,
    ];
    const currentPath = window.location.pathname;

    // Check if the current path starts with any of the routes
    return OpenPageArr.some(route => currentPath.startsWith(route));
};

export function decodeUnicodeString(str) {
    return JSON.parse('"' + str + '"');
}

export function currentYear() {
    return new Date().getFullYear();
}

export function isIOS() {
    return /iPhone|iPad|iPod/i.test(navigator.userAgent);
}

export function isAppInstalled() {
    const isIosMobileDevice = isIOS()
    if (isIosMobileDevice) {
        window.location = "https://search.farwide.com/apppayment"
    } else {
        window.location = "https://apps.apple.com/in/app/farwide-hunt-fish-explore/id1383297776"
    }
}