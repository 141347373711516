import * as React from "react";

import * as Icon from "@mui/icons-material";
import { Box, Button, Grid, Typography } from "@mui/material";
import { isAppInstalled, isIOS } from "../../../utils/common";

const ImageObject = {
    "name": "app_image_3.png",
    "alt_name": null,
    "file_type": "IMAGE",
    "size": "10740",
    "create_time": "2024-10-23 11:20:00",
    "update_time": "2024-10-23 11:20:00",
    "added_by": "2221",
    "folder_seq": "0",
    "tags": null,
    "url": "//farwide.s3.amazonaws.com/asset/MwG1ULxdB7FBRzUeshwNLOA9/2221/6718dbdfb5df81729682399.png",
    "thumbnail_url": ""
}

const AppInformationSecond = (props) => {
    const featureData = [
        {
            icon: <Icon.Verified />,
            text: 'Exclusive access to private land data across USA.',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        },
        {
            icon: <Icon.Verified />,
            text: 'Advanced Mapping Layers and Offline Maps.',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        },
        {
            icon: <Icon.Verified />,
            text: 'Route recording and sharing with FarWide Trax.',
            description: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
        },
    ];
    return (
        <Grid container sx={{ mt: { xs: 6, md: 12 } }} spacing={4} >

            <Grid container item xs={12} sm={12} md={12} lg={6}  >
                <Grid item xs={12} sm={12} md={12} sx={{}}>
                    <Box
                        sx={{
                            paddingInline: { xs: 3, sm: 3, md: 4 }
                        }}
                    >
                        <Typography variant="h3" color="#1B1B1B" sx={{ mb: 3 }}>Unleash Exclusive Premium Features for only $19.99 a year!</Typography>
                        <Typography variant="h6" color="#747579" sx={{ mb: { xs: 3, sm: 4 }, fontWeight: 'normal' }} >Activate Pro to get access to exclusive member tools and features.</Typography>
                    </Box>
                </Grid>

                <Box
                    sx={{
                        paddingInline: { xs: 3, sm: 3, md: 4 },
                        paddingBottom: { xs: 3, sm: 3, md: 9 }
                    }}
                >
                    {featureData.map((feature, index) => (
                        <Box
                            key={index}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                gap: 1,
                                mb: 3
                            }}
                        >
                            <Box color="#26C77A">{feature.icon}</Box>
                            <Box>
                                <Typography sx={{ mb: { md: 1.2 }, fontWeight: '500' }} >{feature.text}</Typography>
                                {/* <Typography sx={{ color: '#85878A' }} >{feature.description}</Typography> */}
                            </Box>
                        </Box>
                    ))}
                    <Button variant="contained" size="medium" onClick={() => { isAppInstalled() }} target="_blank" >
                        Upgrade for $19.99
                    </Button>
                </Box>

            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={6} sx={{ textAlign: { xs: "center", md: "center", lg: 'left' } }}>
                <Box sx={{ display: { xs: 'none', md: 'block' }, paddingInline: { xs: 3, sm: 3, md: 4 } }}>
                    <img className='fw_home_feature_image' src="//farwide.s3.amazonaws.com/asset/OicPxCOCp1f1yoY4zKb2BgRC/2221/677b8405b3a221736147973.webp" alt="FarWide" style={{ maxWidth: "100%" }} />
                </Box>
                <Box sx={{ display: { xs: 'block', md: 'none' }, paddingInline: { xs: 3, sm: 3, md: 4 } }}>
                    <img className='fw_home_feature_image' src="//farwide.s3.amazonaws.com/asset/OicPxCOCp1f1yoY4zKb2BgRC/2221/677b8405b3a221736147973.webp" alt="FarWide" style={{ maxWidth: "100%" }} />
                </Box>

            </Grid>
        </Grid>
    );
};

export default AppInformationSecond;