import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { ROUTE_CHAT, ROUTE_CHECKOUT, ROUTE_LISTER_HOME } from '../../../constants/page';
import { CommonContext } from '../../../store/context/commonContext';
import AuthPopup from '../../../commonComponents/authComponents/AuthPopup';
import { UserAuth } from '../../../store/context/userContext';
import { empty } from '../../../utils/common';
import { Grid, Menu, MenuItem } from '@mui/material';
import * as Icon from '@mui/icons-material';
import LinkHref from '@mui/material/Link'
import ProfileSection from '../../ProfileSection';
import { useEffect, useState } from 'react';
import LandingPageDrawer from "../Sidebar/index"
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { DEFAULT_LOGO_SIZE, SMALL_LOGO_SIZE } from '../../../commonComponents/CommonConstants/commonKeys';

const drawerWidth = 267;

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - 0px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
	position: 'fixed', // Make the position fixed
	top: 0, // Position at the top of the viewport
}));

const LandingPageHeader = (props) => {
	const { user, authPopup, setAuthPopup } = UserAuth()
	const { scroll, toggleNavigationDrawer, screenWidth } = CommonContext()
	const navigate = useNavigate()

	const openAuthPopup = (type) => {
		setAuthPopup({ ...authPopup, type: type, open: true })
	}
	const closeAuthPopup = () => {
		setAuthPopup({ ...authPopup, open: false })
	}
	const location = useLocation();
	const showSidebar = [ROUTE_CHAT, ROUTE_CHECKOUT].includes(
		location.pathname
	);

	const { isTablet, isNineHundred } = CommonContext();

	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleClick = (e) => {
		setAnchorEl(e.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const [aboutFarWideAchorEl, setAboutFarWideAnchorEl] = useState(null);
	const aboutOpen = Boolean(aboutFarWideAchorEl);
	const handleClickAbout = (event) => {
		setAboutFarWideAnchorEl(event.currentTarget)
	};
	const handleCloseAbout = (path) => {
		setAboutFarWideAnchorEl(null);
		if (path) {
			navigate(path)
		}
	};

	return (
		<AppBar sx={scroll > 0 ? { background: '#fff', borderBottom: 0, boxShadow: '0 1px 6px #00000020', pr: '0 !important' } : { background: '#ffffff00', boxShadow: 'unset', pr: '0 !important' }} position="fixed" open={props.open}>
			<LandingPageDrawer />
			<Toolbar
				sx={{
					pr: '0px !important', // keep right padding when drawer closed
					height: "72px",
					p: "16px !important"
				}}
			>
				<Grid container sx={{ margin: '0 auto' }}>
					<Box sx={{ position: "absolute", top: 16 }}>
						<Box sx={{ color: 'var(--black)' }}>
							<IconButton
								id="basic-button"
								aria-controls={open ? 'basic-menu' : undefined}
								aria-haspopup="true"
								aria-expanded={open ? 'true' : undefined}
								onClick={toggleNavigationDrawer(true)}
							>
								<Icon.MenuOutlined />
							</IconButton>
						</Box>
					</Box>
					<Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: "100%" }}>

						<Link className="logo image" to="/" style={{ marginInline: (empty(user) && isTablet) ? "auto" : (!empty(user) && screenWidth <= 768) ? 'auto' : '' }}>
							<Box sx={{ display: 'flex', marginLeft: (empty(user) && isTablet) ? "" : (!empty(user) && isTablet) ? '30px !important' : '48px !important' }}><img style={isTablet ? SMALL_LOGO_SIZE : DEFAULT_LOGO_SIZE} src='../assets/images/farwide-new-logo.svg' alt='FarWide' /></Box>
							{/* <Box className="fw_logo_mob" sx={{ display: { xs: 'block', sm: 'block', md: 'none' }, width: '40px' }}><img src='../assets/images/homepage/farwide-logo.svg' alt='FarWide' /></Box> */}
						</Link>
						{/* {!showSidebar &&
							<IconButton
								className='fw_hdr_toggle_btn'
								edge="start"
								aria-label="open drawer"
								onClick={props.toggleDrawer}
								sx={{
									marginRight: '24px',
									marginLeft: '24px',
									color: 'var(--black)',
									...(props.open),
								}}
							>
							</IconButton>
						} */}
						{
							!isTablet &&
							<Box sx={{ ml: 'auto', display: 'flex', alignItems: 'center' }}>
								<Button
									variant="link"
									color='var(--black)'
									sx={{
										textDecoration: 'none', mr: 1, color: 'var(--black)', "& .MuiButton-icon": {
											ml: 0
										}
									}}
									onClick={handleClickAbout}
									endIcon={aboutOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
								>
									About FarWide
								</Button>
								<Menu
									id="basic-menu"
									anchorEl={aboutFarWideAchorEl}
									open={aboutOpen}
									onClose={() => { handleCloseAbout() }}
									MenuListProps={{
										'aria-labelledby': 'basic-button',
									}}
									disableScrollLock={true}
								>
									<MenuItem onClick={() => { handleCloseAbout('/ourstory') }}>Our Story</MenuItem>
									<MenuItem onClick={() => { handleCloseAbout('/conservationtrust') }}>Conservation</MenuItem>
									{/* <MenuItem onClick={() => { handleCloseAbout('/newtofarwide') }}>New to FarWide?</MenuItem> */}
								</Menu>
								{
									!isTablet ?
										<LinkHref href={ROUTE_LISTER_HOME} sx={{ color: 'var(--black)', display: { xs: 'none', sm: 'block', md: 'block', textDecoration: 'none' } }}>
											<Button variant="link" color='var(--black)' sx={{ textDecoration: 'none', mr: 1 }}>List on FarWide</Button>
										</LinkHref> : <></>
								}
							</Box>
						}
						{/* <Box sx={{ ml: 'auto', display: 'flex', alignItems: 'center' }}>
							<LinkHref href={ROUTE_LISTER_HOME} sx={{ color: 'var(--black)', display: { xs: 'none', sm: 'none', md: 'block', textDecoration: 'none' } }}><Button variant="link" color='var(--black)' sx={{ textDecoration: 'none', mr: 1 }}>List Your Property </Button></LinkHref> */}
						{
							empty(user) ?
								<>
									{/* 
										{
											isTablet && (
												<>
													<Box sx={{ color: 'var(--black)' }}>
														<IconButton
															id="basic-button"
															aria-controls={open ? 'basic-menu' : undefined}
															aria-haspopup="true"
															aria-expanded={open ? 'true' : undefined}
															onClick={handleClick}
														>
															<Icon.MenuOutlined />
														</IconButton>

														<Menu
															id="basic-menu"
															anchorEl={anchorEl}
															open={open}
															onClose={handleClose}
															MenuListProps={{
																'aria-labelledby': 'basic-button',
															}}
														>
															<MenuItem>
																<LinkHref href={ROUTE_LISTER_HOME} sx={{ color: 'var(--black)', display: { md: 'block' }, textDecoration: 'none' }}>
																	List Your Property
																</LinkHref>
															</MenuItem>
															<MenuItem onClick={() => { handleClose(); openAuthPopup('signin') }}>Sign In</MenuItem>
															<MenuItem onClick={() => { handleClose(); openAuthPopup('signup') }}>Sign Up</MenuItem>
														</Menu>
													</Box>
												</>
											)
										} */}

									{
										!isTablet &&
										<Box>
											<Button size={isNineHundred ? 'small' : ''} variant="outlined" sx={{ mr: 1 }} onClick={() => { openAuthPopup('signin') }}>Sign In</Button>
											<Button size={isNineHundred ? 'small' : ''} variant="contained" onClick={() => { openAuthPopup('signup') }}>Sign Up</Button>
										</Box>

									}


									<AuthPopup open={authPopup.open} type={authPopup.type} handleClose={() => { closeAuthPopup(false) }} handleOpen={openAuthPopup} />
								</> :
								<Box sx={{ display: 'flex', alignItems: 'center' }}>
									<ProfileSection />
								</Box>
						}
						{/* </Box> */}
					</Box>
				</Grid>
			</Toolbar>
		</AppBar >)
}

export default LandingPageHeader

