import axios from "axios";
import config from "../config";
import { extractDataFromArray, parseResponseAxios, toastMessage } from "../utils/common";
import { toast } from "react-toastify";



const ListingService = {
    checkIfListingExists: async (callback = () => { }, errCallback = () => { }) => {
        await axios.get(config.apiUrl + "/services/api", {
            params: {
                cmd: "checkIfListingExists"
            }
        }).then((response) => {
            response = parseResponseAxios(response)
            if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
                callback(extractDataFromArray(response, ['data'], ''))
            } else {
                // toast.error(extractDataFromArray(response,['errorMsg'],'Something went wrong, Please try again lator'), {
                //     theme: "colored",
                // })
                toastMessage({
                    message: extractDataFromArray(response, ['errorMsg'], 'Something went wrong, Please try again later'),
                    type: 'error',
                });
                errCallback(extractDataFromArray(response, ['errorMsg'], []))
            }
        });
    },
    getListingData: async (listing_seq, callback = () => { }, errCallback = () => { }) => {
        await axios.get(config.apiUrl + "/services/openapi", {
            params: {
                cmd: "getListingPublicData",
                listing_seq
            }
        }).then((response) => {
            response = parseResponseAxios(response);
            if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
                const data = extractDataFromArray(response, ['data'], []);
                callback(data);
            } else {
                // toast.error(extractDataFromArray(response,['errorMsg'],'Something went wrong, Please try again lator'), {
                //     theme: "colored",
                // })
                errCallback(extractDataFromArray(response, ['errorMsg'], []))
            }
        });
    },
    getListingBookedDates: async (listing_seq, callback = () => { }) => {
        await axios.get(config.apiUrl + "/services/openapi", {
            params: {
                cmd: "getListingBookedDates",
                listing_seq
            }
        }).then((response) => {
            response = parseResponseAxios(response);
            if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
                const data = extractDataFromArray(response, ['data'], []);
                callback(data);
            }
        });
    }
}

export default ListingService;