import React, { useState, useEffect } from "react";
import {
	Box,
	Grid,
	Divider,
	TextField,
	Button,
	Skeleton,
	Typography,
	Avatar,
	Checkbox,
	IconButton,
	Badge,
	Autocomplete,
} from "@mui/material";
import * as Icon from '@mui/icons-material';
import ProfileService from "../../../services/profile";
import styled from '@mui/system/styled';
import GreyLabel from "../../../commonComponents/formElement/GreyLabel";
import UploadImagePopup from "../../../commonComponents/FileManager/UploadImagePopup";
import { decodeUnicodeString, empty, extractDataFromArray, toastMessage } from "../../../utils/common";
import { UserAuth } from "../../../store/context/userContext";
import AccountVerification from "../../../commonComponents/AccountVerification";
import UserService from "../../../services/user";
import { Link } from "react-router-dom";
import { CommonContext } from "../../../store/context/commonContext";
import LoaderButton from "../../../commonComponents/LoaderButton";
import CreateChip from "../../../commonComponents/CreateChip"
import errorAndHelperText from "../../../commonComponents/CommonConstants/errorAndHelperText.json"
import CommonService from "../../../services/misc";
import { ACCOUNT_VERIFICATION_TEXTS } from "../../../commonComponents/CommonConstants/commonKeys";


const PersonalInformation = () => {
	const { setUser } = UserAuth()
	const [formData, setFormData] = useState({
		fname: "",
		lname: "",
		emergency_contact: "",
		email: "",
		is_like_to_rent_land: "N",
		bio: "",
		address_line1: "",
		address_line2: "",
		city: "",
		state: "",
		country: "",
		zipcode: "",
		profile_pic: "",
		countryCode: '',
		stateCode: ''
	});

	const [errors, setErrors] = useState({
		fname: "",
		lname: "",
		phone: '',
		emergency_contact: '',
		bio: '',
		country: '',
		zipcode: '',
		address_line1: '',
		address_line2: '',
		city: '',
		state: '',
		countryCode: '',
		stateCode: ''
	});

	const [loading, setLoading] = useState(true)
	const [countryStateData, setCountryStatesData] = useState(null)

	// State to manage country and state selections
	const [selectedCountry, setSelectedCountry] = useState(null);
	const [selectedState, setSelectedState] = useState(null);
	console.log(selectedCountry?.code2, selectedState?.code);
	const [isEdited, setIsEdited] = useState(false)


	// Prepare countries and states for Autocomplete
	const countries = countryStateData && Object.keys(countryStateData).map(code => ({
		code2: code,
		name: decodeUnicodeString(countryStateData[code].name),
		states: countryStateData[code].states
	})).sort((a, b) => a.name.localeCompare(b.name));

	const getStatesForCountry = (countryCode) => {
		// console.log(countryStateData[countryCode]?.states);
		return countryStateData[countryCode]?.states || [];
	};
	const validateForm = () => {
		let valid = true;
		const newErrors = { ...errors };

		const phoneNumberRegex = /^((\+[1-9]{1,4}[ \-]*)|(\([0-9]{2,3}\)[ \-]*)|([0-9]{2,4})[ \-]*)*?[0-9]{7,16}?[ \-]*[0-9]{0,9}?$/;

		// Validate first name
		if (formData.fname.trim() === "") {
			newErrors.fname = "First name is required";
			valid = false;
		} else if (formData.fname.trim().length > 50) {
			newErrors.fname = "First name should not exceed 50 characters";
			valid = false;
		} else if (formData.fname.trim().length < 50) {
			newErrors.fname = "";
			valid = true;
		} else {
			newErrors.fname = "";
		}

		// Validate last name
		if (formData.lname.trim() === "") {
			newErrors.lname = "Last name is required";
			valid = false;
		} else if (formData.lname.trim().length > 50) {
			newErrors.lname = "Last name should not exceed 50 characters";
			valid = false;
		} else if (formData.lname.trim().length < 50) {
			newErrors.lname = "";
		} else {
			newErrors.lname = "";
		}

		// ... (perform validation for other fields if needed)
		if (formData.phone.trim() !== "" && !phoneNumberRegex.test(formData.phone.trim())) {
			errors.phone = "Phone number is not valid";
			valid = false
		} else if (formData.phone.trim().length > 16) {
			newErrors.phone = 'Phone number should not exceed 16 characters'
			valid = false
		} else {
			newErrors.phone = ''
		}

		if (formData.emergency_contact.trim() !== "" && !phoneNumberRegex.test(formData.emergency_contact.trim())) {

			errors.emergency_contact = "Emergency contact number is not valid";
			valid = false
		} else if (formData.emergency_contact.trim().length > 16) {
			newErrors.emergency_contact = 'Emergency contact number should not exceed 16 characters'
			valid = false
		} else {
			newErrors.emergency_contact = ''
		}

		if (formData.bio === '') {
			// valid = true
		} else if (formData.bio.trim().length > 5000) {
			newErrors.bio = "Bio should not exceed more than 5000 characters";
			valid = false;
		} else {
			newErrors.bio = "";
		}

		if (formData.address_line1 === '') {
			// valid = true
		} else if (formData.address_line1.trim().length > 500) {
			newErrors.address_line1 = "Address should not exceed more than 500 characters";
			valid = false;
		} else {
			newErrors.address_line1 = "";
		}

		if (formData.address_line2 === '') {
			// valid = true
		} else if (formData.address_line2.trim().length > 500) {
			newErrors.address_line2 = "Address should not exceed more than 500 characters";
			valid = false;
		} else {
			newErrors.address_line2 = "";
		}

		if (formData.city === '') {
			// valid = true
		} else if (formData.city.trim().length > 50) {
			newErrors.city = "City name should not exceed more than 50 characters";
			valid = false;
		} else {
			newErrors.city = "";
		}

		if (formData.state === '') {
			// valid = true
		} else if (formData.state.trim().length > 100) {
			newErrors.state = "State name should not exceed more than 100 characters";
			valid = false;
		} else {
			newErrors.state = "";
		}

		if (formData.zipcode === '') {
			// valid = true
		} else if (formData.zipcode.trim().length > 50) {
			newErrors.zipcode = "Zipcode should not exceed more than 50 characters";
			valid = false;
		} else {
			newErrors.zipcode = "";
		}

		if (formData.country === '') {
			// valid = true
		} else if (formData.country.trim().length > 50) {
			newErrors.country = "Country name should not exceed more than 50 characters";
			valid = false;
		} else {
			newErrors.country = "";
		}

		setErrors(newErrors);
		// console.log("is valid", valid)
		return valid;
	};

	// Modify handleChange to work with Autocomplete
	const handleCountryChange = (event, newValue) => {
		setIsEdited(true)
		setSelectedCountry(newValue);

		// Update form data and reset state
		setFormData(prev => ({
			...prev,
			country: newValue ? newValue.name : '',
			state: '', // Reset state when country changes,
			countryCode: newValue?.code2,
			stateCode: '',
		}));
		setSelectedState(null);

		// Clear country-related errors
		setErrors(prev => ({
			...prev,
			country: '',
			state: ''
		}));
	};

	const handleStateChange = (event, newValue) => {
		console.log(newValue);
		setIsEdited(true)
		setSelectedState(newValue);
		// Update form data
		setFormData(prev => ({
			...prev,
			state: newValue ? decodeUnicodeString(newValue.name) : '',
			stateCode: newValue?.code,
		}));

		// Clear state-related errors
		setErrors(prev => ({
			...prev,
			state: '',

		}));
	};

	const { isMobile } = CommonContext();

	useEffect(() => {
		async function getProfileDetails() {
			await ProfileService.getProfileDetails((data) => {
				console.log(data.country_code, data.state_code);
				setFormData({
					fname: data.fname,
					lname: data.lname,
					phone: data.phone,
					emergency_contact: data.emergency_contact,
					email: data.email,
					is_like_to_rent_land: data.is_like_to_rent_land,
					bio: data.bio,
					address_line1: data.address_line1,
					address_line2: data.address_line2,
					city: data.city,
					state: data.state,
					country: data.country,
					zipcode: data.zipcode,
					profile_pic: data.profile_pic,
					email_verification_status: data.email_verification_status,
					countryCode: data.country_code ? data.country_code : '',
					stateCode: data.state_code ? data.state_code : ''
				});
			});
		}
		getProfileDetails();

		CommonService.getCountryCodes((data) => {
			setCountryStatesData(data)
			setLoading(false)
		})
	}, []);

	useEffect(() => {
		// Set country
		if (formData.countryCode && countryStateData) {
			const countryData = {
				code2: formData.countryCode,
				name: decodeUnicodeString(countryStateData[formData.countryCode]?.name || ''),
				states: countryStateData[formData.countryCode]?.states || []
			};
			setSelectedCountry(countryData);

			// Set state if we have both country and state codes
			if (formData.stateCode && countryStateData[formData.countryCode]?.states) {
				const stateData = countryStateData[formData.countryCode].states.find(
					state => state.code === formData.stateCode
				);
				if (stateData) {
					setSelectedState(stateData);
				}
			}
			return;
		}

		// If no country is selected, get current location
		if (countries && !isEdited) {
			UserService.getCurrentLocation((data) => {
				if (data.countryCode && countryStateData[data.countryCode]) {
					const countryData = {
						code2: data.countryCode,
						name: decodeUnicodeString(countryStateData[data.countryCode].name),
						states: countryStateData[data.countryCode].states
					};
					setSelectedCountry(countryData);
					// Update form data and reset state
					setFormData(prev => ({
						...prev,
						country: decodeUnicodeString(countryStateData[data.countryCode].name),
						state: '', // Reset state when country changes,
						countryCode: data.countryCode,
						stateCode: '',
					}));
				}
			});
		}
	}, [countryStateData, formData.countryCode, formData.stateCode]);

	const Asterisk = styled('span')({
		color: '#E94A4A',
	});



	const handleCheck = (event) => {
		const { name } = event.target;
		const fieldValue = formData[name] === 'Y' ? 'N' : 'Y';

		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: fieldValue,
		}));
	};



	const onImageSelect = (data) => {
		if (extractDataFromArray(data, [0, 'file_type'], '') !== 'IMAGE') {
			// toast.error("This File can't be uploaded as Profile picture", {
			// 	theme: 'colored'
			// })
			toastMessage(errorAndHelperText.profile_picture_upload.error);
			return
		}
		let url = extractDataFromArray(data, [0, 'url'], '')
		if (!empty(url)) {
			setFormData({ ...formData, profile_pic: url })
			handleSubmit(url)
		}
	}


	const handleSubmit = async (profile_pic_url) => {

		// Validate the form before submitting
		if (!validateForm()) {
			// Form is not valid, do not submit
			return;
		}

		let { fname, lname, phone, emergency_contact, email, is_like_to_rent_land, bio, address_line1, address_line2, city, state, country, zipcode, profile_pic, stateCode, countryCode } = formData;

		if (!empty(profile_pic_url) && typeof (profile_pic_url) === 'string') {
			profile_pic = profile_pic_url
		}

		// If there is data in the profile, update the existing profile
		ProfileService.updateProfile(fname, lname, phone, emergency_contact, email, is_like_to_rent_land, bio, address_line1, address_line2, city, state, country, zipcode, profile_pic, stateCode ? stateCode : '', countryCode ? countryCode : '', (data) => {
			setUser(data)
			// toast.success("Profile updated successfully", {
			// 	theme: "colored",
			// });
			toastMessage(errorAndHelperText.profile_update.success);

		})

	};

	const handleChange = (e) => {
		const { name, value } = e.target;

		setFormData((prevFormData) => ({
			...prevFormData,
			[name]: value,
		}));
		setErrors((prevErrors) => ({
			...prevErrors,
			[name]: ''
		}));
	};

	const [status, setStatus] = useState({})
	const [emailLoadingButton, setEmailLoadingButton] = useState(false)
	const [showEmailVerification, setShowEmailVerification] = useState(false)
	const [verifyEmailLoadingButton, setVerifyEmailLoadingButton] = useState(false)
	const [isEmailVerified, setIsEmailVerified] = useState(false)
	const [otp, setOtp] = useState("")
	const [otpError, setOtpError] = useState("")

	const sendVerification = () => {
		setVerifyEmailLoadingButton(true)

		UserService.sendVerificationCode((data) => {
			setShowEmailVerification(true)
			setVerifyEmailLoadingButton(false)
		}, (error) => {
			setShowEmailVerification(false)
			setVerifyEmailLoadingButton(false)
		})
	}

	const [openImagePopup, setOpenImagePopup] = useState(false)


	const verifyEmail = () => {
		if (empty(otp)) {
			setOtpError("OTP can not be empty")
		} else {
			setEmailLoadingButton(true)
			UserService.verifyEmail(otp, formData.email, () => {
				setIsEmailVerified(true)
				setShowEmailVerification(false)
				setStatus({ ...status, email_verified: true })
				// emailCompletedCallback()
				// toast.success("Email verified successfully", { theme: 'colored' })
				toastMessage(errorAndHelperText.verify_email.success);

				setEmailLoadingButton(false)
			},
				(errMsg) => {
					// Handle error here, e.g., show an error message
					console.error("Error during email verification:", errMsg);
					// toast.error(errMsg, {
					// 	theme: "colored",
					// });
					toastMessage({
						message: errMsg,
						type: 'error',
					});
					setOtpError(errMsg);
					setEmailLoadingButton(false)
				}
			)
		}
	}

	const greyLabelStyle = {
		mb: 0.5, // Adjust the margin-bottom value as needed
	};

	return (<>
		<Box sx={{ border: '1px solid #E5E7EB', borderRadius: theme => `${theme.card.borderRadius} ` }}>
			<Typography variant="h5" sx={{ p: { xs: 2, sm: 2, md: 3, lg: 3 }, fontWeight: 700 }}>Personal Information</Typography>
			<Divider />
			<Grid container maxWidth="lg" spacing={isMobile ? 2 : 3} sx={{ p: { xs: 2, md: 3, lg: 3 }, }} >
				<Grid item xs={12} sm={12} md={12}>
					<AccountVerification
						verification_title={ACCOUNT_VERIFICATION_TEXTS.guest_common}
						sx={{ width: '100%', mb: 3 }}
						emailCompletedCallback={() => { setFormData({ ...formData, email_verification_status: 'V' }) }}
						emailVerified={status.email_verified === true} />
					<GreyLabel label="Profile Photo" sx={greyLabelStyle} />
					<Box sx={{ display: "flex", alignItems: "center", mb: 2, gap: 2 }}>

						<Badge
							overlap="circular"
							anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
							badgeContent={
								<Box
									sx={{
										width: 28,
										height: 28,
										background: "#333",
										borderRadius: 14,
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
										color: '#fff',
										border: '1px solid #fff',
										cursor: "pointer"
									}}
									onClick={() => { setOpenImagePopup(true) }}>
									<Icon.PhotoCameraOutlined sx={{ fontSize: 18 }} />
								</Box>
							}
						>
							<Avatar
								src={formData.profile_pic}
								alt="Profile Image"
								sx={{ height: "82px", width: "82px" }}
							/>
						</Badge>
						<UploadImagePopup label="Add " buttonText="Add Photo" selectedCallback={onImageSelect} openPopup={openImagePopup} hideButton={true} closePopup={() => { setOpenImagePopup(false) }} />
					</Box>
				</Grid>
				<Grid item xs={12} sm={12} md={6}>
					<GreyLabel label="First Name" required sx={greyLabelStyle}></GreyLabel>
					<TextField
						name="fname"
						value={formData.fname}
						onChange={handleChange}
						fullWidth
						error={Boolean(errors.fname)}
						helperText={errors.fname}
						inputProps={{ maxLength: 51 }}
						required
						sx={{
							"& .MuiInputBase-input": {
								p: '9.5px 14px'
							},
							// mb: 3,
							// pr: 3
						}}
					/>
				</Grid>
				<Grid item xs={12} sm={12} md={6}>
					<GreyLabel label="Last Name" required sx={greyLabelStyle}></GreyLabel>
					<TextField
						required
						name="lname"
						value={formData.lname}
						onChange={handleChange}
						error={Boolean(errors.lname)}
						helperText={errors.lname}
						fullWidth
						inputProps={{ maxLength: 51 }}
						sx={{
							"& .MuiInputBase-input": {
								p: '9.5px 14px'
							},
							// mb: 3,

						}}
					/>
				</Grid>
				{/* </Box> */}
				<Grid item xs={12} sm={12} md={6}>
					<GreyLabel label="Phone Number" sx={greyLabelStyle}></GreyLabel>
					<TextField
						type="number"
						name="phone"
						value={formData.phone}
						onChange={handleChange}
						error={errors.phone ? true : false}
						helperText={errors.phone ? errors.phone : ""}
						// inputProps={{ maxLength: 20 }}
						onInput={(e) => {
							e.target.value = e.target.value.toString().slice(0, 17);
						}}
						fullWidth
						sx={{
							// mb: 3,
							// pr: 3,
							"& .MuiInputBase-input": {
								p: '9.5px 14px'
							},
							"& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
								display: "none",
							},
							"& input[type=number]": {
								MozAppearance: "textfield",
							},
						}}
					/>
				</Grid>
				<Grid item xs={12} sm={12} md={6}>
					<GreyLabel label="Emergency Contact Number" sx={greyLabelStyle}></GreyLabel>
					<TextField
						type="number"
						name="emergency_contact"
						value={formData.emergency_contact}
						onChange={handleChange}
						fullWidth
						error={errors.emergency_contact ? true : false}
						helperText={errors.emergency_contact ? errors.emergency_contact : ""}
						// inputProps={{ maxLength: 20 }}
						onInput={(e) => {
							e.target.value = e.target.value.toString().slice(0, 17);
						}}
						sx={{
							"& .MuiInputBase-input": {
								p: '9.5px 14px'
							},
							"& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
								display: "none",
							},
							"& input[type=number]": {
								MozAppearance: "textfield",
							},
							// mb: 3
						}}
					/>
				</Grid>
				<Grid item xs={12} sm={12} md={12} mb={3} >
					<GreyLabel label="Email" sx={greyLabelStyle}></GreyLabel>
					<TextField
						name="email"
						value={formData.email}
						onChange={handleChange}
						fullWidth
						disabled
						sx={{
							"& .MuiInputBase-input": {
								p: '9.5px 14px'
							},
						}}
						InputProps={{
							readOnly: true,
						}}
					/>
					{loading ? <><Skeleton variant="text" sx={{ width: '200px' }} ></Skeleton></> :
						(formData.email_verification_status === 'V' || isEmailVerified) ? (<CreateChip chipType={'verified'} />) : (<>
							<Box sx={{ display: "flex", justifyContent: "space-between", flexDirection: { xs: 'column', sm: 'row', md: 'row' } }} >
								<Typography sx={{ fontSize: "13px", color: "#E94A4A" }}>
									You haven't verified your email address yet
								</Typography>
								<Link
									onClick={(e) => {
										e.preventDefault()
										sendVerification()
									}}
									style={{
										fontSize: "13px",
										textDecoration: 'underline',
										color: "#1B1B1B",
									}}
								>
									Verify Email
								</Link>
							</Box>
							{
								verifyEmailLoadingButton ? <Skeleton sx={{ width: "100%", height: 200 }}></Skeleton> :
									<Box>
										{
											showEmailVerification &&
											<Box sx={{ background: '#fff', borderRadius: (theme) => `${theme.card.borderRadius} `, p: 3, mt: 3 }}>
												<IconButton sx={{ float: 'right', p: 0 }} onClick={() => { setShowEmailVerification(false) }}><Icon.Close /></IconButton>
												<Typography sx={{ mb: 2 }}>A verification code has been sent to <Typography variant="span" sx={{ color: 'var(--grey900)' }}>{formData.email}</Typography></Typography>
												<Typography sx={{ mb: 1 }}>Please check your inbox and enter the verification code below to verify your email address.</Typography>
												<Box sx={{ display: 'flex', gap: 1, mb: 1 }}>
													<TextField
														variant="outlined"
														value={otp}
														onInput={(e) => {
															setOtp(e.target.value);
															setOtpError("");
														}}
														onKeyDown={(e) => {
															if (e.key === "Enter") {
																verifyEmail();
															}
														}}
														placeholder="Verification Code"
														sx={{
															width: '20ch',
															'& .MuiOutlinedInput-root': {
																height: '42px'
															}
														}}
														autoComplete="new-password"
														error={otpError !== ""}
														helperText={otpError}
													/>
													{/* <Button variant="contained" sx={{ maxHeight: '42px' }} onClick={verifyEmail}>Verify</Button> */}
													<LoaderButton
														variantType='contained'
														onClick={() => { verifyEmail() }}
														isLoading={emailLoadingButton}
														buttonText="Verify"
														isFullWidth={false}
														maxHeight={42}
													/>

												</Box>
												<Button variant="text" color="secondary" sx={{ textDecoration: 'underline', p: 0, fontSize: '12px', minWidth: 'unset', '&:hover': { backgroundColor: 'unset' } }} onClick={sendVerification} >Resend</Button>
											</Box>
										}
									</Box>
							}
						</>)
					}

				</Grid>
				<Grid item xs={12} sm={12} md={12} sx={{ mb: 3 }}>
					<Typography
						sx={{ fontSize: "16px", color: "#85878A", mb: 0.5 }}
					>
						Would you like to list your own land for rent?
					</Typography>


					<Box sx={{ display: "flex", gap: "1rem", }}>

						<Box
							className="activity_type"
						>

							<Box sx={{
								width: "70px",
								display: "flex",
								alignItems: "center",
								border: "1px solid #C5C5C7", borderRadius: "6px"
							}} >
								<Checkbox
									checked={formData.is_like_to_rent_land === "Y"}
									onChange={handleCheck}
									name="is_like_to_rent_land" />
								<Typography sx={{ fontSize: "14px", color: "#96969A", ml: -1 }}>
									Yes
								</Typography>
							</Box>

						</Box>
						<Box

						>

							<Box sx={{
								width: "70px",
								display: "flex",
								alignItems: "center",
								border: "1px solid #C5C5C7", borderRadius: "6px"
							}} >
								<Checkbox
									checked={formData.is_like_to_rent_land === "N"}
									onChange={handleCheck}
									name="is_like_to_rent_land" />
								<Typography sx={{ fontSize: "14px", color: "#96969A", ml: -1 }}>
									No
								</Typography>
							</Box>

						</Box>


					</Box>
				</Grid>
				<Grid item xs={12} sm={12} md={12} sx={{ mb: 2 }}>
					<GreyLabel label="Bio" sx={greyLabelStyle}></GreyLabel>
					<TextField
						multiline
						name="bio"
						value={formData.bio}
						onChange={handleChange}
						error={Boolean(errors.bio)}
						helperText={errors.bio}
						inputProps={{ maxLength: 5001 }}
						placeholder="Add more about you"
						rows={3}
						fullWidth
					/>
				</Grid>
				<Grid item xs={12} sm={12} md={12}>
					<Typography variant="h6" sx={{ fontWeight: 700 }}>Address</Typography>
				</Grid>
				<Grid item xs={12} sm={12} md={12}>
					<GreyLabel label="Address line 1" sx={greyLabelStyle}></GreyLabel>
					<TextField
						sx={{
							"& .MuiInputBase-input": {
								p: '9.5px 14px'
							},
							mb: 3
						}}
						name="address_line1"
						value={formData.address_line1}
						onChange={handleChange}
						error={Boolean(errors.address_line1)}
						helperText={errors.address_line1}
						fullWidth
						inputProps={{ maxLength: 501 }}
					/>
					<Typography variant="caption" sx={{ color: 'var(--grey900)' }}>
						Street address, P.O. box, company name, c/o
					</Typography>
				</Grid>
				<Grid item xs={12} sm={12} md={12}>
					<GreyLabel label="Address line 2" sx={greyLabelStyle}></GreyLabel>
					<TextField
						name="address_line2"
						value={formData.address_line2}
						onChange={handleChange}
						fullWidth
						inputProps={{ maxLength: 501 }}
						error={Boolean(errors.address_line2)}
						helperText={errors.address_line2}
						sx={{
							"& .MuiInputBase-input": {
								p: '9.5px 14px'
							},
							// mb: 1
						}}
					/>
					<Typography variant="caption" sx={{ color: 'var(--grey900)' }}>
						Appartment, suite, unit, building, floor, etc.
					</Typography>
				</Grid>
				<Grid item xs={12} sm={12} md={6}>
					<GreyLabel label="City" sx={greyLabelStyle}></GreyLabel>
					<TextField
						fullWidth
						name="city"
						value={formData.city}
						onChange={handleChange}
						inputProps={{ maxLength: 51 }}
						error={Boolean(errors.city)}
						helperText={errors.city}
						sx={{
							"& .MuiInputBase-input": {
								p: '9.5px 14px'
							},
							// mb: 3,
							// pr: 3
						}}
					/>
				</Grid>
				{/* State Dropdown - Only show if a country is selected */}
				<Grid item xs={12} sm={12} md={6}>
					<GreyLabel label="State / Province / Country Region" sx={greyLabelStyle}></GreyLabel>
					<Autocomplete
						options={selectedCountry ? getStatesForCountry(selectedCountry.code2) : []}
						getOptionLabel={(option) => decodeUnicodeString(option.name)}
						value={selectedState}
						size="small"
						onChange={handleStateChange}
						disabled={!selectedCountry}
						renderInput={(params) => (
							<TextField
								{...params}
								sx={{
									"& .MuiInputBase-root": { height: '42px' }
								}}
								error={Boolean(errors.state)}
								helperText={errors.state}
								placeholder={selectedCountry ? "Select State" : "Select a Country First"}
							/>
						)}
					/>
				</Grid>
				<Grid item xs={12} sm={12} md={6}>
					<GreyLabel label='Zipcode' sx={greyLabelStyle} />
					<TextField
						name="zipcode"
						type="number"
						value={formData.zipcode}
						onChange={handleChange}
						fullWidth
						// inputProps={{ maxLength: 50 }}
						error={Boolean(errors.zipcode)}
						helperText={errors.zipcode}
						onInput={(e) => {
							e.target.value = e.target.value.toString().slice(0, 51);
						}}
						sx={{
							"& .MuiInputBase-input": {
								p: '9.5px 14px'
							},
							"& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
								display: "none",
							},
							"& input[type=number]": {
								MozAppearance: "textfield",
							},
							// mb: 3
						}}
					/>
				</Grid>
				{/* Country Dropdown */}
				<Grid item xs={12} sm={12} md={6}>
					<GreyLabel label="Country" sx={greyLabelStyle}></GreyLabel>
					<Autocomplete
						options={countries}
						getOptionLabel={(option) => option.name}
						value={selectedCountry}
						disabled={loading}
						onChange={handleCountryChange}
						size="small"
						renderInput={(params) => (
							<TextField
								{...params}
								sx={{
									"& .MuiInputBase-root": { height: '42px' }
								}}
								error={Boolean(errors.country)}
								helperText={errors.country}
								placeholder="Select Country"
							/>
						)}
					/>
				</Grid>
				<Grid item xs={12} sm={12} md={12}>
					<Button
						type="button"
						variant="contained"
						color="primary"
						onClick={handleSubmit}
						sx={{ float: 'right', mt: 1 }}
					>
						Save Changes
					</Button>
				</Grid>
			</Grid >
		</Box >



	</>
	);
};
export default PersonalInformation;
