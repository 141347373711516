import { AppBar, Box, Button, Chip, Dialog, Divider, Grid, IconButton, MenuItem, Select, Skeleton, TextField, Toolbar, Typography } from "@mui/material";
import * as Icon from '@mui/icons-material';
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { changeTimeFormat, empty, extractDataFromArray, generatePhrase, toastMessage } from "../../utils/common";
import dayjs from "dayjs";
import { CommonContext } from "../../store/context/commonContext";
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import GreyLabel from "../../commonComponents/formElement/GreyLabel";
import ExperienceService from "../../services/experience";
import OptionsAvailableDialog from "../../commonComponents/Experience/ExpBookingCard/optionsAvailableDialog";
import QuantityInput from "../../commonComponents/formElement/QuantityInput";
import AccountVerification from "../../commonComponents/AccountVerification";
import UserService from "../../services/user";
import PaymentService from "../../services/payments";
import MediaCard from "../../commonComponents/previewPageComponents/mediaCard";
import { Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import LoaderButton from "../../commonComponents/LoaderButton";
import { ACCOUNT_VERIFICATION_TEXTS, FROM_TIME, TO_TIME } from "../../commonComponents/CommonConstants/commonKeys";
import ExpEnquiryPopUp from "../../commonComponents/ExpEnquiryPopUp";
import CreateChip from "../../commonComponents/CreateChip";
import BookingCancellationPopup from "./paymentCancelPopup";
import BookingService from "../../services/bookings";
import errorAndHelperText from "../../commonComponents/CommonConstants/errorAndHelperText.json"

const ExpCheckout = () => {
	const location = useLocation();
	const navigate = useNavigate();
	const queryParams = new URLSearchParams(location.search);
	const { isMobile, isTablet, isNineHundred, OpenExpEnquiryPopup, setOpenExpEnquiryPopup, } = CommonContext();

	const date = dayjs(queryParams.get('dt'));
	const [selectedDate, setDateSelectText] = useState(date);
	const [dialogdateSelectText, setdialogDateSelectText] = useState('');
	const from_time = queryParams.get(FROM_TIME);
	const to_time = queryParams.get(TO_TIME);

	const [slotSelectText, setSlotSelectText] = useState(`${from_time} - ${to_time}`);

	const totalguests = queryParams.get('g');
	const expereinceSeq = Number(queryParams.get('expSeq'));
	const [slot_id, setSelectedSLotID] = useState(queryParams.get('sid'));

	const [guest, setGuest] = useState(Number(totalguests));
	const [experienceData, setExperienceData] = useState(null);
	const [optionalAddOns, setOptionalAddOns] = useState(null)

	const [showAddonCheck, setShowAddonCheck] = useState(false)
	const [typeIdObj, setTypeIdObj] = useState(null);
	const [bookedData, setBookedData] = useState([])

	const [payPopUpTimeout, setPayPopUpTimeout] = useState({ timeout: 0.2, timestamp: Date.now() });
	const [isLoadingButton, setIsLoadingButton] = useState(false)
	// Open ID Verification
	const [openIdPopup, setOpenIdPopup] = useState(false);
	const [loaderButton, setLoaderButton] = useState(false);
	const [accountActive, setAccountActive] = useState(false)
	const [verificationToken, setVerificationToken] = useState(false);
	const [options, setOptions] = useState({})
	const [openPayment, setOpenPayment] = useState(false);
	const [shouldReset, setShouldReset] = useState(false)
	const conservation_donation = 0


	const checkListerAccountStatus = () => {
		if (accountActive) {
			// setOpen(true)
			setLoaderButton(false);
		} else {
			UserService.getAccountCompletionStatus((data) => {
				let statusCompleted = 0;
				Object.keys(data).forEach(key => {
					if (key === 'id_verified' && data[key] !== "I") {
						statusCompleted++;
					} else if (data[key]) {
						statusCompleted++;
					}
				})
				if (Object.keys(data).length === statusCompleted) {
					// setOpen(true)
					setLoaderButton(false);
					setAccountActive(true)
				} else {
					setOpenIdPopup(true)
				}
			})
		}
	}

	const initPayment = () => {
		setLoaderButton(true)
		UserService.getAccountCompletionStatus((data) => {
			PaymentService.isUserVerified(extractDataFromArray(experienceData, ['lister_seq']), (data) => {
				// console.log(data.v_token);
				setVerificationToken(data.v_token)
			})
			console.log(data);
			if (data.id_verified === 'I' || data.email_verified === false) {
				setVerificationToken(verificationToken)
				setOpenIdPopup(true)
			} else if (data.id_verified === 'V') {
				createOrder()

			} else if (data.id_verified === 'P' || data.id_verified === '1') {
				// toast.error("You cannot proceed to booking because ID Verification is still pending.", {
				// 	theme: 'colored'
				// })
				toastMessage(errorAndHelperText.account_completion_status.pending)

				return
			} else {
				// toast.error("Something went wrong. Please try after sometime", {
				// 	theme: 'colored'
				// })
				toastMessage(errorAndHelperText.something_went_wrong)
				setLoaderButton(true)
				return
			}
		})
	}

	const createOrder = () => {
		let checkingInDate = dayjs(selectedDate).format('YYYY-MM-DD');
		let checkingOutDate = selectedDate.add(checkinDateAddCount < 2 ? 0 : checkinDateAddCount - 1, 'day').format('YYYY-MM-DD')

		// Creating a deep copy of the array
		const deepCopyAddons = JSON.parse(JSON.stringify(optionalAddOns));

		// Filtering out entities with selected_quantity of 0
		const filteredAddons = deepCopyAddons.filter(addon => addon.selected_quantity !== 0);

		PaymentService.createOrder(extractDataFromArray(experienceData, ['lister_seq']), extractDataFromArray(experienceData, ['experience_seq']), guest, checkingInDate, checkingOutDate, totalGuestPrice, bookingFee.toFixed(2), 0, 'new', extractDataFromArray(experienceData, ['experience_seq']), setLoaderButton, extractDataFromArray(experienceData, ['listing_version'], 0), extractDataFromArray(experienceData, ['package_version'], 0), '', 'experience', selectedSlot.id, filteredAddons, (data) => {
			if (empty(data)) {
				// toast.error("Some error occured!", {
				// 	theme: 'colored'
				// })
				toastMessage(errorAndHelperText.something_went_wrong)
				return
			}
			setOptions({ ...options, clientSecret: data.client_secret, payment_id: data.payment_id })
			setOpenPayment(true)
			setLoaderButton(false)
			// Set payPopUpTimeout with a new timestamp to trigger the useEffect
			// setPayPopUpTimeout({
			// 	timeout: extractDataFromArray(data, ['lock_expiry'], 5),
			// 	timestamp: Date.now() // Adding a timestamp to force a state change
			// });
			setPayPopUpTimeout({
				timeout: extractDataFromArray(data, ['lock_expiry'], 5),
				timestamp: Date.now() // Adding a timestamp to force a state change
			});
		})
	}

	// unlock Booking on popup close intentionally
	const unlockBooking = (callback) => {
		if (options.payment_id) {
			setIsLoadingButton(true)
			BookingService.unlockBooking(options.payment_id, (data) => {
				if (data === true) {
					callback()
					setIsLoadingButton(false)
				}
			})
		}
	}

	// Open Dialog 

	const [open, setOpen] = useState(false);
	const [slots, setSlots] = useState([]);
	const [notAvailableDates, setNotAvailableDates] = useState(extractDataFromArray(experienceData, ['experience_data', `${typeIdObj?.availability}`, 'not_available_dates'], []))
	const [errorText, setErrorText] = useState('');
	const [dateError, setDateError] = useState(false);
	const today = dayjs();
	const [availabilityRange, setAvailabilityRange] = useState(null)
	useEffect(() => { // Added this to avoid error when clicked on choose button in option available pop up

		if (experienceData !== null) {
			setAvailabilityRange([extractDataFromArray(experienceData, ['experience_data', `${typeIdObj.availability}`, 'from_date'], null), extractDataFromArray(experienceData, ['experience_data', `${typeIdObj.availability}`, 'to_date'], null)])
		}

	}, [experienceData])

	let defaultBookingSelection = [date, date.add(2, 'day')];
	const [dateRange, setDateRange] = useState(defaultBookingSelection);
	const [packageDuration, setPackageDuration] = useState({
		unit: 'hours',
		value: 1
	})
	// console.log(packageDuration);
	// console.log(dayjs(dateRange[0]).format('YYYY-MM-DD'),dayjs(dateRange[1]).format('YYYY-MM-DD'));

	const [cancellationPolicyData, setCancellationPolicyData] = useState({})
	// console.log(cancellationPolicyData);

	function getSelectedPoliciesData(cancellationPolicy) {
		// Get the policies object
		const policies = cancellationPolicy?.cancellation_policy?.policies;

		if (!policies) { return }

		// Filter the selected policies based on the 'isSelected' key
		const selectedPolicies = Object.values(policies).filter(policy => policy.isSelected);

		// Return the selected policies data
		return selectedPolicies.map(policy => ({
			name: policy.name,
			caption: policy.caption,
			rules: policy.rules,
			messageToguest: policy.messageToguest
		}));
	}


	const minValue = 1;
	const [maxValue, setMaxValue] = useState(10)
	const [image, setImage] = useState('/landowner/assets/images/property_detail_page/blank_property_image.png')

	const disableCustomDates = (date) => {
		return (notAvailableDates.length > 0 && notAvailableDates.some(d => date.isSame(d, 'day')));
	};

	const [pricingData, setPricingData] = useState(extractDataFromArray(experienceData, ['experience_data', `${typeIdObj?.pricing}`, 'slot_list'], []))
	// console.log(pricingData);

	const setDialogDates = (date, setDialogDateOpen) => {
		if (dayjs(date[1]).format('YYYY-MM-DD') === dayjs(dateRange[1]).format('YYYY-MM-DD')) {
			date[1] = null;
			setDialogDateOpen(true);
			setDateRange(date);
		} else {
			setDateRange(date);
		}
	}

	const handleClose = (automatic) => {
		if (automatic) {
			setShouldReset(true)
			setOpenPayment(false);
			// Now go back to remove the latest state (LIFO approach)
			window.history.back();
			setOptions({})
		}

		// if popup is getting closed automatically on session expire duration then don't call unlock Booking.
		if (!automatic) {
			unlockBooking(() => {
				setOpenPayment(false);
				setPayPopUpTimeout((prevData) => {
					return {
						timeout: prevData.timeout,
						timestamp: Date.now() // Adding a timestamp to force a state change
					};
				});
				setShouldReset(true)
				setOptions({})
				// Now go back to remove the latest state (LIFO approach)
				window.history.back();
			})
		}
	}

	const handleCloseOptionDialog = () => {
		setOpen(false);
	};
	const handleExpEnquiryPopUpClose = () => {
		setOpenExpEnquiryPopup(false);
	};


	const handleChange = (event) => {
		setGuest(event.target.value);
	};

	function generateTypeIdObject(data) {
		const result = {};
		for (const key in data) {
			if (data.hasOwnProperty(key)) {
				const item = data[key];
				const type = item.type;
				const id = item.id;
				if (type && id) {
					result[type] = id;
				}
			}
		}
		return result;
	}
	// console.log(slots);

	// Utility function to find slot data
	function getSlotData(slots, slotId, date) {
		// console.log(slots, slotId, date);
		return slots.find(slot => slot.id === slotId && slot.date === date) || null;
	}

	const [selectedSlot, setSelectedSlot] = useState({
		date: dayjs(date).format('YYYY-MM-DD'),
		id: slot_id
	})

	function mergeBookedDataInAddons(bookedData, detailData) {
		// console.log(bookedData);

		if (!detailData) {
			return null;
		}
		if (bookedData.length <= 0) {
			return detailData;
		}

		const merged = detailData.map(detail => {
			const booked = bookedData.find(b => b.id === detail.id);
			return {
				...detail,
				...(booked ? {
					total_booked_qty: booked.total_booked_qty,
					total_qty: booked.total_qty
				} : {})
			};
		});

		return merged;
	}


	const handleCheckOut = (slot) => {
		setSelectedSlot(slot)
		setSelectedSLotID(slot.id)
		setDateSelectText(dayjs(slot.date))
		setSlotSelectText(`${slot[FROM_TIME]} - ${slot[TO_TIME]}`)
		setOpen(false)
	};

	useEffect(() => {
		optionalAddOns && optionalAddOns.length > 0 && ExperienceService.getBookedAddonCount(expereinceSeq, slot_id, dayjs(selectedDate).format('YYYY-MM-DD'),
			(data) => {
				// console.log(mergeBookedDataInAddons(data, optionalAddOns));
				setOptionalAddOns(mergeBookedDataInAddons(data, optionalAddOns))

			})
	}, [expereinceSeq, slot_id, selectedDate, typeIdObj, experienceData, slots])

	useEffect(() => {
		// console.log("Call API");
		ExperienceService.getExpBookedData(expereinceSeq, (data) => {
			setBookedData(data)
		})
		ExperienceService.getExperiencePackageData(expereinceSeq, (data) => {
			// console.log('ExpereinceCompleteData', data);
			setExperienceData(data);
			setTypeIdObj(generateTypeIdObject(data.experience_data))
		}, (err) => {
			console.error(err);
			// toast.error(`${err}. You Will be redirected to Search page...`, {
			// 	theme: "colored",
			// })
			toastMessage({
				message: `${err}. You Will be redirected to Search page...`,
				type: 'error'
			})
			setTimeout(() => {
				navigate('/search');
			}, 3000); // 3000 ms = 3 seconds			
		});
	}, [expereinceSeq]);

	useEffect(() => {
		setMaxValue(extractDataFromArray(experienceData, ['experience_data', `${typeIdObj?.guests}`, 'max_guests'], 99))
		setNotAvailableDates(extractDataFromArray(experienceData, ['experience_data', `${typeIdObj?.availability}`, 'not_available_dates'], []))
		// console.log('settingpricingdata', extractDataFromArray(experienceData, ['experience_data', `${typeIdObj?.pricing}`, 'slot_list'], []));
		setPricingData(extractDataFromArray(experienceData, ['experience_data', `${typeIdObj?.pricing}`, 'slot_list'], []))
		setOptionalAddOns(extractDataFromArray(experienceData, ['experience_data', `${typeIdObj?.addon}`, 'addon_list'], null)?.map(addon => ({
			...addon,
			selected_quantity: 0 // Initialize selected_quantity for each addon
		})));
		setShowAddonCheck(extractDataFromArray(experienceData, ['experience_data', `${typeIdObj?.addon}`, 'display'], 0) !== 0)
		setPackageDuration(extractDataFromArray(experienceData, ['experience_data', `${typeIdObj?.pricing}`, 'package_duration'], {}))
		setImage(extractDataFromArray(experienceData, ['experience_data', `${typeIdObj?.photos}`, 'photo_list', '0'], ""))
		setCancellationPolicyData(getSelectedPoliciesData(extractDataFromArray(experienceData, ['experience_data', `${typeIdObj?.cancellation_policy}`], {})))
	}, [typeIdObj, experienceData])

	// console.log(selectedSlot?.price);

	useEffect(() => {
		const slotData = getSlotData(slots, slot_id, dayjs(selectedDate).format('YYYY-MM-DD'));
		// console.log(slotData);
		if (slotData !== null) {
			setSelectedSlot(slotData);
		}
	}, [slots, selectedDate, slot_id]);

	const handleAddonQuantityChange = (index, value) => {
		const updatedAddons = [...optionalAddOns];
		updatedAddons[index].selected_quantity = value;
		setOptionalAddOns(updatedAddons);
	};


	const [checkinDateAddCount, setCheckinDateAddCount] = useState(0)
	const [rangeInterval, setRangeInterval] = useState(0)

	useEffect(() => {
		if (packageDuration.unit === 'hours') {
			setCheckinDateAddCount(0)
		} else {
			setCheckinDateAddCount(Number(packageDuration.value))
		}
	}, [packageDuration])

	useEffect(() => {
		if (checkinDateAddCount < 2) {
			setRangeInterval(0)
		} else {
			setRangeInterval(checkinDateAddCount - 1)
		}
	}, [checkinDateAddCount])



	const guestOptions = Array.from({ length: maxValue - minValue + 1 }, (_, i) => i + minValue);

	const dateRangeContainsUnavailable = (dateRange, unavailableDates) => {
		if (dateRange[0] === null || dateRange === null) return false;

		const start = dayjs(dateRange[0]);
		const end = dayjs(dateRange[1]);

		for (let date of unavailableDates) {
			if (dayjs(date).isBetween(start - 1, end + 1)) {
				return true;
			}
		}

		return false;
	};

	const generateSlots = (start, end, pricingData, bookedData) => {
		let slots = [];
		let current = start;
		// console.log(notAvailableDates);
		// Create a lookup map for booked guest data
		const bookedGuestMap = {};
		bookedData.forEach(item => {
			const dateKey = dayjs(item.date).format('YYYY-MM-DD');
			if (!bookedGuestMap[dateKey]) {
				bookedGuestMap[dateKey] = {};
			}
			bookedGuestMap[dateKey][item.slot_id] = item.booked_guest_count;
		});

		while (current.isBefore(end) || current.isSame(end, 'day')) {
			const currentDateString = current.format('YYYY-MM-DD');
			if (!notAvailableDates.includes(currentDateString)) {
				let daySlots = pricingData.map((item) => {
					const dateKey = currentDateString;
					const bookedCount = bookedGuestMap[dateKey] && bookedGuestMap[dateKey][item.id] ? bookedGuestMap[dateKey][item.id] : 0;
					return {
						date: currentDateString,
						[FROM_TIME]: changeTimeFormat(item[FROM_TIME]),
						[TO_TIME]: changeTimeFormat(item[TO_TIME]),
						description: item.description,
						price: item.price,
						id: item.id,
						booked_guest_count: bookedCount,
						available_guest: maxValue - bookedCount,
					};
				});
				slots = [...slots, ...daySlots];
			}
			current = current.add(1, 'day');
		}
		// console.log(slots);
		return slots;
	};


	useEffect(() => {
		// console.log(notAvailableDates, dateRange, pricingData);
		let days = dayjs(dateRange[1]).diff(dateRange[0], 'days') + 1;

		if (dateRange[0] == null || dateRange[1] == null) {
			setdialogDateSelectText('Select Dates');
		} else if (dateRange[0].format('YYYY-MM-DD') == dateRange[1].format('YYYY-MM-DD')) {
			const invalidCheck = dateRange[0].format('MMM DD, YYYY');
			if (invalidCheck.includes('Invalid')) {
				setDateRange([null, null]);
				return;
			}
			setdialogDateSelectText(`${dateRange[0].format('DD MMM')}`);
		} else {
			const invalidCheck = `${dateRange[0].format('MMM DD, YYYY')}${dateRange[1].format('MMM DD, YYYY')}`;
			if (invalidCheck.includes('Invalid')) {
				setDateRange([null, null]);
				return;
			}
			setdialogDateSelectText(`${dateRange[0].format('DD MMM')} - ${dateRange[1].format('DD MMM')}`);
		}

		if (isNaN(days) || (dateRange[0] === null) || (dateRange[1] === null)) {
			// setErrorText("Select valid date range for this package")
			// setDateError(true)
		}
		// console.log(generateSlots(dayjs(dateRange[0]), dayjs(dateRange[1]), pricingData, bookedData));
		const newSlots = generateSlots(dayjs(dateRange[0]), dayjs(dateRange[1]), pricingData, bookedData)
		// console.log(newSlots);
		if (newSlots.length > 0 && newSlots !== undefined) {
			setSlots(newSlots);
		}

	}, [notAvailableDates, dateRange, pricingData, bookedData]);

	const totalGuestPrice = Number(selectedSlot?.price) * Number(guest);

	const addonSum = optionalAddOns ? optionalAddOns.reduce((sum, addon) => sum + (addon.price * addon.selected_quantity), 0) : 0

	const subtotal = totalGuestPrice + Number(conservation_donation) + addonSum;

	const bookingFee = subtotal * 0.05;

	const total = subtotal + bookingFee;

	const [platFormStripe, setPlatFormStripe] = useState(null)
	const [stripe, setStripe] = useState(null)

	useEffect(() => {
		if (!empty(experienceData)) {
			PaymentService.getListerInfo(
				extractDataFromArray(experienceData, ['lister_seq'], 0),
				async (data) => {
					try {
						let load = await loadStripe(extractDataFromArray(data, ['p_key']));
						setStripe(load);
						let pload = await loadStripe(extractDataFromArray(data, ['fw_key']), {
							stripeAccount: extractDataFromArray(data, ['account_id']),
						});
						setPlatFormStripe(pload);
					} catch (error) {
						console.error('Stripe loading error:', error);
					}
				},
				(err) => {
					console.error('PaymentService error:', err);
					// After 3 seconds, navigate to the search page
					setTimeout(() => {
						navigate('/search');
					}, 3000); // 3000 ms = 3 seconds
				}
			);
		}
	}, [experienceData]);

	return (
		<>
			<div>
				{OpenExpEnquiryPopup && (
					<ExpEnquiryPopUp
						open={OpenExpEnquiryPopup}
						setOpenExpEnquiryPopup={setOpenExpEnquiryPopup}
						experienceData={experienceData}
						handleClose={handleExpEnquiryPopUpClose}
					/>
				)}
			</div>
			<Box sx={{ pb: isNineHundred && location.pathname === '/checkout' && 10 }}>
				<Box sx={{ display: 'flex', alignItems: 'center', gap: 1, textAlign: 'center' }}>
					<IconButton onClick={() => { window.history.back() }}><Icon.West /></IconButton>
					<Typography variant='h4' fontWeight={600}>Review your Booking </Typography>
				</Box>
				<Divider sx={{ my: isMobile ? 2 : 3 }} />
				<Box sx={{ display: 'flex', gap: 4, flexDirection: isNineHundred ? 'column' : 'row' }}>
					<Box sx={{ width: isNineHundred ? '100%' : '60%' }}>
						<Box sx={{ display: 'flex', gap: isNineHundred ? 2 : 3, mb: isNineHundred ? 2 : 4, flexWrap: 'wrap' }}>
							<Box>
								<GreyLabel label={'Date'} />
								<TextField
									sx={{
										zIndex: '99',
										borderRadius: '6px',
										transition: 'border-color 0.3s',
										color: 'var(--black)',
										'& :hover': {
											cursor: 'pointer'
										},
										'& .MuiOutlinedInput-root': {
											backgroundColor: 'white',
										},
										"&. MuiInputBase-root": {
											display: 'none',
										},
										"& .MuiFormControl-root": {
											display: 'none'
										}, "& .MuiFormHelperText-root": {
											ml: 0,
											color: 'var(--primary)'
										}, "& .MuiInputBase-input": {
										}, "& .Mui-error": {
											color: '#d32f2f'
										},
										width: '180px'
									}}
									InputProps={{
										endAdornment: <Icon.CalendarToday />,
										style: { color: 'black' },
										inputProps: { style: { color: 'black' } }
									}}
									autoComplete="off"
									size="small"
									value={selectedDate.format('DD MMM YYYY')}
									onClick={(e) => {
										e.preventDefault();
										setOpen(!open);
									}}
									onKeyDown={(e) => e.preventDefault()}
								/>
							</Box>
							<Box>
								<GreyLabel label={'Slot'} />
								<TextField
									sx={{
										zIndex: '99',
										borderRadius: '6px',
										transition: 'border-color 0.3s',
										color: 'var(--black)',
										'& :hover': {
											cursor: 'pointer'
										},
										'& .MuiOutlinedInput-root': {
											backgroundColor: 'white',
										},
										"&. MuiInputBase-root": {
											display: 'none',
										},
										"& .MuiFormControl-root": {
											display: 'none'
										}, "& .MuiFormHelperText-root": {
											ml: 0,
											color: 'var(--primary)'
										}, "& .MuiInputBase-input": {
										}, "& .Mui-error": {
											color: '#d32f2f'
										},
										width: '22ch'
									}}
									InputProps={{
										endAdornment: <AccessTimeIcon />,
										style: { color: 'black' },
										inputProps: { style: { color: 'black' } }
									}}
									autoComplete="off"
									size="small"
									value={slotSelectText}
									onClick={(e) => {
										e.preventDefault();
										setOpen(!open);
									}}
									onKeyDown={(e) => e.preventDefault()}
								/>
							</Box>
						</Box>
						<Box>
							<GreyLabel label={'Guest'} />
							<Select
								sx={{ width: '180px', height: '42px' }}
								value={guest}
								onChange={handleChange}
							>
								{guestOptions.map(value => (
									<MenuItem key={value} value={value}>{value}</MenuItem>
								))}
							</Select>
						</Box>
						{optionalAddOns ?
							<>
								{
									optionalAddOns.length > 0 && showAddonCheck &&
									<>
										<Divider sx={{ my: isNineHundred ? 3 : 4 }} />
										<Box>
											<Typography fontWeight={600} mb={2}>
												Optional Add-ons
											</Typography>
											{optionalAddOns.map((addon, index) => (
												<Box key={index} sx={{
													width: '100%',
													borderRadius: 2,
													border: '1px solid #E5E7EB',
													p: 3,
													mb: 2,
													display: 'flex',
													width: '100%'
												}}>
													<Box sx={{ display: 'flex', flexDirection: 'column', gap: 2, width: '100%' }}>
														<Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 1, width: '100%' }}>
															<Box sx={{ display: 'flex', alignItems: 'flex-start', gap: isNineHundred ? 1 : 3 }}>
																<span className="material-symbols-outlined" style={{ color: 'var(--black)' }}>
																	{addon.icon}
																</span>
																<Typography variant="subtitle1">
																	{addon.title}
																</Typography>
															</Box>
															<Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
																{addon.selected_quantity === 0 && (
																	<>
																		<Typography variant="subtitle1" fontWeight={600}>
																			${addon.price}
																		</Typography>
																		<Button
																			variant="contained"
																			disabled={addon.quantity - (addon.total_booked_qty || 0) <= 0} // Use 0 if total_booked_qty is not present
																			onClick={() => {
																				handleAddonQuantityChange(index, 1);
																			}}
																		>
																			Add
																		</Button>
																	</>
																)}
																{addon.selected_quantity > 0 && (
																	<QuantityInput
																		value={addon.selected_quantity}
																		setValue={(value) => handleAddonQuantityChange(index, value)}
																		name={'quantity'}
																		min={0}
																		max={addon.quantity - (addon.total_booked_qty || 0)} // Use 0 if total_booked_qty is not present
																	/>
																)}
															</Box>

														</Box>
														{addon.description !== '' && <>
															<Divider />
															<Typography variant="subtitle1" color={'var(--textGrey)'}>
																{addon.description}
															</Typography>
														</>}
													</Box>
												</Box>
											))}
										</Box>
									</>
								}
							</>
							:
							<Box sx={{ marginTop: '32px' }}>
								<Skeleton variant="text" width={146} sx={{ mb: '16px' }} />
								<Box sx={{
									width: '100%', padding: '24px',
									borderRadius: 2,
									border: '1px solid #E5E7EB',
									p: 3,
									mb: 2,
								}}>
									<Skeleton variant="rectangular" width={'100%'} height={131} sx={{ fontSize: '16px', borderRadius: '8px' }} />
								</Box>
							</Box>
						}
						{/* <Divider sx={{ my: 4 }} />
					<Box sx={{ mb: 6 }}>
						<Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
							<Typography
								variant="subtitle1"
								sx={{
									color: 'var(--textGrey)'
								}}

							>
								Would you like to include a message for host
							</Typography>
							<Switch checked={messageSwitch} onChange={() => { setMessageSwitch(!messageSwitch) }} />
						</Box>
						{messageSwitch === true && <TextField placeholder="Type Message" fullWidth multiline rows={3} />}
					</Box> */}
						{!isNineHundred && <LoaderButton
							variantType='contained'
							size="large"
							onClick={() => { initPayment() }}
							isLoading={loaderButton}
							buttonText="Confirm and Pay"
							isFullWidth={true}
							// sx={!isTablet ? { mt: 4 } : { my: 'auto' }}
							mt={!isTablet ? 4 : 'auto'}
							mb={!isTablet ? 0 : 'auto'}
						/>}
					</Box>
					{slots.length > 0 ?
						<PricingCard
							image={image}
							title={extractDataFromArray(experienceData, ['experience_data', `${typeIdObj?.general_info}`, 'title'], '')}
							address={
								extractDataFromArray(experienceData, ['experience_data', `${typeIdObj?.location}`, 'address'], '') === 'Don’t see your address? Add GPS coordinates.'
									?
									extractDataFromArray(experienceData, ['experience_data', `${typeIdObj?.location}`, 'city'], '')
									:
									extractDataFromArray(experienceData, ['experience_data', `${typeIdObj?.location}`, 'address'], '')
							}
							duration={extractDataFromArray(experienceData, ['experience_data', `${typeIdObj?.pricing}`, 'package_duration'], '')}
							level={extractDataFromArray(experienceData, ['experience_data', `${typeIdObj?.general_info}`, 'difficulty_level'], '')}
							experience_type={extractDataFromArray(experienceData, ['experience_data', `${typeIdObj?.general_info}`, 'experience_type'], '')}
							optionalAddOns={optionalAddOns}
							guest={guest}
							price={selectedSlot?.price}
							review={Number(extractDataFromArray(experienceData, ['review_count'], 0))}
							rating={Number(extractDataFromArray(experienceData, ['average_rating'], 0))}
							totalGuestPrice={totalGuestPrice}
							addonSum={addonSum}
							bookingFee={bookingFee}
							total={total}
							refundRate={cancellationPolicyData[0].rules[0].refundRate}
							daysBeforeCheckIn={cancellationPolicyData[0].rules[0].daysBeforeCheckIn}
							cancellationPolicyData={cancellationPolicyData}
						/>
						:
						<Box sx={{
							maxWidth: '462px',
							width: '100%',
							border: '1px solid #E5E7EB',
							borderRadius: '16px',
							p: 3,
							height: 'fit-content',
							display: 'flex',
							flexDirection: 'column',
							gap: 4
						}}>
							<Box sx={{ display: 'flex', gap: '16px' }}>
								<Skeleton variant="rectangular" width={128} height={97} sx={{ borderRadius: '8px' }} />
								<Box sx={{ width: '100%' }}>
									<Skeleton variant="text" sx={{ fontSize: '16px' }} />
									<Skeleton variant="text" sx={{ fontSize: '16px' }} />
									<Skeleton variant="text" sx={{ fontSize: '16px' }} />
									<Skeleton variant="text" sx={{ fontSize: '16px' }} />
								</Box>
							</Box>
							<Box sx={{ width: '100%' }}>
								<Skeleton variant="text" sx={{ fontSize: '16px' }} />
								<Skeleton variant="text" sx={{ fontSize: '16px' }} />
								<Skeleton variant="text" sx={{ fontSize: '16px' }} />
								<Skeleton variant="text" sx={{ fontSize: '16px' }} />
							</Box>
							<Box sx={{ width: '100%' }}>
								<Skeleton variant="text" sx={{ fontSize: '16px' }} />
								<Skeleton variant="text" sx={{ fontSize: '16px' }} />
								<Skeleton variant="text" sx={{ fontSize: '16px' }} />
								<Skeleton variant="text" sx={{ fontSize: '16px' }} />
							</Box>
						</Box>
					}
				</Box>
				<OptionsAvailableDialog
					handleClose={handleCloseOptionDialog}
					open={open}
					slots={slots}
					dateSelectText={dialogdateSelectText}
					errorText={errorText}
					dateError={dateError}
					disableCustomDates={disableCustomDates}
					setDates={setDialogDates}
					dateRange={dateRange}
					guest={guest}
					handleChange={handleChange}
					guestOptions={guestOptions}
					handleCheckOut={handleCheckOut}
					rangeInterval={rangeInterval}
					availabilityRange={availabilityRange}
					title={extractDataFromArray(experienceData, ['experience_data', `${typeIdObj?.general_info}`, 'title'], '')}
				/>
				{
					!empty(options) &&
					<Elements stripe={platFormStripe} options={options}>
						{openPayment && <PayPopup
							open={openPayment}
							handleClose={handleClose}
							guests={guest}
							image={image}
							experienceData={experienceData}
							typeIdObj={typeIdObj}
							optionalAddOns={optionalAddOns}
							guest={guest}
							selectedSlot={selectedSlot}
							totalGuestPrice={totalGuestPrice}
							addonSum={addonSum}
							bookingFee={bookingFee}
							total={total}
							cancellationPolicyData={cancellationPolicyData}
							timeout={payPopUpTimeout}
							shouldReset={shouldReset}
							setShouldReset={setShouldReset}
							isLoadingButton={isLoadingButton}
						/>}

					</Elements>
				}
				<Dialog open={openIdPopup} maxWidth='lg' fullWidth onClose={() => { setOpenIdPopup(false); setLoaderButton(false); }} sx={{
					'& .MuiPaper-root': {
						maxWidth: '1167px'
					}
				}} >
					<Box>
						<Box sx={{ px: isMobile ? 2 : 3, display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
							<Typography variant="h6" sx={{ fontWeight: 700 }}>Ready to book? Verify your details now!</Typography>
							<IconButton sx={{ p: isMobile ? 2 : 3 }} onClick={() => { setOpenIdPopup(false); setLoaderButton(false) }}><Icon.Close /></IconButton>
						</Box>
						<Divider />
						<Box sx={{ p: isMobile ? 2 : 3 }}>
							<AccountVerification
								verification_title={ACCOUNT_VERIFICATION_TEXTS.guest_booking}
								completedCallback={() => { setAccountActive(true); setOpenIdPopup(false); setLoaderButton(false); checkListerAccountStatus(); initPayment(); }}
								skipIDVerification={true}
								sx={{ p: isMobile ? 2 : 3 }}
								subtitle={
									<Box>
										<>
											<Typography sx={{
												color: 'var(--grey900)',
												mb: 2
											}}>Please complete the following two steps in any order, and you will be ready to explore and book on FarWide instantly.</Typography>
											<Typography sx={{
												display: 'flex',
												color: 'var(--grey900)',
												mb: 1
											}}>
												{/* <Chip label="1" variant="outlined"
													size='small'
													sx={{
														color: 'var(--grey900)',
														height: '24px',
														width: '24px',
														bgcolor: 'white',
													}} /> */}
												<CreateChip caption={"1"} chipType={"numbers"} /><Box sx={{ pl: 1 }}>Verify your email address</Box>
											</Typography>
											<Typography sx={{
												display: 'flex',
												color: 'var(--grey900)',
												mb: 1
											}}>
												{/* <Chip label="2" variant="outlined"
													size='small'
													sx={{
														color: 'var(--grey900)',
														height: '24px',
														width: '24px',
														bgcolor: 'white',
														pr: 1
													}} /> */}
												<CreateChip caption={"2"} chipType={"numbers"} /><Box sx={{ pl: 1 }}>Provide a government-issued ID for verification</Box>
											</Typography>
											<Typography sx={{
												color: 'var(--grey900)',
												mb: 2
											}}>
												{/* <Chip label="3" variant="outlined"
										size='small'
										sx={{
											color: 'var(--grey900)',
											height: '24px',
											width: '24px',
											bgcolor: 'white',
											pr: 1
										}} /> Set up your Stripe Connect account.<br /> */}
											</Typography>
											Click below to start -										</>
									</Box>
								}
							/>
						</Box>

					</Box>
				</Dialog>
			</Box>
			{isNineHundred && !openPayment && <Box sx={{
				width: '100%',
				height: '82px',
				position: 'fixed',
				ml: -2,
				bottom: 0,
				backgroundColor: 'white',
				display: 'flex',
				alignItems: 'center', // Vertically align the items
				justifyContent: 'space-between',
				flexDirection: 'column',
				zIndex: '999',
				px: 2,
				borderTop: '2px solid #E9EBEE',
				position: 'fixed',
				bottom: 0,
				backgroundColor: 'white',
				// display: open ? 'none' : 'flex',
				// display: 'none',
				width: '100%',
				justifyContent: 'space-between',
				// "& .MuiButtonBase-root": { p: '6px 8px' },
			}}>
				<LoaderButton
					variantType='contained'
					size="large"
					onClick={() => { initPayment() }}
					isLoading={loaderButton}
					buttonText="Confirm and Pay"
					isFullWidth={true}
					// sx={!isTablet ? { mt: 4 } : { my: 'auto' }}
					mt={!isTablet ? 2 : 'auto'}
					mb={!isTablet ? 0 : 'auto'}
				/>
			</Box>}
		</>
	)
}

export default ExpCheckout;

const PricingCard = ({
	image,
	title,
	address,
	level,
	experience_type,
	rating,
	review,
	price,
	guest,
	conservation_donation = 0,
	optionalAddOns = [],
	totalGuestPrice,
	addonSum,
	bookingFee,
	total,
	cancellationPolicyTitle = 'Cancellation Policy',
	refundRate,
	daysBeforeCheckIn,
	duration,
	cancellationPolicyData
}) => {

	const [openCancellationPolicy, setOpenCancellationPolicy] = useState(false)
	const { isNineHundred } = CommonContext()
	const [selectedAddOnQuantity, setSelectedAddOnQuantity] = useState(0)
	const [showSelectedAddOns, setShowSelectedAddOns] = useState(false)

	let durationText = ''
	if (duration.unit === 'days') {
		durationText = generatePhrase(duration.value, 'Day')
	} else if (duration.unit === 'hours') {
		durationText = generatePhrase(duration.value, 'Hour')
	}

	useEffect(() => {
		// Creating a deep copy of the array
		const deepCopyAddons = JSON.parse(JSON.stringify(optionalAddOns));

		// Filtering out entities with selected_quantity of 0
		setSelectedAddOnQuantity(deepCopyAddons.filter(addon => addon.selected_quantity !== 0))
	}, [optionalAddOns])

	const showAddOns = () => {
		setShowSelectedAddOns(!showSelectedAddOns)
	}


	return (
		<Box sx={{
			maxWidth: isNineHundred ? '100%' : '462px',
			width: '100%',
			border: '1px solid #E5E7EB',
			borderRadius: '16px',
			p: 3,
			height: 'fit-content',
			// mb: 10,
			position: 'sticky',
			top: '96px',
		}}>
			<Box sx={{ display: 'flex', gap: 2 }}>
				{/* Media Card Section */}
				<MediaCard
					imageObj={image}
					height={'97px'}
					width={'128px'}
					borderRadius={'8px'}
				/>
				<Box sx={{
					width: '100%',
					overflow: 'hidden',
				}}>
					{/* Title, Address, and Experience Info */}
					<Typography
						sx={{
							fontWeight: '600',
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis'
						}}
					>
						{title}
					</Typography>
					<Typography
						sx={{
							color: 'var(--textGrey)',
							whiteSpace: 'nowrap',
							overflow: 'hidden',
							textOverflow: 'ellipsis'
						}}
					>
						{address}
					</Typography>
					<Box sx={{ display: 'flex', gap: 1, "& .MuiChip-label": { color: 'var(--grey900)' }, flexWrap: 'wrap' }}>
						{/* <Chip
							label={experience_type}
							sx={{ height: '24px' }}
						/> */}
						<CreateChip
							chipType={experience_type}
							caption={experience_type}
							icon={false}
							sx={{ height: '24px' }}
						/>
						{/* <Chip
							label={level}
							sx={{ height: '24px' }}
						/> */}
						<CreateChip
							caption={level}
							sx={{ height: '24px' }}
						/>
						{/* <Chip
							label={durationText}
							sx={{ height: '24px' }}
						/> */}
						<CreateChip
							caption={durationText}
							sx={{ height: '24px' }}
						/>
					</Box>
					{/* Rating and Reviews */}
					{review !== 0 && (
						<Box sx={{ display: 'flex', gap: 1, alignItems: 'center', flexWrap: 'wrap', mt: 1 }}>
							<Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
								<Icon.Star sx={{ color: "#F7C32E" }} />
								<Typography sx={{ fontWeight: 700 }}>
									{parseFloat(rating).toFixed(1)}
								</Typography>
							</Box>
							<Typography>
								{/* {review} reviews */}
								{generatePhrase(review, 'review')}
							</Typography>
						</Box>
					)}
				</Box>
			</Box>
			<Divider sx={{ my: 2.5 }} />
			{/* Pricing Details */}
			<Box>
				<Typography sx={{ fontWeight: '600', mb: 1 }}>
					Pricing Details
				</Typography>
				<Box sx={{
					width: '100%',
					display: 'flex',
					flexDirection: 'column',
					gap: 0.5,
					"& .MuiTypography-root": { color: 'var(--textGrey)' },
					pb: 1,
				}}>
					<Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
						<Typography>
							${price} x {guest} Guests
						</Typography>
						<Typography>
							${(totalGuestPrice).toFixed(2)}
						</Typography>
					</Box>
					{/* Optional Add-Ons */}
					{addonSum > 0 && (
						<>
							<Box
								sx={{
									display: 'flex',
									flexDirection: 'row',
									justifyContent: 'space-between'
								}}
							>
								<Box
									sx={{ display: 'flex', flexDirection: 'row', gap: 1, cursor: 'pointer' }}
									onClick={
										showAddOns
									}
								>
									<Typography sx={{ color: 'var(--textGrey) !important' }}>Add On</Typography>
									<Box
										sx={{
											border: '1px solid #85878A',
											borderRadius: 2,
											height: 14,
											width: 18,
											textAlign: 'center',
											alignSelf: 'center',
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'end'
										}}
									>
										<Typography
											sx={{
												color: '#85878A !important',
												fontSize: '10px',
												lineHeight: 1
											}}
										>
											{selectedAddOnQuantity.length}
										</Typography>
									</Box>
									<Icon.KeyboardArrowDownOutlined sx={{ rotate: showSelectedAddOns ? '180deg' : 'unset', color: 'var(--textGrey) !important', marginLeft: '-6px' }} />
								</Box>
								<Typography>
									${(addonSum).toFixed(2)}
								</Typography>
							</Box>
							{
								showSelectedAddOns &&
								<Box sx={{
									width: '100%',
									display: 'flex',
									flexDirection: 'column',
									gap: 0.5,
									"& .MuiTypography-root": { color: 'var(--textGrey)' },
									backgroundColor: 'rgba(0, 0, 0, 0.08)',
									padding: 0.5,
									borderRadius: 2
								}}>
									{optionalAddOns.map((addon, index) => (
										addon.selected_quantity > 0 && (
											<Box key={index} sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
												<Typography>{addon.title} x {addon.selected_quantity}</Typography>
												<Typography>${(addon.price * addon.selected_quantity).toFixed(2)}</Typography>
											</Box>
										)
									))}
								</Box>
							}
						</>
					)}
					<Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
						<Typography>
							Platform Fee (5%)
						</Typography>
						<Typography>
							${bookingFee.toFixed(2)}
						</Typography>
					</Box>
					{/* <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
						<Typography>
							Conservation Donation
						</Typography>
						<Typography>
							${conservation_donation}
						</Typography>
					</Box> */}
				</Box>
				<Divider />
			</Box>
			{/* Total Amount */}
			<Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', py: 2 }}>
				<Typography sx={{ fontWeight: '600' }}>
					Total
				</Typography>
				<Typography sx={{ fontWeight: '600' }}>
					${total.toFixed(2)}
				</Typography>
			</Box>
			<Divider />
			{/* Cancellation Policy */}
			<Box sx={{ pt: 2 }}>
				<Typography sx={{ fontWeight: '600' }}>
					{cancellationPolicyTitle}
				</Typography>
				<Typography sx={{ color: 'var(--textGrey)' }}>
					Get a {refundRate === 100 ? 'full' : ''} refund if you cancel by {dayjs().add(daysBeforeCheckIn, 'day').format('DD MMM, YYYY')}.&nbsp;
					<span
						onClick={() => {
							setOpenCancellationPolicy(true)
						}}
						style={{
							color: 'var(--black)',
							textDecoration: 'underline',
							cursor: 'pointer'
						}}>
						Learn more
					</span>
				</Typography>
			</Box>
			<CancellationPolicyPopup open={openCancellationPolicy} setOpen={setOpenCancellationPolicy} selectedCancellationPolicy={cancellationPolicyData} />
		</Box>
	)
}


const PayPopup = ({ open, handleClose, image, experienceData, typeIdObj, optionalAddOns, guest, selectedSlot, totalGuestPrice, addonSum, bookingFee, total, cancellationPolicyData, timeout, shouldReset, setShouldReset, isLoadingButton }) => {
	const stripe = useStripe();
	const elements = useElements();
	const [bookingLoader, setBookingLoader] = useState(false);
	const { isNineHundred } = CommonContext();
	const [cancelConfirmationOpen, setCancelConfirmation] = useState(false)
	const [cancelTransactionType, setcancelTransactionType] = useState('')
	const [secondsRemaining, setSecondsRemaining] = useState(timeout.timeout * 60); // Convert timeout from minutes to seconds


	const handleSubmit = async (event) => {
		// Check if event is defined before attempting to access its properties
		if (event) {
			event.preventDefault();
		}

		if (!stripe || !elements) {
			setBookingLoader(false);
			return;
		}

		setBookingLoader(true);
		const result = await stripe.confirmPayment({
			elements,
			confirmParams: {
				return_url: window.location.origin + "/payment",
			},
		});

		if (result.error) {
			setBookingLoader(false);
			window.location.href = "/payment?payment_intent=" + result.error.payment_intent.id + "&redirect_status=failed&em=" + result.error.message;
		} else {
			setBookingLoader(false);
			// Handle successful payment
		}

		// Reset loader state after payment attempt
		setBookingLoader(false);
	};

	const [lastState, setLastState] = useState(null); // useState to store last pushed state

	useEffect(() => {
		const handleBeforeUnload = (event) => {
			if (open) {
				// Show an alert instead of the default confirmation
				alert("Are you sure you want to leave? Your progress may be lost.");
				// Returning a value here for browsers that support it
				event.returnValue = "Are you sure you want to leave? Your progress may be lost.";
				return "Are you sure you want to leave? Your progress may be lost.";
			}
		};

		const handlePopState = (event) => {
			if (open) {
				// Show an alert when the back button is pressed
				handleCancelPopup('cancel');
				// Push a state to the browser history to intercept the back button
				window.history.pushState(null, '', window.location.href);
				// Prevent the back navigation by calling preventDefault
				event.preventDefault();
			}
		};

		// Only attach event listeners if PayPopup is open
		if (open && !bookingLoader) {
			// Check if the current state is different from the last pushed state
			if (lastState !== window.location.href) {
				// Push a state to the browser history to intercept the back button
				window.history.pushState(null, '', window.location.href);
				setLastState(window.location.href); // Update the last state using setLastState
			}

			// Attach beforeunload and popstate event listeners
			window.addEventListener('beforeunload', handleBeforeUnload);
			window.addEventListener('popstate', handlePopState);
		}

		// Cleanup event listeners when PayPopup is closed or component unmounts
		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
			window.removeEventListener('popstate', handlePopState);
		};
	}, [open, bookingLoader, lastState]); // Add `lastState` to dependencies

	const handleCancelPopup = (type) => {
		setcancelTransactionType(type)
		setCancelConfirmation(!cancelConfirmationOpen)
	}

	// Convert seconds to hh:mm:ss format
	const formatTime = (seconds) => {
		const hours = Math.floor(seconds / 3600); // Calculate hours
		const minutes = Math.floor((seconds % 3600) / 60); // Calculate minutes
		const remainingSeconds = seconds % 60; // Calculate remaining seconds

		// Format as hh:mm:ss
		return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
	};


	// Countdown Timer Effect
	useEffect(() => {
		if (open) {
			if (secondsRemaining <= 0) {
				toastMessage(errorAndHelperText.session_expired)
				handleClose(true); // Close the dialog when the timer reaches zero
				return;
			}

			const timer = setInterval(() => {
				setSecondsRemaining(prev => prev - 1);
			}, 1000);

			return () => clearInterval(timer); // Clean up the timer when the component unmounts or the countdown finishes
		}


	}, [secondsRemaining, handleClose]);

	// Reset timer when PayPopup mounts or opens
	useEffect(() => {
		// Reset the countdown to the original timeout value whenever the popup opens
		if (open && shouldReset) {
			console.log('reset to 5');
			setSecondsRemaining(timeout.timeout * 60); // Reset to the initial timeout value (in seconds)
			setShouldReset(false)
		}
	}, [open, timeout, handleClose]); // Depend on open and timeout to trigger when PayPopup opens

	return (
		<Dialog open={open} fullScreen={isNineHundred} sx={{
			borderRadius: (theme) => `${theme.card.borderRadius} `,
			'& .MuiDialog-paper': isNineHundred && {
				padding: 0,
			},
			'& .MuiDialog-paper': { maxWidth: '1000px', borderRadius: isNineHundred ? '0 !important' : '16px', },
			'& .MuiPaper-root': {
				overflow: 'hidden'
			}
		}}
			PaperProps={{ style: { borderRadius: (theme) => `${theme.card.borderRadius} ` } }}
			fullWidth

		>
			<Box sx={{ mb: isNineHundred && 8, overflow: 'auto' }}>
				{!isNineHundred ? <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
					<Typography variant="h5" sx={{ p: 2, fontWeight: 700 }}>Confirm and Pay</Typography>
					<IconButton onClick={() => {
						// handleClose()
						handleCancelPopup('cancel')
					}} sx={{ mr: 2 }}>
						<Icon.Close />
					</IconButton>
				</Box> :
					<AppBar sx={{ position: 'relative', backgroundColor: 'white', boxShadow: 'none', height: '56px' }}>
						<Toolbar>
							<IconButton
								edge="start"
								color="inherit"
								onClick={() => {
									handleCancelPopup('cancel')
								}}
								aria-label="close"
							>
								<Icon.ArrowBackOutlined color="black" />
							</IconButton>
							<Typography sx={{ m: 'auto' }} variant="h6" component="div">
								Confirm and Pay
							</Typography>
						</Toolbar>
						<Divider />
					</AppBar>}
				{!isNineHundred && <Divider />}
				<Grid container spacing={2} sx={{ p: 2 }}>
					<Grid item xs={12} sm={12} md={6}>
						<form onSubmit={handleSubmit}>
							<PaymentElement options={{ terms: { card: 'never' } }} />
							{!isNineHundred && <Divider sx={{ my: 3 }} />}
							<Box
								sx={isNineHundred && {
									width: '100%',
									ml: -2,
									height: '82px',
									position: 'fixed',
									bottom: 0,
									backgroundColor: 'white',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between',
									flexDirection: 'column',
									boxShadow: '0 -2px 4px 0 rgba(0,0,0,0.15)',
									zIndex: '9999',
									pl: 2,
									pr: 2
								}}
							>
								<LoaderButton
									variantType='contained'
									size="large"
									onClick={() => { handleSubmit() }}
									isLoading={bookingLoader}
									buttonText="Send Booking Request"
									isFullWidth={true}
									mt={isNineHundred ? 2 : 0}
									sx={{ borderRadius: 2 }}
								/>
							</Box>
							<Box sx={{
								textAlign: 'center',
								mt: 3
							}}>
								<Typography sx={{ fontWeight: 600, textAlign: 'center' }} variant="subtitle1">
									Complete transaction in - {formatTime(secondsRemaining)}
								</Typography>
								<Typography sx={{ textAlign: 'center', color: 'var(--textGrey)' }} variant="caption">
									Please do not refresh or leave this page to complete the transaction.
								</Typography>
							</Box>
						</form>
					</Grid>
					<Grid item xs={12} sm={12} md={6} sx={{ pb: '0 !important', pb: { sm: 3, md: 0 } }} >
						<PricingCard
							image={image}
							title={extractDataFromArray(experienceData, ['experience_data', `${typeIdObj?.general_info}`, 'title'], '')}
							address={
								extractDataFromArray(experienceData, ['experience_data', `${typeIdObj?.location}`, 'address'], '') === 'Don’t see your address? Add GPS coordinates.'
									?
									extractDataFromArray(experienceData, ['experience_data', `${typeIdObj?.location}`, 'city'], '')
									:
									extractDataFromArray(experienceData, ['experience_data', `${typeIdObj?.location}`, 'address'], '')
							} level={extractDataFromArray(experienceData, ['experience_data', `${typeIdObj?.general_info}`, 'difficulty_level'], '')}
							experience_type={extractDataFromArray(experienceData, ['experience_data', `${typeIdObj?.general_info}`, 'experience_type'], '')}
							optionalAddOns={optionalAddOns}
							guest={guest}
							price={selectedSlot?.price}
							review={Number(extractDataFromArray(experienceData, ['review_count'], 0))}
							rating={Number(extractDataFromArray(experienceData, ['average_rating'], 0))}
							totalGuestPrice={totalGuestPrice}
							addonSum={addonSum}
							bookingFee={bookingFee}
							total={total}
							refundRate={cancellationPolicyData[0].rules[0].refundRate}
							daysBeforeCheckIn={cancellationPolicyData[0].rules[0].daysBeforeCheckIn}
							duration={extractDataFromArray(experienceData, ['experience_data', `${typeIdObj?.pricing}`, 'package_duration'], '')}
							cancellationPolicyData={cancellationPolicyData}
						/>
					</Grid>
				</Grid>
			</Box>
			<BookingCancellationPopup isLoadingButton={isLoadingButton} open={cancelConfirmationOpen} setOpen={setCancelConfirmation} handleCloseMainPopup={handleClose} type={cancelTransactionType} />
		</Dialog>
	)
}

const CancellationPolicyPopup = ({ open, setOpen, selectedCancellationPolicy }) => {
	return (
		<Dialog
			open={open}
			sx={{
				'& .MuiDialog-paper': {
					maxWidth: 600,
					width: '100%'
				},
			}}>
			<Box>
				<Box sx={{ p: 2.5, display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
					<Typography variant="h6" sx={{ fontWeight: 700 }}>
						Cancellation Policy
					</Typography>
					<IconButton onClick={() => { setOpen(false) }}>
						<Icon.Close />
					</IconButton>
				</Box>
				<Divider />
				<Box sx={{ p: 2.5 }}>
					{/* <Typography fontWeight={600} mb={3}>
						{selectedCancellationPolicy[0].caption}
					</Typography> */}
					<Typography>
						{selectedCancellationPolicy[0].messageToguest}
					</Typography>
				</Box>

			</Box>
		</Dialog>
	)
}

