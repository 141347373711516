import * as Icon from "@mui/icons-material";
import { Box, Button, Checkbox, FormControlLabel, Grid, TextField, Typography } from "@mui/material";
import LinkHref from '@mui/material/Link';
import { useState } from "react";
import { empty, toastMessage, validateJSON } from "../../utils/common";
import CommonService from "../../services/misc";
import errorAndHelperText from "../../commonComponents/CommonConstants/errorAndHelperText.json"
import GreyLabel from "../../commonComponents/formElement/GreyLabel";
import { CommonContext } from "../../store/context/commonContext";
// Install Swiper modules


const validationSchema = {
    name: {
        type: 'string',
        required: true,
        requiredMsg: errorAndHelperText.helperTextsObj.send_us_message.name,
    },
    email: {
        type: 'string',
        required: true,
        requiredMsg: errorAndHelperText.helperTextsObj.send_us_message.email,
        isValid: (jsonData, value) => {
            return String(value)
                .toLowerCase()
                .match(/@[^.]*\./) === null ? errorAndHelperText.helperTextsObj.send_us_message.email : "";

        }
    },
    phone: {
        type: 'number',
        required: true,
        requiredMsg: errorAndHelperText.helperTextsObj.send_us_message.phone,
    },
    message: {
        type: 'string',
        required: true,
        requiredMsg: 'Please provide a message.',
    },
}


const ContactUs = () => {
    const { isTablet } = CommonContext()
    const [formData, setFormData] = useState({
        email: '',
        name: '',
        phone: '',
        message: ''
    })

    const [errors, setErrors] = useState('')
    const submitMsg = () => {
        let errorArray = validateJSON(formData, validationSchema)
        setErrors(errorArray)
        if (!empty(errorArray)) {
            return;
        }

        CommonService.submitContactForm(formData.name, formData.email, formData.phone, formData.message, (data) => {
            // toast.success("Your message is submitted.", {
            //     theme: "colored"
            // })
            toastMessage(errorAndHelperText.submit_contact_form.success)
            setFormData({
                email: '',
                name: '',
                phone: '',
                message: ''
            })
        })


    }

    return (
        <>
            <Grid container maxWidth={"lg"} sx={{ m: "auto", mt: 12 }}>
                <Grid item xs={12} sm={12} md={12} sx={{ mt: isTablet ? 4 : 12, mb: 4, textAlign: 'center' }}>
                    <Typography variant="h3">Contact Us</Typography>
                    <Typography variant="body" sx={{ color: 'text.secondary' }}>We'd love to hear from you. Let us know how we can assist.</Typography>
                </Grid>

            </Grid>
            <Box maxWidth={"lg"} sx={{ m: "auto", mb: 12, display: 'flex', flexDirection: isTablet ? 'column-reverse' : 'row', display: 'flex', }}>

                <Box sx={{ m: 2 }}>
                    <Box sx={{ background: "var(--grey300)", mb: 4, display: 'flex', alignItems: 'center', p: 3, borderRadius: theme => `${theme.card.borderRadius} ` }}>
                        <Box sx={{ background: '#fff', borderRadius: '60px', width: '42px', height: '42px', display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 2 }}>
                            <Icon.EmailOutlined />
                        </Box>
                        <Typography>  <LinkHref href="mailto:contact@farwide.com" style={{ color: 'var(--grey900)', textDecoration: 'none' }}> contact@farwide.com </LinkHref></Typography>
                    </Box>
                    <Box sx={{ background: "var(--grey300)", mb: 4, display: 'flex', alignItems: 'center', p: 3, borderRadius: theme => `${theme.card.borderRadius} ` }}>
                        <Box sx={{ background: '#fff', borderRadius: '60px', width: '42px', height: '42px', display: 'flex', justifyContent: 'center', alignItems: 'center', mr: 2 }}>
                            <Icon.CallOutlined />
                        </Box>
                        {/* <Typography><LinkHref href="tel:(308) 225-5053" style={{ color: 'var(--grey900)', textDecoration: 'none' }}>(308) 225-5053</LinkHref></Typography> */} {/* Changed on January 9 2025 */}
                        <Typography><LinkHref href="tel:(910) 225-4376" style={{ color: 'var(--grey900)', textDecoration: 'none' }}>(910) 225-4376</LinkHref></Typography>
                    </Box>

                    <Box sx={{ background: "var(--grey300)", display: 'flex', justifyContent: 'center', alignItems: 'center', p: { xs: 3, md: 10 }, borderRadius: theme => `${theme.card.borderRadius} ` }}>
                        <img src="/assets/images/homepage/contact_us.svg" alt="contact" style={{ maxWidth: '100%' }} />
                    </Box>
                </Box>

                <Box sx={{ m: 2 }}>
                    <Box sx={{ border: 'solid 1px var(--grey500)', p: isTablet ? 2 : 3, borderRadius: theme => `${theme.card.borderRadius} ` }}>
                        <Typography variant="h5" sx={{ fontWeight: 700, mb: 3 }}>Send us a Message.</Typography>
                        <Box sx={{ mb: 3 }}>
                            <GreyLabel label="Your Name" required />
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={formData.name}
                                onInput={(e) => { setFormData({ ...formData, name: e.target.value }) }}
                                error={errors.name ? true : false}
                                helperText={errors.name ? errors.name : ''}
                                sx={{ "& .MuiInputBase-root": { height: '42px' } }}
                            />
                        </Box>

                        <Box sx={{ mb: 3 }}>
                            <GreyLabel label="Email Address" required />
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={formData.email}
                                onInput={(e) => { setFormData({ ...formData, email: e.target.value }) }}
                                error={errors.email ? true : false}
                                helperText={errors.email ? errors.email : ''}
                                sx={{ "& .MuiInputBase-root": { height: '42px' } }}
                            />
                        </Box>
                        <Box sx={{ mb: 3 }}>
                            <GreyLabel label="Mobile Number" required />
                            <TextField
                                variant="outlined"
                                fullWidth
                                value={formData.phone}
                                onInput={(e) => { setFormData({ ...formData, phone: e.target.value }) }}
                                error={errors.phone ? true : false}
                                helperText={errors.phone ? errors.phone : ''}
                                sx={{ "& .MuiInputBase-root": { height: '42px' } }}
                            />
                        </Box>
                        <Box sx={{ mb: 3 }}>
                            <GreyLabel label="Message" required />
                            <TextField
                                variant="outlined"
                                multiline
                                rows={3}
                                fullWidth
                                value={formData.message}
                                onInput={(e) => { setFormData({ ...formData, message: e.target.value }) }}
                                error={errors.message ? true : false}
                                helperText={errors.message ? errors.message : ''}
                            />
                        </Box>
                        <FormControlLabel sx={{
                            mb: 4,
                            '& .MuiTypography-root': {
                                fontSize: '13px',
                                color: '#85878A'
                            }
                        }} control={<Checkbox defaultChecked />} label="By submitting this form you agree to our terms and conditions." />

                        <Button variant="contained" color="primary" onClick={submitMsg}> Send Message</Button>


                    </Box>

                </Box>

            </Box >
        </>
    );
};

export default ContactUs;
