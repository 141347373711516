import axios from "axios";
import { extractDataFromArray, parseResponseAxios } from "../utils/common";
import config from "../config";

const HomePageService = {
    getSampleBlogPosts: async (callback = () => { }, errCallBack = () => { }) => {
        await axios.get(config.apiUrl + "/services/openapi", {
            params: {
                cmd: "getSampleBlogPosts",
            }
        }).then((response) => {
            response = parseResponseAxios(response)
            if (extractDataFromArray(response, ['errorCode'], 0) === 0) {
                callback(extractDataFromArray(response, ['data'], []))
            } else {
                errCallBack(extractDataFromArray(response, ['errorMsg'], []))
            }
        });
    },
}

export default HomePageService