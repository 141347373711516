import { Box, Dialog } from "@mui/material";
import SignIn from "../SignIn";
import SignUp from "../SignUp";
import ForgotPassword from "../ForgotPassword";

const AuthPopup = ({ open, handleClose, type, handleOpen, admin }) => {
    let modalBody = '';
    switch (type) {
        case "signin":
            modalBody = <SignIn handleOpen={handleOpen} handleClose={handleClose} admin={admin} />
            break;
        case "signup":
            modalBody = <SignUp handleOpen={handleOpen} handleClose={handleClose} />
            break;
        case "forgotpassword":
            modalBody = <ForgotPassword handleOpen={handleOpen} handleClose={handleClose} />
            break;
        default:
            modalBody = <SignIn handleOpen={handleOpen} handleClose={handleClose} />
            break;
    }

    return (
        <Dialog className="authPopUpP"
            open={open}
            onClose={handleClose}
            maxWidth='lg'
            sx={{
                "& .MuiDialog-paper": {
                    // backgroundColor: '#F1F1F1',
                    borderRadius: 'unset'
                },
                "& .MuiInputBase-root": {
                    backgroundColor: 'white'
                },
                "& .MuiIconButton-root": {
                    backgroundColor: "white",
                    borderRadius: '50%'
                },
                "& .MuiPaper-root": {
                    display: 'flex',
                    borderRadius: '1rem',
                    maxWidth:'lg',
                    width:'100%'
                },
                width: '100%',
                overflow: 'hidden'

            }}
            slotProps={{ backdrop: { style: { backgroundColor: 'rgba(27, 27, 27, 0.898)', }, }, }}
        >
            <Box sx={{
                display: 'flex',
                flexWrap: 'nowrap',
                // p: { xs: 3, sm: 3, md: 6, lg: 6 },
                justifyContent: 'center',
                borderRadius: '1rem',
                overflow: 'auto',
                maxWidth: 'lg',
                width: '100%'
            }}>
                <Box sx={{ display: 'flex', maxWidth: 'lg', width: '100%' }}>
                    {modalBody}
                </Box>
            </Box>
        </Dialog >
    )
}


export default AuthPopup;