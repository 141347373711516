import { Avatar, Box, Button, Chip, IconButton, Typography } from "@mui/material";
import * as Icon from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { ROUTE_LISTER } from "../../constants/page";
import AdminListingPopup from "../AdminLisitingPopup";
import { useState } from "react";
import CreateChip from "../CreateChip";
import config from "../../config";

const getStatusChip = (status) => {
    switch (status) {
        case 'P':
            return <>
                {/* <Chip variant="filled" color="secondary" label="Pending" /> */}

                <CreateChip chipType={'Pending'} />

            </>
        case 'V':
            return <>

                {/* <Chip variant="filled" color="primary" label="Approved" /> */}

                <CreateChip chipType={'Approved'} />

            </>
        case 'D':
            return <>
                {/* <Chip variant="filled" color="error" label="Declined" /> */}

                <CreateChip chipType={'Declined'} />

            </>
        case 'S':
            return <>
                {/* <Chip variant="filled" color="warning" label="Spam" /> */}

                <CreateChip chipType={'Spam'} />

            </>
        default:
            break
    }
}
const AdminBanner = ({ lister_pic, lister_seq, lister_name, status, listing_seq, listing_type }) => {
    const [popupConfig, setPopupConfig] = useState({ open: false, type: '' })
    const navigate = useNavigate();

    const handleBack = () => {
        if (window.history.length > 2) {
            navigate(-1);
        } else {
            navigate('/dashboard');
        }
    };

    const openPopup = (type) => {
        setPopupConfig({ open: true, type: type })
    }

    const [currentStatus, setCurrentStatus] = useState(status)
    return (
        <>
            {
                config.module === 'admin' &&
                <Box>
                    <Box sx={{ display: 'flex', flexDirection: 'row', gap: 3, mb: 2 }}>
                        <Box>
                            <IconButton
                                color="inherit"
                                onClick={handleBack}
                                aria-label="close"
                                sx={{
                                    padding: 0,
                                    borderRadius: 4,
                                    '&:hover': {
                                        backgroundColor: 'white !important',
                                    },
                                }}
                            >
                                <Icon.ArrowBack sx={{ marginRight: 1, color: '#26C77A' }} />
                                {/* Uncomment below line if you want a text label */}
                                <Typography variant="body1" sx={{ color: '#26C77A' }}>Back</Typography>
                            </IconButton>
                        </Box>
                    </Box>
                </Box>
            }
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 3, backgroundColor: '#E0F7EC', borderRadius: 4, flexWrap: 'wrap', mb: 3 }} >
                {/* <Link to={ROUTE_LISTER + "/" + lister_seq}> */}
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    <Avatar src={lister_pic} />
                    <Typography>{lister_name.trim()}</Typography>
                </Box>
                {/* </Link> */}
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', flexWrap: 'wrap' }}>
                    {
                        currentStatus === 'P' ?
                            <>
                                <Button variant="outlined" size="small" color="secondary" startIcon={<Icon.BlockOutlined />} sx={{ backgroundColor: '#fff', ":hover": { backgroundColor: '#fff' } }} onClick={() => { openPopup("S") }}>{status !== "S" ? "Spam" : "Not Spam"}</Button>

                                <Button variant="contained" size="small" color="error" startIcon={<Icon.CloseOutlined />} onClick={() => { openPopup("D") }}>{currentStatus !== "D" ? "Decline" : "Un Decline"}</Button>

                                <Button variant="contained" size="small" color="primary" startIcon={<Icon.Done />} onClick={() => { openPopup("V") }}>{currentStatus !== "V" ? "Approve" : "Un Approve"}</Button>
                            </>
                            :
                            <Button variant="contained" size="small" color="secondary" startIcon={<Icon.AccessTimeOutlined />} onClick={() => { openPopup("P") }}>Move to Pending</Button>
                    }
                </Box>
            </Box>

            {
                getStatusChip(currentStatus)
            }
            <AdminListingPopup open={popupConfig.open} handleClose={() => { setPopupConfig({ open: false, type: '' }) }} type={popupConfig.type} listing_seq={listing_seq} listing_type={listing_type} setCurrentStatus={setCurrentStatus} />
        </>
    )
}


export default AdminBanner;