import { Box, Grid, Skeleton, Typography } from "@mui/material"
import { useLocation } from "react-router"

const SkeletonPackageCard = () => {
    const location = useLocation()

    return (
        <Grid container spacing={2} sx={{ mb: 4 }}>
            <Grid item md={4} xs={12}>
                <Box sx={{ position: 'relative', height: '100%', maxHeight: 268 }}>
                    <Skeleton variant="rectangular" width="100%" height='100%' sx={{ borderRadius: "8px" }}>
                        <div style={{ paddingTop: '57%' }} />
                    </Skeleton>
                    {
                        !location.pathname.includes('/search') &&
                        <Skeleton
                            variant="rectangular"
                            width={90}
                            height={30}
                            sx={{
                                position: "absolute",
                                top: 8,
                                left: 8,
                                zIndex: 2,
                                borderRadius: 4
                            }}
                        />
                    }
                    {
                        location.pathname.includes('/search') &&
                        <Skeleton
                            variant="rectangular"
                            width={34}
                            height={34}
                            sx={{
                                position: "absolute",
                                top: 8,
                                right: 8,
                                zIndex: 2,
                                borderRadius: 1
                            }}
                        />
                    }
                </Box>
            </Grid>
            <Grid item md={8} xs={12}>
                <Skeleton variant="rectangular" width="100%" sx={{ borderRadius: "8px", height: 32 }}>
                    <div style={{ paddingTop: "10%", }} />
                </Skeleton>
                <Typography variant="body2" sx={{ mt: 1 }}>
                    <Skeleton />
                </Typography>
                <Typography variant="body1">
                    <Skeleton width='25%' />
                </Typography>
                <Box
                    sx={{ display: 'flex', flexDirection: 'row', gap: 1, mt: 1 }}
                >
                    <Skeleton
                        variant="rectangular"
                        width={70}
                        height={25}
                        sx={{ borderRadius: "16px" }}
                    />
                    <Skeleton
                        variant="rectangular"
                        width={70}
                        height={25}
                        sx={{ borderRadius: "16px" }}
                    />
                    <Skeleton
                        variant="rectangular"
                        width={70}
                        height={25}
                        sx={{ borderRadius: "16px" }}
                    />
                </Box>
                <Typography variant="h6" sx={{ mt: 1 }}>
                    <Skeleton width='25%' />
                </Typography>
                <Skeleton variant="rectangular" width="40%" sx={{ borderRadius: "8px", mt: 1.5 }}>
                    <div style={{ paddingTop: "10%", }} />
                </Skeleton>

            </Grid>
        </Grid>
    )
}

export default SkeletonPackageCard