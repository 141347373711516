import { Chip, Avatar, CircularProgress } from '@mui/material';
// import { ACCESS, EXPERIENCE } from '../CommonConstants/commonKeys';
import * as Icon from "@mui/icons-material";

// map for chip types
const getChipTypeMap = (isloading) => ({
  'access': {
    caption: 'Access',
    variant: 'outlined',
  },
  'experience': {
    caption: 'Experience',
    variant: 'outlined',
  },
  'Property': {
    caption: 'Property',
    variant: 'outlined',
  },
  'Access': {
    caption: 'Access',
    variant: 'outlined',
  },
  'Experience': {
    caption: 'Experience',
    variant: 'outlined',
  },
  'Events': {
    icon: <img src={'/assets/images/event_exp_icon.svg'} style={{ height: '16px', width: '16px' }} />,
    caption: 'Events',
    size: 'small',
    border: 'none'
  },
  'RE': {
    icon: <img src={'/assets/images/event_exp_icon.svg'} style={{ height: '16px', width: '16px' }} />,
    caption: 'Events',
    size: 'small',
    border: 'none'
  },
  'time': {
    icon: <Icon.AccessTimeOutlined />,
    border: 'none'
  },
  'Guides': {
    caption: 'Guides',
    icon: <img src={'/assets/images/tour_guide_exp_icon.svg'} style={{ height: '16px', width: '16px' }} />,
    border: 'none'
  },
  'GT': {
    caption: 'Guides',
    icon: <img src={'/assets/images/tour_guide_exp_icon.svg'} style={{ height: '16px', width: '16px' }} />,
    border: 'none'

  },
  'Other': {
    caption: 'Other',
    icon: <img src={'/assets/images/outdoor_activity_exp_icon.svg'} style={{ height: '16px', width: '16px' }} />,
    border: 'none'

  },
  'OA': {
    caption: 'Other',
    icon: <img src={'/assets/images/outdoor_activity_exp_icon.svg'} style={{ height: '16px', width: '16px' }} />,
    border: 'none'

  },
  'Learn': {
    caption: 'Learn',
    icon: <img src={'/assets/images/in-person.svg'} style={{ height: '16px', width: '16px' }} />,
    border: 'none'
  },
  'IP': {
    caption: 'Learn',
    icon: <img src={'/assets/images/in-person.svg'} style={{ height: '16px', width: '16px' }} />,
    border: 'none'
  },
  'In-Person Training': {
    caption: 'Learn',
    icon: <img src={'/assets/images/in-person.svg'} style={{ height: '16px', width: '16px' }} />,
    border: 'none'
  },
  'Guest': {
    caption: 'Guest',
    icon: <Icon.PersonOutlineOutlined fontSize="small" color="var(--textGrey)" />,
  },
  'Day': {
    caption: 'Day',
    icon: <Icon.CalendarTodayOutlined fontSize="small" color="var(--textGrey)" sx={{ p: "2px" }} />,
  },
  'Lodging': {
    caption: 'Lodging',
    icon: <Icon.NightShelterOutlined fontSize="small" color="var(--textGrey)" />,
  },
  'RV Access': {
    caption: 'RV Access',
    icon: <Icon.AirportShuttleOutlined fontSize="small" color="var(--textGrey)" />,
  },
  'Draft': {
    caption: 'Draft',
    variant: 'outlined',
    color: 'red',
    textColor: 'red',
    bgColor: 'white'
  },
  'Active': {
    caption: 'Active',
    variant: 'filled',
    // color: '#26C77A',
    textColor: '#fff',
    bgColor: '#26C77A',
    borderColor: '#26C77A'
  },
  'Approval Declined': {
    caption: 'Approval Declined',
    variant: 'filled',
    // color: '#26C77A',
    textColor: '#fff',
    bgColor: '#E94A4A',
    borderColor: '#E94A4A'
  },
  'Closed': {
    caption: 'Closed',
    variant: 'filled',
    // color: '#26C77A',
    textColor: '#fff',
    bgColor: '#1B1B1B',
    borderColor: '#1B1B1B'
  },
  'Waiting for Admin Approval': {
    caption: 'Waiting for Admin Approval',
    variant: 'outlined',
    borderColor: '#26C77A',
    textColor: '#26C77A',
    bgColor: '#fff',
    icon: <Icon.ScheduleOutlined color='#26C77A' />
  },
  'Full Payment': {
    caption: 'Full Payment',
    variant: 'outlined',
    borderColor: '#26C77A',
    textColor: '#26C77A',
    bgColor: '#fff',
  },
  'Hold': {
    caption: 'Hold',
    variant: 'outlined',
    // color: 'red',
    // textColor: 'red',
    // bgColor: 'white'
  },
  'Paid': {
    caption: 'Paid',
    variant: 'filled',
    // color: 'red',
    // textColor: 'red',
    // bgColor: 'white'
  },
  'Refunded': {
    caption: 'Refunded',
    variant: 'outlined',
    // color: 'red',
    // textColor: 'red',
    // bgColor: 'white'
  },
  'Tag': {
    variant: 'outlined',
    showPointer: true
  },
  'PrintTag': {
    variant: 'filled',
    showPointer: false
  },
  'emailLandowner': {
    variant: 'filled',
    showPointer: false,
    icon: <Icon.Email />
  },
  'emailGuest': {
    variant: 'filled',
    showPointer: false,
    icon: <Icon.EmailOutlined sx={{ fontSize: '20px' }} />
  },
  'Declined': {
    caption: 'Declined',
    variant: 'filled',
    size: 'small',
    textColor: '#fff',
    bgColor: '#E94A4A',
    borderColor: '#E94A4A'
  },
  'Cancelled': {
    caption: 'Cancelled',
    variant: 'filled',
    size: 'small',
    textColor: '#fff',
    bgColor: '#E94A4A',
    borderColor: '#E94A4A'
  },
  'Requested': {
    caption: 'Requested',
    variant: 'filled',
    size: 'small',
    textColor: '#fff',
    bgColor: '#1B1B1B',
    borderColor: '#1B1B1B'
  },
  'Completed': {
    caption: 'Completed',
    variant: 'filled',
    size: 'small',
    textColor: '#fff',
    bgColor: '#26C77A',
    borderColor: '#26C77A'
  },
  'Confirmed': {
    caption: 'Confirmed',
    variant: 'filled',
    size: 'small',
    textColor: '#fff',
    bgColor: '#26C77A',
    borderColor: '#26C77A'
  },
  'Checked-In': {
    caption: 'Checked-In',
    variant: 'outlined',
    size: 'small',
    borderColor: '#26C77A',
    textColor: '#26C77A',
    bgColor: '#fff',
  },
  'contact': {
    caption: 'Call 308-225-5053',
    variant: 'outlined',
    // color: 'red',
    // textColor: 'red',
    bgColor: 'white'
  },
  'help': {
    caption: 'Help',
    variant: 'outlined',
    icon: <Icon.HelpOutlineOutlined />,
    // color: 'red',
    // textColor: 'red',
    bgColor: 'white'
  },
  "numbers": {
    variant: 'outlined',
    size: 'small',
    textColor: 'var(--grey900)',
    height: '24px',
    width: '24px',
    bgColor: 'white',
  },
  "messageChip": {
    caption: 'Access',
    size: 'small',
    variant: 'outlined',
    bgColor: 'white',
    textColor: '#747579',
    borderColor: '#979797',
    showPointer: true,
  },
  'S': {
    bgColor: "#26C77A",
    textColor: "white",
    size: 'small',
  },
  'F': {
    bgColor: "#e94a4a",
    textColor: "white",
    size: 'small',
  },
  'Failed': {
    caption:'Failed',
    bgColor: "#e94a4a",
    textColor: "white",
    size: 'small',
  },
  'R': {
    variant: 'outlined',
    borderColor: '#26C77A',
    textColor: '#26C77A',
    bgColor: '#fff',
    size: 'small',
  },
  'C': {
    // label: paymentStatusText,
    variant: 'outlined',
    borderColor: '#26C77A',
    textColor: '#26C77A',
    bgColor: '#fff',
    size: 'small',
  },
  'RC': {
    // label: paymentStatusText,
    // backgroundColor: "#FFFFFF", 
    // color: "#26C77A",  
    // border: "1px solid #26C77A",
    // size: 'small',
  },
  'RF': {
    // label: paymentStatusText,
    // backgroundColor: "#FFFFFF", 
    // color: "#26C77A",  
    // border: "1px solid #26C77A",
    // size: 'small',
  },
  'RP': {
    // label: paymentStatusText,
    // backgroundColor: "#FFFFFF", 
    // color: "#26C77A",  
    // border: "1px solid #26C77A",
    // size: 'small',
  },
  'RA': {
    // label: paymentStatusText,
    // backgroundColor: "#FFFFFF", 
    // color: "#26C77A",  
    // border: "1px solid #26C77A",
    // size: 'small',
  },
  'I': {
    bgColor: "#FFFFFF",
    textColor: "#FF962D",
    borderColor: "#FF962D",
    size: 'small',
  },
  'A': {
    bgColor: "#FF962D",
    textColor: "#FFFFFF",
    size: 'small',
  },
  'verified': {
    caption: 'verified',
    icon: <Icon.Verified color='#26C77A' />,
    textColor: '#26C77A',
    size: 'small',
    variant: 'outlined',
    border: 'none',
    bgColor: 'white'
  },
  'stripeActive': {
    caption: 'Active',
    variant: 'filled',
    size: 'small',
    // color: '#26C77A',
    textColor: '#fff',
    bgColor: '#26C77A',
    borderColor: '#26C77A',
    icon: <Icon.Verified color='#fff' />
  },
  'stripePending': {
    caption: 'Pending',
    variant: 'filled',
    size: 'small',
    // color: '#26C77A',
    textColor: '#E94A4A',
    bgColor: '#FCE5E5',
    border: 'none',
    icon: <Icon.ScheduleOutlined color='#E94A4A' />
  },
  "packages": {
    variant: "outlined",
    padding: '0px 5px',
    border: '1px solid #bdbdbd',
    textColor: '#333',
    bgColor: "#fff"
  },
  'Pending': {
    caption: 'Pending',
    variant: 'filled',
    textColor: '#fff',
    bgColor: '#1B1B1B',
    borderColor: '#1B1B1B'
  },
  "Approved": {
    caption: 'Approved',
    variant: 'filled',
    textColor: '#fff',
    bgColor: '#26C77A',
    borderColor: '#26C77A'
  },
  "Spam": {
    caption: 'Spam',
    variant: 'filled',
    textColor: '#fff',
    bgColor: '#FF9C11',
    borderColor: '#FF9C11'
  }
  ,
  "refresh": {
    caption: 'Refresh',
    variant: 'outlined',
    border: '1px solid #bdbdbd',
    textColor: '#333',
    bgColor: '#fff',
    // Only apply loading state if disabled prop was actually passed
    icon: typeof isloading !== 'undefined' && isloading ?
      <CircularProgress size="16px" sx={{ color: '#333' }} /> :
      <Icon.Sync sx={{ fontSize: '16px' }} />
  }
});

// Default chip properties
const defaultChip = {
  bgColor: 'rgba(0, 0, 0, 0.08)',
  icon: '', // Replace with actual default icon path
  caption: 'Default Chip',
  textColor: 'rgba(0, 0, 0, 0.87)'
};

const CreateChip = ({
  chipType,
  caption: propCaption, // renamed to propCaption for clarity
  image,
  sx,
  showPointer: propShowPointer,
  disabled,
  variant,
  size: propSize,
  color,
  avatar: avatarImage,
  icon: showIcon,
  ...otherProps
}) => {
  const isDisabled = typeof disabled === 'function' ? disabled() : !!disabled;

  // isloading = isDisabled

  // console.log("chipType :- ", chipType);
  // console.log("chipType size:- ", propSize);

  // Function to create chip details based on type, if type is not available then default chip will load.
  const createChip = (chipType, propCaption) => {

    const ChipTypeMap = getChipTypeMap(isDisabled);

    // Check if chipType is valid in ChipTypeMap
    const isValidChipType = !!ChipTypeMap[chipType];

    // Use ChipTypeMap[chipType] if valid, otherwise use defaultChip and ignore props
    const chipDetails = isValidChipType ? ChipTypeMap[chipType] : defaultChip;

    // return createChipForType(chipDetails.bgColor, chipDetails.textColor, chipDetails.icon, caption || chipDetails.caption);  // commented out to fix props not working from object issue

    // Use destructuring to extract properties from chipDetails
    const {
      bgColor,
      textColor,
      icon: chipIcon,
      variant,
      borderColor,
      color: chipColor,
      size: chipSize, // size from ChipTypeMap
      caption: chipCaption,
      border,
      showPointer: chipShowPointer,
      width: chipWidth,
      height: chipHeight,
      margin: chipMargin,
      padding: chipPadding
    } = chipDetails;

    // Determine final size based on priority
    const finalSize = propSize || chipSize || "medium";

    // Determine final icon based on conditions:
    // If showIcon is explicitly false, don't show icon
    // If showIcon is true or undefined, use chipIcon if available
    const finalIcon = showIcon === false ? undefined : chipIcon;

    // Determine final caption based on priority:
    // 1. If propCaption is provided, use it (even for invalid chipTypes)
    // 2. If valid chipType but no propCaption, use caption from ChipTypeMap
    // 3. If invalid chipType and no propCaption, use "Default Chip"
    const finalCaption = propCaption || (isValidChipType ? chipCaption : "Default Chip");

    const finalShowPointer = propShowPointer !== undefined ? propShowPointer : (chipShowPointer || false);

    return createChipForType(
      bgColor,
      textColor,
      finalIcon,
      finalCaption,
      variant,
      chipColor,
      finalSize,
      borderColor,
      border,
      finalShowPointer,
      avatarImage,
      chipWidth,
      chipHeight,
      chipMargin,
      chipPadding
    );
  };

  // Function to create chip component
  const createChipForType = (bgColor, textColor, icon, caption, variant, color, size, borderColor, border, showPointer, avatarImage, width,
    height,
    margin,
    padding) => {
    // console.log("final size applied", size);
    // console.log("Icon being applied:", icon ? "yes" : "no");
    // console.log("variant :- ", variant);
    // console.log("Width being applied:", width);
    // console.log("Height being applied:", height);
    // console.log("Margin being applied:", margin);
    // console.log("Padding being applied:", padding);
    return (
      <Chip
        label={caption}
        icon={icon}
        avatar={avatarImage ? <Avatar src={avatarImage} /> : undefined}
        disabled={isDisabled}
        variant={variant}
        size={size}
        color={color || "default"}
        sx={{
          backgroundColor: bgColor || defaultChip.bgColor,
          cursor: showPointer && !isDisabled ? 'pointer' : 'default',
          color: textColor || defaultChip.textColor,
          borderColor: borderColor,
          border: border,
          width: width,
          height: height,
          margin: margin,
          padding: padding,
          ...sx,
        }}
        {...otherProps}
      />
    );
  };

  // Render the chip based on the type and caption
  return createChip(chipType, propCaption);
};

export default CreateChip;

// CreateChip Component Documentation
// The CreateChip component is a reusable component that generates a Material-UI Chip by its chip type, allowing customization of its appearance and behavior.

// Props
// chipType (string): The type of chip to create, which determines its styling and icon based on a predefined mapping.
// caption (string): The text label displayed on the chip.
// image (string): URL of an image to be displayed as an avatar on the chip.
// sx (object): Additional custom styles applied to the chip.
// showPointer (boolean): If true, changes the cursor to a pointer on hover when the chip is not disabled.
// disabled (boolean or function): If true or a function that returns true, disables the chip interaction.
// variant (string): The variant of the chip (e.g., outlined, filled).
// size (string): The size of the chip (e.g., small, medium, large).
// color (string): The color of the chip (e.g., default, primary, secondary).
// avatar (element): An Avatar component or element to be displayed on the chip.
// ...otherProps (object): Any other props that should be passed to the Chip component.