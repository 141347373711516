import { Box, Typography, useMediaQuery } from "@mui/material";
import * as Icon from "@mui/icons-material";
import { CommonContext } from "../../../store/context/commonContext";

const ImageObject = {
    "name": "features_app_image.png",
    "alt_name": null,
    "file_type": "IMAGE",
    "size": "242761",
    "create_time": "2024-10-23 10:16:53",
    "update_time": "2024-10-23 10:16:53",
    "added_by": "2221",
    "folder_seq": "0",
    "tags": null,
    "url": "//farwide.s3.amazonaws.com/asset/6A7EPwRWA7DMlRQMxECh6_pU/2221/6718cd15403871729678613.png",
    "thumbnail_url": ""
}

const insertBreakAfterWord = (text, targetWord, isLargeScreen) => {
    if (!isLargeScreen || !targetWord) return text; // Apply only if it's a large screen and targetWord exists

    const parts = text.split(targetWord);
    if (parts.length === 1) return text; // If the targetWord is not found, return the original text
    return (
        <>
            {parts[0]}
            {targetWord} <br />
            {parts[1]}
        </>
    );
};



const AppInformation = () => {

    const isLargeScreen = useMediaQuery('(min-width:900px)'); // Screen size >= 900px
    const featureData = [
        {
            icon: (
                <img
                    src="/assets/images/icon_images/feature_1.svg"
                    alt="Book Properties and Experiences"
                    style={{ width: 42, height: 42 }}
                />
            ),
            text: 'Book Properties and Experiences',
            breakAfter: 'Properties', // Apply <br /> after this word
            description: 'Find and book your perfect outdoor adventure. Arrive and enjoy, leave the planning to us.',
        },
        {
            icon: (
                <img
                    src="/assets/images/icon_images/feature_3.svg"
                    alt="Book Properties and Experiences"
                    style={{ width: 42, height: 42 }}
                />
            ),
            text: 'Advanced Mapping Tools',
            breakAfter: 'Advanced', // Apply <br /> after this word
            description: 'Prepare in advance with our powerful tools: map your hunt area, set routes, explore boat access, trails, and more—all in a few clicks.',
        },
        {
            icon: (
                <img
                    src="/assets/images/icon_images/feature_2.svg"
                    alt="Book Properties and Experiences"
                    style={{ width: 42, height: 42 }}
                />
            ),
            text: 'Make Your Mark',
            breakAfter: 'Your', // Apply <br /> after this word
            description: 'Explore new routes and record with FarWide Trax. Save them to come back again or share with friends.',
        },
        {
            icon: (
                <img
                    src="/assets/images/icon_images/feature_4.svg"
                    alt="Book Properties and Experiences"
                    style={{ width: 42, height: 42 }}
                />
            ),
            text: 'Connect and Conserve',
            breakAfter: 'and', // Apply <br /> after this word
            description: 'Join a community of outdoor enthusiasts via our community Feed to share and learn. Support conservation efforts for land and wildlife management.',
        },
    ];
    return (
        <>
            <Box sx={{
                width: '100%',
                py: { xs: 7, md: 10.5 }
            }}>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: { xs: "left", sm: 'left', md: 'center' },
                        paddingInline: { xs: 3, sm: 3, md: 4 },
                        gap: 3
                    }}
                >
                    <Typography variant="h3" sx={{ textAlign: { xs: "left", sm: 'left', md: 'center' } }} >Find Access. Utilize Tools. {isLargeScreen && <br></br>}Connect with Others</Typography>
                    <Typography variant="h6" color='var(--grey900)' sx={{ textAlign: { xs: "left", sm: 'left', md: 'center' } }} >FarWide is the only resource you need to elevate your next outdoor experience.</Typography>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', sm: 'column', md: 'row' },
                        alignItems: { xs: 'center', sm: 'center', md: 'flex-start' },
                        gap: { xs: 6, sm: 6, md: 8 },
                        mt: { xs: 5.5, sm: 5.5, md: 7 },
                        paddingInline: { xs: 3, sm: 3, md: 4 }
                    }}
                >
                    <Box>
                        {featureData.slice(0, 2).map((feature, index) => (
                            <Box key={index} sx={{ mb: 6 }} >
                                <Box color="#26C77A" sx={{ width: 42, height: 42 }}>{feature.icon}</Box>
                                <Typography variant="h6" color="#1b1b1b" sx={{ mb: { md: 0.5 }, fontWeight: '700' }} >{insertBreakAfterWord(feature.text, feature.breakAfter, isLargeScreen)}</Typography>
                                <Typography variant="subtitle1" color="#747579" sx={{ mb: { sm: 3 }, color: '#85878A', maxWidth: 550 }} >{feature.description}</Typography>
                            </Box>
                        ))}
                    </Box>
                    <Box
                        sx={{
                            width: { xs: '320px ', sm: '320px', md: '100%' },
                        }}
                    >
                        <img
                            src={`//farwide.s3.amazonaws.com/asset/Eohb9sxTr3ZpKp-OpFds3bEz/2221/677b834a7ed1d1736147786.webp`}
                            alt="Mobile preview 1"
                            style={{
                                width: '100%',
                                height: 'auto',
                                display: 'block'  // Removes bottom spacing
                            }}
                        />
                    </Box>
                    <Box>
                        {featureData.slice(2, 4).map((feature, index) => (
                            <Box key={index} sx={{ mb: 6 }}>
                                <Box color="#26C77A" sx={{ width: 42, height: 42 }} >{feature.icon}</Box>
                                <Typography variant="h6" color="#1b1b1b" sx={{ mb: { md: 0.5 }, fontWeight: '700' }} >{insertBreakAfterWord(feature.text, feature.breakAfter, isLargeScreen)}</Typography>
                                <Typography variant="subtitle1" color="#747579" sx={{ mb: { sm: 3 }, color: '#85878A', maxWidth: 550 }} >{feature.description}</Typography>
                            </Box>
                        ))}
                    </Box>
                </Box>
            </Box>
        </>
    )
};
export default AppInformation