
import React, { useEffect, useState } from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";

import BookingBody from "../bookings/sections/BookingBody";

import { CommonContext } from "../../store/context/commonContext";
import { showZendeskLauncher } from "../../utils/common"

const Bookings = () => {
	const [bookingStatus, setBookingStatus] = useState(() => {
		const savedBookingStatus = localStorage.getItem("bookingStatus");
		return savedBookingStatus || "A"; // Default value "A" if not found in localStorage
	});
	const [isRecExists, setIsRecExistss] = useState(true)
	// const [selectedTab, setSelectedTab] = useState(0);
	const [selectedTab, setSelectedTab] = useState(() => {
		const savedTab = parseInt(localStorage.getItem("tab"));
		return isNaN(savedTab) ? 0 : savedTab;
	});

	const handleTabChange = (event, newValue) => {
		setSelectedTab(newValue);
		localStorage.setItem('tab', newValue);
	};

	const handleBookingStatusChange = (newStatus) => {
		setBookingStatus(newStatus);
		localStorage.setItem("bookingStatus", newStatus);
	};

	const { isTablet, isNineHundred } = CommonContext()

	// const [bookingStatus, setBookingStatus] = useState('A')
	useEffect(() => {
		showZendeskLauncher(true)
	}, [])

	// console.log("location", location.pathname)

	useEffect(() => {
		const handleBeforeUnload = () => {
			localStorage.removeItem("tab");
			localStorage.removeItem("bookingStatus");
		};

		window.addEventListener("beforeunload", handleBeforeUnload);

		return () => {
			window.removeEventListener("beforeunload", handleBeforeUnload);
		};
	}, []);

	// const setTabIndex = (newValue) => {
	// 	localStorage.setItem('tab value', newValue);
	// }



	return (
		<>
			<Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 2, }} >
				<Typography variant="h4">Bookings</Typography>
				{/* <Link to={ROUTE_SEARCH}>
					<Button variant="light" startIcon={<Icon.Search />} >Search</Button>
				</Link> */}
			</Box>
			<Box sx={!isTablet && { border: "1px solid var(--grey500)", borderRadius: theme => `${theme.card.borderRadius} `, maxWidth: { xs: '100%', sm: '100%', md: '100%' } }}>
				{isRecExists && <Tabs
					value={selectedTab}
					onChange={handleTabChange}
					variant="scrollable"
					scrollButtons="auto"
					sx={{
						borderBottom: "1px solid var(--grey500)",
						borderTopLeftRadius: theme => `${theme.card.borderRadius} `,
						borderTopRightRadius: theme => `${theme.card.borderRadius} `,
						width: "100%",
						display: "flex",
						justifyContent: "space-around",
						"& .MuiButtonBase-root": {
							fontWeight: '600'
						}
					}}
				>
					<Tab label="All" onClick={() => { setBookingStatus('A'); localStorage.setItem("bookingStatus", 'A'); }} />
					<Tab label="Upcoming" onClick={() => { setBookingStatus('R'); localStorage.setItem("bookingStatus", 'R'); }} />
					<Tab label="Checked-In" onClick={() => { setBookingStatus('CI'); localStorage.setItem("bookingStatus", 'CI'); }} />
					{/* <Tab label="Active" onClick={() => { setBookingStatus('O') }} /> */}
					<Tab label="Cancelled" onClick={() => { setBookingStatus('N'); localStorage.setItem("bookingStatus", 'N'); }} />
					<Tab label="Declined" onClick={() => { setBookingStatus('D'); localStorage.setItem("bookingStatus", 'D'); }} />
					<Tab label="Completed" onClick={() => { setBookingStatus('M'); localStorage.setItem("bookingStatus", 'M'); }} />
				</Tabs>}
				<BookingBody filter={selectedTab} bookingStatus={bookingStatus} isRecExists={isRecExists} setIsRecExistss={setIsRecExistss} />
			</Box>
		</>
	);
};

export default Bookings;
