import { Box, Button, Dialog, Divider, IconButton, Typography } from "@mui/material"
import * as Icon from '@mui/icons-material';
import { CommonContext } from "../../store/context/commonContext";
import LoaderButton from "../../commonComponents/LoaderButton";
import { useState } from "react";


const BookingCancellationPopup = ({ open, setOpen, handleCloseMainPopup, type, isLoadingButton }) => {
    const { isMobile } = CommonContext()
    let btnText = ''
    if (type === 'cancel') {
        btnText = 'Yes, Cancel'
    } else if (type === 'exit') {
        btnText = 'Exit Now'
    }

    return (
        <Dialog
            open={open}
            sx={{
                '& .MuiDialog-paper': {
                    maxWidth: 600,
                    width: '100%'
                },
            }}>
            <Box>
                <Box sx={{ p: 2.5, display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography variant="h6" sx={{ fontWeight: 700 }}>
                        Are you sure you want to {type} transaction?
                    </Typography>
                    <IconButton onClick={() => { setOpen(false) }}>
                        <Icon.Close />
                    </IconButton>
                </Box>
                <Divider />
                <Box sx={{ p: 2.5 }}>
                    <Typography>
                        This booking will not be completed if you {type} now and it may not be available later if the slots get sold out. <br /><br />
                        Are you sure you want to proceed?
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "end",
                        gap: 1,
                        p: 2.5,
                        width: "100%",
                        flexDirection: isMobile ? 'column' : 'row'
                    }}
                >
                    <Button
                        variant="text"
                        size="small"
                        color="secondary"
                        onClick={() => { setOpen(false) }}
                    >
                        Continue Booking
                    </Button>
                    <LoaderButton
                        variantType={"contained"}
                        size={"small"}
                        onClick={() => {
                            handleCloseMainPopup(false)
                            setOpen(false)
                            window.history.back()
                        }}
                        buttonText={btnText}
                        isLoading={isLoadingButton}
                    />
                </Box>
            </Box>
        </Dialog>
    )
}

export default BookingCancellationPopup