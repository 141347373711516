import { createContext, useContext, useEffect, useRef, useState } from "react";

//  ADDED BELOW LOGIC TO SHOW PREVIOUSLY SELECTED SEARCH TAB AND FILTER IN HEADER AFTER USER REFRSHES PAGE ALSO SELECT KW IF IT IS PRESENT IN URL

let url = new URL(window.location.href);
let isActivityPresent = url.searchParams.get('at')
let isMndPresent = url.searchParams.get('mnd')
let isMxdPresent = url.searchParams.get('mxd')
let isMnpPresent = url.searchParams.get('mnp')
let isMxpPresent = url.searchParams.get('mxp')
let isEatPresent = url.searchParams.get('eat')
let isSpeciesPresent = url.searchParams.get('sp')
let isStartDatePresent = url.searchParams.get('d1')
let isEndDatePresent = url.searchParams.get('d2')
let isSortPresent = url.searchParams.get('sb')
let activitySelected;
let searchkeyword;
let mnd;
let mxd;
let mnp;
let mxp;
let eat;
let d1;
let d2;
let sp;
let sb;


if (isActivityPresent === '' || isActivityPresent === null) {
    activitySelected = 'access'
    if (url.searchParams.get('kw') === null) {
        searchkeyword = ''
    } else {
        searchkeyword = url.searchParams.get('kw')
    }
} else {
    activitySelected = isActivityPresent
    searchkeyword = url.searchParams.get('kw')
}

if (isMndPresent === '' || isMndPresent === null) {
    mnd = ''
} else {
    mnd = url.searchParams.get('mnd')
}

if (isMxdPresent === '' || isMxdPresent === null) {
    mxd = ''
} else {
    mxd = url.searchParams.get('mxd')
}

if (isMnpPresent === '' || isMnpPresent === null) {
    mnp = ''
} else {
    mnp = url.searchParams.get('mnp')
}

if (isMxpPresent === '' || isMxpPresent === null) {
    mxp = ''
} else {
    mxp = url.searchParams.get('mxp')
}

if (isStartDatePresent === '' || isStartDatePresent === null) {
    d1 = ''
} else {
    d1 = url.searchParams.get('d1')
}

if (isEndDatePresent === '' || isEndDatePresent === null) {
    d2 = ''
} else {
    d2 = url.searchParams.get('d2')
}

if (isEatPresent === '' || isEatPresent === null) {
    eat = ''
} else {
    eat = url.searchParams.get('eat')
}

if (isSpeciesPresent === '' || isSpeciesPresent === null) {
    sp = ''
} else {
    sp = url.searchParams.get('sp')
}

if (isSortPresent === '' || isSortPresent === null) {
    sb = 'R'
} else {
    sb = url.searchParams.get('sb')
}


const defaultProvider = {
    isMobile: null,
    isTablet: null,
    isNineHundred: null,
    screenWidth: 0,
    scroll: 0,
    searchFilters: {
        activity: activitySelected, // Changed it from "A" to "activitySelected" while working on new search drop down menu
        kw: searchkeyword,
        start_date: d1,
        end_date: d2,
        species: sp,
        amenties: [],
        sort: sb,
        mnd: mnd,
        mxd: mxd,
        mnp: mnp,
        mxp: mxp,
        eat: eat
    },
    setSearchFilters: () => { },
    isMessagesDashboard: true,
    isShowMessages: false,
    isProfile: false,
}


const commonContext = createContext(defaultProvider);


const CommonContextProvider = ({ children }) => {
    const [width, setWidth] = useState(window.innerWidth);
    const [scroll, setScroll] = useState(0);
    const [searchFilters, setSearchFilters] = useState(defaultProvider.searchFilters);
    const [isMessagesDashboard, setIsMessageDashboard] = useState(true);
    const [isShowMessages, setIsShowMessages] = useState(false);
    const [isProfile, setIsProfile] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [detail, setDetail] = useState({});
    const [start, setStart] = useState(0);
    const listContainerRef = useRef(null);
    const [seqValue, setSeqValue] = useState("");
    // const [refDetected, setRefDetected] = useState("")
    const scrollableDivRef = useRef(null);
    const [toBottom, setToBottom] = useState(true)
    const [newMessageReceivedStatus, setNewMessageReceivedStatus] = useState("")
    const [lastMessageseq, setLastMessageseq] = useState()
    const [updateConnectionListMessage, setUpdateConnectionListMessage] = useState({
        message: "",
        receiver_seq: ""
    })
    // console.log("listContainerRef", listContainerRef)
    const [userData, setUserData] = useState({
        u_seq: "",
        u_type: ""
    })

    const [senderSeqToSendChat, setSenderSeqToSendChat] = useState("")
    const [senderTypeToSendChat, setSenderTypeToSendChat] = useState("")
    const [newUserMessageReceivedStatus, setNewUserMessageReceivedStatus] = useState(false)
    const [isNewMessage, setIsNewMessage] = useState(false)
    const [profilePhoto, setProfilePhoto] = useState("")
    const [userDetail, setuserDetail] = useState({});
    const [reviewPresent, setReviewPresent] = useState()
    const [errorMessage, setErrorMessage] = useState('')
    const [isListingPresent, setIsListingPresent] = useState(false)
    const [isStripeAdded, setIsStripeAdded] = useState(false)
    const [showSearchType, setShowSearchType] = useState('')
    const [value, setValue] = useState(0);
    const [clikedBanner, setClikedBanner] = useState(false)
    const [OpenExpEnquiryPopup, setOpenExpEnquiryPopup] = useState(false)
    const [filterChanged, setFilterChanged] = useState(false)
    const [durationFromContext, setDurationFromContext] = useState([mnd, mxd])
    const [priceFromContext, setPriceFromContext] = useState([mnp, mxp])
    const [datesFromContext, setDatesFromContext] = useState([d1, d2])
    const [openNavigationDrawer, setOpenNavigationDrawer] = useState(false);
    const [clearFiltersToggle, setClearFilterToggle] = useState(false)
    const [showManagePopUp, setShowManagePopUp] = useState(true)
    const [isAdminManagedAccount, setIsAdminManagedAccount] = useState(null)
    const [isManageAccountSelected, setIsManageAccountSelected] = useState(false)
    const [userProfileDetails, setUserProfileDetails] = useState({})


    const toggleNavigationDrawer = (newOpen) => () => {
        setOpenNavigationDrawer(newOpen);
    };




    //   console.log("limit", limit, start)

    // console.log("SenderSequence : ", senderSeqToSendChat)
    function setFilters(filter) {
        setSearchFilters({ ...searchFilters, ...filter })
    }

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    function handleScroll() {
        setScroll(window.scrollY);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        window.addEventListener('scroll', handleScroll, { passive: true });

        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
            window.removeEventListener('scroll', handleScroll);
        }
    }, []);

    // useEffect(()=> {
    // 	console.log("Common page filters", searchFilters);
    // 	console.log("Common page type", showSearchType);
    // },[searchFilters, showSearchType])

    const isMobile = width <= 480;
    const isTablet = width <= 768;
    const isNineHundred = width <= 900;
    const isLoginHamburger = width <= 1010;

    const values = {
        isMobile,
        searchFilters,
        isTablet,
        isNineHundred,
        isLoginHamburger,
        scroll,
        setSearchFilters: setFilters,
        screenWidth: width,
        isMessagesDashboard,
        setIsMessageDashboard,
        isShowMessages,
        setIsShowMessages,
        isProfile,
        setIsProfile,
        isLoading,
        setIsLoading,
        userData, setUserData,
        listContainerRef,
        start, setStart, senderSeqToSendChat, setSenderSeqToSendChat, setDetail, detail,
        newMessageReceivedStatus,
        setNewMessageReceivedStatus, scrollableDivRef,
        // refDetected,setRefDetected
        toBottom, setToBottom,
        updateConnectionListMessage, setUpdateConnectionListMessage,
        senderTypeToSendChat, setSenderTypeToSendChat,
        seqValue, setSeqValue,
        lastMessageseq, setLastMessageseq,
        newUserMessageReceivedStatus, setNewUserMessageReceivedStatus,
        isNewMessage, setIsNewMessage,
        profilePhoto, setProfilePhoto,
        userDetail, setuserDetail,
        reviewPresent, setReviewPresent,
        errorMessage, setErrorMessage,
        isListingPresent, setIsListingPresent, isStripeAdded, setIsStripeAdded,
        showSearchType, setShowSearchType, value, setValue,
        clikedBanner, setClikedBanner,
        OpenExpEnquiryPopup, setOpenExpEnquiryPopup,
        filterChanged, setFilterChanged,
        durationFromContext, setDurationFromContext,
        priceFromContext, setPriceFromContext,
        datesFromContext, setDatesFromContext,
        openNavigationDrawer, setOpenNavigationDrawer,
        toggleNavigationDrawer, clearFiltersToggle, setClearFilterToggle,
        showManagePopUp, setShowManagePopUp,
        isAdminManagedAccount, setIsAdminManagedAccount,
        isManageAccountSelected, setIsManageAccountSelected,
        userProfileDetails, setUserProfileDetails
    }

    //    console.log("userData", userData)

    return (<commonContext.Provider value={values}>{children}</commonContext.Provider>)
}

const CommonContext = () => {
    return useContext(commonContext)
}
// export { UserProvider };
export { CommonContext, CommonContextProvider }
