import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
} from "@mui/material";
import { UserAuth } from "../../../store/context/userContext";
import GreyLabel from "../../../commonComponents/formElement/GreyLabel";
import { toast } from "react-toastify";
import UserService from "../../../services/user";
import { toastMessage } from "../../../utils/common";
import errorAndHelperText from "../../../commonComponents/CommonConstants/errorAndHelperText.json"

const ChangePassword = () => {
  // Get user data from the UserAuth context
  const { user } = UserAuth();

  // State hooks for storing new password, confirm password, and validation errors
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [passwordError, setPasswordError] = useState(""); // Error for new password
  const [cnfpasswordError, setcnfPasswordError] = useState(""); // Error for confirm password
  const [passwordsMatchError, setPasswordsMatchError] = useState(false); // Error for mismatching passwords

  // Handle password change when the user submits the form
  const handlePasswordChange = () => {
    // Validate password using regex (password must be at least 8 characters long)
    const passwordRegex = /^(?=.*[a-zA-Z0-9]).{8,}$/;
    if (!passwordRegex.test(newPassword)) {
      setPasswordError('Password must be at least 8 characters long');
      return; // Stop execution if validation fails
    }

    // Check if the passwords match
    if (newPassword !== confirmNewPassword) {
      // Set error if passwords do not match
      setPasswordsMatchError(true);
      return; // Stop execution if passwords do not match
    } else {
      setPasswordsMatchError(false); // Reset mismatch error if passwords match
    }

    // If validation passes, call the service to update the password
    UserService.updatePassword(newPassword, (data) => {
      // Show a success toast notification
      // toast.success('Password Changed Successfully', {
      //   theme: 'colored',
      // });
      toastMessage(errorAndHelperText.password_change.success)
    });

    // Reset the password fields after successful change
    setNewPassword("");
    setConfirmNewPassword("");
  };

  return (
    <Box sx={{ border: " 1px solid #E5E7EB", borderRadius: "16px" }}>
      {/* Header section displaying the page title and user's email */}
      <Box>
        <Typography
          sx={{
            color: "#1B1B1B",
            fontSize: "28px",
            fontWeight: "800",
            borderBottom: "1px solid #C5C5C7",
            p: { xs: 2, sm: 2, md: 3, lg: 3 },
          }}
        >
          Change Password
          <Typography
            sx={{
              color: "#1B1B1B",
              marginBottom: "10px",
              fontSize: "14px",
            }}
          >
            Your current Account Id -&nbsp;
            <span style={{ color: "#26C77A" }}>{user.email}</span>
          </Typography>
        </Typography>
      </Box>

      {/* Main form for changing password */}
      <Box sx={{ p: { xs: 2, sm: 2, md: 3, lg: 3 } }}>
        {/* Label and input for the new password */}
        <GreyLabel label={"New password"} sx={{ mb: 0.5 }} />
        <TextField
          fullWidth
          value={newPassword}
          autoComplete="new-password" // Disable browser's password suggestion
          onChange={(e) => {
            // Update state and clear any existing errors when the user types
            setNewPassword(e.target.value);
            setPasswordError(""); // Reset password error
          }}
          sx={{ mb: 2 }}
          size="small"
          type="password"
          error={passwordError !== ""} // Show error if password validation fails
          helperText={passwordError} // Display the error message
          InputProps={{
            autoComplete: 'new-password', // Disable autocomplete for passwords
          }}
        />

        {/* Label and input for confirming the new password */}
        <GreyLabel label={"Confirm Password"} sx={{ mb: 0.5 }} />
        <TextField
          fullWidth
          value={confirmNewPassword}
          autoComplete="new-password" // Disable browser's password suggestion
          onChange={(e) => {
            // Update state and clear any errors when the user types
            setConfirmNewPassword(e.target.value);
            setcnfPasswordError(""); // Reset confirm password error
            setPasswordsMatchError(false); // Reset mismatch error
          }}
          type="password"
          error={cnfpasswordError !== "" || passwordsMatchError} // Show error if confirm password has an issue
          helperText={cnfpasswordError || (passwordsMatchError && "Passwords do not match")} // Display relevant error message
          InputProps={{
            autoComplete: 'new-password', // Disable autocomplete for passwords
          }}
          size="small"
        />

        {/* Button to submit the password change request */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            pt: 3,
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={handlePasswordChange} // Call the password change handler on click
          >
            Save Changes
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default ChangePassword;
