import React, { useEffect, useState, Suspense, lazy } from "react";
import { Box, Typography } from "@mui/material";
import Transactiontable from "./sections/Transactiontable";
import { showZendeskLauncher } from "../../utils/common";
import config from "../../config";

// Use React.lazy to dynamically import ListingPopUp
const ListingPopUp = config.module === 'lister'
  ? lazy(() => import('../../pages/home/sections/listingPopUp'))
  : null;

const Transactions = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    showZendeskLauncher(true);
  }, []);

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          border: "1px solid #fff",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 1,
        }}
      >
        <Typography variant="h4">Transactions</Typography>
      </Box>

      <Box
        sx={{
          border: "1px solid #E5E7EB",
          borderRadius: "16px",
          width: "100%",
        }}
      >
        <Transactiontable setOpen={setOpen} />
      </Box>

      {ListingPopUp && (
        <Suspense fallback={<div>Loading...</div>}>
          <ListingPopUp open={open} setOpen={setOpen} />
        </Suspense>
      )}
    </Box>
  );
};

export default Transactions;
