import { Box, IconButton, Paper, Popper, Typography, styled } from "@mui/material";
import { useRef, useState } from "react";
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import * as Icon from '@mui/icons-material';
import { TOOLTIP_TEXT_FONTSIZE } from "../../../CommonConstants/commonKeys";





const CustomPopper = styled(Popper, {
    shouldForwardProp: (prop) => prop !== 'arrow',
})(({ theme, arrow }) => ({
    zIndex: 9999,
    '& > div': {
        position: 'relative',
    },
    '&[data-popper-placement*="bottom"]': {
        '& > div': {
            marginTop: arrow ? 2 : 0,
        },
        '& .MuiPopper-arrow': {
            top: 0,
            left: 0,
            marginTop: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '0 1em 1em 1em',
                borderColor: `transparent transparent ${theme.palette.background.paper} transparent`,
            },
        },
    },
    '&[data-popper-placement*="top"]': {
        '& > div': {
            marginBottom: arrow ? 2 : 0,
        },
        '& .MuiPopper-arrow': {
            bottom: 0,
            left: 0,
            marginBottom: '-0.9em',
            width: '3em',
            height: '1em',
            '&::before': {
                borderWidth: '1em 1em 0 1em',
                borderColor: `${theme.palette.background.paper} transparent transparent transparent`,
            },
        },
    },
    '&[data-popper-placement*="right"]': {
        '& > div': {
            marginLeft: arrow ? 2 : 0,
        },
        '& .MuiPopper-arrow': {
            left: 0,
            marginLeft: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 1em 1em 0',
                borderColor: `transparent ${theme.palette.background.paper} transparent transparent`,
            },
        },
    },
    '&[data-popper-placement*="left"]': {
        '& > div': {
            marginRight: arrow ? 2 : 0,
        },
        '& .MuiPopper-arrow': {
            right: 0,
            marginRight: '-0.9em',
            height: '3em',
            width: '1em',
            '&::before': {
                borderWidth: '1em 0 1em 1em',
                borderColor: `transparent transparent transparent ${theme.palette.background.paper}`,
            },
        },
    },
}));

const Arrow = styled('div')({
    position: 'absolute',
    fontSize: 7,
    width: '3em',
    height: '3em',
    '&::before': {
        content: '""',
        margin: 'auto',
        display: 'block',
        width: 0,
        height: 0,
        borderStyle: 'solid',
        // boxShadow : '0px -1px 3px -1px rgba(0,0,0,0.2), 0px 0px 0px 0px rgb(0 0 0 / 14%), 1px 2px 4px 0px rgb(10 10 10 / 42%)'
    },
});
// ... (previous imports)

const SystemToolTip = ({ content }) => {
    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);
    const [flip, setFlip] = useState({
        enabled: true,
        altBoundary: true,
        rootBoundary: 'document',
    });
    const [preventOverflow, setPreventOverflow] = useState({
        enabled: true,
        altAxis: true,
        altBoundary: true,
        tether: true,
        rootBoundary: 'document',
    });
    const [arrowRef, setArrowRef] = useState(null);

    const handleMouseEnter = () => {
        setOpen(true);
    };

    const handleMouseLeave = () => {
        setOpen(false);
    };

    const id = open ? 'scroll-playground' : null;

    return (
        <Box sx={{ position: 'relative' }}>
            <IconButton
                ref={anchorRef}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
                aria-describedby={id}
                sx={{ p: 0 }}
            >
                <Icon.SmartToyOutlined fontSize="small" />
            </IconButton>
            <CustomPopper
                id={id}
                open={open}
                arrow={true}
                anchorEl={anchorRef.current}
                placement={"top"}
                disablePortal={false}
                modifiers={[
                    {
                        name: 'flip',
                        enabled: flip.enabled,
                        options: {
                            altBoundary: flip.altBoundary,
                            rootBoundary: flip.rootBoundary,
                            padding: 8,
                        },
                    },
                    {
                        name: 'preventOverflow',
                        enabled: preventOverflow.enabled,
                        options: {
                            altAxis: preventOverflow.altAxis,
                            altBoundary: preventOverflow.altBoundary,
                            tether: preventOverflow.tether,
                            rootBoundary: preventOverflow.rootBoundary,
                            padding: 8,
                        },
                    },
                    {
                        name: 'arrow',
                        enabled: true,
                        options: {
                            element: arrowRef,
                        },
                    },
                ]}
            >
                <div>
                    <Arrow ref={setArrowRef} className="MuiPopper-arrow" />
                    <Paper sx={{ maxWidth: 'fit-content', overflow: 'auto', boxShadow: '0px -1px 3px -1px rgba(0,0,0,0.2), 0px 0px 0px 0px rgb(0 0 0 / 14%), 1px 2px 4px 0px rgb(10 10 10 / 42%)' }}>
                        <Box sx={{ p: 1.5 }}>
                            <Typography sx={{ fontSize: TOOLTIP_TEXT_FONTSIZE, color: '#85878A' }}>
                                {content}
                            </Typography>
                        </Box>
                    </Paper>
                </div>
            </CustomPopper>
        </Box>
    );
};

export default SystemToolTip;
