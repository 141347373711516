/* 
    put common keynames here 
    Put the keys in following structure
    Lister or Guest => PageName 
    Put the keys in common group to be identifiable lator
*/

// Lister => Create Experience common Keys

export const FROM_TIME = 'from_time'
export const TO_TIME = 'to_time'
export const RESPONSIVE_SPACING = { xs: 2, sm: 2, md: 3, lg: 3 }

export const EXPERIENCE = {
    string: 'Experience',
    value: 'experience'
}

export const ACCESS = {
    string: 'Access',
    value: 'access',
}
// common logo size for 
// Guest => Landing page layout (sidebar and header), Dashboard page layout (sidebar and header), Search page layout (sidebar and header)
// Lister => Landing page layout (sidebar and header), Dashboard page layout (sidebar and header)
// Common => Signup and Sign in, Transaction Details
export const SMALL_LOGO_SIZE = { width: '160px' } // mobile logo size
export const DEFAULT_LOGO_SIZE = { width: '172px' } // default logo size

export const ACCOUNT_VERIFICATION_TEXTS = {
    "lister_experience" : "Complete account setup to submit and publish this experience",
    "lister_common" : "Account setup is mandatory to publish your listing - takes only a few minutes.",
    "guest_common" : "This step is quick and easy to complete.",
    "guest_booking" : "This step is quick and easy to complete."
}
export const MAX_WORD_COUNT_LIMIT = 1200;


// common font size for text inside tooltip

export const TOOLTIP_TEXT_FONTSIZE = '14px'