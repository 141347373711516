import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import * as Icon from "@mui/icons-material";
import { IconButton, ListItemButton, ListItemIcon, ListItemText, Toolbar, Tooltip, Typography } from '@mui/material';
import { UserAuth } from '../../../store/context/userContext';
import AuthPopup from '../../../commonComponents/authComponents/AuthPopup';
import LinkHref from '@mui/material/Link'
import { ROUTE_LISTER_HOME } from '../../../constants/page';
import { empty, extractDataFromArray } from '../../../utils/common';
import { CommonContext } from '../../../store/context/commonContext';
import { Link, useLocation } from 'react-router-dom';
import config from '../../../config';
import { DEFAULT_LOGO_SIZE, SMALL_LOGO_SIZE } from '../../../commonComponents/CommonConstants/commonKeys';
import { currentYear } from "../../../utils/common"




const sideBarItems = [
    {
        link: config.farwideBaseUrl,
        label: 'Home',
        icon: <Icon.HomeOutlined />
    },
    {
        link: '/app',
        label: 'App',
        icon: {
            default: '/assets/images/icon_images/sidebar_app_icon_.svg',
            selected: '/assets/images/icon_images/sidebar_app_icon_active.svg'
        },
        target: '_blank'
    },
    // {
    //     link: config.magazineURL,
    //     label: 'Blog',
    //     icon: <Icon.DescriptionOutlined />,
    //     target: '_blank'
    // },
    {
        link: '/conservationtrust',
        label: 'Conservation Trust',
        icon: {
            default: '/assets/images/icon_images/mountain.svg',
            selected: '/assets/images/icon_images/mountain_active.svg'
        },
        target: '_blank'
    },
    {
        link: config.searchURL + "Missouri&isExplore=true&fetchLR=true&lat=38.3046615&lon=-92.437099&zl=4",
        label: 'Mapping & Tools',
        icon: {
            default: '/assets/images/icon_images/mapping_tools.svg',
            selected: '/assets/images/icon_images/mapping_tools_active.svg'
        },
        target: '_blank'
    },
    {
        link: '/ourstory',
        label: 'Our Story',
        icon: {
            default: '/assets/images/icon_images/our_story_farwide_logo_grey.svg',
            selected: '/assets/images/icon_images/our_story_farwide_logo_green.svg'
        },
        target: '_blank'
    },
]

const contactUs = [
    {
        link: window.location.origin + "/contactus",
        label: 'Contact FarWide',
        icon: <Icon.EmailOutlined />
    },
    {
        link: config.privacyTermsLink,
        label: 'Terms & Conditions',
        icon: <Icon.DescriptionOutlined />,
        target: '_blank'
    }
]

const LandingPageDrawer = () => {

    const { user, authPopup, setAuthPopup } = UserAuth()
    const { isTablet, toggleNavigationDrawer, openNavigationDrawer } = CommonContext();
    const location = useLocation();

    // console.log("window location", window.location.origin);

    const isSelected = (itemLink) => {
        const currentPath = location.pathname;
        const currentOrigin = window.location.origin;

        // For app page
        if (itemLink === '/app') {
            return currentPath.startsWith('/app');
        }

        // For conservation trust page
        if (itemLink === '/conservationtrust') {
            return currentPath.startsWith('/conservationtrust');
        }

        // For the contact page
        if (itemLink === `${currentOrigin}/contactus`) {
            return currentPath.startsWith('/contactus');
        }

        // For the our story page
        if (itemLink === '/ourstory') {
            return currentPath.startsWith('/ourstory');
        }

        // For the home page
        if (itemLink === config.farwideBaseUrl) {
            // Only highlight home when we're exactly on '/' 
            // and not on any other paths
            return currentPath === '/' &&
                !currentPath.startsWith('/app') &&
                !currentPath.startsWith('/conservationtrust') &&
                !currentPath.startsWith('/contactus') &&
                !currentPath.startsWith('/ourstory');
        }

        // For other internal pages (if any)
        if (itemLink.startsWith(currentOrigin)) {
            return currentPath.startsWith(new URL(itemLink).pathname);
        }

        // For external links (blog, mapping tools, etc.)
        return false;
    };



    const openAuthPopup = (type) => {
        setAuthPopup({ ...authPopup, type: type, open: true })
    }
    const closeAuthPopup = () => {
        setAuthPopup({ ...authPopup, open: false })
    }

    const handleItemClick = () => {
        // console.log('handleitemclick')
        if (!isTablet)
            return
        else
            toggleNavigationDrawer()
    }

    const DrawerList = (
        <Box sx={{ minWidth: 292, mt: 3, marginInline: 3, mb: 3, height: '100%' }} role="presentation" onClick={toggleNavigationDrawer(false)}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    // height: 'calc( 100vh - 273px )'
                    height: '100%',
                    gap: 5
                }}
            >
                <Box>
                    <Box>

                        {
                            empty(user) &&
                            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", gap: 1, mb: 3 }}>
                                <LinkHref href={ROUTE_LISTER_HOME} sx={{ color: 'var(--black)', width: "100%", textAlign: "center" }}><Button variant="link" color='var(--black)' sx={{ textDecoration: 'none' }}>List on FarWide </Button></LinkHref>
                                <Button fullWidth size='small' variant="outlined" onClick={() => { openAuthPopup('signin') }} sx={{ fontSize: "16px !important" }}>Sign In</Button>
                                <Button fullWidth size='small' variant="contained" onClick={() => { openAuthPopup('signup') }} sx={{ fontSize: "16px !important" }}>Sign Up</Button>
                            </Box>
                        }
                        <Box>
                            {sideBarItems.map((item, index) => {
                                return (
                                    <Link to={item.link} style={{ textDecoration: "none", }} key={index} target={extractDataFromArray(item, ['target'], '')} >

                                        {/* <Tooltip title={item.label} placement="right"> */}
                                        <ListItemButton
                                            selected={isSelected(item.link)}
                                            onClick={handleItemClick}
                                            sx={{
                                                mb: 0.5,
                                                height: 40,
                                                paddingLeft: 1,
                                                display: 'flex',
                                                justifyContent: 'center',
                                                '& .MuiListItemButton-root': {
                                                    backgroundColor: 'red !important'
                                                },
                                                "&:hover": {
                                                    backgroundColor: '#F5F5F6 !important'
                                                },
                                                "&.Mui-selected": { backgroundColor: '#F5F5F6 !important' },
                                                backgroundColor: '#FFFFFF !important'
                                            }}
                                        >
                                            {/* Handle different types of icons */}
                                            {(() => {
                                                // For Material-UI icons
                                                if (React.isValidElement(item.icon)) {
                                                    return (
                                                        <ListItemIcon
                                                            sx={{
                                                                mr: 0,
                                                                color: isSelected(item.link) ? '#26C77A' : '#949494',
                                                                "&.Mui-selected": { color: '#26C77A !important' },
                                                            }}
                                                        >
                                                            {item.icon}
                                                        </ListItemIcon>
                                                    );
                                                }
                                                // For image icons
                                                return (
                                                    <Box sx={{
                                                        ml: "0px !important",
                                                        width: '24px',
                                                        height: '24px',
                                                    }}>
                                                        <img
                                                            src={isSelected(item.link) ? item.icon.selected : item.icon.default}
                                                            alt={`${item.label} icon`}
                                                            style={{
                                                                width: '24px',
                                                                height: '24px',
                                                            }}
                                                        />
                                                    </Box>
                                                );
                                            })()}

                                            {< ListItemText primary={item.label}
                                                sx={{
                                                    ml: 1,
                                                    // "& .MuiListItemText-primary": { fontWeight: 600, color: '#1B1B1B' },
                                                    '& .MuiTypography-root': {
                                                        fontWeight: 600,
                                                        color: isSelected(item.link) ? '#26C77A' : '#1B1B1B'
                                                    }
                                                }} />}
                                        </ListItemButton>
                                        {/* </Tooltip> */}

                                    </Link>
                                )
                            })}
                        </Box>
                    </Box>

                </Box>
                <Box>
                    <Box
                        className="WRAPPER"
                        sx={{
                            // display: 'flex',
                            // flexDirection: 'column',
                            // justifyContent: 'space-between',
                            // // height: 'calc( 100vh - 273px )'
                            // height: '100%'
                        }}
                    >

                        <Box
                            sx={{
                                // position: 'fixed',
                                // bottom: 0,
                                marginBottom: 3
                            }}
                        >
                            {contactUs.map((item, index) => {
                                return (
                                    <Link to={item.link} style={{ textDecoration: "none", }} key={index} target={extractDataFromArray(item, ['target'], '')} >

                                        {/* <Tooltip title={item.label} placement="right"> */}
                                        <ListItemButton
                                            selected={isSelected(item.link)}
                                            onClick={handleItemClick}
                                            sx={{
                                                mb: 0.5,
                                                height: 40,
                                                display: 'flex',
                                                paddingLeft: 1,
                                                justifyContent: 'center',
                                                '& .MuiListItemButton-root': {
                                                    backgroundColor: 'red !important'
                                                },
                                                "&:hover": {
                                                    backgroundColor: '#F5F5F6 !important'
                                                },
                                                "&.Mui-selected": { backgroundColor: '#F5F5F6 !important' },
                                                backgroundColor: '#FFFFFF !important'
                                            }}
                                        >
                                            <ListItemIcon sx={{ mr: 0, color: isSelected(item.link) ? '#26C77A' : '#949494', "&.Mui-selected": { color: 'red !important' }, }}>{item.icon}</ListItemIcon>
                                            {< ListItemText primary={item.label}
                                                sx={{
                                                    ml: 1,
                                                    // "& .MuiListItemText-primary": { fontWeight: 600, color: '#1B1B1B' },
                                                    '& .MuiTypography-root': {
                                                        fontWeight: 600,
                                                        color: isSelected(item.link) ? '#26C77A' : '#1B1B1B'
                                                    }
                                                }} />}
                                        </ListItemButton>
                                        {/* </Tooltip> */}

                                    </Link>
                                )
                            })}
                            <Box
                                sx={{
                                    mb: 4
                                }}
                            >
                                <Divider sx={{ width: '100%' }} />
                            </Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    gap: 3,
                                    mb: 4
                                }}
                            >
                                <Link className="logo image" to="https://www.instagram.com/farwide_outdoors/" target='_blank' >
                                    <Box><img src='../assets/images/instagram.svg' alt='FarWide' /></Box>
                                    {/* <Box className="fw_logo_mob" sx={{ display: { xs: 'block', sm: 'block', md: 'none' }, width: '40px' }}><img src='../assets/images/homepage/farwide-logo.svg' alt='FarWide' /></Box> */}
                                </Link>
                                <Link className="logo image" to="https://www.facebook.com/farwideoutdoors/" target='_blank'>
                                    <Box ><img src='../assets/images/facebook-black.svg' alt='FarWide' /></Box>
                                    {/* <Box className="fw_logo_mob" sx={{ display: { xs: 'block', sm: 'block', md: 'none' }, width: '40px' }}><img src='../assets/images/homepage/farwide-logo.svg' alt='FarWide' /></Box> */}
                                </Link>
                            </Box>
                        </Box>
                    </Box>
                    <Typography variant="caption" sx={{ color: '#949494' }}> &copy; {currentYear()} &nbsp; FarWide Technologies. All rights reserved.</Typography>
                </Box>
            </Box>
            <AuthPopup open={authPopup.open} type={authPopup.type} handleClose={() => { closeAuthPopup(false) }} handleOpen={openAuthPopup} />
        </Box>
    );

    return (
        <Box>

            <Drawer open={openNavigationDrawer} onClose={toggleNavigationDrawer(false)} sx={{ zIndex: 9999999 }}>
                <Toolbar

                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        position: 'sticky',
                        top: 0,
                        backgroundColor: 'white',
                        zIndex: 1
                        // px: 2,
                        // height: '72px',
                    }}
                >
                    <Link to="/" onClick={toggleNavigationDrawer(false)}>
                        <Box sx={{ ml: "0px !important", display: 'flex' }}><img style={isTablet ? SMALL_LOGO_SIZE : DEFAULT_LOGO_SIZE} src='/landowner/assets/images/farwide-new-logo.svg' alt='FarWide' /></Box>
                    </Link>
                    <IconButton onClick={toggleNavigationDrawer(false)} sx={{ p: 0 }}>
                        <Icon.CloseOutlined sx={{ color: '#000000', p: 0 }} />
                    </IconButton>
                </Toolbar>
                <Divider />
                {DrawerList}
            </Drawer>
        </Box>
    );
}
export default LandingPageDrawer;