import * as React from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import * as Icon from "@mui/icons-material";
import { ROUTE_CREATE_PROPERTY } from "../../../constants/page";
import { Link } from "react-router-dom";
import MediaCard from "../../previewPageComponents/mediaCard";

const Introduction = ({ ourstorydata }) => {
    return (
        <Grid sx={{ px: { xs: 2, lg: 3 }, }}>
            <Grid item xs={12} sm={12} md={12}>
                <Box
                    className=""
                    sx={{
                        borderRadius: 2,
                        paddingTop: { lg: 10, xs: 5, sm: 5 },
                        // paddingBottom: { lg: 10, xs: 7.5, sm: 10 },
                    }}
                >
                    <Grid container spacing={5}>
                        {ourstorydata?.cardsSection?.cards?.map((card, index) => (
                            <Grid item container display="flex" xs={12} sm={4} md={4} key={index}>
                                <Box
                                    sx={{
                                        width: {
                                            sm: "100%",
                                            md: '100%'
                                        },
                                        // maxWidth: 367,
                                        pb: { xs: 0, sm: 0,  },
                                    }}
                                >
                                    <Box>
                                        <Box>
                                            <Box
                                                className="IMAGEEE"
                                                sx={{
                                                    pb: 4
                                                }}
                                            >
                                                <MediaCard
                                                    imageObj={card.media}
                                                    width='100%'
                                                    height='253px'
                                                    borderRadius={'16px'}
                                                />
                                            </Box>
                                            <Typography
                                                variant="h5"
                                                sx={{
                                                    color: '#1B1B1B',
                                                    fontWeight: "bold",
                                                    mb: 1.5,
                                                }}
                                            >
                                                {card.heading}
                                            </Typography>
                                            <Typography
                                                variant="h6"
                                                sx={{
                                                    color: "#1B1B1B",
                                                    fontWeight: 400,
                                                    fontSize: '1rem'
                                                }}>
                                                {card.description}
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                    {/* <Box
                        sx={{
                            mt: 4,
                            justifyContent: "center",
                            display: "flex",
                        }}
                    >
                        <Box>
                            <Link
                            to={ROUTE_CREATE_PROPERTY} 
                            >
                                <Button
                                    variant="outlined"
                                    sx={{ textTransform: "none", fontSize: "17px" }}
                                >

                                    View More
                                </Button>
                            </Link>
                        </Box>
                    </Box> */}
                </Box>
            </Grid>
        </Grid>
    );
};

export default Introduction;
