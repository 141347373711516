import { Box, Typography } from "@mui/material";
import * as Icon from '@mui/icons-material';
import { empty, getActivityType } from "../../utils/common";
import { Link, useNavigate } from "react-router-dom";
import { CommonContext } from "../../store/context/commonContext";
import { ACCESS, EXPERIENCE } from "../CommonConstants/commonKeys";
import MediaCard from "../previewPageComponents/mediaCard";


const PackageCardSmall = ({ image, title, address, rating, review, size = '', nearest_town, width = "60%", slug, area, activity_type, propertyName, listingSlug, listing_seq, type }) => {
    const navigate = useNavigate()
    const isMobile = CommonContext()
    rating = Number(rating)
    review = Number(review)

    return (
        <Box sx={{ p: { xs: 1, sm: 1, md: 2, lg: 3 }, mb: isMobile ? 2 : 3, display: 'flex', gap: 2, border: '1px solid var(--grey500)', borderRadius: '8px', width: '100%' }} onClick={
            () => {
                if (!empty(slug)) {
                    navigate("/package/" + slug)
                }
            }}>
            {image && Object.keys(image).length > 0 && (
				<MediaCard
					imageObj={image}
					width={'100px'}
					height={'90px'}
					borderRadius={'8px'}
				/>
			)}
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', width: empty(size) ? '100%' : width, textOverflow: 'ellipsis', overflow: 'hidden' }}>
                <Box>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                        {title}
                    </Typography>
                    {type === ACCESS.value && <Typography sx={{}}>
                        <Typography noWrap sx={{ color: 'var(--grey900)' }} component={'span'}>
                            {area} Acres, {getActivityType(activity_type)} at
                        </Typography>
                        <Link target={'_blank'} to={`/property/${listingSlug}-${listing_seq}`} className="propertyName"   >
                            <Typography sx={{color: 'var(--grey900)', textDecoration: 'underline', fontSize: '16px', display:'inline-block', paddingInline:0.5}} >{" " + propertyName + " "}</Typography>
                        </Link>
                        <Typography component={'span'} sx={{ color: 'var(--grey900)', fontSize: '16px', }} variant='body2' >
                            near {nearest_town}
                        </Typography>

                    </Typography>}
                    {
                        type === EXPERIENCE.value &&
                        <Typography sx={{ color: 'var(--textGrey)', fontSize: '16px' }}>{address}</Typography>
                    }
                </Box>
                {review !== 0 && (
                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                            <Icon.Star sx={{ color: "#F7C32E" }} />
                            <Typography sx={{ fontWeight: 700 }}>
                                {parseFloat(rating).toFixed(1)}
                            </Typography>
                        </Box>
                        <Typography>
                            {review} reviews
                        </Typography>
                    </Box>
                )}
            </Box>

        </Box>
    )
}

export default PackageCardSmall;