import { UserAuth } from "../store/context/userContext";
import { Navigate, useLocation } from "react-router";

export const ProtectedRoute = ({ children }) => {
    const { user } = UserAuth();
    let location = useLocation();
    if (!user) {
      // user is not authenticated redirect to login page with done url if exists
      return <Navigate to={"/login?done="+encodeURIComponent(location.pathname+location.search)} />;
    }
    return children;
  };