import React, { useRef, useState } from 'react';
import { Box } from "@mui/material";
import PlayCircleOutlinedIcon from '@mui/icons-material/PlayCircleOutlined';
import SingleImagePreview from './singleImagePreview';

const MediaCard = ({ imageObj, height, width, borderRadius, iconSize = '2rem', preview = false, minHeight }) => {
    const boxRef = useRef(null);
    const [openPreview, setOpenPreview] = useState(false); // State for ImagePreview dialog

    if (!imageObj) {
        return null;
    }

    const handleOpenPreview = () => {
        setOpenPreview(true);
    };

    const handleClosePreview = () => {
        setOpenPreview(false);
    };

    if (imageObj.type === 'VIDEO' || imageObj.file_type === 'VIDEO') {
        return (
            <>
                <div
                    className=''
                    style={{ position: 'relative', display: 'flex', cursor: preview ? 'pointer' : 'unset', maxWidth: width, width: '100%' }}
                    onClick={handleOpenPreview} // Open preview on click

                >
                    {/* Use boxWidth for the play icon container */}
                    <div className=''
                        style={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            maxWidth: width, width: '100%',
                            height: height,
                            backgroundColor: 'rgba(0, 0, 0, 0.5)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: '1',
                            borderRadius: borderRadius,
                            minHeight: minHeight ? minHeight : 'unset'
                        }}>
                        <PlayCircleOutlinedIcon
                            color="white"
                            size={64}
                            style={{ opacity: 0.8 }}
                            sx={{ color: 'white', zIndex: '2', height: iconSize, width: iconSize }}
                        />
                    </div>
                    <Box
                        ref={boxRef} // Reference for getting actual width
                        sx={{
                            maxWidth: width, width: '100%',
                            height: height,
                            borderRadius: borderRadius,
                            backgroundRepeat: "no-repeat",
                            backgroundPosition: "center center",
                            backgroundSize: "cover",
                            backgroundImage: `url('${imageObj.thumbnail_url}')`,
                            minHeight: minHeight ? minHeight : 'unset'
                        }}
                    />
                </div>

                {/* ImagePreview for video */}
                {preview && <SingleImagePreview open={openPreview} handleClose={handleClosePreview} image={imageObj} />}
            </>
        );
    }

    return (
        <>
            <div
                className=''
                style={{ position: 'relative', display: 'flex', cursor: preview ? 'pointer' : 'unset', width: '100%', maxWidth: width }}
                onClick={handleOpenPreview} // Open preview on click
            >
                <Box
                    ref={boxRef} // Reference for non-video case
                    sx={{
                        width: '100%',
                        height: height,
                        borderRadius: borderRadius,
                        backgroundImage: `url('${imageObj.url}')`,
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center center",
                        backgroundSize: "cover",
                        minHeight: minHeight ? minHeight : 'unset'
                    }}
                />
            </div>

            {/* ImagePreview for image */}
            {preview && <SingleImagePreview open={openPreview} handleClose={handleClosePreview} image={imageObj} />}
        </>
    );
};

export default MediaCard;
