import { Outlet, useParams } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';
import { useLocation } from 'react-router-dom';
import Header from "./Header";
import SideBar from "./Sidebar";
import { useEffect, useRef, useState } from 'react';
import { ROUTE_PAYMENT } from '../../constants/page';
import { CommonContext } from '../../store/context/commonContext';
import SmallFooter from '../../commonComponents/previewPageComponents/SmallFooter';

const DashboardLayout = () => {
    const location = useLocation();
    const { isTablet, isMobile } = CommonContext();

    // setSeqValue(seqFromLocalStorage.user_seq);
    // console.log("seqFromLocalStorage.seq", seqFromLocalStorage)
    const showSidebar = ['/checkout', ROUTE_PAYMENT].includes(
        location.pathname
    );
    const [open, setOpen] = useState(!isTablet);
    const toggleDrawer = () => {
        setOpen(!open);
    };

    const topRef = useRef(null);


    // useEffect(() => {

    //     exponentialBackoff(() => {

    //         return topRef?.current !== null
    //     }, 5, 1000, () => {

    //         if (!empty(params) && !location.pathname.includes('/chat')) { //  Added !location.pathname.includes('/chat') to avoid scroll top on messages screens
    //             topRef?.current?.scrollIntoView();
    //         } else {

    //         }
    //     })

    // }, [params]);

    useEffect(() => {
        if (topRef.current && !location.pathname.includes('/chat')) {
            topRef.current.scrollIntoView();
        }
    }, [location.pathname]);
    

    return (<Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Header toggleDrawer={toggleDrawer} open={open} />
        {!showSidebar && <SideBar toggleDrawer={toggleDrawer} open={open} />}

        <Box
            component="main"
            sx={{
                flexGrow: 1,
                height: '100svh',
                overflow: (location.pathname.includes('/chat') && isMobile) ? 'unset !important' : 'auto',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
            }}
        >
            <Box>
                <Toolbar ref={topRef} sx={{
                    "& .MuiToolbar-root": {
                        height: '72px'
                    },
                }} />
                <Grid container maxWidth="lg" sx={{ m: "auto" }}>
                    <Grid item md={12} sm={12} xs={12} sx={{ pl: { xs: 2, md: 3, lg: 3 }, pr: { xs: 2, md: 3, lg: 3 }, pb: { xs: location.pathname.includes('/chat') ? 0 : 6, md: 6, lg: 6 }, paddingTop: isTablet ? 2 : 5, }}>
                        <Outlet />
                    </Grid>
                </Grid>
            </Box>
            <SmallFooter />
        </Box>
    </Box>)
}

export default DashboardLayout