import { Box, Button, ButtonGroup, IconButton, Typography } from "@mui/material";
import * as Icon from "@mui/icons-material";
import { CommonContext } from "../../store/context/commonContext";
import { empty, toastMessage, ucFirst } from "../../utils/common";
import config from "../../config";
import { Link, useFetcher } from "react-router-dom";
import { ROUTE_CREATE_PROPERTY, ROUTE_PROPERTY } from "../../constants/page";
import WishlistService from "../../services/wishlist";
import { useEffect, useState } from "react";
import { UserAuth } from "../../store/context/userContext";
import ShareButton from "../ShareButton";


const Header = ({ type, seq, title, rating, reviewCount, areaSize, address, nearestTown, listing_seq, listingTitle, listingSlug, ltln }) => {
    const { isMobile } = CommonContext();
    const { user, authPopup, setAuthPopup } = UserAuth()
    const [wishlist, setWishlist] = useState(false)
    const handleWishlist = () => {
        if (empty(user)) {
            setAuthPopup({
                ...authPopup,
                type: type,
                open: true,
                callback: () => {
                    toggleWishlist()
                }
            })
        } else {
            toggleWishlist()
        }
    }

    // useEffect(()=> {
    //   console.log("rating ", rating, reviewCount)
    // }, [])

    const toggleWishlist = () => {
        setWishlist(!wishlist)
        if (!wishlist) {
            setWishlist(true)
            WishlistService.saveWishlist(type === "property" ? 'L' : 'P', seq, (data, errorMsg) => {
                if (data) {
                    // toast.success(errorMsg, {
                    //     theme: "colored",
                    // })
                    toastMessage({
                        message: errorMsg,
                        type: 'success'
                    })
                }
                setWishlist(true)
            })
        } else {
            setWishlist(false)
            WishlistService.removeFromWishlist(type === "property" ? 'L' : 'P', seq, (data, errorMsg) => {
                // toast.success(errorMsg, {
                //     theme: "colored",
                // })
                toastMessage({
                    message: errorMsg,
                    type: 'success'
                })
                setWishlist(false)
            })
        }
    }



    useEffect(() => {
        if (config.module === 'guest' && user) {
            WishlistService.isWishlist(seq, type === "property" ? 'L' : 'P', (data) => {
                // console.log(data,'iswishlist data')
                setWishlist(data)
            })
        }
    }, [seq, type, user])

    // This use effect is to refresh wishlist object on user login or logout status change
    useEffect(() => {
        if (!user) {
            setWishlist(false)
        }
    }, [user])

    return (
        <Box sx={{}}>
            {
                !isMobile &&
                <ButtonGroup sx={{ float: 'right', position: 'relative', top: '2.5rem', display: 'flex', gap: 1 }}>
                    {
                        config.module === 'guest' &&
                        <>
                            <IconButton sx={{ borderRadius: (theme) => theme.card.borderRadiusMd, backgroundColor: 'var(--grey300)' }} onClick={handleWishlist}>
                                {wishlist ? <Icon.Favorite color="buttonRed" /> : <Icon.FavoriteBorderOutlined />}
                            </IconButton>
                            <ShareButton shareUrl={window.location.href} />
                        </>
                    }
                </ButtonGroup>
            }
            {
                config.module === 'lister' &&
                <>
                    {!isMobile && <Box sx={{ float: 'right', mr: isMobile ? 0 : 1, mt: isMobile ? 2 : 0 }}>
                        <ShareButton shareUrl={window.location.href.includes('/landowner') ? window.location.href.replace('/landowner', '') : window.location.href} />
                    </Box>}
                    <Link to={ROUTE_CREATE_PROPERTY + "?listing_seq=" + listing_seq + "&edit=1"}>
                        <Button size="small" variant="contained" sx={{ float: 'right', mr: isMobile ? 0 : 1, mt: isMobile ? 2 : 0, height: '42px' }}>
                            <Icon.EditOutlined sx={{ mr: 1 }} />Edit</Button>
                    </Link>
                </>

            }
            <Typography variant="h4" sx={{ mb: 2, mt: isMobile && 2, mr: '40px', fontWeight: '600' }}>{title}</Typography>
            <Box sx={{ display: 'flex', gap: isMobile ? 1 : 2, mb: isMobile ? 2 : 3, alignItems: 'center', flexWrap: 'wrap' }}>
                {
                    !empty(reviewCount) &&
                    <Box sx={{ display: "flex", gap: 1 }}>
                        <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
                            <Icon.Star sx={{ color: "#F7C32E" }} />
                            <Typography variant="body1" fontWeight={'bold'}>
                                {parseFloat(rating).toFixed(1)}
                            </Typography>
                        </Box>

                        <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                            <Typography variant="body1" color='grey300'>
                                {reviewCount} reviews
                            </Typography>
                        </Box>
                    </Box>
                }
                {isMobile && <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mr: 2, }}>
                    {
                        type === 'package' &&
                        <>  <Typography sx={{ color: 'var(--grey900)' }}>at </Typography>
                            <Link to={ROUTE_PROPERTY + "/" + listingSlug + "-" + listing_seq} style={{ textDecoration: 'underline', color: 'color: var(--black)' }} >
                                <Typography noWrap sx={{ display: 'block', maxWidth: '250px' }}>
                                    {listingTitle}
                                </Typography>
                            </Link>
                        </>
                    }
                </Box>}
                <Box sx={isMobile ? { display: 'flex', width: '100%', gap: 1 } : { display: 'flex', gap: 1 }}>
                    <Icon.MapOutlined />
                    <Typography sx={{ mr: 1, color: 'var(--grey900)' }}>
                        {areaSize} acres
                    </Typography>
                </Box>
                {!isMobile && <Box sx={{ display: 'flex', gap: 1, alignItems: 'center', mr: 2, }}>
                    {
                        type === 'package' ?
                            <Link to={ROUTE_PROPERTY + "/" + listingSlug + "-" + listing_seq} style={{ textDecoration: 'underline', color: 'color: var(--black)' }} >
                                <Typography noWrap sx={{ display: 'block', maxWidth: '250px' }}>
                                    {listingTitle}
                                </Typography>
                            </Link>
                            :
                            (
                                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Icon.LocationOnOutlined sx={{ mr: 1, }} />
                                    <Typography sx={{ color: 'var(--grey900)' }}>
                                        Nearest Town, {nearestTown}
                                    </Typography>
                                </Box>
                            )
                    }
                </Box>}
                {
                    type === "package" &&

                    <Box sx={{ display: 'flex', alignItems: { xs: 'flex-start', sm: 'flex-start', md: 'center' } }}>
                        <Icon.LocationOnOutlined sx={{ mr: 1, }} />
                        <Typography sx={{ color: 'var(--grey900)', overflow: 'hidden', whiteSpace: 'unset', textOverflow: 'ellipsis', maxWidth: isMobile ? '300px' : null, display: 'block' }}>
                            Nearest Town, {nearestTown}
                        </Typography>
                    </Box>
                }
                {
                    type === "property" && isMobile &&

                    <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                        <Icon.LocationOnOutlined sx={{ mr: 1, }} />
                        <Typography sx={{ color: 'var(--grey900)', overflow: 'hidden', whiteSpace: 'unset', textOverflow: 'ellipsis', maxWidth: isMobile ? '300px' : null, display: 'block' }}>
                            Nearest Town, {nearestTown}
                        </Typography>
                    </Box>
                }


            </Box>
        </Box>
    )
}

export default Header;