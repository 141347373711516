import { AppBar, Box, Checkbox, Dialog, Divider, FormControlLabel, FormGroup, Grid, IconButton, MenuItem, Select, Skeleton, Toolbar, Typography } from "@mui/material";
import * as Icon from '@mui/icons-material';
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import PackageService from "../../services/package";
import { capitalizeWords, empty, extractDataFromArray, generatePhrase, getDateToString, getImageUrl, showZendeskLauncher, toastMessage } from "../../utils/common";
import ListingService from "../../services/listing";
import dayjs from "dayjs";
import CustomTooltip from "../../commonComponents/CustomTooltip";
import PaymentService from "../../services/payments";
import { loadStripe } from "@stripe/stripe-js";
import { Elements, PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import PackageCardSmall from "../../commonComponents/PackageCardSmall";
import CustomDateRangePicker from "../../projectComponents/CustomDateRangePicker";
import UserService from "../../services/user";
import AccountVerification from "../../commonComponents/AccountVerification";
import { CommonContext } from "../../store/context/commonContext";
import ArrowBackOutlined from "@mui/icons-material/ArrowBackOutlined";
import PackageCardMobile from "../../commonComponents/PackageCardMobile";
import LoaderButton from "../../commonComponents/LoaderButton";
import theme from "../../theme";
import CloseIcon from '@mui/icons-material/Close';
import { ACCESS, ACCOUNT_VERIFICATION_TEXTS } from "../../commonComponents/CommonConstants/commonKeys";
import CreateChip from "../../commonComponents/CreateChip";
import BookingCancellationPopup from "./paymentCancelPopup";
import BookingService from "../../services/bookings";
import errorAndHelperText from "../../commonComponents/CommonConstants/errorAndHelperText.json"

const GuestAndDatesSelection = ({ guests, startDate, endDate, minDays, maxDays, notAvailableDates, onDateChange = () => { }, onGuestsChange = () => { }, minGuests, maxGuests, isPopup = false, }) => {
	const { isMobile } = CommonContext()
	startDate = dayjs(startDate);
	endDate = dayjs(endDate);


	const handleDateChange = (dateRange) => {
		onDateChange(dateRange);
	};

	const handleGuestChange = (e) => {
		const selectedValue = parseInt(e.target.value, 10);
		onGuestsChange(selectedValue);
	};

	return (
		<Box sx={{
			border: '1px solid var(--grey500)',
			borderRadius: (theme) => `${theme.card.borderRadius} `,
			mb: isMobile ? 2 : 3,
		}}>
			<Box sx={{ display: "flex", gap: 2, p: isMobile ? 2 : 3, alignItems: 'center' }}>
				<Icon.PersonOutlineOutlined />
				<Typography sx={{ fontWeight: '600' }}>
					Guests
				</Typography>
				<Box sx={{ marginLeft: "auto", }}>
					<Select
						displayEmpty
						value={guests}
						disabled={isPopup}
						IconComponent={Icon.KeyboardArrowDownOutlined}
						onChange={handleGuestChange}
						sx={{
							height: "42px",
						}}
					>
						{Array.from({ length: maxGuests - minGuests + 1 }, (_, index) => (
							<MenuItem
								key={index + parseInt(minGuests)}
								value={index + parseInt(minGuests)}
							>
								{index + parseInt(minGuests)}
							</MenuItem>
						))}
					</Select>
				</Box>
			</Box>
			<Divider />
			<Box sx={{ display: "flex", gap: 2, p: { xs: 2, sm: 3, md: 3, lg: 3 }, alignItems: 'center', justifyContent: 'space-between' }}>
				<Box sx={{ display: 'flex', gap: 3 }}>
					<Icon.CalendarTodayOutlined />
					<Typography sx={{ fontWeight: '600' }} >
						Days
					</Typography>
				</Box>
				<Box sx={{
					'& :hover': {
						cursor: 'pointer',
					},
				}}>
					<CustomDateRangePicker
						onChange={(date) => handleDateChange(date)}
						defaultDateRange={[startDate, endDate]}
						minDays={parseInt(minDays)}
						maxDays={parseInt(maxDays)}
						notAvailableDates={notAvailableDates}
						isPopup={isPopup}
					/>
				</Box>
			</Box>
		</Box>
	);
}


const PricingCard = ({ price, guests, selectedDays, initPayment, selectedOptions, minDays, maxDays, selectionPriceSum }) => {
	let total_package_price = price * (guests) * (selectedDays)
	let booking_fee = parseFloat(((total_package_price + selectionPriceSum) * 0.05).toFixed(2));
	let conservation_donation = 0;
	const { isMobile, isTablet } = CommonContext()

	return (
		<Box sx={{ mb: isTablet && 12 }}>
			<Box sx={{ display: 'flex', alignItems: 'center', gap: '5px', }}>
				<Typography sx={{ fontWeight: "600", fontSize: '18px' }}>
					Pricing Details
				</Typography>
				<CustomTooltip content={<>Total price is inclusive of FarWide fees </>} />
			</Box>
			<Typography variant="caption" sx={{ color: 'var(--grey900)', fontWeight: '0.875rem' }}>
				Prices Are for Exclusive Access to This Property.
			</Typography>
			<Box sx={{
				display: "flex",
				justifyContent: "space-between",
				mt: isMobile ? 2 : 3,
				color: 'var(--grey900)'
			}}>
				<Box>
					{`$${price} x ${generatePhrase(guests, 'Guest')} x ${generatePhrase(selectedDays, 'Day')}`}
				</Box>

				<Box>
					${parseFloat(total_package_price).toFixed(2)}
				</Box>
			</Box>
			{selectedOptions.map((option) => (
				(selectedDays > minDays ? selectedDays : minDays) > 1 && isNaN(option.amount) ? 0 : parseFloat(option.amount * (option.is_per_day ? selectedDays - 1 : 1)).toFixed(2) != 0 &&
					<Box sx={{ color: 'var(--grey900)', display: "flex", justifyContent: "space-between" }} key={option.caption}>
						<Box>${isNaN(option.amount) ? 0 : parseFloat(option.amount).toFixed(2)} x {option.is_per_day && `${generatePhrase(selectedDays > minDays ? selectedDays - 1 : minDays - 1, 'Night')} (${capitalizeWords(option.caption)})`}</Box>
						<Box>${isNaN(option.amount) ? 0 : parseFloat(option.amount * (option.is_per_day ? (selectedDays > minDays ? selectedDays : minDays) - 1 : 1)).toFixed(2)}</Box>
					</Box>
			))}
			<Box sx={{ display: "flex", justifyContent: "space-between", color: 'var(--grey900)' }}>
				<Box>Platform Fee (5%)</Box>
				<Box>${parseFloat(booking_fee).toFixed(2)}</Box>
			</Box>
			<Divider sx={{ mt: 2, mb: 2 }} />
			<Box sx={{
				display: "flex",
				fontWeight: "600",
				justifyContent: "space-between",
			}}>
				<Box><Typography sx={{ fontWeight: '600' }} >Total</Typography></Box>
				{/* <Box>
					${parseFloat((price * guests * selectedDays)
						+ conservation_donation + booking_fee).toFixed(2)}
				</Box> */}
				<Box sx={{ color: 'var(--black)' }}>
					${isNaN(total_package_price + conservation_donation + booking_fee) ? 0 : (parseFloat(total_package_price + conservation_donation + booking_fee + (selectionPriceSum)).toFixed(2))}
				</Box>
			</Box>
		</Box>
	)
}

const PayPopup = ({ open, handleClose, listingData, packageData, guests, startDate, endDate, selectedDays, maxDays, minDays, selectedOptions, selectionPriceSum, timeout, isLoadingButton }) => {
	// // // console.log(startDate, endDate)
	const stripe = useStripe();
	const elements = useElements();
	const [bookingLoader, setBookingLoader] = useState(false);
	const { isNineHundred } = CommonContext();
	const [cancelConfirmationOpen, setCancelConfirmation] = useState(false)
	const [cancelTransactionType, setcancelTransactionType] = useState('')
	const [secondsRemaining, setSecondsRemaining] = useState(timeout.timeout * 60); // Convert timeout from minutes to seconds

	// const handleSubmit = async (event) => {
	// 	// We don't want to let default form submission happen here,
	// 	// which would refresh the page.
	// 	event.preventDefault();
	// 	// setLoaderButton(false)
	// 	if (!stripe || !elements) {
	// 		// Stripe.js hasn't yet loaded.
	// 		// Make sure to disable form submission until Stripe.js has loaded.
	// 		return;
	// 	}
	// 	setBookingLoader(true);

	// 	const result = await stripe.confirmPayment({
	// 		//`Elements` instance that was used to create the Payment Element
	// 		elements,
	// 		confirmParams: {
	// 			return_url: window.location.origin + "/payment",
	// 		},
	// 	});
	// 	// setLoaderButton(false)

	// 	if (result.error) {
	// 		window.location.href = "/payment?payment_intent=" + result.error.payment_intent.id + "&redirect_status=failed&em=" + result.error.message;
	// 		// setLoaderButton(false)
	// 	} else {
	// 		// setLoaderButton(false)
	// 		// Your customer will be redirected to your `return_url`. For some payment
	// 		// methods like iDEAL, your customer will be redirected to an intermediate
	// 		// site first to authorize the payment, then redirected to the `return_url`.
	// 	}
	// };

	const handleSubmit = async (event) => {
		// Check if event is defined before attempting to access its properties
		if (event) {
			event.preventDefault();
		}

		if (!stripe || !elements) {
			setBookingLoader(false);
			return;
		}

		setBookingLoader(true);
		const result = await stripe.confirmPayment({
			elements,
			confirmParams: {
				return_url: window.location.origin + "/payment",
			},
		});

		if (result.error) {
			setBookingLoader(false);
			window.location.href = "/payment?payment_intent=" + result.error.payment_intent.id + "&redirect_status=failed&em=" + result.error.message;
		} else {
			setBookingLoader(false);
			// Handle successful payment
		}

		// Reset loader state after payment attempt
		setBookingLoader(false);
	};

	const [lastState, setLastState] = useState(null); // useState to store the last pushed state


	useEffect(() => {
		const handleBeforeUnload = (event) => {
			if (open) {
				// Show an alert instead of the default confirmation
				alert("Are you sure you want to leave? Your progress may be lost.");
				// Returning a value here for browsers that support it
				event.returnValue = "Are you sure you want to leave? Your progress may be lost.";
				return "Are you sure you want to leave? Your progress may be lost.";
			}
		};

		const handlePopState = (event) => {
			if (open) {
				// Show an alert when the back button is pressed
				!cancelConfirmationOpen && handleCancelPopup('cancel');
				// Push a state to the browser history to intercept the back button
				window.history.pushState(null, '', window.location.href);
				// Prevent the back navigation by calling preventDefault
				event.preventDefault();
			}
		};

		// Only attach event listeners if PayPopup is open
		if (open && !bookingLoader) {
			// Check if the current state is different from the last pushed state
			if (lastState !== window.location.href) {
				// Push a state to the browser history to intercept the back button
				window.history.pushState(null, '', window.location.href);
				setLastState(window.location.href); // Update the last state using setLastState
			}

			// Attach beforeunload and popstate event listeners
			window.addEventListener('beforeunload', handleBeforeUnload);
			window.addEventListener('popstate', handlePopState);
		}

		// Cleanup event listeners when PayPopup is closed or component unmounts
		return () => {
			window.removeEventListener('beforeunload', handleBeforeUnload);
			window.removeEventListener('popstate', handlePopState);
		};
	}, [open, bookingLoader, lastState]); // Add `lastState` to dependencies

	// Convert seconds to hh:mm:ss format
	const formatTime = (seconds) => {
		const hours = Math.floor(seconds / 3600); // Calculate hours
		const minutes = Math.floor((seconds % 3600) / 60); // Calculate minutes
		const remainingSeconds = seconds % 60; // Calculate remaining seconds

		// Format as hh:mm:ss
		return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
	};


	// Countdown Timer Effect
	useEffect(() => {
		if (open) {
			if (secondsRemaining <= 0) {
				toastMessage(errorAndHelperText.session_expired)
				handleClose(true); // Close the dialog when the timer reaches zero
				return;
			}

			const timer = setInterval(() => {
				setSecondsRemaining(prev => prev - 1);
			}, 1000);

			return () => clearInterval(timer); // Clean up the timer when the component unmounts or the countdown finishes
		}


	}, [secondsRemaining, handleClose]);

	// Reset timer when PayPopup mounts or opens
	useEffect(() => {
		// Reset the countdown to the original timeout value whenever the popup opens
		if (open)
			setSecondsRemaining(timeout.timeout * 60); // Reset to the initial timeout value (in seconds)
	}, [open, timeout]); // Depend on open and timeout to trigger when PayPopup opens


	const [listerSeq, setListerSeq] = useState('')
	const [paymentDetails, setPaymentDetails] = useState({})

	useEffect(() => {

		setListerSeq(extractDataFromArray(listingData, ['lister_seq'], ''))

	}, [listingData])

	useEffect(() => {

		PackageService.getCancellationPolicyData('lister', listerSeq, (data) => {
			setPaymentDetails(data)
			// setCancelReasons(data.cancelationResaons)
		})
	}, [listerSeq])

	const handleCancelPopup = (type) => {
		setcancelTransactionType(type)
		setCancelConfirmation(true)
	}


	return (
		<Dialog open={open} fullScreen={isNineHundred} sx={{
			borderRadius: (theme) => `${theme.card.borderRadius} `,
			'& .MuiDialog-paper': isNineHundred && {
				borderRadius: 0,
				padding: 0,
			},
			'& .MuiDialog-paper': { maxWidth: '1000px' }
		}}
			PaperProps={{ style: { borderRadius: (theme) => `${theme.card.borderRadius} ` } }}
			fullWidth

		>
			<Box sx={{ mb: 8, borderRadius: (theme) => `${theme.card.borderRadius} ` }}>
				{!isNineHundred ? <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
					<Typography variant="h5" sx={{ p: 2, fontWeight: 700 }}>Confirm and Pay</Typography>
					<IconButton onClick={() => handleCancelPopup('cancel')} sx={{ mr: 2 }}>
						<CloseIcon />
					</IconButton>
				</Box> :
					<AppBar sx={{ position: 'relative', backgroundColor: 'white', boxShadow: 'none', height: '56px' }}>
						<Toolbar>
							<IconButton
								edge="start"
								color="inherit"
								onClick={() => { handleCancelPopup('cancel') }}
								aria-label="close"
							>
								<ArrowBackOutlined color="black" />
							</IconButton>
							<Typography sx={{ m: 'auto' }} variant="h6" component="div">
								Confirm and Pay
							</Typography>
						</Toolbar>
						<Divider />
					</AppBar>}
				{!isNineHundred && <Divider />}
				<Grid container spacing={2} sx={{ p: 2 }}>
					<Grid item xs={12} sm={12} md={6}>
						<form onSubmit={handleSubmit}>
							<PaymentElement options={{ terms: { card: 'never' } }} />
							{!isNineHundred && <Divider sx={{ my: 3 }} />}
							<Box sx={{ p: 2, my: isNineHundred ? 2 : 0, backgroundColor: 'var(--grey300)', borderRadius: (theme) => `${theme.card.borderRadius} ` }}>
								<Typography variant="body2">Cancellation Policy:</Typography>
								<Typography variant="body2" sx={{ color: "var(--grey900)" }}>
									{extractDataFromArray(paymentDetails, ['messageToguest'], '')}</Typography>
							</Box>
							<Box
								sx={isNineHundred && {
									width: '100%',
									ml: -2,
									height: '82px',
									position: 'fixed',
									bottom: 0,
									backgroundColor: 'white',
									display: 'flex',
									alignItems: 'center',
									justifyContent: 'space-between',
									flexDirection: 'column',
									boxShadow: '0 -2px 4px 0 rgba(0,0,0,0.15)',
									zIndex: '9999',
									pl: 2,
									pr: 2
								}}
							>
								{/* <Button onClick={handleSubmit} fullWidth variant="contained" sx={{ mt: isNineHundred ? 2 : 3 }}>
									Send Booking Request
								</Button> */}
								<LoaderButton
									variantType='contained'
									size="large"
									onClick={() => { handleSubmit() }}
									isLoading={bookingLoader}
									buttonText="Send Booking Request"
									isFullWidth={true}
									mt={isNineHundred ? 2 : 3}
									sx={{ borderRadius: 2 }}
								/>
							</Box>
							<Box sx={{
								textAlign: 'center',
								mt: 3
							}}>
								<Typography sx={{ fontWeight: 600, textAlign: 'center' }} variant="subtitle1">
									Complete transaction in - {formatTime(secondsRemaining)}
								</Typography>
								<Typography sx={{ textAlign: 'center', color: 'var(--textGrey)' }} variant="caption">
									Please do not refresh or leave this page to complete the transaction.
								</Typography>
							</Box>
						</form>
					</Grid>
					<Grid item xs={12} sm={12} md={6} >
						<Box sx={!isNineHundred && { border: '1px solid var(--grey500)', p: 3, borderRadius: (theme) => `${theme.card.borderRadius} ` }}>
							{!isNineHundred && <PackageCardSmall
								image={extractDataFromArray(packageData, ['package_json', 'media_library', '0'])}
								title={extractDataFromArray(packageData, ['title'])}
								rating={extractDataFromArray(packageData, ['average_rating'], 0)}
								area={extractDataFromArray(listingData, ['listing_json', 'location_info', 'property_area'], '')}
								address={extractDataFromArray(listingData, ['listing_json', 'location_info', 'property_address'], '')}
								nearest_town={extractDataFromArray(listingData, ['listing_json', 'location_info', 'nearest_town'], '')}
								review={extractDataFromArray(packageData, ['review_count'], 0)}
								propertyName={extractDataFromArray(listingData, ['listing_json', 'property_details', 'name'], '')}
								listingSlug={extractDataFromArray(listingData, ['listing_json', 'property_details', 'slug'], '')}
								listing_seq={extractDataFromArray(listingData, ['listing_seq'], '')}
								activity_type={extractDataFromArray(packageData, ['package_json', 'activity_type'], '')}
								borderRadius={theme.card.borderRadiusMd}
								type={ACCESS.value}
							/>}
							{/* <GuestAndDatesSelection
								guests={guests}
								startDate={startDate}
								endDate={endDate}
								minDays={minDays}
								maxDays={maxDays}
								minGuests={minGuests}
								maxGuests={maxGuests}
								onDateChange={handleDateChange}
								onGuestsChange={handleGuestsChange}
								isPopup={true}
							/> */}
							<Box sx={{
								border: '1px solid var(--grey500)',
								borderRadius: (theme) => `${theme.card.borderRadiusMd} `,
								mb: 2,
							}}>
								<Box sx={{
									display: 'flex',
									height: '4rem',
									pl: 2,
									alignItems: 'center'
								}}>
									<Icon.PersonOutlineOutlined sx={{ mr: 1, color: 'var(--grey900)' }} />
									<Typography variant="body1" fontWeight={'600'}>{generatePhrase(guests, 'Guest')}</Typography>
								</Box>
								<Divider sx={{ borderColor: 'var(--grey500)' }} />
								<Box sx={{
									display: 'flex',
									height: '4rem',
									pl: 2,
									alignItems: 'center'
								}}>
									<Icon.CalendarTodayOutlined sx={{ mr: 1, color: 'var(--grey900)' }} />
									<Typography variant="body1" fontWeight={'600'}>{generatePhrase(selectedDays, 'Day')} {dayjs(startDate).format('DD MMM') == dayjs(endDate).format('DD MMM') ? `(${dayjs(endDate).format('DD MMM')})` : `(${dayjs(startDate).format('DD')}-${dayjs(endDate).format('DD MMM')})`}</Typography>
								</Box>
							</Box>
							<Box>

							</Box>
							<Box>
								<PricingCard
									listerSeq={extractDataFromArray(listingData, ['lister_seq'])}
									price={extractDataFromArray(packageData, ['package_json', 'price_per_guest'], 1)}
									selectedDays={selectedDays}
									guests={guests}
									listingData={listingData}
									packageData={packageData}
									selectedOptions={selectedOptions}
									minDays={minDays}
									maxDays={maxDays}
									selectionPriceSum={selectionPriceSum}
								/>
							</Box>
						</Box>
					</Grid>
				</Grid>

			</Box>
			<BookingCancellationPopup isLoadingButton={isLoadingButton} open={cancelConfirmationOpen} setOpen={setCancelConfirmation} handleCloseMainPopup={handleClose} type={cancelTransactionType} />
		</Dialog>
	)
}
const PackageCheckout = () => {
	const [loading, setLoading] = useState(true);
	const [loaderButton, setLoaderButton] = useState(false);
	const [packageData, setPackageData] = useState({});
	const [listingData, setListingData] = useState({});
	const location = useLocation();
	const navigate = useNavigate();
	const queryParams = new URLSearchParams(location.search);
	const packageSeq = queryParams.get("p");
	const totalguests = queryParams.get("g");
	// const startDate = queryParams.get("sd");
	// const endDate = queryParams.get("ed");
	let prevSelection = queryParams.get('sel')
	prevSelection = prevSelection?.split(",")

	const type = queryParams.get('type')

	const [payPopUpTimeout, setPayPopUpTimeout] = useState({ timeout: 0, timestamp: 0 });

	const [optionalAddOns, setOptionalAddOns] = useState([])
	const [nonMandatoryCount, setNonMandatoryCount] = useState(0)
	const [totalNonZeroAmount, setTotalNonZeroAmount] = useState(0)
	const [isLoadingButton, setIsLoadingButton] = useState(false)

	// console.log(nonMandatoryCount);

	//pricing section related options
	// // console.log(optionalAddOns.filter(option => prevSelection.includes(option.caption)));
	const [selectedOptions, setSelectedOptions] = useState(
		optionalAddOns.filter(option => prevSelection.includes(option.caption))
	);

	useEffect(() => {
		setSelectedOptions(optionalAddOns.filter(option => prevSelection.includes(option.caption)))
	}, [optionalAddOns])


	// // // console.log(selectedOptions);
	const [selectionPriceSum, setSelectionPriceSum] = useState(0)
	// console.log(selectionPriceSum);
	const [selectionNames, setSelectionNames] = useState('')


	const [startDate, setStartDate] = useState(dayjs(queryParams.get("sd")));
	const [endDate, setEndDate] = useState(dayjs(queryParams.get("ed")));
	const totalSelectedDays = dayjs(endDate).diff(startDate, 'days') + 1
	const { isMobile, isTablet } = CommonContext()
	useEffect(() => {
		showZendeskLauncher(!isTablet)
	}, [isTablet])

	const [guests, setGuests] = useState(totalguests)

	const [selectedDays, setSelectedDays] = useState(parseInt(totalSelectedDays))
	const [notAvailableDates, setNotAvailableDates] = useState([]);


	const handleDateChange = (dateRange) => {
		// Calculate the difference in days between start and end dates
		const days = dayjs(dateRange[1]).diff(dayjs(dateRange[0]), 'day') + 1;
		// // console.log(days)
		// Check if days is NaN and set it to 0 in that case
		setSelectedDays(isNaN(days) ? 0 : days);
		setStartDate(dateRange[0])
		setEndDate(dateRange[1])

		localStorage.setItem(`${packageSeq}-bookingSelection`, JSON.stringify({
			guest: guests,
			startDate: getDateToString(dayjs(dateRange[0])),
			endDate: getDateToString(dayjs(dateRange[1]))
		}));
	};

	const handleGuestsChange = (guests) => {
		// // console.log('setting guest count',guests)
		setGuests(guests)

		let storedSelection = JSON.parse(localStorage.getItem(`${packageSeq}-bookingSelection`));
		if (storedSelection) {
			storedSelection.guest = guests;
			localStorage.setItem(`${packageSeq}-bookingSelection`, JSON.stringify(storedSelection));
		}
	}

	const handleNotAvailabeDates = (data) => {
		// setNotAvailableDatesStringArray(data)
		if (Array.isArray(data) && data && data.length > 0) {
			let notAvlDatesDayJS = data.map(date => dayjs(date))
			setNotAvailableDates(notAvlDatesDayJS)
		}
	}



	const pricePerGuest = extractDataFromArray(packageData, ['package_json', 'price_per_guest'], 0)
	useEffect(() => {
		PackageService.getPackageData(packageSeq, (data) => {
			setPackageData(data)
			ListingService.getListingData(extractDataFromArray(data, ['listing_seq'], 0), (listing_data) => {
				setListingData(listing_data)
				setLoading(false)
			})
			ListingService.getListingBookedDates(extractDataFromArray(data, ['listing_seq'], 0), (data) => {
				// // console.log(data)
				handleNotAvailabeDates(data)
			});
			setOptionalAddOns(extractDataFromArray(data, ['addon_pricing'], []))
		},
			(err) => {
				console.error(err);
				// toast.error(`${err}. You Will be redirected to Search page...`, {
				// 	theme: "colored",
				// })
				toastMessage({
					message: `${err}. You Will be redirected to Search page...`,
					type: 'error'
				})
				setTimeout(() => {
					navigate('/search');
				}, 3000); // 3000 ms = 3 seconds	
			})
	}, [packageSeq])

	const minDays = parseInt(extractDataFromArray(packageData, ['package_json', 'min_days'], 0))
	const maxDays = parseInt(extractDataFromArray(packageData, ['package_json', 'max_days'], 0))
	const minGuests = parseInt(extractDataFromArray(packageData, ['package_json', 'min_guests'], 0))
	const maxGuests = parseInt(extractDataFromArray(packageData, ['package_json', 'max_guests'], 0))

	// // console.log(minGuests, 'minguests', maxGuests, 'maxGuests', minDays, 'mindays', maxDays, 'maxDays')
	let total_package_price = extractDataFromArray(packageData, ['package_json', 'price_per_guest'], 1) * (guests) * (selectedDays)
	let booking_fee = parseFloat(((total_package_price + selectionPriceSum) * 0.05).toFixed(2));
	let conservation_donation = 0;

	const [open, setOpen] = useState(false);

	const [options, setOptions] = useState({})
	const [accountActive, setAccountActive] = useState(false)
	const [openIdPopup, setOpenIdPopup] = useState(false);
	const [verificationToken, setVerificationToken] = useState(false);
	// // console.log(verificationToken)

	const image = extractDataFromArray(packageData, ['package_media', '0', 'url'])


	const [isReviewPopup, setIsReviewPopup] = useState(true)

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = (automatic) => {
		if (automatic) {
			// Now go back to remove the latest state (LIFO approach)
			window.history.back();
			setOpen(false);
			setOptions({})
		}

		// if popup is getting closed automatically on session expire duration then don't callf unlock Booking.
		if (!automatic) {
			unlockBooking(() => {
				setOpen(false);
				setPayPopUpTimeout((prevData) => {
					return {
						timeout: prevData.timeout,
						timestamp: Date.now() // Adding a timestamp to force a state change
					};
				});
				setOptions({})

				// Now go back to remove the latest state (LIFO approach)
				window.history.back();
			})
		}
	}

	const handleVerifyIdentity = () => {
		setOpenIdPopup(false)
		verifyIdentity(verificationToken)
	}
	const verifyIdentity = async (clientSecret) => {
		const { error } = await stripe.verifyIdentity(clientSecret);

		if (error) {

		} else {
			PaymentService.updateUserStatus(extractDataFromArray(listingData, ['lister_seq']), () => {
				// createOrder()
				// toast.success("Your verifcation is submitted successfully. Awaiting approval.", { theme: 'colored' })
				toastMessage(errorAndHelperText.account_verification.submitted)
			})
		}
	}

	const createOrder = () => {
		let checkingInDate = dayjs(startDate).format('YYYY-MM-DD');
		let checkingOutDate = dayjs(endDate).format('YYYY-MM-DD');

		// alert(checkingInDate,checkingOutDate)


		PaymentService.createOrder(extractDataFromArray(listingData, ['lister_seq']), extractDataFromArray(listingData, ['listing_seq']), guests, checkingInDate, checkingOutDate, (extractDataFromArray(packageData, ['package_json', 'price_per_guest'], 1) * guests * selectedDays)
			, booking_fee, conservation_donation, 'new', packageSeq, setLoaderButton, extractDataFromArray(listingData, ['listing_version']), extractDataFromArray(packageData, ['package_version']), selectionPriceSum, 'access', '', [], (data) => {
				if (empty(data)) {
					// toast.error("Some error occured!", {
					// 	theme: 'colored'
					// })
					toastMessage(errorAndHelperText.something_went_wrong)
					return
				}
				setOptions({ ...options, clientSecret: data.client_secret, payment_id: data.payment_id })
				setOpen(true)
				setLoaderButton(false)
				setPayPopUpTimeout({
					timeout: extractDataFromArray(data, ['lock_expiry'], 5),
					timestamp: Date.now() // Adding a timestamp to force a state change
				});
			})
	}


	const initPayment = () => {
		setLoaderButton(true)
		UserService.getAccountCompletionStatus((data) => {
			PaymentService.isUserVerified(extractDataFromArray(listingData, ['lister_seq']), (data) => {
				setVerificationToken(data.v_token)
			})

			if (data.id_verified === 'I' || data.email_verified === false) {
				setVerificationToken(verificationToken)
				setOpenIdPopup(true)
			} else if (data.id_verified === 'V') {
				createOrder()

			} else if (data.id_verified === 'P' || data.id_verified === '1') {
				// toast.error("You cannot proceed to booking because ID Verification is still pending.", {
				// 	theme: 'colored'
				// })
				toastMessage(errorAndHelperText.account_completion_status.pending)

				return
			} else {
				// toast.error("Something went wrong. Please try after sometime", {
				// 	theme: 'colored'
				// })
				toastMessage(errorAndHelperText.something_went_wrong)
				setLoaderButton(true)
				return
			}
		})
	}

	const checkListerAccountStatus = () => {
		if (accountActive) {
			setOpen(true)
			setLoaderButton(false);
		} else {
			UserService.getAccountCompletionStatus((data) => {
				let statusCompleted = 0;
				Object.keys(data).forEach(key => {
					if (key === 'id_verified' && data[key] !== "I") {
						statusCompleted++;
					} else if (data[key]) {
						statusCompleted++;
					}
				})
				if (Object.keys(data).length === statusCompleted) {
					setOpen(true)
					setLoaderButton(false);
					setAccountActive(true)
				} else {
					setOpenIdPopup(true)
				}
			})
		}
	}
	// unlock Booking on popup close intentionally
	const unlockBooking = (callback) => {
		if (options.payment_id) {
			setIsLoadingButton(true)
			BookingService.unlockBooking(options.payment_id, (data) => {
				if (data === true) {
					callback()
					setIsLoadingButton(false)
				}
			})
		}
	}


	const [stripe, setStripe] = useState(null)
	const [platFormStripe, setPlatFormStripe] = useState(null)
	useEffect(() => {
		if (!empty(listingData)) {
			PaymentService.getListerInfo(extractDataFromArray(listingData, ['lister_seq'], 0), async (data) => {
				let load = await loadStripe(extractDataFromArray(data, ['p_key']))
				setStripe(load)
				let pload = await loadStripe(extractDataFromArray(data, ['fw_key']), {
					stripeAccount: extractDataFromArray(data, ['account_id']),
				})
				setPlatFormStripe(pload)
			},
				(err) => {
					console.error('PaymentService error:', err);
					// After 3 seconds, navigate to the search page
					setTimeout(() => {
						navigate('/search');
					}, 3000); // 3000 ms = 3 seconds
				})
		}
	}, [listingData])

	useEffect(() => {
		if (!Array.isArray(selectedOptions) || selectedOptions.length === 0) {
			setSelectionPriceSum(0);
			setSelectionNames('');
			return;
		}

		let tempStr = '';
		let sum = 0;
		const daysToUse = Math.max(selectedDays, minDays);
		if (daysToUse > 1) {
			selectedOptions.forEach((option, index) => {
				const amount = parseFloat(option.amount);
				if (!isNaN(amount)) {
					sum += amount * (option.is_per_day ? selectedDays - 1 : 1);
				}
				tempStr += option.caption;
				if (index < selectedOptions.length - 1) {
					tempStr += ',';
				}
			});
		}
		setSelectionPriceSum(sum);
		setSelectionNames(tempStr);
	}, [selectedOptions, selectedDays, minDays]);


	useEffect(() => {
		const count = optionalAddOns.filter(item => !item.is_mandatory).length
		setNonMandatoryCount(count)
		count === 0 && setSelectedOptions(optionalAddOns.filter(item => item.is_mandatory)); // if count will be greater than 0, then check for prevSelection from package detail page and then add These as default condition,, add a new condition for it...
		setTotalNonZeroAmount(optionalAddOns.filter(item => (item.amount != 0)).length)
	}, [optionalAddOns])


	const handleOptionChange = (option) => {
		if (selectedOptions.some((selectedOption) => selectedOption.caption === option.caption)) {
			// If option already exists in selectedOptions, remove it
			setSelectedOptions(selectedOptions.filter((selectedOption) => selectedOption.caption !== option.caption));
		} else {
			// Otherwise, add it
			setSelectedOptions([...selectedOptions, option]);
		}
	};


	return (
		<>
			<Box>
				<Box sx={{ display: 'flex', alignItems: 'center', gap: 1, textAlign: 'center' }}>
					<IconButton onClick={() => { window.history.back() }}><Icon.West /></IconButton>
					<Typography variant='h3' >Review your Booking </Typography>
				</Box>
				<Divider sx={isMobile ? { mt: 2, mb: 2 } : { mt: 3, mb: 3 }} />

				{
					loading && <Box sx={{ mx: 'auto' }} maxWidth={660}>
						<Skeleton sx={{ pb: 10 }} />
						<Skeleton sx={{ pb: 10 }} />
						<Skeleton sx={{ pb: 10 }} />
						<Skeleton sx={{ pb: 10 }} />
						<Skeleton sx={{ pb: 10 }} />
					</Box>
				}

				{
					!loading && <Box sx={{ mx: 'auto' }} maxWidth={660}>
						{!isMobile ? <PackageCardSmall
							image={extractDataFromArray(packageData, ['package_json', 'media_library', 0])}
							title={extractDataFromArray(packageData, ['title'])}
							rating={extractDataFromArray(packageData, ['average_rating'], 0)}
							area={extractDataFromArray(listingData, ['listing_json', 'location_info', 'property_area'], '')}
							address={extractDataFromArray(listingData, ['listing_json', 'location_info', 'property_address'], '')}
							nearest_town={extractDataFromArray(listingData, ['listing_json', 'location_info', 'nearest_town'], '')}
							propertyName={extractDataFromArray(listingData, ['listing_json', 'property_details', 'name'], '')}
							listingSlug={extractDataFromArray(listingData, ['listing_json', 'property_details', 'slug'], '')}
							listing_seq={extractDataFromArray(listingData, ['listing_seq'], '')}
							activity_type={extractDataFromArray(packageData, ['package_json', 'activity_type'], '')}
							review={extractDataFromArray(packageData, ['review_count'], 0)}
							type={ACCESS.value}
						/>
							:
							// Mobile view package small card design
							<Box sx={{ mb: 2 }}>
								<PackageCardMobile
									image={extractDataFromArray(packageData, ['package_media', '0'])}
									type={extractDataFromArray(packageData, ['package_media', '0', 'type'])}
									thumbnail={extractDataFromArray(packageData, ['package_media', '0', 'thumbnail_url'])}
									title={extractDataFromArray(packageData, ['title'], '')}
									area={extractDataFromArray(listingData, ['listing_json', 'location_info', 'property_area'], '')}
									activity_type={extractDataFromArray(packageData, ['package_json', 'activity_type'], '')}
									propertyName={extractDataFromArray(listingData, ['listing_json', 'property_details', 'name'], '')}
									listingSlug={extractDataFromArray(listingData, ['listing_json', 'property_details', 'slug'], '')}
									listing_seq={extractDataFromArray(listingData, ['listing_seq'], '')}
									address={extractDataFromArray(listingData, ['listing_json', 'location_info', 'property_address'], '')}
									nearest_town={extractDataFromArray(listingData, ['listing_json', 'location_info', 'nearest_town'], '')}
									showBorder={false}

								/>
							</Box>
						}
						<GuestAndDatesSelection
							guests={guests}
							startDate={startDate}
							endDate={endDate}
							minDays={minDays}
							maxDays={maxDays}
							minGuests={minGuests}
							maxGuests={maxGuests}
							onDateChange={handleDateChange}
							onGuestsChange={handleGuestsChange}
							notAvailableDates={notAvailableDates}
						/>
						<Box sx={{ mb: 3 }}>
							{nonMandatoryCount > 0 && totalNonZeroAmount > 0 && (selectedDays > minDays ? selectedDays : minDays) > 1 && <Box>
								<Typography variant="subtitle1" >
									Optional Add Ons
								</Typography>
								<FormGroup
									sx={{
										"& .MuiFormControlLabel-root": { height: '24px' },
										"& .MuiCheckbox-root": { padding: 0 },
										pl: 1
									}}
								>
									{optionalAddOns.map((option, index) => {
										// // // console.log(option.is_mandatory)
										return (
											!option.is_mandatory &&
											<FormControlLabel
												key={option.caption}
												control={
													<Checkbox
														checked={option.is_mandatory ? true : selectedOptions.some((selectedOption) => selectedOption.caption === option.caption)}
														onChange={() => handleOptionChange(option)}
														disabled={option.is_mandatory}
													/>
												}
												label={`${capitalizeWords(option.caption)} $${option.amount}${option.is_per_day ? `/Night` : ''}`}
											/>
										)
									})}
								</FormGroup>
							</Box>}
						</Box>

						<PricingCard
							listerSeq={extractDataFromArray(listingData, ['lister_seq'])}
							price={extractDataFromArray(packageData, ['package_json', 'price_per_guest'], 1)}
							selectedDays={selectedDays}
							guests={guests}
							stripe={stripe}
							platFormStripe={platFormStripe}
							listingData={listingData}
							packageData={packageData}
							initPayment={initPayment}
							selectedOptions={selectedOptions}
							minDays={minDays}
							maxDays={maxDays}
							selectionPriceSum={selectionPriceSum}
						/>
						{/* <BookingCard
								packageSeq={packageSeq}
								guests={guests}
								defaultDateRange={[startDate,endDate]}
								minDays={minDays}
								maxDays={maxDays}
								minGuests={minGuests}
								maxGuests={maxGuests}
								handleGuestChange={handleGuestsChange}
								handleDateChange={handleDateChange}
								price={pricePerGuest}
								notAvailableDates={notAvailableDates}
							/> */}
						<Box
							sx={isTablet && {
								width: '100%',
								height: '82px',
								position: 'fixed',
								ml: -2,
								bottom: 0,
								backgroundColor: 'white',
								display: 'flex',
								alignItems: 'center', // Vertically align the items
								justifyContent: 'space-between',
								flexDirection: 'column',
								boxShadow: '0 -2px 4px 0 rgba(0,0,0,0.15)',
								zIndex: '999',
								px: 2
							}}
						>
							{/* <Button
								fullWidth
								size="large"
								variant="contained"
								sx={!isTablet ? { mt: 4 } : { my: 'auto' }}
								onClick={initPayment}
							>
								Confirm and Pay
							</Button> */}
							<LoaderButton
								variantType='contained'
								size="large"
								onClick={() => { initPayment() }}
								isLoading={loaderButton}
								buttonText="Confirm and Pay"
								isFullWidth={true}
								// sx={!isTablet ? { mt: 4 } : { my: 'auto' }}
								mt={!isTablet ? 4 : 'auto'}
								mb={!isTablet ? 0 : 'auto'}
							/>
						</Box>


						{
							!empty(options) &&
							<Elements stripe={platFormStripe} options={options}>
								{open && <PayPopup open={open} handleClose={handleClose} listingData={listingData}
									packageData={packageData}
									guests={guests}
									startDate={startDate}
									selectedDays={selectedDays}
									endDate={endDate}
									maxDays={maxDays}
									minGuests={minGuests}
									maxGuests={maxGuests}
									onDateChange={handleDateChange}
									onGuestsChange={handleGuestsChange}
									setLoaderButton={setLoaderButton}
									selectedOptions={selectedOptions}
									minDays={minDays}
									selectionPriceSum={selectionPriceSum}
									timeout={payPopUpTimeout}
									isLoadingButton={isLoadingButton}
								/>}

							</Elements>
						}
					</Box>
				}
				{/* <IDVerificationPopup open={openIdPopup} handleClose={() => { setOpenIdPopup(false) }} handleProceed={handleVerifyIdentity} /> */}
				<Dialog open={openIdPopup} maxWidth='lg' fullWidth onClose={() => { setOpenIdPopup(false); setLoaderButton(false); }} sx={{
					'& .MuiPaper-root': {
						maxWidth: '1167px'
					}
				}} >
					<Box>
						<Box sx={{ px: isMobile ? 2 : 3, display: "flex", justifyContent: 'space-between', alignItems: 'center' }}>
							<Typography variant="h6" sx={{ fontWeight: 700 }}>Ready to book? Verify your details now!</Typography>
							<IconButton sx={{ p: isMobile ? 2 : 3 }} onClick={() => { setOpenIdPopup(false); setLoaderButton(false) }}><Icon.Close /></IconButton>
						</Box>
						<Divider />
						<Box sx={{ p: isMobile ? 2 : 3 }}>
							<AccountVerification
								verification_title={ACCOUNT_VERIFICATION_TEXTS.guest_booking}
								completedCallback={() => { setAccountActive(true); setOpenIdPopup(false); setLoaderButton(false); checkListerAccountStatus(); initPayment(); }}
								skipIDVerification={true}
								sx={{ p: isMobile ? 2 : 3 }}
								subtitle={
									<Box>
										<>
											<Typography sx={{
												color: 'var(--grey900)',
												mb: 2
											}}>Please complete the following two steps in any order, and you will be ready to explore and book on FarWide instantly.</Typography>
											<Typography sx={{
												display: 'flex',
												color: 'var(--grey900)',
												mb: 1
											}}>
												{/* <Chip label="1" variant="outlined"
													size='small'
													sx={{
														color: 'var(--grey900)',
														height: '24px',
														width: '24px',
														bgcolor: 'white',
													}} /> */}
												<CreateChip caption={"1"} chipType={"numbers"} /><Box sx={{ pl: 1 }}>Verify your email address</Box>
											</Typography>
											<Typography sx={{
												display: 'flex',
												color: 'var(--grey900)',
												mb: 1
											}}>
												{/* <Chip label="2" variant="outlined"
													size='small'
													sx={{
														color: 'var(--grey900)',
														height: '24px',
														width: '24px',
														bgcolor: 'white',
														pr: 1
													}} /> */}
												<CreateChip caption={"2"} chipType={"numbers"} /><Box sx={{ pl: 1 }}>Provide a government-issued ID for verification</Box>
											</Typography>
											<Typography sx={{
												color: 'var(--grey900)',
												mb: 2
											}}>
												{/* <Chip label="3" variant="outlined"
										size='small'
										sx={{
											color: 'var(--grey900)',
											height: '24px',
											width: '24px',
											bgcolor: 'white',
											pr: 1
										}} /> Set up your Stripe Connect account.<br /> */}
											</Typography>
											Click below to start -										</>
									</Box>
								}
							/>
						</Box>

					</Box>
				</Dialog>
			</Box>
		</>
	)
}

export default PackageCheckout;