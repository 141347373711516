import { Box, MenuItem, Select, Typography } from "@mui/material"
import { useEffect, useState } from "react";
import { empty } from "../../../utils/common";
import PaginationMUI from '@mui/material/Pagination'
import { CommonContext } from "../../../store/context/commonContext";

const Pagination = ({ totalRecords, currentPage, setCurrentPage, setRowsPerPage }) => {
    const [page, setPage] = useState(empty(currentPage) ? 1 : currentPage);
    const [rowsPerPage, _setRowsPerPage] = useState(10);

    let totalPages = Math.ceil(totalRecords / rowsPerPage)
    const { screenWidth } = CommonContext()

    const onPageSelect = (nextPage) => {
        setPage(nextPage)
        setCurrentPage(nextPage)
    }
    const onRowsPerPageSelect = (e) => {
        _setRowsPerPage(e.target.value)
        setRowsPerPage(e.target.value)
        setPage(1)
        setCurrentPage(1)
    }

    useEffect(()=>{
        setPage(empty(currentPage) ? 1 : currentPage)
    },[currentPage])

    return (
        <>{
            totalRecords > 0 &&

            <Box sx={{ p: { xs: 2, sm: 2, md: 3, lg: 3 }, display: 'flex', flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between', alignItems: 'center' }}>
                <Box sx={{ display: 'flex', marginBottom: { xs: '1rem', md: '0' }, flexDirection: { xs: 'column', md: 'row' }, alignItems: 'center', }}>
                    <Box sx={{ display: 'flex', marginBottom: { xs: '1rem', md: '0' }, alignItems: 'center', justifyContent: { xs: 'space-between', sm: 'space-between', md: 'normal' } }}>
                        <Typography sx={{ mr: 1 }}>Rows per page</Typography>
                        <Select
                            value={rowsPerPage}
                            onChange={onRowsPerPageSelect}
                            size="small"
                            sx={{ px: 1, mr: 1, height: '42px' }}
                        >
                            <MenuItem value={5}>{5}</MenuItem>
                            <MenuItem value={10}>{10}</MenuItem>
                            <MenuItem value={20}>{20}</MenuItem>
                            <MenuItem value={50}>{50}</MenuItem>
                            <MenuItem value={100}>{100}</MenuItem>

                        </Select>
                    </Box>
                    <Typography sx={{ mr: 1, }}>Showing {((page - 1) * rowsPerPage) + 1} - {(page === totalPages) ? totalRecords : page * rowsPerPage} of {totalRecords} entries </Typography>
                </Box>
                <Box>
                    <PaginationMUI size={screenWidth <= 1100 ? 'small' : 'medium'} count={totalPages} page={page} onChange={(e, value) => { onPageSelect(value) }} />
                </Box>
            </Box>
        }
        </>
    )
}

export default Pagination