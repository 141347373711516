import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton';
import * as Icon from '@mui/icons-material';
import 'photoswipe/style.css';
import UploadPopup from '../../../commonComponents/FileManager/components/UploadPopup';
import UploadImagePopup from "../../../commonComponents/FileManager/UploadImagePopup"
import errorAndHelperText from "../../../commonComponents/CommonConstants/errorAndHelperText.json"
import { Button,  Dialog, Divider, Typography } from '@mui/material';
import 'swiper/css';
import 'swiper/css/pagination';
import { ImagePreview } from '../../../commonComponents/previewPageComponents/HeaderGallery';
import { toastMessage } from '../../../utils/common';


const UploadReviewPhotos = ({ setToggle, reviewPresent, reviewData, image, setImage, setReviewData, section, limit = 0 }) => {
    const [photos, setPhotos] = useState([]);
    // console.log("reviewData={reviewData}", reviewData)


    const [conflictOpen, setConflictOpen] = useState(false)
    const [newSelection, setNewSelection] = useState({})
    const [exceedCount, setExceedCount] = useState(0)
    // console.log(photos);

    const [open, setOpen] = useState(false)
    const [selectedImageIndex, setSelectedImageIndex] = useState(null);
    // console.log(reviewData);

    const handleClose = () => {
        setOpen(false);
        setSelectedImageIndex(null); // Reset selected image index when closing
    }

    const handleOpen = (index) => {
        setSelectedImageIndex(index); // Set the index of the clicked image
        setOpen(true);
    }

    const handleConflictProceed = () => {
        setNewSelection((prevImages) => {
            prevImages.splice(-exceedCount); // remove last added images
            return [...prevImages]; // Return a new array to trigger state update
        });
        if (reviewData?.listing?.images === "[]" && newSelection?.length > 0 && section === 'listing') {
            reviewData.listing.images = []
        } if (reviewData?.package?.images === "[]" && newSelection?.length > 0 && section === 'package') {
            reviewData.package.images = []
        } if (reviewData?.host?.images === "[]" && newSelection?.length > 0 && section === 'host') {
            reviewData.host.images = []
        }

        newSelection.forEach(photo => {
            setReviewData(prevState => ({
                ...prevState,
                [section]: {
                    ...prevState[section],
                    images: Array.isArray(prevState[section]?.images) ? [...prevState[section]?.images, photo] : [photo]
                }
            }));
        });



        setPhotos((prevPhotos) => [...prevPhotos, ...newSelection]);
        setImage((prevPhotos) => [...prevPhotos, ...newSelection]);
        setToggle(true)
        setConflictOpen(false)
    };


    useEffect(() => {
        // Update photos state with images from reviewPresent when the component mounts
        if (reviewPresent && reviewPresent[section] && Array.isArray(reviewPresent[section].images)) {
            setPhotos(reviewPresent[section].images);
        }
    }, [reviewPresent, section]);

    const onImageSelect = (images) => {
        for (let entry of images) {
            if (entry.type !== 'IMAGE') {
                // toast.error(`Only image files are allowed. Please upload an image.`, {
                //     theme: "colored",
                // });
                toastMessage(errorAndHelperText.image_uplaod.error);
                return false;
            }
        }
        if (limit != 0) {
            if (images.length + photos.length > limit) {
                // toast.warning(`You can upload upto ${limit} photos`, {
                //     theme: "colored",
                // });
                toastMessage({
                    message: `You can upload upto ${limit} photos`,
                    type: 'warning',
                });
                setNewSelection(images)
                setExceedCount(images.length + photos.length - limit)
                setConflictOpen(true)
                return
            }
        }
        // console.log("image in useEffect", images)
        // console.log("reviewData={reviewData}", reviewData)
        if (reviewData?.listing?.images === "[]" && images?.length > 0 && section === 'listing') {
            reviewData.listing.images = []
        } if (reviewData?.package?.images === "[]" && images?.length > 0 && section === 'package') {
            reviewData.package.images = []
        } if (reviewData?.host?.images === "[]" && images?.length > 0 && section === 'host') {
            reviewData.host.images = []
        }

        images.forEach(photo => {
            setReviewData(prevState => ({
                ...prevState,
                [section]: {
                    ...prevState[section],
                    images: Array.isArray(prevState[section]?.images) ? [...prevState[section]?.images, photo] : [photo]
                }
            }));
        });



        setPhotos((prevPhotos) => [...prevPhotos, ...images]);
        setImage((prevPhotos) => [...prevPhotos, ...images]);
        setToggle(true)
    };


    const removeSelectedPhoto = (index) => {
        // console.log('photos', photos)
        let updatedPhotos = photos.filter((photo, photoIndex) => photoIndex !== index);
        // console.log('updatedPhotos', updatedPhotos)
        let arr = []
        updatedPhotos.forEach((item) => {
            arr.push(item)
        })
        // updatedPhotos.forEach(photo => {

        setReviewData(prevState => ({
            ...prevState,
            [section]: {
                ...prevState[section],
                images: arr
            }
        }));

        setPhotos(updatedPhotos);
        setToggle(true)

    };
    return (
        <Grid container direction="column" spacing={3}>
            <Grid item>
                <UploadImagePopup label="Add Photos" buttonText={'Add Photos'} selectedCallback={onImageSelect} />
                <Box><Typography variant="caption" sx={{ mt: '-30px', color: 'var(--grey900)' }}>*You can upload upto {limit} photos</Typography></Box>
            </Grid>
            <UploadPopup />
            <Grid item>
                <Box sx={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-start" }}  >
                    {photos.map((photo, index) => {
                        // console.log(photo);
                        return (
                            <Box key={"photo_" + index} sx={{ padding: 1 }}>
                                <ShowUploadedReviewImages
                                    handleOpen={handleOpen}
                                    curImgIndex={index}
                                    image={photo}
                                    removeSelectedPhoto={() => removeSelectedPhoto(index)}
                                    reviewPresent={reviewPresent}
                                />
                            </Box>
                        )
                    })}
                </Box>
            </Grid>
            {Array.isArray(photos) && <ImagePreview open={open} handleClose={handleClose} handleOpen={handleOpen} images={photos} imageIndex={selectedImageIndex} setSelectedImageIndex={setSelectedImageIndex} />}
            <Dialog open={conflictOpen}>
                <Box>
                    <Box sx={{ p: 2.5 }}>
                        <Typography variant="h6" sx={{ fontWeight: 700 }}>
                            Maximum upload limit reached
                        </Typography>
                    </Box>
                    <Divider />
                    <Box sx={{ p: 2.5 }}>
                        <Typography>
                            {
                                (newSelection.length - exceedCount) === 0 ? `More images can not be uploaded as you have already reached the maximum upload limit of ${limit} images.`
                                    : `Only ${newSelection.length - exceedCount} of these images will be uploaded as you have reached the maximum upload limit of ${limit} images.`
                            }
                        </Typography>
                    </Box>
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "end",
                            gap: 1,
                            p: 2.5,
                            width: "100%",
                        }}
                    >
                        <Button
                            variant="contained"
                            color="error"
                            onClick={handleConflictProceed}
                        >
                            Proceed
                        </Button>
                        <Button
                            variant="text"
                            color="secondary"
                            onClick={() => { setConflictOpen(false); setNewSelection([]) }}
                        >
                            CANCEL
                        </Button>
                    </Box>
                </Box>
            </Dialog>
        </Grid>
    );
}

export default UploadReviewPhotos;

{/* <Grid container spacing={{xs: 0.5, sm: 0.5, md: 0.5, lg: 0.5 }}  >
                    {photos.map((photo, index) => (
                        <Grid item  xs={12} sm={6} md={6} lg={4}  key={"photo_" + index}>
                            <ShowUploadedReviewImages
                                curImgIndex={index}
                                imagePath={photo.url}
                                removeSelectedPhoto={() => removeSelectedPhoto(index)}
                                reviewPresent={reviewPresent}
                            />
                        </Grid>
                    ))}
                </Grid> */}


export function ShowUploadedReviewImages({ reviewPresent, curImgIndex, image, removeSelectedPhoto, handleOpen }) {
    const handleRemoveSelectedPhoto = (e) => {
        e.preventDefault();
        removeSelectedPhoto();
    };

    return (
        <Box className="upload_image"
            sx={{
                borderRadius: 2, display: 'flex', alignItems: 'center',
                //  backgroundImage: `url(${image.url} )`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "cover !important", width: "100%", aspectRatio: 1,
                position: 'relative',
                cursor: 'pointer',
                ":hover": {

                    ".upload_image_del_icon": {
                        opacity: 1,
                    },
                },
            }}>
            <img
                onClick={() => { handleOpen(curImgIndex) }}
                style={{
                    height: '76px',
                    position: 'relative',
                    width: '92px',
                    borderRadius: '8px',
                    objectFit: "cover"
                }} src={image.url} />
            <IconButton size="small" className='upload_image_del_icon' aria-label="delete" onClick={handleRemoveSelectedPhoto} sx={{
                boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)", position: 'absolute',
                top: 0,
                right: 0,
                opacity: 0,
                transition: 'opacity 0.3s ease',
            }}>
                <Icon.Close sx={{ fontSize: 16, color: "var(--black)" }} />
            </IconButton>
        </Box>
    );
}