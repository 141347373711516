import { Box, Button, Grid, IconButton, TextField, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import * as Icon from '@mui/icons-material';
import { UserAuth } from "../../../store/context/userContext";
const ForgotPassword = ({ handleOpen }) => {
    let { authPopup, setAuthPopup } = UserAuth()
    const closePopup = () => {
        setAuthPopup({ ...authPopup, open: false })
    }
    return (
        <Grid container maxWidth="lg" sx={{ m: "auto" }}
            direction="row"
            justifyContent="center"
            alignItems="stretch">
            <Grid item xs={12} sm={8} md={6}>
                <Box sx={{ backgroundColor: "#F1F1F1", height: "100%", alignItems: "center", display: "flex", justifyContent: "center" }}>
                    <img className="responsive_img" src="/landowner/assets/images/login/forgot_password.png" alt="Forgot Password" />
                </Box>
            </Grid>
            <Grid item xs={12} sm={8} md={6}>
                <Box sx={{ float: "right", m: 1 }}>
                    <IconButton onClick={closePopup}><Icon.Close /></IconButton>
                </Box>
                <Box sx={{ p: 6 }}>
                    <Box sx={{ mb: 3 }}>
                        <img src='../landowner/assets/images/farwide-new-logo.svg' alt='FarWide' />
                    </Box>

                    <Typography variant="h4" color="secondary" sx={{ fontWeight: 700, mb: 1 }}>Forgot password? </Typography>
                    <Typography> Enter the email address associated with an account.</Typography>


                    <Grid container spacing={3} sx={{ mt: 2 }}>
                        <Grid item xs={12} sm={12} md={12}>
                        <Typography sx={{}} >Email</Typography>
                            <TextField fullWidth id="email" label="" type={"email"} variant="outlined" />
                        </Grid>
                        <Grid item xs={12} sm={12} md={12} sx={{ textAlign: "center" }}>
                            <Typography variant="body2">Back to <Link onClick={() => handleOpen("signin")} color='secondary'>Sign in</Link></Typography>
                        </Grid>

                        <Grid item xs={12} sm={12} md={12}>
                            <Button fullWidth type='submit' variant='contained' size='large'>
                                Reset Password
                            </Button>
                        </Grid>
                    </Grid>
                </Box>


            </Grid>
        </Grid>
    )
}
export default ForgotPassword