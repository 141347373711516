import {
  Avatar,
  Box,
  CircularProgress,
  Divider,
  InputBase,
  Typography,
} from "@mui/material";
import * as Icon from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import SendIcon from "@mui/icons-material/Send";
import { useLocation, useNavigate, useParams, useHistory } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import LeftChat from "./LeftChat";
import RightChat from "./RightChat"
// import Menu from "@mui/material/Menu";
// // import MenuItem from "@mui/material/MenuItem";
// import EmojiPicker from "emoji-picker-react";
// import CircularProgress from "@mui/material/CircularProgress";
import Skeleton from "@mui/material/Skeleton";
import { MessagesApi } from "../../../services/messagesApi";
// import messagejson from "../message.json"
import moment from "moment";
import { MessageContext } from "../../../store/context/messageContext";
import config from "../../../config";
import { extractDataFromArray } from "../../../utils/common";

const settings = [
  { icon: <Icon.Computer sx={{ color: "#747579" }} />, title: "From Computer" },
  {
    icon: <Icon.CollectionsOutlined sx={{ color: "#747579" }} />,
    title: "From File Manager",
  },
];

const arr = [0, 0, 0, 0];

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);


// Custom hook to manage viewport height
const useViewportHeight = () => {
  const [viewportHeight, setViewportHeight] = useState(window.innerHeight - 30);

  useEffect(() => {
    const handleResize = () => {
      setViewportHeight(window.innerHeight - 30);
    };

    window.addEventListener('resize', handleResize);
    handleResize(); // Set initial height

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return viewportHeight;
};

const ShowMessages = ({
  userMessageLastSeqValue,
  fetchUserMessages,
  userMessages,
  setUserMessages,
  setOpenMessageDrawer,
  openMessageDrawer,
  setOpenInfoDrawer,
  openInfoDrawer,
  loadingUser,
  setToggleNewMessage,
  toggleNewMessage,
  setLoadingUser,
  isComingFromContactPage,
  setIsComingFromContactPage,
  setIsInputClicked,
  connections
}) => {
  const navigate = useNavigate();
  const param = useParams();


  const {
    isMobile,
    setIsMessageDashboard,
    setIsShowMessages,
    setIsProfile,
    setIsLoading,
    listContainerRef,
    senderSeqToSendChat,
    scrollableDivRef,
    setToBottom, newMessageReceivedStatus,
    senderTypeToSendChat, seqValue, isNewMessage, setIsNewMessage, setNewUserMessageReceivedStatus, setDetail, userDetail, setuserDetail, setLastUserReadMessageSeq, randomNumber, setIntervalTime, intervalTime, heightVariable, isMessageFromAdmin, setIsMessageFromAdmin, setUserType, userType
  } = MessageContext();

  useEffect(() => {

    setTimeout(() => {

      scrollableDivRef?.current?.scrollIntoView({ // Added this to avoid entire page scroll when last message is shown using scrollableDivRef 
        behavior: 'instant',
        block: 'nearest',
        inline: 'start'
      });
    }, 300)
  }, [])



  useEffect(() => {

    if (userDetail.detail == "") {
      setLoadingUser(false)
    }
  }, [])

  let userType1 = '';
  useEffect(() => {
    fetchUserMessages(param.seq)
    setLoadingUser(true)


    if (!Array.isArray(connections) || !param) {
      userType1 = '';
      return;
    }

    // Find the matching conversation object
    const matchingConnection = connections?.find(connection =>
      connection?.conversation && connection?.conversation?.seq === param?.seq
    );

    // Check if the matching connection has type "A"
    if (matchingConnection && matchingConnection?.conversation) {
      userType1 = matchingConnection?.conversation?.type === "A" ? "A" : "";
    } else {
      userType1 = '';
    }


    MessagesApi.getUserInfo(param.seq, userType1, (res) => {
      setDetail(res)
      setuserDetail(res)
      setLoadingUser(false)
    })

    setIntervalTime(300)
    setTimeout(() => {
      setIntervalTime(20000)
    }, 400)
  }, [param.seq])


  const location = useLocation();
  const [newLastMessageSeq, setNewLastMessageSeq] = useState("")
  const [isReply, setIsReply] = useState({
    flag: false,
    conversation: {},
    time: "",
  });
  const [connectionDetail, setConnectionDetail] = useState({
    active: false,
    lastseen: "",
  });


  const [scrollTop, setScrollTop] = useState()
  const [scrollHeight, setScrollHeight] = useState()
  const [clientHeight, setClientHeight] = useState()
  const [isScrollNearBottom, setScrollNearBottom] = useState(false);
  // let firstMessageSeqValue = '' replaced this variable with below state to fix issue of firstMessageSeqValue not updating correctly while implementing circular loader

  const [firstMessageSeqValue, setFirstMessageSeqValue] = useState('');


  let lastDisplayedDate = null;
  const [emoji, setEmoji] = useState("");

  const onEmojiClick = (event) => {

    // setMessage(event.emoji);
  };

  let hasScrollreachedTop = true;
  const [showLoader, setShowLoader] = useState(false) // Added state to show circular loader
  const [reachedFirstEverMessage, setReachedFirstEverMessage] = useState(false) // Added this state to avoid showing circular loader when reached first ever message in the chat

  const [anchorElUser, setAnchorElUser] = useState(null);
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };
  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [anchorElUser1, setAnchorElUser1] = useState(null);
  const handleOpenUserMenu1 = (event) => {
    setAnchorElUser1(event.currentTarget);
  };
  const handleCloseUserMenu1 = () => {
    setAnchorElUser1(null);
  };

  function createMessageObject(sendMessage, param, senderSeqToSendChat, senderTypeToSendChat, config) {
    let obj = {};

    if (senderSeqToSendChat.length === 0) {
      if (config.module === 'lister') {
        obj = { ...sendMessage, receiver_seq: param.seq, receiver_type: "G" };
      } else {
        obj = { ...sendMessage, receiver_seq: param.seq, receiver_type: "L" };
      }
    } else {
      obj = { ...sendMessage, receiver_seq: senderSeqToSendChat, receiver_type: senderTypeToSendChat };
    }

    return obj;
  }


  // USER LAST SEEN LOGIC STARTS

  useEffect(() => {

    const intervalId = setInterval(() => {
      const result = createMessageObject(sendMessage, param, senderSeqToSendChat, senderTypeToSendChat, config);


      MessagesApi.getUserLastSeen(
        result.receiver_seq,
        (res) => {

          const dateTime = res.last_seen;
          let local_date = moment.utc(dateTime).local().format('YYYY-MM-DD HH:mm:ss');
          const [date, time] = local_date.split(" ");

          const statusTime = moment(local_date);
          const currentTime = moment();

          const timeDifference = currentTime.diff(statusTime, 'seconds');

          if (timeDifference <= 60) {
            setConnectionDetail({ active: true, lastseen: "" });
          } else {
            const currentDate = moment();
            const isToday = date === currentDate.format('YYYY-MM-DD');

            if (isToday) {
              const formattedTime = moment(local_date).format('h:mm A');
              setConnectionDetail({ active: false, lastseen: `at ${formattedTime}` });
            } else {
              const providedDate = moment(date);
              const yesterday = moment().subtract(1, 'day');

              if (providedDate.isSame(yesterday, 'day')) {
                setConnectionDetail({ active: false, lastseen: "Yesterday" });
              } else {
                const formattedDate = providedDate.format('MM/DD/YY');
                setConnectionDetail({ active: false, lastseen: `on ${formattedDate}` });
              }
            }
          }


          setIsLoading(false);
        },
        (err) => {
          // console.log(err);
          setIsLoading(false);
        }
      );
    }, intervalTime);
    return () => clearInterval(intervalId);

  }, [intervalTime, senderSeqToSendChat, param.seq]);

  // USER LAST SEEN LOGIC ENDS


  // DIVIDER CHAT DATE FORMAT LOGIC STARTS

  const formatDividerChatDate = (dateString) => {
    const currentDate = new Date();
    const chatDate = new Date(dateString);

    const formatDateWithoutTime = (date) =>
      new Date(date.getFullYear(), date.getMonth(), date.getDate());

    if (formatDateWithoutTime(currentDate).getTime() === formatDateWithoutTime(chatDate).getTime()) {
      return "Today";
    }

    const yesterday = new Date(currentDate);
    yesterday.setDate(currentDate.getDate() - 1);

    if (formatDateWithoutTime(yesterday).getTime() === formatDateWithoutTime(chatDate).getTime()) {
      return "Yesterday";
    }

    return chatDate.toLocaleDateString("en-US", {
      year: "numeric",
      month: "short",
      day: "numeric",
    });
  };

  // DIVIDER CHAT DATE FORMAT LOGIC ENDS


  const [sendMessage, setSendMessage] = useState({
    cmd: "setMessage",
    receiver_seq: senderSeqToSendChat,
    receiver_type: senderTypeToSendChat,
    package_seq: "",
    message: "",
    message_type: "text",
    attachments: [],
    device: [],
    ip: [],
  })


  const reversedApiResponse = [...userMessages].reverse();


  useEffect(() => {
    if (reversedApiResponse?.length > 0) {

      // firstMessageSeqValue = reversedApiResponse[0].seq; replaced this variable with below state update function to fix issue of firstMessageSeqValue not updating correctly while implementing circular loader

      setFirstMessageSeqValue(reversedApiResponse[0].seq);
      setNewLastMessageSeq(reversedApiResponse[reversedApiResponse.length - 1]?.seq)
      setLastUserReadMessageSeq(reversedApiResponse[reversedApiResponse.length - 1]?.seq)

    }

  }, [reversedApiResponse])


  // SEND MESSAGE LOGIC STARTS

  const handleSendMessage = () => {
    let obj = {}
    if (senderSeqToSendChat.length === 0) {
      if (config.module === 'lister') {

        obj = { ...sendMessage, receiver_seq: param.seq, receiver_type: "G" }
      } else {
        obj = { ...sendMessage, receiver_seq: param.seq, receiver_type: "L" }

      }
    } else {
      obj = { ...sendMessage, receiver_seq: senderSeqToSendChat, receiver_type: senderTypeToSendChat }

    }


    if (sendMessage.message !== "") {


      MessagesApi.setMessage(
        obj,
        (res) => {

        },
        (err) => {
          // console.log(err);
        }
      );

      setTimeout(() => {
        if (senderSeqToSendChat === "") {
          newMessages(newLastMessageSeq, param.seq);
        } else {
          newMessages(newLastMessageSeq, senderSeqToSendChat);

        }
        setToggleNewMessage(true)

      })

      setTimeout(() => {
        scrollableDivRef?.current?.scrollIntoView();
      }, 600)
    }


    setSendMessage({ ...sendMessage, message: "" });

    setIsReply({ flag: false, conversation: {}, time: "" });

  };


  // SEND MESSAGE LOGIC ENDS





  const [shouldFetchData, setShouldFetchData] = useState(false);
  const [topApiCalled, setTopApiCalled] = useState(false);
  const [bottomApiCalled, setBottomApiCalled] = useState(false);


  // NEW MESSAGE FETCH LOGIC STARTS


  const newMessages = async (userMessageLastSeqValue, ReceiverSequenceValuw) => {
    // console.log("last message seq", userMessageLastSeqValue,newLastMessageSeq)
    let responce = await MessagesApi.getNewUserMessages(newLastMessageSeq, ReceiverSequenceValuw, "", (res) => {

      const filterUniqueNewMessages = res.filter(newMessage => !userMessages.some(existingMessage => existingMessage.seq === newMessage.seq));

      setUserMessages((prevMessages) => [...filterUniqueNewMessages, ...prevMessages]);
    }, (error) => {
      // console.log(error)
    })

  }

  // NEW MESSAGE FETCH LOGIC ENDS


  // NEW MESSAGE STATUS FROM PARTICULAR USER 

  useEffect(() => {
    const fetchData = async (currentSenderSeq) => {
      if (currentSenderSeq === "") {
        currentSenderSeq = param.seq
      }
      await MessagesApi.getNewUserMessageReceivedStatus(currentSenderSeq,
        (res) => {

          setNewUserMessageReceivedStatus(res)
          setIsNewMessage(res.received_new_user_message)

        },
        (err) => {
          // console.log(err);
        }
      );
    };

    fetchData(senderSeqToSendChat);


  }, [senderSeqToSendChat, newLastMessageSeq]);



  useEffect(() => {
    setTimeout(() => {

      if ((newLastMessageSeq !== "" && isNewMessage) || newMessageReceivedStatus || toggleNewMessage || !newMessageReceivedStatus) {

        const result = createMessageObject(sendMessage, param, senderSeqToSendChat, senderTypeToSendChat, config);

        newMessages(newLastMessageSeq, result.receiver_seq);

      } else if (isNewMessage || newMessageReceivedStatus || toggleNewMessage || !newMessageReceivedStatus) {

        const result = createMessageObject(sendMessage, param, senderSeqToSendChat, senderTypeToSendChat, config);

        newMessages(userMessageLastSeqValue, result.receiver_seq);

      }
    })
  }, [newMessageReceivedStatus, isNewMessage, randomNumber])


  // SCROLL LOGIC STARTS
  const isLoadingRef = useRef(false); // Added this ref to fix issue when scroll top === 0 no api call was happening in previous code 
  const scrollContainerRef = useRef(null); // Add this ref for the scroll container to set scroll position at the message before old messages are loaded

  const handleScroll = (event) => {
    setToBottom(false)
    const currentScrollTop = event.target.scrollTop; // Added extra variable

    setScrollTop(currentScrollTop)
    setScrollHeight(event.target.scrollHeight)
    setClientHeight(event.target.clientHeight)


    const distanceToBottom = scrollHeight - (scrollTop + clientHeight);
    const isNearBottom = distanceToBottom < 0.1 * scrollHeight;

    setScrollNearBottom(isNearBottom);


    if (scrollHeight - scrollTop === clientHeight) {
      setShouldFetchData(true);

      let obj = { ...sendMessage, receiver_seq: senderSeqToSendChat }

    }
    if (currentScrollTop === 0 && !isLoadingRef.current) {  // Added !isLoadingRef.current to fix issue when scroll top === 0 no api call was happening in previous code 
      isLoadingRef.current = true;

      const result = createMessageObject(sendMessage, param, senderSeqToSendChat, senderTypeToSendChat, config);


      if (hasScrollreachedTop) {
        if (!reachedFirstEverMessage) {
          setShowLoader(true)
        }
        MessagesApi.getOlderUserMessages(firstMessageSeqValue, result.receiver_seq, 10, userType1, (res) => {
          if (res.length === 0) { // Condition to not show loader if first message reached
            setReachedFirstEverMessage(true)
          } else {
            setReachedFirstEverMessage(false)
          }
          const filterUniqueNewMessages = res.filter(newMessage => !userMessages.some(existingMessage => existingMessage.seq === newMessage.seq));

          setUserMessages((prevMessages) => [...prevMessages, ...filterUniqueNewMessages])
          if (filterUniqueNewMessages.length > 0) { // This logic help track scroll position of scroll when getOlderUserMessages api is called in order to set scroll to the same postion when old messages are loaded 


            // setFirstMessageSeqValue(filterUniqueNewMessages[filterUniqueNewMessages.length - 1].seq);

            // Use requestAnimationFrame to ensure DOM has updated
            requestAnimationFrame(() => {
              // Calculate and set the new scroll position
              const newScrollTop = scrollContainerRef.current.scrollHeight - scrollHeight;
              scrollContainerRef.current.scrollTop = newScrollTop;
            });
          }
          setShowLoader(false)
        }, (error) => {
          setShowLoader(false)
          isLoadingRef.current = false;

        })
        isLoadingRef.current = false;
        hasScrollreachedTop = false
      }

      setBottomApiCalled(true);
      setTopApiCalled(false);


    }
  }

  let obj = { ...sendMessage, receiver_seq: senderSeqToSendChat };


  // SCROLL LOGIC ENDS



  // const viewportHeight = useViewportHeight(); 

  // useEffect(() => {
  //   if (isMobile) {
  //     // Prevent scrolling on mobile
  //     document.body.style.overflow = 'hidden';
  //     document.documentElement.style.overflow = 'hidden';
  //   }

  //   return () => {
  //     // Restore scrolling when component unmounts
  //     document.body.style.overflow = '';
  //     document.documentElement.style.overflow = '';
  //   };
  // }, [isMobile]);


  // Scroll the input into view on focus (for iOS devices)
  const handleFocus = () => {
    // console.log("heree 1");
    setIsInputClicked(true)
    // setTimeout(() => {
    //   const inputElement = document.querySelector(".message-input");
    //   if (inputElement) {
    //     console.log("heree 222");
    //     inputElement.scrollIntoView({ behavior: "smooth", block: "center" });
    //   }
    // }, 300); // Delay to allow the keyboard to open first

  };

  const handleBlur = () => {
    // console.log("heree 3");
    setIsInputClicked(false)
    // document.body.style.position = ""; // Re-enable scrolling when input loses focus
  };


  return (
    <Box sx={{ flex: 1, border: "1px solid #E5E7EB", borderRadius: 4.5 }}>
      {location.pathname === "/chat" ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // height: "calc(100vh - 290px)",  // Changed height 196px (After search box was hidden) to 263px then to 290px after footer was added (Before Dec 9 2024)
            height: heightVariable, // Added this after footer was added to fix messages not showing on smaller desktop devices (Dec 9 2024)
            width: "100%",
          }}
        >
          <img
            src={"chat_image.svg"}
            alt="message"
            width={277}
            height={153}
          />{" "}
        </Box>
      ) : (

        <Box
          sx={
            isMobile
              ? {
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                // height: "calc(100svh - 104px)", // Original code block before search box hidden
                height: "calc(100svh - 197px)", // Added this after search box hidden and, Changed height 130px to 197px after footer was added 
                // height: `${viewportHeight - 113}px`,commented out 
                // overflow: 'hidden',
                // touchAction: 'none'
              }
              : {
                flex: 1,
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                // height: "calc(100vh - 290px)",   // Changed height 196px (After search box was hidden) to 263px then to 290px after footer was added (Before Dec 9 2024)
                height: heightVariable, // Added this after footer was added to fix messages not showing on smaller desktop devices (Dec 9 2024)
              }
          }
        >

          <Box
            sx={{
              borderBottom: "1px solid #E5E7EB",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              paddingInline: 2,
              paddingBlock: 2,
              gap: 1
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 2,
              }}
            >
              {isMobile ? (
                <Icon.ArrowBackOutlined
                  onClick={() => {
                    setIsMessageDashboard(true);
                    setIsShowMessages(false);
                    setIsProfile(false);
                    navigate("/chat");
                    setIsComingFromContactPage(false);
                  }}
                  sx={{
                    color: "#747579",
                    cursor: "pointer",
                    width: 24,
                    height: 24,
                  }}
                />
              ) : (
                <Icon.MenuOpen
                  sx={{
                    color: "#747579",
                    cursor: "pointer",
                    width: 24,
                    height: 24,
                    transform: openMessageDrawer ? 'scaleX(1)' : 'scaleX(-1)',
                    transition: 'transform 0.3s ease',
                  }}
                  onClick={() => {
                    setOpenMessageDrawer(!openMessageDrawer);
                  }}
                />
              )}

              {loadingUser ? <Skeleton variant="circular" width={42} height={42} sx={{ mb: '6px' }} /> :

                <Avatar
                  alt="Remy Sharp"
                  src={userDetail.profile_pic}
                  sx={{ width: 42 }}
                />
              }


              <Box
                sx={{
                  textAlign: "left",
                }}
              >
                {loadingUser ? <Skeleton variant="rectangular" width={168} height={16} sx={{ mb: 1 }} /> :
                  <Box sx={{
                    display: "flex",
                    alignContent: "center",
                    gap: 1,
                  }}>
                    <Typography
                      variant="body1"
                      className="ellipsis"
                      sx={{
                        color: "#1B1B1B",
                        fontWeight: 600,
                      }}
                    >
                      {userDetail.device === "" ? extractDataFromArray(userDetail, ['conversation', 'fname'], '') + " " + extractDataFromArray(userDetail, ['conversation', 'lname'], '') : extractDataFromArray(userDetail, ['fname'], '') + " " + extractDataFromArray(userDetail, ['lname'], '')}
                    </Typography>
                    <Icon.FiberManualRecord
                      sx={
                        connectionDetail.active
                          ? { width: 10, color: "#26C77A" }
                          : { width: 0, color: "" }
                      }
                    />
                  </Box>
                }
                {loadingUser ? (
                  <Skeleton animation="wave" height={12} width="105px" />
                ) : (
                  <Typography variant="caption" sx={{ color: "#85878A" }}>
                    {connectionDetail.lastseen === "on Invalid date" ? "" : connectionDetail.active
                      ? "online"
                      : `Last seen ${connectionDetail.lastseen}`}
                  </Typography>

                )}

              </Box>
            </Box>
            {/* <Box>
              {
                loadingUser ? <Skeleton variant="circular" width={24} height={24} sx={{ mb: 1 }} /> :
                  <Icon.InfoOutlined
                    sx={{
                      width: 24,
                      color: openInfoDrawer ? "#26C77A" : "#747579",
                      cursor: "pointer",
                      display: 'none'
                    }}
                    onClick={() => {
                      if (isMobile) {
                        setIsMessageDashboard(false);
                        setIsShowMessages(false);
                        setIsProfile(true);

                      }
                      setOpenInfoDrawer(!openInfoDrawer);
                    }}
                  />
              }
            </Box> */}
          </Box>

          <Main
            ref={scrollContainerRef} // Added  ref to track scroll position
            className="thinScrollBar"
            id="color-list"
            onScroll={handleScroll}
            open={openMessageDrawer}
            sx={{
              overflow: "scroll",
              overflowY: "auto",
              overflowX: "hidden",
              scrollbarWidth: "thin",
              scrollbarColor: "auto",
              display: "flex",
              flexDirection: "column",

            }}
          >

            {showLoader && ( // This shows cicular loader
              <Box
                sx={{
                  position: 'sticky',
                  top: 8,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: 'fit-content',
                  zIndex: 1,
                  padding: 0,
                  backgroundColor: 'white', // Optional for better visibility
                  marginInline: 'auto',
                  borderRadius: '50%'

                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: 50, // Container size with padding space
                    height: 50,
                    borderRadius: '50%',
                    backgroundColor: '#f0f0f0', // Background color for circular background
                    padding: 1, // Padding for space around the CircularProgress
                  }}
                >
                  <CircularProgress size={24} />
                </Box>
              </Box>
            )}
            <Box sx={{ padding: "16px 0 0 0" }} className="Bar" ref={listContainerRef} >
              {reversedApiResponse?.map((message, i) => {

                const formattedDate = formatDividerChatDate(extractDataFromArray(message, ['update_time'], ''));


                const shouldDisplayDate =
                  i === 0 || lastDisplayedDate !== formattedDate;


                if (shouldDisplayDate) {
                  lastDisplayedDate = formattedDate;
                }
                if (message.from.type === "A") {
                  setIsMessageFromAdmin(true)
                } else {
                  setIsMessageFromAdmin(false)
                }

                return (
                  <Box key={i} ref={listContainerRef}>

                    {shouldDisplayDate && (
                      <Divider
                        sx={{
                          pt: 0,
                          pl: 2,
                          pr: 2,
                          pb: 0,
                          fontSize: "10px",
                          color: "#85878A",
                          lineHeight: "16px",
                        }}
                      >
                        {formattedDate}
                      </Divider>
                    )}
                    {
                      // message.from.seq != seqValue   this condition was before user can message himself 
                      message.from.seq != seqValue || (config.module === "guest" && message.from.type === "L") || (config.module === "lister" && message.from.type === "G") ?
                        <LeftChat
                          onchat2={false}
                          seqValue={seqValue}
                          message={message}
                          key={i}
                          i={i}
                          isReply={isReply}
                          setIsReply={setIsReply}
                        // scrollableDivRef={scrollableDivRef} // Passed it to fix scroll issue in iphone devices, after search was hidden
                        /> :
                        <RightChat
                          onchat2={true}
                          seqValue={seqValue}
                          message={message}
                          key={i}
                          i={i}
                          isReply={isReply}
                          setIsReply={setIsReply}
                        // scrollableDivRef={scrollableDivRef} // Passed it to fix scroll issue in iphone devices, after search was hidden
                        />
                    }
                  </Box>
                );
              })}
              <Box ref={scrollableDivRef} />
              {/* Commented out after search was hidden to fix scroll issue in iphone devices */}
            </Box>


          </Main>

          {isReply.flag ?
            <Box >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "12px 24px 0px 24px",
                  justifyContent: "flex-start",
                  alignItems: "left",
                  textAlign: "left",
                  gap: "2px",
                  backgroundColor: "#FFFFFF",
                  borderRadius: 1.5,
                  borderTop: "1px solid #E5E7EB"
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 1,
                    }}
                  >

                    <Typography variant="subtitle1" sx={{ fontWeight: 600 }}>
                      Replying to {isReply.conversation.from.seq === seqValue ? "You" : isReply.conversation.from.fname}
                    </Typography>
                  </Box>
                  <Icon.Close
                    sx={{ width: 15, cursor: "pointer" }}
                    onClick={() => {
                      setIsReply({ flag: false, conversation: {} });
                    }}
                  />
                </Box>
                <Box>
                  <Typography
                    variant="caption"
                    sx={
                      isReply
                        ? { color: "#85878A", }
                        : { color: "#85878A" }
                    }
                  >
                    {isReply.conversation.messages.message}
                  </Typography>
                </Box>
              </Box>
            </Box> : ""}
          <Box
            sx={
              isMobile
                ? {
                  display: "flex",
                  alignItem: "center",
                  flexDirection: "row",
                  padding: 2,
                  gap: 1,
                  alignItems: "flex-end",
                  borderTop: "1px solid #E5E7EB",
                }
                : {
                  display: "flex",
                  alignItem: "center",
                  flexDirection: "row",
                  padding: 2,
                  gap: 2,
                  alignItems: "flex-end",
                }
            }
          >



            {/* <Box
              sx={
                isMobile
                  ? {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                    gap: "16px",
                    height: "42px",
                  }
                  : {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "row",
                    gap: "6px",
                    height: "56px",
                  }
              }
            >
              <Menu
                sx={
                  isMobile
                    ? {
                      mt: "-42px",
                      ml: "-35px",
                      padding: "0px",
                      borderRadius: "4px",
                      "& .MuiPaper-root": {
                        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px;",
                      },
                    }
                    : {
                      mt: "-52px",
                      ml: "-42px",
                      padding: "15px",
                      position: "absolute",
                      borderRadius: "4px",
                      "& .MuiPaper-root": {
                        boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px;",
                      },
                    }
                }
                anchorEl={anchorElUser}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
              >
                {settings.map((setting) => (
                  <MenuItem
                    key={setting.title}
                    onClick={handleCloseUserMenu}
                    sx={
                      isMobile
                        ? {
                          color: "black",
                        }
                        : {
                          width: "186px",
                        }
                    }
                  >
                    <Typography
                      sx={
                        isMobile
                          ? {
                            fontSize: "12px",
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            fontWeight: "600",
                          }
                          : {
                            fontSize: "15px",
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            fontWeight: "600",
                          }
                      }
                      textAlign="center"
                    >
                      {setting.icon} {setting.title}
                    </Typography>
                  </MenuItem>
                ))}
              </Menu>
              <Icon.SentimentSatisfiedAltOutlined
                sx={{ width: 24, height: 24, cursor: "pointer" }}
                onClick={handleOpenUserMenu1}
              />
              <Menu
                sx={{
                  mt: "-52px",
                  ml: "-42px",
                  padding: "15px",
                  position: "absolute",
                  borderRadius: "4px",
                  "& .MuiPaper-root": {
                    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px;",
                  },
                }}
                anchorEl={anchorElUser1}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                open={Boolean(anchorElUser1)}
                onClose={handleCloseUserMenu1}
              >
                <EmojiPicker onEmojiClick={onEmojiClick} />
              </Menu>
            </Box> */}

            {isReply.flag ? (
              <Box
                sx={{
                  flex: 1,
                  border: "1px solid #C5C5C7",
                  borderRadius: 1.5,
                }}
              >

                <InputBase
                  placeholder="Type a Message"
                  sx={
                    isMobile
                      ? {
                        flex: 1,
                        height: 42,
                        border: "none",
                        pt: 0,
                        pb: 0,
                        pl: 2,
                        pr: 2,
                        borderRadius: 1.5,
                        width: "100%",
                        fontSize: "16px",
                      }
                      : {
                        flex: 1,
                        height: 56,
                        border: "none",
                        pt: 0,
                        pb: 0,
                        pl: 2,
                        pr: 2,
                        borderRadius: 1.5,
                        width: "100%",
                      }
                  }
                  value={sendMessage.message}
                  onInput={(e) => {
                    setSendMessage(e.target.value);
                  }}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      handleSendMessage();
                    }
                  }}
                  autoFocus
                />
              </Box>
            ) : (
              <InputBase
                className="message-input"
                placeholder="Type a Message"
                sx={
                  isMobile
                    ? {
                      flex: 1,
                      height: 42,
                      border: "1px solid #C5C5C7",
                      pt: 0,
                      pb: 0,
                      pl: 2,
                      pr: 2,
                      borderRadius: 1.5,
                      fontSize: "16px",
                    }
                    : {
                      flex: 1,
                      height: 56,
                      border: "1px solid #C5C5C7",
                      pt: 0,
                      pb: 0,
                      pl: 2,
                      pr: 2,
                      borderRadius: 1.5,
                    }
                }
                value={sendMessage.message}
                onFocus={handleFocus} // Trigger focus scroll handling
                onBlur={handleBlur}   // Reset body scroll lock on blur
                onInput={(e) => {
                  setSendMessage({ ...sendMessage, message: e.target.value });
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSendMessage();
                  }
                }}
                inputProps={{ autoFocus: isMobile ? false : true }}
                disabled={isMessageFromAdmin}
              />
            )}
            <Box
              sx={
                isMobile
                  ? {
                    backgroundColor: isMessageFromAdmin ? 'var(--grey700)' : "#26C77A",
                    color: "white",
                    width: 42,
                    height: 42,
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: isMessageFromAdmin ? "default" : "pointer",
                  }
                  : {
                    backgroundColor: isMessageFromAdmin ? 'var(--grey700)' : "#26C77A",
                    color: "white",
                    width: 56,
                    height: 56,
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: isMessageFromAdmin ? "default" : "pointer",
                  }
              }
              onClick={() => { handleSendMessage() }}
            >
              <SendIcon sx={{ width: 24, height: 24 }} />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ShowMessages;
