import { Box, Button, IconButton, LinearProgress, TextField, Typography } from "@mui/material";
import * as Icon from "@mui/icons-material";
import { useEffect, useState } from "react";
import UserService from "../../services/user";
import config from "../../config";
import { empty, extractDataFromArray, toastMessage } from "../../utils/common";
import { Link, useLocation } from "react-router-dom";
import IDVerificationPopup from "../IdVerificationPopup";
import { UserAuth } from "../../store/context/userContext";
import { CommonContext } from '../../../src/store/context/commonContext'
import LoaderButton from "../LoaderButton";
import CreateChip from "../CreateChip";
import errorAndHelperText from "../CommonConstants/errorAndHelperText.json"

const AccountVerification = ({ verification_title, subtitle = "Please click on each of the actions below to complete the setup:", sx = {}, completedCallback = () => { }, inCompleteCallback = () => { }, emailCompletedCallback = () => { }, emailVerified = null, disableSubmit, setDisableSubmit = () => { }, setOpenIdPopup, accountCompletionStatusObject = {} }) => {
    const [status, setStatus] = useState(accountCompletionStatusObject)
    const [completionPercentage, setCompletionPercentage] = useState(0)
    const [loader, setLoader] = useState(false)
    const [loading, setLoading] = useState(true)
    const [emailLoadingButton, setEmailLoadingButton] = useState(false)
    const [verifyEmailLoadingButton, setVerifyEmailLoadingButton] = useState(false)
    const [stripeLoadingButton, setStripeLoadingButton] = useState(false)
    const { user } = UserAuth();
    const [open, setOpen] = useState(false)
    const [showEmailVerification, setShowEmailVerification] = useState(false)
    const [otp, setOtp] = useState("")
    const [otpError, setOtpError] = useState("");
    const { isMobile, setIsStripeAdded } = CommonContext();
    const locationRoute = useLocation()
    const [stripeMode, setStripeMode] = useState(false)

    // Show dismiss button when stripeMode is common, email is verified, and ID is not verified
    const showDismissButton = stripeMode === 'common' &&
        status.email_verified === true &&
        status.id_verified !== 'V' &&
        status.id_verified !== 'P';

    const refreshStatus = (refreshToast = false) => {
        setLoader(true);
        UserService.getAccountCompletionStatus((data) => {
            const stripeMode = extractDataFromArray(data, ['stripe_mode'], 'individual');
            setStripeMode(stripeMode);
            const isStripeAdded = extractDataFromArray(data, ['stripe_connect'], false);

            setLoader(false);
            setLoading(false);
            setStatus(data);

            // Check if is_admin_managed is 1
            if (data.is_admin_managed === 1) {
                setCompletionPercentage(100); // Set progress to 100%
                setDisableSubmit(false); // Enable submission if it depends on progress
                completedCallback(); // Call the completion callback
                if (refreshToast) {
                    toastMessage(errorAndHelperText.account_verification.success);
                }
                return; // Skip further processing
            }

            setIsStripeAdded(isStripeAdded);

            let statusCompleted = 0;
            let emailVerified = false;

            Object.keys(data).forEach((key) => {
                if (key === 'email_verified') {
                    emailVerified = data[key] === true;
                } else if (key === 'id_verified') {
                    if (config.module === 'guest') {
                        if (data[key] === 'V') {
                            statusCompleted++;
                        }
                    } else {
                        if (data[key] === 'P' || data[key] === 'V') {
                            statusCompleted++;
                        }
                    }
                }
                // else if (data[key]=== 'stripe_connect' ) {  // If stripe is re-introduced. then handle it here. just put data[key] === 'stripe_connect'. then data[key] is true then increase step
                // if(data[key] === true){
                //     statusCompleted++;
                // }
                // }
            });

            if (emailVerified) {
                statusCompleted++;
            }

            // Adjust progress calculation based on stripe_mode
            // let baseCompletion = config.module === 'lister' ? 70 : 80; // when, stripe was mandatory, if stripe becomes mandatory again, replace below line with this one
            let baseCompletion = 80;
            if (stripeMode === 'common') {
                setDisableSubmit(false);
            }

            let newCompletionPercentage = baseCompletion + statusCompleted * 10;

            // Cap the completion percentage at 100
            if (newCompletionPercentage > 100) {
                newCompletionPercentage = 100;
            }

            setCompletionPercentage(newCompletionPercentage);

            if (newCompletionPercentage === 100) {
                completedCallback();
            } else {
                // inCompleteCallback();
            }

            if (refreshToast) {
                toastMessage(errorAndHelperText.account_verification.success);
            }
        });
    };



    useEffect(() => {
        if (loading) {
            refreshStatus()
        }
    }, [loading])

    useEffect(() => {
        if (completionPercentage === 100) {
            completedCallback()
            return
        } else {
            inCompleteCallback()
        }

    }, [completionPercentage])



    useEffect(() => {
        if (emailVerified !== null && status.email_verified === false) {
            setStatus({ ...status, email_verified: emailVerified })
            if (emailVerified && completionPercentage < 100) {
                setCompletionPercentage((completionPercentage + 10))
            }
        }
    }, [emailVerified])

    if (loading && (locationRoute.pathname.includes('/dashboard') || locationRoute.pathname.includes('/account'))) { // Added condition to fix account verfication not loading issue
        // console.log("locationRoute", locationRoute);

        return

    }

    // Added new condition here to not show id verfication if completion percentage is 100
    if (completionPercentage === 100) {
        return null;
    }

    const sendVerification = () => {
        setVerifyEmailLoadingButton(true)
        UserService.sendVerificationCode((data) => {
            setShowEmailVerification(true)
            setVerifyEmailLoadingButton(false)
        }, (err) => {
            setShowEmailVerification(false)
            setVerifyEmailLoadingButton(false)
        })
    }

    const verifyEmail = () => {
        if (empty(otp)) {
            setOtpError(errorAndHelperText.otp.error.message)
        } else {
            setEmailLoadingButton(true)
            UserService.verifyEmail(otp, user.email, () => {
                setShowEmailVerification(false)
                setStatus({ ...status, email_verified: true })
                setCompletionPercentage((completionPercentage + 10))
                emailCompletedCallback()
                // toast.success("Email verified successfully", { theme: 'colored' })
                toastMessage(errorAndHelperText.verify_email.success)
                setEmailLoadingButton(false)
            },
                (errMsg) => {
                    // Handle error here, e.g., show an error message
                    // toast.error(errMsg, {
                    //     theme: "colored",
                    // });
                    toastMessage({
                        message: errMsg,
                        type: 'error',
                    })
                    setOtpError(errMsg);
                    setEmailLoadingButton(false)
                }
            )
        }
    }

    const handleDismiss = () => {
        setCompletionPercentage(100)
    };

    return (
        <Box>
            <Box sx={{ background: 'var(--grey300)', borderRadius: (theme) => `${theme.card.borderRadius} `, p: isMobile ? 2 : 3, ...sx }}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Typography sx={{ fontWeight: 700 }} >{verification_title}</Typography>
                    {showDismissButton && <IconButton onClick={() => { handleDismiss() }}>
                        <Icon.Close />
                    </IconButton>}
                </Box>
                <Box sx={{ textAlign: "right", width: "100%" }}>
                    <Typography color="gray" variant="caption" sx={{ mb: 1 }}>{completionPercentage}%</Typography>
                </Box>
                <LinearProgress value={completionPercentage} variant="determinate" sx={{ height: 6, borderRadius: 1, backgroundColor: '#E0F7EC !important', mb: 2 }} />
                <Typography color="gray" sx={{ mb: 2 }}>{subtitle}</Typography>


                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1, justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                        {
                            extractDataFromArray(status, ['email_verified'], false) ?
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mx: 2.5 }}>
                                    <Icon.Verified color="primary" fontSize="16" />
                                    <Typography color="primary">Email Verified</Typography>
                                </Box>
                                :
                                // <Button variant="outlined" startIcon={<Icon.MailOutlined />} sx={{ cursor: 'pointer', backgroundColor: "#fff" }} color="secondary" onClick={sendVerification} >Verify Email</Button>
                                <LoaderButton
                                    variantType='outlined'
                                    buttonColor="secondary"
                                    onClick={() => { sendVerification() }}
                                    buttonBackGorundColor="#fff"
                                    ButtonIcon={<Icon.MailOutlined />}
                                    isLoading={verifyEmailLoadingButton}
                                    buttonText={
                                        <>
                                            Verify Email
                                            <span style={{ color: 'red' }}>&nbsp;*</span>
                                        </>
                                    }
                                    isFullWidth={false}
                                />


                        }

                        {
                            extractDataFromArray(status, ['id_verified'], "I") === "V" ?
                                <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mx: 2.5 }}>
                                    <Icon.Verified color="primary" fontSize="16" />
                                    <Typography color="primary" sx={{ fontWeight: 500 }}>ID Verified</Typography>
                                </Box>
                                :

                                extractDataFromArray(status, ['id_verified'], "I") === "P" ?
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mx: 2.5 }}>
                                        <Icon.Verified color="primary" fontSize="16" />
                                        <Typography color="primary" sx={{ fontWeight: 500 }}>Awaiting ID Approval</Typography>
                                    </Box>
                                    :

                                    extractDataFromArray(status, ['id_verified'], "I") === "F" ?
                                        <>
                                            <Button startIcon={<Icon.ErrorOutlineOutlined />} variant="contained" color="error" sx={{ cursor: 'pointer', }} onClick={() => { setOpen(true) }} >Verification Failed. Retry</Button>
                                            <IDVerificationPopup open={open} handleClose={() => { setOpen(false) }} callback={() => { setStatus({ ...status, id_verified: true }); refreshStatus() }} source="diff" />
                                        </>

                                        // <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mx: 2.5 }}>
                                        //     <Icon.Verified color="error" fontSize="16" />
                                        //     <Typography color="error" sx={{ fontWeight: 500 }}></Typography>
                                        // </Box>
                                        :
                                        <>
                                            {/* <Button startIcon={<Icon.PermContactCalendarOutlined />} variant="outlined" color="secondary" onClick={() => { setOpen(true) }} sx={{ cursor: 'pointer', backgroundColor: "#fff" }} >Verify IDDDD</Button> */}
                                            <LoaderButton
                                                variantType='outlined'
                                                buttonColor="secondary"
                                                onClick={() => { setOpen(true); setStripeLoadingButton(true); }}
                                                buttonBackGorundColor="#fff"
                                                ButtonIcon={<Icon.PermContactCalendarOutlined />}
                                                isLoading={stripeLoadingButton}
                                                buttonText={
                                                    <>
                                                        Verify ID
                                                        {config.module === 'guest' && <span style={{ color: 'red' }}>&nbsp;*</span>}
                                                    </>
                                                }
                                                isFullWidth={false}
                                            />
                                            <IDVerificationPopup open={open} handleClose={() => { setOpen(false); }} callback={() => { setStatus({ ...status, id_verified: true }); refreshStatus() }} setStripeLoadingButton={setStripeLoadingButton} />
                                        </>

                        }
                        {/* {
                            config.module === 'lister' &&
                            (
                                extractDataFromArray(status, ['stripe_connect'], false) ?
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mx: 2.5 }}>
                                        <Icon.Verified color="primary" fontSize="16" />
                                        <Typography color="primary" sx={{ fontWeight: 500 }}>Stripe Connect</Typography>
                                    </Box> :
                                    <Link to="/account?s=payout" target={"_blank"} ><Button variant="outlined" startIcon={<Icon.MonetizationOnOutlined />} sx={{ cursor: 'pointer', backgroundColor: "#fff" }} color="secondary" >Add Payout Details<span style={{ color: 'red' }}>&nbsp;*</span> </Button></Link>
                            )
                        } */}
                        {
                            config.module === 'lister' &&
                            stripeMode && stripeMode !== 'common' && (
                                extractDataFromArray(status, ['stripe_connect'], false) ? (
                                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5, mx: 2.5 }}>
                                        <Icon.Verified color="primary" fontSize="16" />
                                        <Typography color="primary" sx={{ fontWeight: 500 }}>Stripe Connect</Typography>
                                    </Box>
                                ) : (
                                    <Link to="/account?s=payout" target={"_blank"}>
                                        <Button
                                            variant="outlined"
                                            startIcon={<Icon.MonetizationOnOutlined />}
                                            sx={{ cursor: 'pointer', backgroundColor: "#fff" }}
                                            color="secondary"
                                        >
                                            Add Payout Details<span style={{ color: 'red' }}>&nbsp;*</span>
                                        </Button>
                                    </Link>
                                )
                            )
                        }


                    </Box>
                    {/* <Chip variant="outlined" sx={{ background: '#fff' }} disabled={loader} label="Refresh" icon={loader ? <CircularProgress size="16px" sx={{ color: '#333' }} /> : <Icon.Sync sx={{ fontSize: '16px' }} />} onClick={() => { refreshStatus(true) }} /> */}
                    <CreateChip showPointer chipType={'refresh'} disabled={loader} caption="Refresh" onClick={() => { refreshStatus(true) }} />
                    {/* <LoaderChip
                  variantType='outlined'
                  onClick={() => { refreshStatus(true); }}
                  buttonBackGorundColor="#fff"
                  ButtonIcon={<Icon.Sync />}
                  isLoading={true}
                  chipText="Refresh"
                /> */}

                </Box>
                {
                    showEmailVerification &&
                    <Box sx={{ background: '#fff', borderRadius: (theme) => `${theme.card.borderRadius} `, p: 3, mt: 3 }}>
                        <IconButton sx={{ float: 'right', p: 0 }} onClick={() => { setShowEmailVerification(false) }}><Icon.Close /></IconButton>
                        <Typography sx={{ mb: 2 }}>A verification code has been sent to <Typography variant="span" sx={{ color: 'var(--grey900)' }}>{user.email}</Typography></Typography>
                        <Typography sx={{ mb: 1 }}>Please check your inbox and enter the verification code below to verify your email address.</Typography>
                        <Box sx={{ display: 'flex', gap: 1, mb: 1 }}>
                            <TextField
                                variant="outlined"
                                value={otp}
                                onInput={(e) => {
                                    setOtp(e.target.value);
                                    setOtpError("");
                                }}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        verifyEmail();
                                    }
                                }}
                                placeholder="Verification Code"
                                sx={{
                                    width: '20ch',
                                    '& .MuiOutlinedInput-root': {
                                        height: '42px'
                                    }
                                }}
                                autoComplete="new-password"
                                error={otpError !== ""}
                                helperText={otpError}
                            />
                            {/* <Button variant="contained" sx={{ maxHeight: '42px' }} onClick={verifyEmail}>Ver</Button> */}
                            <LoaderButton
                                variantType='contained'
                                onClick={() => { verifyEmail() }}
                                isLoading={emailLoadingButton}
                                buttonText="Verify"
                                isFullWidth={false}
                                maxHeight={42}
                            />

                        </Box>
                        <Button variant="text" color="secondary" sx={{ textDecoration: 'underline', p: 0, fontSize: '12px', minWidth: 'unset', '&:hover': { backgroundColor: 'unset' } }} onClick={sendVerification} >Resend</Button>
                    </Box>
                }

            </Box>
            {config.module === 'lister' && locationRoute.pathname !== '/dashboard' && locationRoute.pathname !== '/account' && <Box sx={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
                <Button variant="contained" disabled={disableSubmit} onClick={() => { setOpenIdPopup(false) }} sx={{ mt: 2, ml: 'auto' }}>
                    Submit
                </Button>
            </Box>}
        </Box>
    )
}



export default AccountVerification;

