import React from "react";
import {
  Box,
  Button,
  Typography,
  FormControlLabel,
  Radio,
  Divider,
  Switch,
} from "@mui/material";


import ProfileService from "../../../services/profile";

const Settings = () => {
  const handledeleteprofile = () => {
    ProfileService.deleteProfile((data) => {
   
    });
  };
  return (
    <>
      <Box
        sx={{
          border: " 1px solid #E5E7EB",

          marginBottom: "10px",
          borderRadius: "16px",
        }}
      >
        <Typography
          sx={{
            borderBottom: "1px solid #C5C5C7",
            color: "#1B1B1B",
            p:3,

            fontSize: "28px",
            fontWeight: "800",
          }}
        >
          Notification Settings
        </Typography>
        <Box sx={{  p:3}}>
          <Typography
            sx={{
              fontSize: "16px",
              color: "#85878A",
            }}
          >
            Newsletter*
          </Typography>
          <Box
  display="flex"
  flexDirection={{ xs: "column", sm: "row" }}
  alignItems="center"
  justifyContent="space-between"
  marginBottom="10px"
>
  <FormControlLabel
    value="option1"
    control={<Radio />}
    sx={{
      fontSize: "16px",
      color: "#85878A",
      marginBottom: { xs: "5px", sm: 0 },
    }}
    label="Option 1"
  />
  <FormControlLabel
    value="option2"
    control={<Radio />}
    sx={{
      fontSize: "16px",
      color: "#85878A",
      marginBottom: { xs: "5px", sm: 0 },
    }}
    label="Option 2"
  />
  <FormControlLabel
    value="option3"
    control={<Radio />}
    sx={{
      fontSize: "16px",
      color: "#85878A",
      marginBottom: { xs: "5px", sm: 0 },
    }}
    label="Option 3"
  />
  <FormControlLabel
    value="option4"
    sx={{
      fontSize: "16px",
      color: "#85878A",
    }}
    control={<Radio />}
    label="Option 4"
  />
</Box>

          <Divider />
          <Box display="flex" alignItems="center" marginTop="10px">
            <Typography
              variant="body1"
              sx={{ marginRight: "1rem", fontSize: "16px", color: "#85878A" }}
            >
              I would like to receive booking assist reminders
            </Typography>
            <Box sx={{ marginLeft: "auto" }}>
              <Switch />
            </Box>
          </Box>

          <Box display="flex" alignItems="center">
            <Typography
              variant="body1"
              sx={{ marginRight: "1rem", fontSize: "16px", color: "#85878A" }}
            >
              I would like to receive emails about Booking promotions
            </Typography>
            <Box sx={{ marginLeft: "auto" }}>
              <Switch />
            </Box>
          </Box>

          <Box display="flex" alignItems="center">
            <Typography
              variant="body1"
              sx={{ marginRight: "1rem", fontSize: "16px", color: "#85878A" }}
            >
              I would like to know about information and offers related to my
              upcoming trip
            </Typography>
            <Box sx={{ marginLeft: "auto" }}>
              <Switch />
            </Box>
          </Box>

          <Box display="flex" alignItems="center">
            <Typography
              variant="body1"
              sx={{ marginRight: "1rem", fontSize: "16px", color: "#85878A" }}
            >
              Send SMS confirmation for all online payments
            </Typography>
            <Box sx={{ marginLeft: "auto" }}>
              <Switch />
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          border: " 1px solid #E5E7EB",

          borderRadius: "16px",
          padding: "20px",
        }}
      >
        <Typography
          sx={{
            color: "#1B1B1B",

            fontSize: "16px",
            fontWeight: "800",
          }}
        >
          Delete Account
        </Typography>
        <Typography
          sx={{
            color: "#85878A",
            fontSize: "16px",
            mb:2,
          }}
        >
          Lorem Ipsum is simply dummy text of the printing and typesetting
          industry.
        </Typography>
        <Box>
          <Box
            style={{
              display: "flex",
             
            }}
          >
            <Button
              onClick={handledeleteprofile}
            variant="outlined"
            color="error"
            >
              Delete Account
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default Settings;
