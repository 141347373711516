import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Grid from '@mui/material/Grid';

import Header from "./../DashboardLayout/Header";
// import SideBar from "./Sidebar";
import { useEffect, useRef, useState } from 'react';
import { empty, exponentialBackoff } from '../../utils/common';
import Footer from '../LandingPageLayout/Footer';

const SearchLayout = () => {
    const params = useParams()
    const location = useLocation()
    const [open, setOpen] = useState(true);
    const toggleDrawer = () => {
        setOpen(!open);
    };
    const topRef = useRef(null);


    useEffect(() => {

        exponentialBackoff(() => {

            return topRef?.current !== null
        }, 5, 1000, () => {

            if (!empty(params)) {
                topRef?.current?.scrollIntoView();
            } else {

            }
        })

    }, [params]);


    return (<Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Header toggleDrawer={toggleDrawer} open={open} />
        <Box

            component="main"
            sx={{
                flexGrow: 1,
                height: '100svh',
                overflow: 'auto',
            }}
        >
            <Toolbar ref={topRef} sx={{
                "& .MuiToolbar-root": {
                    height: '72px'
                },
            }} />
            <Grid container >
                <Grid item md={12} sm={12} xs={12} >
                    <Outlet />
                </Grid>
            </Grid>
            {location.pathname !== '/search' && <Footer />}
        </Box>
    </Box>)
}

export default SearchLayout