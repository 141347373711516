import React from 'react';
import { Dialog, IconButton, Box, CardMedia } from '@mui/material';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

const SingleImagePreview = ({ open, handleClose, image }) => {
    if (!image) {
        return null;
    }

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            PaperProps={{
                sx: {
                    backgroundColor: '#0009',
                },
            }}
        >
            <IconButton
                onClick={handleClose}
                sx={{
                    width: '40px',
                    borderRadius: '20px',
                    position: 'absolute',
                    top: '1rem',
                    right: '1rem',
                    zIndex: 2,
                    background: "#ddd",
                    "&:hover": {
                        background: '#ddd',
                    },
                }}
            >
                <CloseOutlinedIcon />
            </IconButton>

            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
                {(image.type === 'IMAGE' || image.file_type === 'IMAGE') ? (
                    <img
                        src={image.url}
                        alt={image.media_name}
                        style={{
                            width: '100%',
                            maxHeight: '100vh',
                            objectFit: 'contain',
                        }}
                    />
                ) : (
                    <CardMedia
                        component="video"
                        id="video"
                        sx={{
                            width: '100%',
                            maxHeight: '100vh',
                            borderRadius: '16px !important',
                        }}
                        src={image.url}
                        controls
                        autoPlay
                    />
                )}
            </Box>
        </Dialog>
    );
};

export default SingleImagePreview;