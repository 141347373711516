import { Box, Skeleton } from "@mui/material";
import { useState } from "react";

const ImageDisplay = ({ src, divStyle, imgStyle, divClassName,imgClassName }) => {
    const [loading, setLoading] = useState(true);

    // Handle image load event
    const handleImageLoad = () => {
        setLoading(false);
    };

    return (
        <Box className={divClassName} sx={divStyle}>
            {loading && (
                // Display Skeleton Loader with wave animation
                <Skeleton
                    variant="rectangular"
                    width="100%"
                    height="100%"
                    animation="wave"
                />
            )
            }
            <img
                src={src}
                style={imgStyle}
                onLoad={handleImageLoad}
                className={imgClassName}
            />
        </Box>
    );
};

export default ImageDisplay;
