import { Box, Divider, Typography } from "@mui/material"
import ApplyStyles from "../../previewPageComponents/htmlParser/ApplyStyles";
import { extractDataFromArray } from "../../../utils/common";
import { RESPONSIVE_SPACING } from "../../CommonConstants/commonKeys";

const ThingsToKnow = ({ formData }) => {
    // console.log(formData);
    const title = extractDataFromArray(formData, ['caption'], '');
    const preparation = extractDataFromArray(formData, ['preparation'], '');
    const expection = extractDataFromArray(formData, ['expection'], '');
    const bringItems = extractDataFromArray(formData, ['bring_item'], '');
    const showComponent = (preparation === '' || preparation === '<p><br></p>') && (expection === '' || expection === '<p><br></p>') && (bringItems === '' || bringItems === '<p><br></p>')
    if (showComponent) {
        // console.log('return null');
        return null
    }

    return (
        <Box>
            <Typography variant="subtitle1" sx={{
                fontWeight: '600',
                mb: 2.5,

            }}>
                {title}
            </Typography>
            {!(preparation === '' || preparation === '<p><br></p>') && <>
                <Typography variant="subtitle1" sx={{
                    // fontWeight: '600',
                }}>
                    How to Prepare
                </Typography>
                <Box
                    sx={{
                        mb: 1.5,
                        "& .applyStyles": {
                            color: 'var(--textGrey)',
                        }
                    }}
                >
                    <ApplyStyles
                        htmlString={preparation}
                    />
                </Box>
            </>}
            {!(expection === '' || expection === '<p><br></p>') && <>
                <Typography variant="subtitle1" sx={{
                    // fontWeight: '600',
                }}>
                    What to Expect
                </Typography>
                <Box
                    sx={{
                        mb: 1.5,
                        "& .applyStyles": {
                            color: 'var(--textGrey)',
                        }
                    }}
                >
                    <ApplyStyles
                        htmlString={expection}
                    />
                </Box>
            </>}
            {!(bringItems === '' || bringItems === '<p><br></p>') && <>
                <Typography>Things to Bring</Typography>
                <Box
                    sx={{
                        mb: 1.5,
                        "& .applyStyles": {
                            color: 'var(--textGrey)',
                        }
                    }}
                >
                    <ApplyStyles
                        htmlString={bringItems}
                    />
                </Box>
            </>}
            <Divider sx={{ my: RESPONSIVE_SPACING }} />
        </Box>
    )
}
export default ThingsToKnow