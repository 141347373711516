import { Box, Button, Divider, MenuItem, Select, TextField, Typography } from "@mui/material";
import GreyLabel from "../../formElement/GreyLabel";
import * as Icon from "@mui/icons-material";
import dayjs from "dayjs";
import { DesktopDateRangePicker, LocalizationProvider, MobileDateRangePicker, SingleInputDateRangeField, LicenseInfo } from "@mui/x-date-pickers-pro";
import { useEffect, useState } from "react";
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { CommonContext } from "../../../store/context/commonContext";
import { changeTimeFormat, empty } from "../../../utils/common";
import OptionsAvailableDialog from "./optionsAvailableDialog";
import { UserAuth } from "../../../store/context/userContext";
import { useNavigate } from "react-router";
import { FROM_TIME, TO_TIME } from "../../CommonConstants/commonKeys";

LicenseInfo.setLicenseKey(
    "370e579ab4fef48a1739afecb9c68d3bTz04OTIyMyxFPTE3NDU3NTAwNTUwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y"
);

const ExpBookingCard = ({ guest, handleChange, guestOptions, notAvailableDates = [], starting_price, pricingData, bookedData = [], maxGuest, seq, durationObj, open, setOpen, availabilityRange, bookedSlotsData, title }) => {
    let rangeInterval = 0
    if (durationObj.unit === 'hours') {
        rangeInterval = 0
    } else if (durationObj.unit === 'days' && durationObj.value === 1) {
        rangeInterval = 0
    } else if (durationObj.unit === 'days') {
        rangeInterval = Number(durationObj.value) - 1
    }

    const { isNineHundred, setOpenExpEnquiryPopup } = CommonContext();
    const navigate = useNavigate()

    let defaultBookingSelection = [dayjs(), dayjs().add(2, 'day')];
    const [openDate, setOpenDate] = useState(false);
    const [dateSelectText, setDateSelectText] = useState('');
    const [errorText, setErrorText] = useState('');
    const [dateError, setDateError] = useState(false);
    const [dateRange, setDateRange] = useState(defaultBookingSelection);
    const [slots, setSlots] = useState([]);
    const today = dayjs();



    const { user, authPopup, setAuthPopup } = UserAuth();
    const openAuthPopup = (type, slot) => {
        setAuthPopup({
            ...authPopup,
            type: type,
            open: true,
            callback: () => {
                handleCheckOut(slot);
            },
        });
    };

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };


    const disableCustomDates = (date) => {
        return (notAvailableDates.length > 0 && notAvailableDates.some(d => date.isSame(d, 'day')));
    };


    const setDates = (date) => {
        if (dayjs(date[1]).format('YYYY-MM-DD') === dayjs(dateRange[1]).format('YYYY-MM-DD')) {
            date[1] = null;
            setOpenDate(true);
            setDateRange(date);
        } else {
            setDateRange(date);
        }
    }

    const setDialogDates = (date, setDialogDateOpen) => {
        if (dayjs(date[1]).format('YYYY-MM-DD') === dayjs(dateRange[1]).format('YYYY-MM-DD')) {
            date[1] = null;
            setDialogDateOpen(true);
            setDateRange(date);
        } else {
            setDateRange(date);
        }
    }

    // const dateRangeContainsUnavailable = (dateRange, unavailableDates) => {
    //     if (dateRange[0] === null || dateRange === null) return false;

    //     const start = dayjs(dateRange[0]);
    //     const end = dayjs(dateRange[1]);

    //     for (let date of unavailableDates) {
    //         if (dayjs(date).isBetween(start - 1, end + 1)) {
    //             return true;
    //         }
    //     }

    //     return false;
    // };

    const handleCheckOut = (slot) => {
        if (empty(user)) {
            openAuthPopup("signin", slot);
            return;
        }

        const typeParams = "&type=" + 'exp';
        const slotidParams = "&sid=" + slot.id;
        const dateParams = "&dt=" + slot.date;
        const expSeqParams = "&expSeq=" + seq;
        const toParams = `&${TO_TIME}=` + slot[TO_TIME];
        const fromParams = `&${FROM_TIME}=` + slot[FROM_TIME];

        // console.log(typeParams, slotidParams, dateParams, expSeqParams);


        navigate(
            "/checkout?g=" +
            guest +
            typeParams +
            slotidParams +
            dateParams +
            expSeqParams +
            toParams +
            fromParams
        )
    };


    // const generateSlots = (start, end, pricingData, bookedData) => {
    //     let slots = [];
    //     let current = start;

    //     // Create a lookup map for booked guest data
    //     const bookedGuestMap = {};
    //     bookedData.forEach(item => {
    //         const dateKey = dayjs(item.date).format('YYYY-MM-DD');
    //         if (!bookedGuestMap[dateKey]) {
    //             bookedGuestMap[dateKey] = {};
    //         }
    //         bookedGuestMap[dateKey][item.slot_id] = item.booked_guest_count;
    //     });

    //     console.log(notAvailableDates);

    //     while (current.isBefore(end) || current.isSame(end, 'day')) {
    //         if (!dateRangeContainsUnavailable([current, current], notAvailableDates)) {
    //             let daySlots = pricingData.map((item) => {
    //                 const dateKey = current.format('YYYY-MM-DD');
    //                 const bookedCount = bookedGuestMap[dateKey] && bookedGuestMap[dateKey][item.id] ? bookedGuestMap[dateKey][item.id] : 0;
    //                 return {
    //                     // date: current.format("ddd, DD MMM"),
    //                     date: current.format("YYYY-MM-DD"),
    //                     [FROM_TIME]: changeTimeFormat(item[FROM_TIME]),
    //                     [TO_TIME]: changeTimeFormat(item[TO_TIME]),
    //                     description: item.description,
    //                     price: item.price,
    //                     id: item.id,
    //                     booked_guest_count: bookedCount,
    //                     available_guest: maxGuest - bookedCount,
    //                 };
    //             });
    //             slots = [...slots, ...daySlots];
    //         }
    //         current = current.add(1, 'day');
    //     }
    //     console.log(slots);
    //     return slots;
    // };

    const generateSlots = (start, end, pricingData, bookedData) => {
        let slots = [];
        let current = start;

        // Create a lookup map for booked guest data
        const bookedGuestMap = {};
        bookedData.forEach(item => {
            const dateKey = dayjs(item.date).format('YYYY-MM-DD');
            if (!bookedGuestMap[dateKey]) {
                bookedGuestMap[dateKey] = {};
            }
            bookedGuestMap[dateKey][item.slot_id] = item.booked_guest_count;
        });

        while (current.isBefore(end) || current.isSame(end, 'day')) {
            const currentDateString = current.format('YYYY-MM-DD');
            if (!notAvailableDates.includes(currentDateString)) {
                let daySlots = pricingData.map((item) => {
                    const dateKey = currentDateString;
                    const bookedCount = bookedGuestMap[dateKey] && bookedGuestMap[dateKey][item.id] ? bookedGuestMap[dateKey][item.id] : 0;
                    return {
                        date: currentDateString,
                        [FROM_TIME]: changeTimeFormat(item[FROM_TIME]),
                        [TO_TIME]: changeTimeFormat(item[TO_TIME]),
                        description: item.description,
                        price: item.price,
                        id: item.id,
                        booked_guest_count: bookedCount,
                        available_guest: maxGuest - bookedCount,
                    };
                });
                slots = [...slots, ...daySlots];
            }
            current = current.add(1, 'day');
        }
        // console.log(slots);
        return slots;
    };


    useEffect(() => {
        let days = dayjs(dateRange[1]).diff(dateRange[0], 'days') + 1;

        if (dateRange[0] == null || dateRange[1] == null) {
            setDateSelectText('Select Dates');
        } else if (dateRange[0].format('YYYY-MM-DD') == dateRange[1].format('YYYY-MM-DD')) {
            const invalidCheck = dateRange[0].format('MMM DD, YYYY');
            if (invalidCheck.includes('Invalid')) {
                setDateRange([null, null]);
                return;
            }
            setDateSelectText(`${dateRange[0].format('DD MMM')}`);
        } else {
            const invalidCheck = `${dateRange[0].format('MMM DD, YYYY')}${dateRange[1].format('MMM DD, YYYY')}`;
            if (invalidCheck.includes('Invalid')) {
                setDateRange([null, null]);
                return;
            }
            setDateSelectText(`${dateRange[0].format('DD MMM')} - ${dateRange[1].format('DD MMM')}`);
        }

        if (isNaN(days) || (dateRange[0] === null) || (dateRange[1] === null)) {
            // setErrorText("Select valid date range for this package")
            // setDateError(true)
        }

        const newSlots = generateSlots(dayjs(dateRange[0]), dayjs(dateRange[1]), pricingData, bookedData)
        // console.log(newSlots);
        if (newSlots.length > 0 && newSlots !== undefined) {
            setSlots(newSlots);
        }

    }, [notAvailableDates, dateRange, pricingData, bookedData]);

    // Console logs for debugging
    // console.log('slots', slots);
    // console.log('notAvailableDates', notAvailableDates);
    // console.log('bookedData', bookedData);

    const isPastTime = (slot) => { // Added this function to check if start time of slot has passed or not
        const slotDate = dayjs(slot.date); // Parse the slot date
        const currentTime = dayjs(); // Get current date and time


        // Check if the slot date matches today's date
        if (slotDate.isSame(currentTime, 'day')) {
            const slotTime = dayjs(slot.date + ' ' + slot.from_time, 'YYYY-MM-DD h:mm A'); // Parse the date and time together
            return slotTime.isBefore(currentTime); // Check if slot time has passed
        }
        return false;
    };

    return (
        <Box sx={{
            width: '40%',
            border: '1px solid #E5E7EB',
            borderRadius: '16px',
            p: 3,
            height: 'fit-content',
            position: 'sticky',
            top: '100px',
            right: '20px',
            display: isNineHundred ? 'none' : 'unset'
        }}>
            {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2.5 }}>
                <Typography sx={{ fontWeight: '600', fontSize: '20px' }} >
                    From ${starting_price}
                </Typography>
                <span style={{ color: 'var(--textGrey)' }}> / person</span>
            </Box> */}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    mb: 2.5
                }}
            >
                <Typography variant='body2'>
                    From&nbsp;
                </Typography>
                <Typography variant='h6' sx={{ fontWeight: 'bold' }}>
                    ${starting_price}&nbsp;
                </Typography>
                <Typography variant='body2'>
                    per guest
                </Typography>
            </Box>
            <Box sx={{
                width: '100%',
                mb: 3,
                backgroundColor: '#F5F5F6',
                borderRadius: 2,
                p: 3,
                display: 'flex',
                gap: 3,
                justifyContent: 'space-between',
                height: '120px'
            }}>
                <Box sx={{ width: '100%' }}>
                    <GreyLabel label={'Dates'} />
                    <Box sx={{
                        position: "relative",
                        overflow: 'hidden',

                        '& :hover': {
                            cursor: 'pointer',
                        },
                        "& .MuiFormHelperText-root": {
                            ml: 0,
                            color: 'green'
                        },
                        "&. MuiTextField-root": {
                            display: 'none'
                        },
                    }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            {!isNineHundred ?
                                <Box className="date-select-input-box">
                                    <DesktopDateRangePicker
                                        open={openDate}
                                        calendars={1}
                                        clearable={true}
                                        slots={{ field: SingleInputDateRangeField }}
                                        slotProps={{
                                            textField: {
                                                size: "small",
                                            },
                                            actionBar: { actions: ["clear"] },
                                        }}
                                        sx={{
                                            width: '100%',
                                            '& .MuiOutlinedInput-root': {
                                                backgroundColor: 'white',
                                            },
                                            "&. MuiInputBase-root": {
                                                display: 'none'
                                            },
                                            "& .MuiFormControl-root": {
                                                display: 'none'
                                            }
                                        }}
                                        format="DD MMM YYYY"
                                        onOpen={() => setOpenDate(true)}
                                        value={dateRange}
                                        onChange={(date) => setDates(date)}
                                        disablePast
                                        disableHighlightToday
                                        shouldDisableDate={disableCustomDates}
                                        onClose={() => setOpenDate(false)}
                                    />
                                </Box>
                                :
                                <MobileDateRangePicker
                                    open={openDate}
                                    calendars={1}
                                    clearable={true}
                                    slots={{ field: SingleInputDateRangeField }}
                                    slotProps={{
                                        textField: {
                                            size: "small",
                                            InputProps: { endAdornment: <Icon.CalendarMonth />, placeholder: 'Select Dates' },
                                            error: !!dateError,
                                            helperText: errorText
                                        },
                                        actionBar: { actions: ["clear"] },
                                    }}
                                    sx={{
                                        width: '100%',
                                        '& .MuiOutlinedInput-root': {
                                            backgroundColor: 'white',
                                        }
                                    }}
                                    format="DD MMM YYYY"
                                    value={dateRange}
                                    onChange={(date) => setDates(date)}
                                    disablePast
                                    disableHighlightToday
                                    closeOnSelect
                                    shouldDisableDate={disableCustomDates}
                                    onClose={() => setOpenDate(false)}
                                />}
                        </LocalizationProvider>
                    </Box>
                    <TextField
                        sx={{
                            zIndex: '99',
                            marginTop: '-40px',
                            borderRadius: '6px',
                            transition: 'border-color 0.3s',
                            color: 'var(--black)',
                            '& :hover': {
                                cursor: 'pointer'
                            },
                            '& .MuiOutlinedInput-root': {
                                backgroundColor: 'white',
                            },
                            "&. MuiInputBase-root": {
                                display: 'none',
                            },
                            "& .MuiFormControl-root": {
                                display: 'none'
                            }, "& .MuiFormHelperText-root": {
                                ml: 0,
                                color: 'var(--primary)'
                            }, "& .MuiInputBase-input": {
                            }, "& .Mui-error": {
                                color: '#d32f2f'
                            },
                            width: '100%',
                        }}
                        InputProps={{
                            endAdornment: <Icon.CalendarToday />,
                            style: { color: 'black' },
                            inputProps: { style: { color: 'black' } }
                        }}
                        error={!!dateError}
                        helperText={errorText}
                        autoComplete="off"
                        size="small"
                        value={dateSelectText}
                        onClick={(e) => {
                            e.preventDefault();
                            setOpenDate(!openDate);
                        }}
                        onKeyDown={(e) => e.preventDefault()}
                    />
                </Box>
                <Box sx={{
                    "& .MuiInputBase-root": {
                        height: '40px',
                        backgroundColor: 'white',
                    }
                }}>
                    <GreyLabel label={'Guests'} />
                    <Select
                        sx={{ width: '100px' }}
                        value={guest}
                        onChange={handleChange}
                    >
                        {guestOptions.map(value => (
                            <MenuItem key={value} value={value}>{value}</MenuItem>
                        ))}
                    </Select>
                </Box>
            </Box>
            <Box>
                <Box sx={{
                    mb: 3,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2
                }}>
                    {slots.slice(0, 2).map((slot, index) => {
                        const slotDate = dayjs(slot.date);
                        const isAvailable = slotDate.isBetween(dayjs(availabilityRange[0]), dayjs(availabilityRange[1]), null, '[]'); // Check if within range
                        // console.log("SLOTS DETAILS", slot);
                        return (
                            <Box key={index} sx={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 2 }}>
                                <Box sx={{ display: 'flex', width: '100%', justifyContent: 'space-between', gap: 1 }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                                        <Typography variant='subtitle1' fontWeight={600} sx={{ mb: 1 }}>
                                            {dayjs(slot.date).format("ddd, DD MMM")}
                                        </Typography>
                                        {/* <Typography variant='caption' sx={{ mb: 1 }}>
                                        {slot[FROM_TIME]} - {slot[TO_TIME]}
                                    </Typography> */}
                                        {rangeInterval !== 0 ? <Typography variant='caption'>
                                            {dayjs(slot.date).format("DD MMM YYYY")}, {slot[FROM_TIME]} - {dayjs(slot.date).add(rangeInterval, 'day').format("DD MMM YYYY")}, {slot[TO_TIME]}
                                        </Typography>
                                            :
                                            <Typography variant='caption'>{slot[FROM_TIME]} - {slot[TO_TIME]}
                                            </Typography>
                                        }
                                        <Typography variant='caption'>
                                            {slot.description}
                                        </Typography>
                                        <Typography variant='caption'>
                                            {
                                                slot.available_guest === 0 ? "" : slot.booked_guest_count === 0 ? ''
                                                    :
                                                    slot.booked_guest_count === 1 ?
                                                        `Join ${slot.booked_guest_count} other guest`
                                                        :
                                                        `Join ${slot.booked_guest_count} other guests`
                                            }
                                        </Typography>
                                    </Box>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                                        {/* <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, "& .MuiTypography-root": { textWrap: 'nowrap' } }}>
                                            <Typography variant='subtitle1' sx={{ fontWeight: '600' }}>
                                                ${slot.price}
                                            </Typography>
                                            <Typography variant='caption'> / person</Typography>
                                        </Box> */}
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <Typography variant='caption'>
                                                From&nbsp;
                                            </Typography>
                                            <Typography variant='subtitle1' sx={{ fontWeight: 'bold' }}>
                                                ${slot.price}&nbsp;
                                            </Typography>
                                            <Typography variant='caption' sx={{ textWrap: 'nowrap' }} >
                                                per guest
                                            </Typography>
                                        </Box>
                                        {isAvailable && (slot.available_guest !== 0 ? <Button
                                            variant='contained'
                                            disabled={slot.available_guest < guest || isPastTime(slot)} // Added this codition to disable button if start time has already passed previously it was like this {slot.available_guest < guest ? true : false}
                                            sx={{ minWidth: 112 }}
                                            onClick={() => { handleCheckOut(slot) }}
                                        >
                                            {
                                                isPastTime(slot) ? 'Closed' : 'Choose'
                                            }
                                        </Button>
                                            :
                                            <Button
                                                variant='contained'
                                                disabled={slot.available_guest === 0} // Added this codition to disable button if start time has already passed previously it was like this {slot.available_guest < guest ? true : false}
                                                sx={{ minWidth: 112 }}
                                            // onClick={() => { handleCheckOut(slot) }}
                                            >
                                                Sold Out
                                            </Button>)
                                        }
                                        {!isAvailable &&
                                            <Button variant='contained' sx={{ backgroundColor: 'black !important' }} onClick={() => { setOpenExpEnquiryPopup(true) }} >Request</Button>
                                        }
                                    </Box>
                                </Box>
                                <Divider />
                            </Box>
                        )
                    })}
                </Box>
                <Button variant='light' fullWidth onClick={handleClickOpen}>
                    Show all dates
                </Button>
            </Box>
            <OptionsAvailableDialog
                handleClose={handleClose}
                open={open}
                slots={slots}
                openDate={openDate}
                setOpenDate={setOpenDate}
                dateSelectText={dateSelectText}
                errorText={errorText}
                dateError={dateError}
                disableCustomDates={disableCustomDates}
                setDates={setDialogDates}
                dateRange={dateRange}
                guest={guest}
                handleChange={handleChange}
                guestOptions={guestOptions}
                handleCheckOut={handleCheckOut}
                rangeInterval={rangeInterval}
                availabilityRange={availabilityRange}
                bookedSlotsData={bookedSlotsData}
                title={title}
            />
        </Box>
    )
}

export default ExpBookingCard;