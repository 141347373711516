import * as React from "react";
import { Box, Grid } from "@mui/material";

import 'swiper/css';

import TitleSection from "../aboutus/section/TitleSection";
import GallerySection from "../aboutus/section/GallerySection";
import OurStory from "../aboutus/section/OurStory";
import CardSection from "../aboutus/section/Cards";


import "./style.css";
// Install Swiper modules

const AboutUs = () => {
  return (
      <Box sx={{ p: 2 }}>
        <Grid
          container
          maxWidth="lg"
          sx={{ m: "auto" }}
          direction="row"
          justifyContent="center"
        >


          {/* header */}

          <TitleSection />

          <Grid
            item
            container
            className="mobmarginbottom"
            sx={{ mb: 10, justifyContent: "center" }}
          >
            <GallerySection />
            <OurStory />
          </Grid>

          <CardSection />

          {/* <Button
            variant="contained"
            size="large"
            sx={{ textTransform: "none", mb: 10, fontSize: "17px" }}
          >
            {" "}
            Let’s Begin Now
            <Icon.ArrowForward sx={{ mr: 1 }} />
          </Button> */}
        </Grid>
      </Box>
  );
};

export default AboutUs;
