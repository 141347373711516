import { Box, Button, Grid, Skeleton, Typography } from "@mui/material"
import { CommonContext } from "../../../store/context/commonContext"
import config from "../../../config";
import { useLocation } from "react-router";

const SkeletonPropertyPreview = () => {
    const { isNineHundred } = CommonContext();
    const location = useLocation()
    return (
        <>
            <Box sx={{ width: "100%", p: (window.location.href.includes('/landowner') && location.pathname.includes('/property')) ? 2 : window.location.href.includes('/landowner') ? 0 : 2 }}>
                {/* Title */}
                {
                    !isNineHundred &&
                    <Box>
                        <Skeleton variant="text" width="80%" sx={{ fontSize: '2.9rem' }} />

                        {/* Location */}
                        <Box display="flex" alignItems="center" gap={1} mb={2} >
                            <Skeleton variant="circular" width={20} height={20} />
                            <Skeleton width="40%" variant="text" sx={{ fontSize: '1.5rem' }} />
                        </Box>
                    </Box>
                }

                {/* Images */}
                <Grid container spacing={1} mb={2}>
                    <Grid item xs={12} sm={12} md={6} lg={6}>
                        <Skeleton
                            variant="rectangular"

                            sx={{ borderRadius: "16px", height: { xs: 250, sm: 250, md: 400 } }}
                        />
                    </Grid>
                    {
                        !isNineHundred &&
                        <Grid item xs={0} sm={0} md={6} lg={6}>
                            <Grid container spacing={1}>
                                {[...Array(2)].map((_, index) => (
                                    <Grid item xs={12} key={index}>
                                        <Skeleton
                                            variant="rectangular"
                                            height={196}
                                            sx={{ borderRadius: "16px" }}
                                        />
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                    }
                </Grid>
                {
                    isNineHundred &&
                    <Box>
                        <Skeleton variant="text" width="80%" sx={{ fontSize: '2.9rem' }} />

                        {/* Location */}
                        <Box display="flex" alignItems="center" gap={1} mb={2} >
                            <Skeleton variant="circular" width={20} height={20} />
                            <Skeleton width="40%" variant="text" sx={{ fontSize: '1.5rem' }} />
                        </Box>
                    </Box>
                }
                {/* Tags */}
                <Box display="flex" gap={1} mb={2}>
                    {[...Array(3)].map((_, index) => (
                        <Skeleton
                            key={index}
                            variant="rectangular"
                            width={60}
                            height={28}
                            sx={{ borderRadius: 4 }}
                        />
                    ))}
                </Box>

                {/* Description and Pricing card */}
                <Box sx={{ display: "flex", justifyContent: "space-between", gap: 2 }}>
                    <Box sx={{ width: { xs: '100%', sm: '100%', md: "60%" } }}>
                        {/* Description */}
                        <Skeleton variant="text" sx={{ fontSize: '1.5rem', width: { xs: '60%', sm: '60%', md: '40%' }, mb: 2 }} />

                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="95%" />
                        <Skeleton variant="text" width="90%" />
                        <Skeleton variant="text" width="85%" mb={2} />

                        {/* Activities */}
                        <Skeleton variant="text" sx={{ fontSize: '1.5rem', width: { xs: '60%', sm: '60%', md: '40%' }, mb: 3, mt: 3 }} />

                        <Grid container spacing={1}>
                            {[...Array(4)].map((_, index) => (
                                <Grid item xs={6} sm={6} md={6} key={index}>
                                    <Skeleton
                                        variant="rectangular"
                                        height={30}
                                        sx={{ borderRadius: "4px", width: { xs: '80%', sm: '80%', md: '60%' } }}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                    {
                        !isNineHundred && config.module !== 'lister' &&
                        <Box sx={{ width: "40%" }}>
                            {/* Pricing card */}
                            <Box sx={{ p: 2, border: "1px solid #e0e0e0", borderRadius: "8px" }}>
                                <Skeleton width="30%" height={30} mb={2} />

                                {/* Date Picker */}
                                <Box display="flex" gap={1} mb={2}>
                                    <Skeleton variant="rectangular" width="60%" height={40} />
                                    <Skeleton variant="rectangular" width="30%" height={40} />
                                </Box>

                                {/* Time Slots */}
                                {[...Array(2)].map((_, index) => (
                                    <Box
                                        key={index}
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="flex-start"
                                        gap={1}
                                        mb={1}
                                    >
                                        <Box>

                                            <Skeleton width="200px" variant="text" sx={{ fontSize: '1.5rem', mb: 1, mt:0.5 }} />
                                            <Skeleton width="100px" />
                                            <Skeleton width="50px" />
                                        </Box>
                                        <Box>
                                            <Skeleton variant="text" width='100px' sx={{ fontSize: '2rem' }} />
                                            <Skeleton variant="rectangular" width="110px" height={42} sx={{ borderRadius: 2 }} />
                                        </Box>
                                    </Box>
                                ))}

                                {/* Show all dates button */}
                                <Button
                                    variant="contained"
                                    color="primary"
                                    fullWidth
                                    sx={{ mt: 2 }}
                                    disabled
                                >
                                    <Skeleton width="50%" height={36} />
                                </Button>
                            </Box>
                        </Box>
                    }
                </Box>
            </Box>
        </>
    )
}

export default SkeletonPropertyPreview