import { Box, Skeleton } from "@mui/material";
import { useState } from "react";

const AuthImage = () => {
    // const [loading, setLoading] = useState(true);

    // const handleImageLoad = () => {
    //     setLoading(false);
    // };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-end',
                // borderRight: '1px solid rgba(0, 0, 0, 0.12)',
                width: '50%',
                p: 6,
                bgcolor: '#f1f1f1',
            }}
        >
            {/* {loading && (
                <Skeleton
                    variant="rectangular"
                    width="100%"
                    // height={550} // Same height as the image
                    sx={{ borderRadius: '8px' }} // Optional: to match image style
                    animation={'wave'}
                />
            )} */}
            <img
                src="/assets/images/login/farwideauthpage.png"
                alt="FarWide Login"
                style={{
                    maxHeight: '550px',
                    // display: loading ? 'none' : 'block' // Hide image until loaded
                }}
                // onLoad={handleImageLoad}
                className="responsive_img"
            />
        </Box>
    );
};

export default AuthImage;
