import { Avatar, Box, Divider, Typography, Button } from "@mui/material";
import * as Icon from "@mui/icons-material";
import { UserAuth } from "../../store/context/userContext";
import { empty, extractDataFromArray, generatePhrase } from "../../utils/common";
import { Link } from "react-router-dom";
import config from "../../config";
import { useEffect, useState } from "react";
import { CommonContext } from "../../store/context/commonContext";

const LandOwner = ({ listingData, setOpenEnquiryPopup }) => {
    // console.log("listing data", listingData)
    const [listerReview, setlisterReview] = useState({});
    const [litsterRating, setLitsterRating] = useState({});
    const { screenWidth } = CommonContext();

    useEffect(() => {
        setlisterReview(extractDataFromArray(listingData, ['lister_review_count'], '0'))
        setLitsterRating(extractDataFromArray(listingData, ['lister_average_rating'], '0'))
    }, [listingData])


    const handleOpen = () => {
        // setOpen(true);
        setOpenEnquiryPopup(true);
    };
    const { user } = UserAuth()
    return (
        <>
            <Typography variant="h5" fontWeight={600}>
                Landowner
            </Typography>
            <Divider sx={{ mb: 2, mt: 2 }} />
            <Box
                sx={{
                    display: 'flex',
                    gap: 2,
                    justifyContent: 'space-between',
                    flexDirection: screenWidth <= 400 ? 'column' : 'row',
                }}
            >
                <Box sx={{
                    display: 'flex',
                    gap: 2,
                    width: '100%'

                }}>
                    <Avatar
                        src={extractDataFromArray(listingData, ['profile_pic'], '')}
                        sx={{
                            // ...theme.typography.mediumAvatar,
                            width: '75px',
                            height: '75px',
                            margin: '8px 0 8px 8px !important',
                            cursor: 'pointer'
                        }}
                    />
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', width: '100%', flexWrap: 'wrap', gap: 1 }}>
                        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                            <Typography sx={{ fontSize: '12px', color: 'grey' }}>Hosted by</Typography>
                            <Typography variant="h6" sx={{ fontWeight: 700 }}>{extractDataFromArray(listingData, ['fname'], '')} {extractDataFromArray(listingData, ['lname'], '')}</Typography>
                            {
                                !empty(listerReview) &&
                                <Box sx={{ display: "flex", gap: 1, }}>
                                    <Box sx={{ display: 'flex', gap: 0.5, alignItems: 'center' }}>
                                        <Icon.Star sx={{ color: "#F7C32E" }} />
                                        <Typography variant="body1" fontWeight={'bold'}>
                                            {parseFloat(litsterRating).toFixed(1)}
                                        </Typography>
                                    </Box>

                                    <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
                                        <Typography variant="body1" color='grey300'>
                                            {generatePhrase(listerReview, 'review')}
                                        </Typography>
                                    </Box>
                                </Box>
                            }
                        </Box>
                        {config.module === "guest" && <Box
                            sx={{
                                display: 'flex',
                                gap: 2,
                                alignItems: 'end'

                            }}
                        >
                            <Button variant="light" onClick={handleOpen} sx={{ pl: 1.5, pr: 1.5, pt: 1, pb: 1, fontSize: "0.875rem", fontWeight: 600, }} >{"Contact " + extractDataFromArray(listingData, ['fname'], '')}</Button>
                        </Box>
                        }
                    </Box>
                </Box>
                {/* {config.module === "guest" && <Box
                    sx={{
                        display: 'flex',
                        gap: 2,
                        alignItems: 'end'

                    }}
                >
                    <Button variant="light" onClick={handleOpen} sx={{ pl: 1.5, pr: 1.5, pt: 1, pb: 1, fontSize: "0.875rem", fontWeight: 600, marginLeft: screenWidth <= 400 ? '91px': '0px' }} >{"Contact " + extractDataFromArray(listingData, ['fname'], '')}</Button>
                </Box>
                } */}
            </Box>
            {/* extractDataFromArray(user, ['name'], '') */}
        </>
    )
}

export default LandOwner;
