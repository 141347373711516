import { Box, Divider, Typography } from "@mui/material";
import * as Icon from '@mui/icons-material'
import { styled } from '@mui/material/styles';

import { extractDataFromArray, isValueEqual } from "../../utils/common";
import CustomTooltip from "../CustomTooltip";

const ColumnBox = styled(Box)(
    ({ theme }) => `
        display: flex;
        gap: 0.5rem;
        flex: 50%;
        ${theme.breakpoints.down("sm")} {
            flex: 100%;
          }
        width: 100%;
        margin-bottom: 0.5rem;
  `,
);

const GroundRules = ({ arrivalInstructions, groundRules }) => {



    return (
        <>
            <Typography variant="h5" fontWeight={600}>
                Property Details & Ground Rules
            </Typography>
            <Divider sx={{ mb: 3, mt: 2 }} />
            <Box sx={{ display: 'flex', flexWrap: 'wrap', mb:5 }}>
                <ColumnBox >
                    <Icon.ArrowForward />
                    <Typography sx={{color:'#747579'}}>Check-In: After {extractDataFromArray(arrivalInstructions, ['check_in_time'])}</Typography>
                </ColumnBox>
                <ColumnBox>
                    <Icon.ArrowForward />
                    <Typography sx={{color:'#747579'}} >ATV's: {extractDataFromArray(groundRules, ['atv', 'available'])}</Typography>
                </ColumnBox>
                <ColumnBox>
                    <Icon.ArrowForward />
                    <Typography sx={{color:'#747579'}} >Check Out: Before {extractDataFromArray(arrivalInstructions, ['check_out_time'])}</Typography>
                </ColumnBox>
                <ColumnBox>
                    <Icon.ArrowForward />
                    <Typography sx={{color:'#747579'}}>Parking {extractDataFromArray(groundRules, ['parking', 'available'])}</Typography>
                </ColumnBox>
                <ColumnBox>
                    <Icon.ArrowForward />
                    <Typography sx={{color:'#747579'}}>Nearby Interstate: {extractDataFromArray(arrivalInstructions, ['interstate_range'])}</Typography>
                </ColumnBox>
                <ColumnBox>
                    <Icon.ArrowForward />
                    <Typography sx={{color:'#747579'}}>Vehicles: {extractDataFromArray(groundRules, ['total_vehicle', 'total'])}</Typography>
                </ColumnBox>
                <ColumnBox>
                    <Icon.ArrowForward />
                    <Typography sx={{color:'#747579'}}>Nearby Highway: {extractDataFromArray(arrivalInstructions, ['highway_range'])}</Typography>
                </ColumnBox>
                <ColumnBox>
                    <Icon.ArrowForward />
                    <Typography sx={{color:'#747579'}}>Drive-in Access: {extractDataFromArray(groundRules, ['drivein_access', 'available'])}</Typography>
                </ColumnBox>
                <ColumnBox>
                    <Icon.ArrowForward />
                    <Typography sx={{color:'#747579'}}>Nearby Airport: {extractDataFromArray(arrivalInstructions, ['airport_range'])}</Typography>
                </ColumnBox>
                <ColumnBox>
                    <Icon.ArrowForward />
                    <Typography sx={{color:'#747579'}}>Walk-in Access: {extractDataFromArray(groundRules, ['walkin_access', 'available'])}</Typography>
                </ColumnBox>
                <ColumnBox>
                    <Icon.ArrowForward />
                    <Typography sx={{color:'#747579'}}>Campfires: {extractDataFromArray(groundRules, ['campfire', 'available'])}</Typography>
                </ColumnBox>
                <ColumnBox>
                    <Icon.ArrowForward />
                    <Typography sx={{color:'#747579'}}>Guests: {extractDataFromArray(groundRules, ['total_guests', 'total'])}</Typography>
                </ColumnBox>
                <ColumnBox>
                    <Icon.ArrowForward />
                    <Typography sx={{color:'#747579'}}>Pets: {extractDataFromArray(groundRules, ['pets', 'available'])}</Typography>
                </ColumnBox>
            </Box>
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
                <Typography sx={{ flex: '100%', fontWeight: 500 }}>Additional Info: </Typography>
                <ColumnBox >
                    <Icon.ArrowForward />
                    <Typography sx={{color:'#747579'}}>Tent Friendly: {extractDataFromArray(groundRules, ['tent', 'available'], 'No')}</Typography>
                    {
                        isValueEqual(extractDataFromArray(groundRules, ['tent', 'available'], 'No'), "Yes") &&
                        <CustomTooltip
                            content={
                                <Box sx={{ p: 2 }}>
                                    <Typography sx={{mb : 1}}>{extractDataFromArray(groundRules, ['tent', 'describe_tent_experience'], '')}</Typography>
                                    {
                                        isValueEqual(extractDataFromArray(groundRules, ['tent', 'drinking_water', 'available'], 'No'), "Yes") &&
                                        <Box sx={{mb : 1}}>
                                            <Typography sx={{ fontWeight: 500 }} >Drinking Water</Typography>
                                            <Typography >{extractDataFromArray(groundRules, ['tent', 'drinking_water', 'sources'], []).join(", ")}</Typography>
                                            <Typography >{extractDataFromArray(groundRules, ['tent', 'drinking_water', 'additional_info'], '')}</Typography>
                                        </Box>
                                    }
                                    {
                                        isValueEqual(extractDataFromArray(groundRules, ['tent', 'cooking_area', 'available'], 'No'), "Yes") &&
                                        <Box sx={{mb : 1}}>
                                            <Typography sx={{ fontWeight: 500 }} >Cooking Space</Typography>
                                            <Typography >{extractDataFromArray(groundRules, ['tent', 'cooking_area', 'list'], []).join(", ")}</Typography>
                                            <Typography >{extractDataFromArray(groundRules, ['tent', 'cooking_area', 'additional_info'], '')}</Typography>
                                        </Box>
                                    }
                                    {
                                        isValueEqual(extractDataFromArray(groundRules, ['tent', 'garbage_area', 'available'], 'No'), "Yes") &&
                                        <Box sx={{mb : 1}}>
                                            <Typography sx={{ fontWeight: 500 }} >Garbage Disposal</Typography>
                                            <Typography >{extractDataFromArray(groundRules, ['tent', 'garbage_area', 'list'], []).join(", ")}</Typography>
                                            <Typography >{extractDataFromArray(groundRules, ['tent', 'garbage_area', 'additional_info'], '')}</Typography>
                                        </Box>
                                    }
                                </Box>
                            }
                        />
                    }
                </ColumnBox>
                <ColumnBox>
                    <Icon.ArrowForward />
                    <Typography sx={{color:'#747579'}}>RV Friendly: {extractDataFromArray(groundRules, ['atv', 'available'], 'No')}</Typography>
                    {
                        isValueEqual(extractDataFromArray(groundRules, ['rv', 'available'], 'No'), "Yes") &&
                        <CustomTooltip
                            content={
                                <Box sx={{ p: 2 }}>
                                    <Typography sx={{mb : 1}}>{extractDataFromArray(groundRules, ['rv', 'describe_rv_experience'], '')}</Typography>
                                    {
                                        <Box sx={{mb : 1}}>
                                            <Typography sx={{ fontWeight: 500 }} >Parking Surface</Typography>
                                            <Typography >{extractDataFromArray(groundRules, ['rv', 'parking_surface'], []).join(", ")}</Typography>
                                        </Box>
                                    }
                                    {
                                        isValueEqual(extractDataFromArray(groundRules, ['rv', 'surface_level', 'available'], 'No'), "Yes") &&
                                        <Box sx={{mb : 1}}>
                                            <Typography sx={{ fontWeight: 500 }} >Surface Level</Typography>
                                            <Typography >{extractDataFromArray(groundRules, ['rv', 'surface_level', 'additional_info'], '')}</Typography>
                                        </Box>
                                    }
                                    {
                                        isValueEqual(extractDataFromArray(groundRules, ['rv', 'electric_hookup_access', 'available'], 'No'), "Yes") &&
                                        <Box sx={{mb : 1}}>
                                            <Typography sx={{ fontWeight: 500 }} >Electric Hookup</Typography>
                                            <Typography >{extractDataFromArray(groundRules, ['rv', 'electric_hookup_access', 'list'], '')}</Typography>
                                            <Typography >{extractDataFromArray(groundRules, ['rv', 'electric_hookup_access', 'additional_info'], '')}</Typography>
                                        </Box>
                                    }
                                    {
                                        isValueEqual(extractDataFromArray(groundRules, ['rv', 'water_hookup', 'available'], 'No'), "Yes") &&
                                        <Box sx={{mb : 1}}>
                                            <Typography sx={{ fontWeight: 500 }} >Water Hookup</Typography>
                                            <Typography >{extractDataFromArray(groundRules, ['rv', 'water_hookup', 'additional_info'], '')}</Typography>
                                        </Box>
                                    }
                                    {
                                        isValueEqual(extractDataFromArray(groundRules, ['rv', 'sewage_hookup', 'available'], 'No'), "Yes") &&
                                        <Box sx={{mb : 1}}>
                                            <Typography sx={{ fontWeight: 500 }} >Sewage Hookup</Typography>
                                            <Typography >{extractDataFromArray(groundRules, ['rv', 'sewage_hookup', 'list'], []).join(", ")}</Typography>
                                            <Typography >{extractDataFromArray(groundRules, ['rv', 'sewage_hookup', 'additional_info'], '')}</Typography>
                                        </Box>
                                    }
                                    {
                                        isValueEqual(extractDataFromArray(groundRules, ['rv', 'generator', 'available'], 'No'), "Yes") &&
                                        <Box sx={{mb : 1}}>
                                            <Typography sx={{ fontWeight: 500 }} >Generator</Typography>
                                            <Typography >{extractDataFromArray(groundRules, ['rv', 'generator', 'additional_info'], '')}</Typography>
                                        </Box>
                                    }
                                </Box>
                            }
                        />
                    }
                </ColumnBox>
            </Box>
        </>
    )
}

export default GroundRules;