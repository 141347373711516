import { Box, Divider, Grid, IconButton, LinearProgress, TextField, Typography } from "@mui/material";
import { Link, useLocation, useNavigate, } from "react-router-dom";
import { useEffect, useState } from "react";
import { UserAuth } from "../../../store/context/userContext";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import { CommonContext } from "../../../store/context/commonContext";
import GreyLabel from "../../../commonComponents/formElement/GreyLabel";
import config from "../../../config";
import LoaderButton from "../../LoaderButton";
import * as Icon from "@mui/icons-material";
import { DEFAULT_LOGO_SIZE, RESPONSIVE_SPACING, SMALL_LOGO_SIZE } from "../../CommonConstants/commonKeys";
import AuthImage from "../AuthImage";
import GoogleAuth from "../googleSignInUp";

const validationSchema = Yup.object().shape({
    email: Yup.string().required('Email required').email('Invalid email').matches(/@[^.]*\./, 'Invalid email'),
    passwd: Yup.string().required("Password required")
})
const SignIn = ({ handleOpen, handleClose, closeDialog, admin }) => {
    const [email, setEmail] = useState('');
    const [passwd, setPasswd] = useState('');
    let { login, loginUserWithThirdPartyApp } = UserAuth()
    const { isNineHundred, isMobile, screenWidth, isTablet } = CommonContext()
    const [loading, setLoading] = useState(false)
    // let { authPopup, setAuthPopup } = UserAuth()
    const { user, setAuthPopup, authPopup } = UserAuth();
    const navigate = useNavigate();
    const location = useLocation()

    useEffect(() => {
        if (user) {
            // Get the current URL
            let currentURL = window.location.href;
            // Decode the URL to handle encoded characters
            currentURL = decodeURIComponent(currentURL);

            // Check if the current URL is the login page
            if (currentURL.includes('/login')) {
                let redirectURL = window.location.origin;

                // Extract the 'done' parameter if it exists
                const doneParam = new URLSearchParams(window.location.search).get('done');

                if (doneParam) {
                    // If 'done' parameter exists, use it as the redirect URL
                    redirectURL = doneParam;
                } else if (config.module === 'lister') {
                    // If no 'done' parameter and module is 'lister', redirect to '/landowner'
                    redirectURL += '/landowner';
                }

                // Redirect to the appropriate URL
                window.location.href = redirectURL;
            }

        }
    }, [user])

    const handleLogin = () => {
        setLoading(true)
        login(email, passwd, () => {
            handleClose()
        }, () => {
            setLoading(false)
        })
    }

    // console.log(location.pathname)

    closeDialog = typeof closeDialog === 'undefined' ? true : false
    if (admin) { closeDialog = false }
    const handleKeyPress = (e) => {
        if (e.keyCode === 13) {
            handleSubmit(handleLogin)()
        }
    }

    const closePopup = () => {
        setAuthPopup({ ...authPopup, open: false })
        // navigate('/')
        if (
            !(location.pathname.includes('/search') ||
                location.pathname.includes('/package') ||
                location.pathname.includes('/property') ||
                location.pathname.includes('/aboutus') ||
                location.pathname.includes('/contactus') ||
                location.pathname.includes('/experience'))
        ) {
            if (!user) {
                navigate('/')
            }
        }
    }


    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(validationSchema),
        defaultValues: {}
    });

    const handleGoogleSuccess = async ({ idToken, profileObj }) => {
        setLoading(true)
        // console.log("Recieved accessToken from gooogle :- ", idToken);
        // console.log("profileObj :- ", profileObj);
        loginUserWithThirdPartyApp(idToken, () => {
            // console.log("successfull call");
            handleClose()
        }, () => {
            handleClose()
            // console.log("failed call");
            setLoading(false)
        })
    };

    return (
        <>
            {closeDialog && <Box sx={{ float: "right", position: 'absolute', top: 0, right: 0, "& .MuiButtonBase-root": { bgcolor: '#f1f1f1' }, m: 1 }}>
                <IconButton onClick={closePopup}><Icon.Close /></IconButton>
            </Box>}
            <Grid container maxWidth="lg" sx={{ m: "auto", borderRadius: theme => `${theme.card.borderRadius} `, }}
                direction="row"
                justifyContent="center"
                alignItems="stretch"
                flexWrap={'nowrap'}
            >
                {!isNineHundred && <AuthImage />}
                <Grid item xs={12} sm={8} md={6} sx={{ flexBasis: screenWidth === 900 ? '67.77% !important' : '100%', maxWidth: screenWidth === 900 ? '67.77% !important' : '100%', ml: !isNineHundred && 'auto', display: 'flex' }}>
                    {loading && <LinearProgress color="success" />}
                    <Box sx={{ p: isNineHundred ? 2 : 6, display: 'flex', flexDirection: 'column', justifyContent: "center" }}>
                        {
                            // a tag used instead of <Link> cause, /landowner is not a route.
                            <a href={config.module === 'lister' ? '/landowner' : '/'}>
                                <Box sx={{ mb: 3 }}>
                                    <img style={{ ... (isTablet ? SMALL_LOGO_SIZE : DEFAULT_LOGO_SIZE) }} src='../assets/images/farwide-new-logo.svg' alt='FarWide' />
                                </Box>
                            </a>
                        }

                        <Typography variant="h4" color="secondary" sx={{ fontWeight: 700, mb: 1 }}>Welcome </Typography>
                        {
                            !admin &&
                            <Typography sx={{ mb: 3, color: 'var(--grey900)' }}>New here? <Link onClick={() => handleOpen("signup")} color='secondary' >Sign Up</Link></Typography>
                        }
                        {
                            !admin &&
                            <GoogleAuth
                                onSuccess={handleGoogleSuccess}
                                buttonText="Sign in with Google"
                                variant="contained"
                            />
                        }
                        {
                            !admin &&
                            <Box sx={{ paddingBlock: 3.5 }}>
                                <Divider><Typography variant="button" sx={{ color: '#747579', fontWeight: '400', textTransform: 'unset' }}>or Sign in with Email</Typography></Divider>
                                {/* <Box
                                sx={{ display: 'flex', flexDirection: 'column' }}
                            >
                                <Typography variant="button" sx={{ color: '#747579', fontWeight: '400', textTransform: 'unset' }}>or</Typography> <Typography variant="button" sx={{ color: '#747579', fontWeight: '400', textTransform: 'unset' }}>Sign in with Email</Typography>
                            </Box> */}
                            </Box>
                        }
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={12} md={12}>
                                <GreyLabel label={'Email'} required />
                                <TextField
                                    fullWidth
                                    id="email"
                                    label=""
                                    size="small"
                                    type={"email"}
                                    onInput={(e) => { setEmail(e.target.value) }}
                                    onKeyDown={handleKeyPress}
                                    variant="outlined"
                                    autoComplete="new-password"
                                    {...register("email")}
                                    error={errors.email ? true : false}
                                    helperText={errors.email?.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12}>
                                <GreyLabel label={'Password'} required />
                                <TextField fullWidth id="password" label="" type={"Password"} onInput={(e) => { setPasswd(e.target.value) }} variant="outlined" autoComplete="new-password" onKeyDown={handleKeyPress}
                                    {...register("passwd")}
                                    size="small"
                                    error={errors.passwd ? true : false}
                                    helperText={errors.passwd?.message}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12} md={12} sx={{ textAlign: "right" }}>
                                <Typography ><Link to={process.env.REACT_APP_FORGOT_PASSWORD_LINK} target={isMobile ? "" : "_blank"} color='secondary' >Forgot password?</Link></Typography>
                            </Grid>

                            <Grid item xs={12} sm={12} md={12}>
                                <LoaderButton
                                    variantType='contained'
                                    onClick={() => { handleSubmit(handleLogin)() }}
                                    isLoading={loading}
                                    buttonText="Sign In"
                                    isFullWidth={true}
                                />
                            </Grid>
                        </Grid>
                    </Box>



                </Grid>
            </Grid>
        </>
    )
}
export default SignIn