import React, { useEffect, useState } from "react";
import { Box, Card, CardContent, Grid, List, ListItem, ListItemText, Typography } from "@mui/material";
import "./style.css";
import PersonalInformation from "./sections/PersonalInformation";
import MyInterest from "./sections/MyInterest";
import ChangePassword from "./sections/ChangePassword";
import { CommonContext } from "../../store/context/commonContext";
import { showZendeskLauncher } from "../../utils/common";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import Settings from "./sections/Settings";

// LeftCard component for rendering the sidebar menu items.
const LeftCard = ({ menuItems, handleMenuItemClick, selectedMenuItem, isMobile }) => (
  <Box>
    <Card
      sx={{
        borderRadius: "1rem",
        border: "1px solid #E5E7EB",
        boxShadow: "none",
        '& .MuiCardContent-root': {
          p: isMobile ? 0 : 2, // Adjust padding based on mobile view
          mx: 1
        },
        '& .MuiCardContent-root:last-child': {
          p: isMobile ? 0 : 2,
        }
      }}
    >
      <CardContent sx={!isMobile && { p: '1rem !important' }}>
        <List component="nav">
          {/* Loop through menuItems to create each menu item */}
          {menuItems.map((item, index) => (
            <ListItem
              key={index}
              sx={{
                backgroundColor: selectedMenuItem === index ? "#E0F7EC" : "inherit", // Highlight selected item
                borderRadius: "0.5rem",
                mb: !isMobile && 0.5,
                cursor: 'pointer',
                padding: '4px 16px',
                "&:hover": {
                  backgroundColor: "#E0F7EC", // Hover effect
                },
              }}
              onClick={() => handleMenuItemClick(index)} // Trigger handleMenuItemClick when item is clicked
            >
              <ListItemText
                disableTypography
                primary={<Typography sx={{ fontWeight: "600", color: selectedMenuItem === index ? "#26C77A" : "inherit", }} >{item}</Typography>} />
            </ListItem>
          ))}
        </List>
      </CardContent>
    </Card>
  </Box>
);

// Profile component, which is the main container for the account settings page.
const Profile = () => {
  const navigate = useNavigate(); // To handle navigation based on menu selection.

  // Menu items available for the sidebar navigation.
  const menuItems = [
    "Personal Information",
    "Change Password",
    "My Interests",
    // "Settings", // Optionally, Settings can be added to the menu.
  ];

  const { isMobile } = CommonContext(); // Context to determine if the view is mobile or not.
  const [searchParams, setSearchParams] = useSearchParams(); // React Router's hook to manage URL search params.
  
  // Get the selected menu from the URL parameters
  let selectedMenu = searchParams.get("s");
  let selectedMenuIndex = 0;

  // Set the default index for the selected menu based on the query string 's'
  if (selectedMenu === 'changepassword') {
    selectedMenuIndex = 1;
  } else if (selectedMenu === 'myinterest') {
    selectedMenuIndex = 2;
  } else if (selectedMenu === 'personal') {
    selectedMenuIndex = 0;
  }

  const [selectedMenuItem, setSelectedMenuItem] = useState(selectedMenuIndex); // State to manage which menu item is selected.

  // Function to handle menu item click and update the selected menu and the URL.
  const handleMenuItemClick = (index) => {
    setSelectedMenuItem(index);
    const menuParam = ["personal", "changepassword", "myinterest"][index]; // Set URL query param based on selected menu item.
    navigate(`?s=${menuParam}`, { replace: true }); // Update the URL with the selected menu item.
  };

  // Function to render the content based on the selected menu item.
  const renderCard = () => {
    switch (selectedMenuItem) {
      case 0:
        return <PersonalInformation />; // Render Personal Information component.
      case 1:
        return <ChangePassword />; // Render Change Password component.
      case 2:
        return <MyInterest />; // Render My Interests component.
      case 3:
        return <Settings />; // Render Settings component (commented in menuItems).
      default:
        return <PersonalInformation />; // Default case to render Personal Information if no match.
    }
  };

  // useEffect to show Zendesk launcher and manage query parameters in URL on page load/unload.
  useEffect(() => {
    showZendeskLauncher(true); // Show Zendesk launcher on page load.
    
    const cleanup = () => {
      // Cleanup function to remove query parameters from URL when navigating away from the profile page.
      const currentPath = window.location.pathname;
      if (currentPath !== "/account") {
        setSearchParams({}); // Clear the URL parameters if not on the profile page.
        navigate("/account", { replace: true }); // Navigate back to the default account page.
      }
    };

    // Add event listener for the 'beforeunload' event to trigger cleanup.
    window.addEventListener("beforeunload", cleanup);

    return () => {
      // Remove event listener when the component is unmounted or when dependencies change.
      window.removeEventListener("beforeunload", cleanup);
    };
  }, [navigate, setSearchParams]);

  return (
    <>
      {/* Page Title */}
      <Grid
        container
        maxWidth="lg"
        sx={{
          display: "flex",
          border: "1px solid #fff",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h4" sx={{ mb: 2 }}>
          Account Settings
        </Typography>
      </Grid>

      {/* Main Grid container */}
      <Grid
        container
        maxWidth="lg"
        sx={{ m: "auto" }}
        spacing={{ xs: 0, md: 3 }}
      >
        {/* Left Column for Menu */}
        <Grid item xs={12} sm={12} md={4} sx={{ mb: { xs: 2 } }}>
          <LeftCard
            menuItems={menuItems}
            handleMenuItemClick={handleMenuItemClick}
            selectedMenuItem={selectedMenuItem}
            isMobile={isMobile}
          />
        </Grid>

        {/* Right Column for Content */}
        <Grid item xs={12} sm={12} md={8}>
          {renderCard()} {/* Render selected content */}
        </Grid>
      </Grid>
    </>
  );
};

export default Profile;
