import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import * as Icon from "@mui/icons-material";
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import Typography from '@mui/material/Typography';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Slide from '@mui/material/Slide';
import { generatePhrase } from '../../../utils/common';
import { Box, Button, Divider, MenuItem, Select, TextField } from '@mui/material';
import { DesktopDateRangePicker, LocalizationProvider, MobileDateRangePicker, SingleInputDateRangeField } from '@mui/x-date-pickers-pro';
import GreyLabel from '../../formElement/GreyLabel';
import { CommonContext } from '../../../store/context/commonContext';
import { useState } from 'react';
import dayjs from 'dayjs';
import { FROM_TIME, TO_TIME } from '../../CommonConstants/commonKeys';
import { useRef } from 'react';
import { useEffect } from 'react';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function OptionsAvailableDialog({ handleClose, open, slots, dateSelectText, errorText, dateError, disableCustomDates, setDates, dateRange, guest, handleChange, guestOptions, handleCheckOut, rangeInterval = 0, availabilityRange, bookedSlotsData, title }) {
    const { isNineHundred, setOpenExpEnquiryPopup, screenWidth, isMobile } = CommonContext();

    const widthRef = useRef(null)
    const [openDate, setOpenDate] = useState(false);
    const [widthValue, setWidthValue] = useState('fit-content')
    const maxWidth = '764px'

    useEffect(() => {
        const updateWidth = () => {
            if (widthRef.current) {
                setWidthValue(widthRef.current.clientWidth);
            }
        };

        // Check if ref has a value whenever it's assigned
        if (widthRef.current) {
            updateWidth();
        }

        // Observe changes in ref assignment by rerunning when widthRef.current changes
        const intervalId = setInterval(() => {
            if (widthRef.current) {
                updateWidth();
                clearInterval(intervalId); // Clear interval once the ref has a value
            }
        }, 100); // Check every 100ms

        return () => clearInterval(intervalId); // Cleanup interval on component unmount
    }, [widthRef, screenWidth]);

    useEffect(() => {
        // console.log("bookedData in options available", bookedSlotsData);

    }, [bookedSlotsData])

    // Group slots by date
    const groupedSlots = slots.reduce((acc, slot) => {
        if (!acc[slot.date]) {
            acc[slot.date] = [];
        }
        acc[slot.date].push(slot);
        return acc;
    }, {});

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
            sx={{
                "& .MuiDialog-paper": { borderRadius: '0 !important' },
                "& header": { position: 'fixed', borderBottom: '1px solid rgba(0, 0, 0, 0.12)', backgroundColor: 'rgba(0, 0, 0, 0.12)' },
            }}
        >
            <AppBar sx={{ position: 'relative', boxShadow: 'none !important' }}>
                <Toolbar sx={{ backgroundColor: 'white !important' }}>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <ArrowBackIcon sx={{ color: 'var(--black)' }} />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1, fontWeight: '600' }} variant="h6" component="div">
                        {title}
                    </Typography>
                </Toolbar>
            </AppBar>
            <Box sx={{ height: '100%', display: 'flex', width: '100%', justifyContent: !isNineHundred && 'space-between', paddingTop: '72px', flexDirection: isNineHundred ? 'column' : 'row' }}>
                {/* Left side options */}
                <Box sx={{ maxWidth: !isNineHundred ? 'calc(50% - 100px)' : '100%', width: '100%', display: 'flex', justifyContent: isNineHundred ? 'center' : 'flex-end' }}>
                    <Box sx={{ maxWidth: '500px', width: '100%', pt: 5, pr: isNineHundred ? 2 : 3, pl: isNineHundred ? 2 : 3 }}>
                        <Typography sx={{ flex: 1, fontWeight: '600' }} variant="h6" component="div">
                            {generatePhrase(slots.length, 'Option')} Available
                        </Typography>
                        <Box
                            ref={widthRef}
                            sx={{
                                width: '100%',
                                maxWidth: maxWidth,
                                borderRadius: 2,
                                display: 'flex',
                                gap: 3,
                                justifyContent: 'space-between',
                                mt: isNineHundred ? 3 : 5,
                                flexWrap: 'wrap',
                                mb: 3,
                            }}>
                            {/* Date Picker */}
                            <Box sx={{ width: isNineHundred ? '55%' : '100%', maxWidth: '300px', height: '72px' }}>
                                <GreyLabel label={'Dates'} />
                                <Box sx={{
                                    position: "relative",
                                    overflow: 'hidden',
                                    '& :hover': {
                                        cursor: 'pointer',
                                    },
                                    "& .MuiFormHelperText-root": {
                                        ml: 0,
                                        color: 'green'
                                    },
                                    "&. MuiTextField-root": {
                                        display: 'none'
                                    },
                                }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        {!isNineHundred ?
                                            <Box className="date-select-input-box">
                                                <DesktopDateRangePicker
                                                    open={openDate}
                                                    calendars={1}
                                                    clearable={true}
                                                    slots={{ field: SingleInputDateRangeField }}
                                                    slotProps={{
                                                        textField: {
                                                            size: "small",
                                                        },
                                                        actionBar: { actions: ["clear"] },
                                                    }}
                                                    sx={{
                                                        width: '100%',
                                                        '& .MuiOutlinedInput-root': {
                                                            backgroundColor: 'white',
                                                        },
                                                        "&. MuiInputBase-root": {
                                                            display: 'none'
                                                        },
                                                        "& .MuiFormControl-root": {
                                                            display: 'none'
                                                        }
                                                    }}
                                                    format="DD MMM YYYY"
                                                    onOpen={() => setOpenDate(true)}
                                                    value={dateRange}
                                                    onChange={(date) => setDates(date, setOpenDate)}
                                                    disablePast
                                                    disableHighlightToday
                                                    shouldDisableDate={disableCustomDates}
                                                    onClose={() => setOpenDate(false)}
                                                />
                                            </Box>
                                            :
                                            <MobileDateRangePicker
                                                open={openDate}
                                                calendars={1}
                                                clearable={true}
                                                slots={{ field: SingleInputDateRangeField }}
                                                slotProps={{
                                                    textField: {
                                                        size: "small",
                                                        InputProps: { endAdornment: <Icon.CalendarMonth />, placeholder: 'Select Dates' },
                                                        error: !!dateError,
                                                        helperText: errorText
                                                    },
                                                    actionBar: { actions: ["clear"] },
                                                }}
                                                sx={{
                                                    width: '100%',
                                                    '& .MuiOutlinedInput-root': {
                                                        backgroundColor: 'white',
                                                    }
                                                }}
                                                format="DD MMM YYYY"
                                                value={dateRange}
                                                onChange={(date) => setDates(date, setOpenDate)}
                                                disablePast
                                                disableHighlightToday
                                                closeOnSelect
                                                shouldDisableDate={disableCustomDates}
                                                onClose={() => setOpenDate(false)}
                                            />}
                                    </LocalizationProvider>
                                </Box>
                                <TextField
                                    sx={{
                                        zIndex: '99',
                                        marginTop: '-40px',
                                        borderRadius: '6px',
                                        transition: 'border-color 0.3s',
                                        color: 'var(--black)',
                                        '& :hover': {
                                            cursor: 'pointer'
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            backgroundColor: 'white',
                                        },
                                        "&. MuiInputBase-root": {
                                            display: 'none',
                                        },
                                        "& .MuiFormControl-root": {
                                            display: 'none'
                                        }, "& .MuiFormHelperText-root": {
                                            ml: 0,
                                            color: 'var(--primary)'
                                        }, "& .MuiInputBase-input": {
                                        }, "& .Mui-error": {
                                            color: '#d32f2f'
                                        },
                                        width: '100%',
                                        minWidth: '175px'
                                    }}
                                    InputProps={{
                                        endAdornment: <Icon.CalendarToday />,
                                        style: { color: 'black' },
                                        inputProps: { style: { color: 'black' } }
                                    }}
                                    error={!!dateError}
                                    helperText={errorText}
                                    autoComplete="off"
                                    size="small"
                                    value={dateSelectText}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setOpenDate(!openDate);
                                    }}
                                    onKeyDown={(e) => e.preventDefault()}
                                />
                            </Box>
                            {/* Guest Selection */}
                            <Box sx={{
                                "& .MuiInputBase-root": {
                                    height: '40px',
                                    backgroundColor: 'white',
                                }
                            }}>
                                <GreyLabel label={'Guests'} />
                                <Select
                                    sx={{ width: '100px' }}
                                    value={guest}
                                    onChange={handleChange}
                                >
                                    {guestOptions.map(value => (
                                        <MenuItem key={value} value={value}>{value}</MenuItem>
                                    ))}
                                </Select>
                            </Box>
                        </Box>
                        {!isNineHundred && <Divider sx={{ width: '100%', mb: !isNineHundred && 3 }} />}
                        {!isNineHundred && <Button variant='contained' sx={{ backgroundColor: 'black !important' }} onClick={() => { setOpenExpEnquiryPopup(true) }} >Request Availability</Button>}
                    </Box>
                </Box>
                {isNineHundred && <Divider sx={{ mx: isNineHundred ? 2 : 4 }} />}
                {/* Right Side Slots */}
                <Box sx={{ width: '100%', maxHeight: 'calc(100vh - 72px)', overflowY: 'auto', display: 'flex', justifyContent: isNineHundred && 'center' }}>
                    <Box sx={{ maxWidth: '700px', pl: isNineHundred ? 2 : 3, pr: isNineHundred ? 2 : 3, width: '100%' }} >
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ height: isNineHundred ? '24px' : '40px', maxWidth: maxWidth }} />
                            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 5, maxWidth: maxWidth, pb: 6 }}>
                                {Object.keys(groupedSlots).map(date => (
                                    <Box key={date}>
                                        <Typography sx={{ fontWeight: '600', mb: 1 }}>
                                            {dayjs(date).format("ddd, DD MMM")}
                                        </Typography>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2.5, }}>
                                            {groupedSlots[date].map(slot => {
                                                // if(slot.guest)
                                                // console.log("slotss in options", slot);

                                                const slotDate = dayjs(slot.date);
                                                const isAvailable = slotDate.isBetween(dayjs(availabilityRange[0]), dayjs(availabilityRange[1]), null, '[]'); // Check if within range

                                                const isPastTime = (slot) => { // Added this function to check if start time of slot has passed or not
                                                    const slotDate = dayjs(slot.date); // Parse the slot date
                                                    const currentTime = dayjs(); // Get current date and time


                                                    // Check if the slot date matches today's date
                                                    if (slotDate.isSame(currentTime, 'day')) {
                                                        const slotTime = dayjs(slot.date + ' ' + slot.from_time, 'YYYY-MM-DD h:mm A'); // Parse the date and time together
                                                        return slotTime.isBefore(currentTime); // Check if slot time has passed
                                                    }
                                                    return false;
                                                };

                                                return (
                                                    <Box
                                                        onClick={() => {
                                                            if (!isPastTime(slot)) {
                                                                handleCheckOut(slot)
                                                            }
                                                        }}
                                                        key={slot.id}
                                                        sx={{
                                                            display: 'flex',
                                                            borderRadius: '16px',
                                                            border: '1px solid #E5E7EB',
                                                            p: 3,
                                                            maxWidth: maxWidth,
                                                            width: '100%',
                                                            justifyContent: 'space-between',
                                                            background: isPastTime(slot) && '#f8f8f8',
                                                            gap: 1,
                                                            alignItems: 'flex-start',
                                                            ":hover": {
                                                                border: !isPastTime(slot) && '1px solid #1b1b1b',
                                                                cursor: !isPastTime(slot) && 'pointer'
                                                            }
                                                        }}>
                                                        <Box sx={{ display: 'flex', flexDirection: 'column', }}>
                                                            {rangeInterval !== 0 ? <Typography variant={isMobile ? 'caption' : 'subtitle1'} sx={{ fontWeight: '500' }}>
                                                                {dayjs(slot.date).format("DD MMM YYYY")}, {slot[FROM_TIME]} - {dayjs(slot.date).add(rangeInterval, 'day').format("DD MMM YYYY")}, {slot[TO_TIME]}
                                                            </Typography>
                                                                :
                                                                <Typography variant={isMobile ? 'caption' : 'subtitle1'} sx={{ fontWeight: '500' }}>{slot[FROM_TIME]} - {slot[TO_TIME]}
                                                                </Typography>
                                                            }
                                                            <Typography variant={isMobile ? 'caption' : 'subtitle1'} sx={{ color: 'var(--textGrey)' }}>
                                                                {slot.description}
                                                            </Typography>
                                                            {/* <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                                                <Typography sx={{ fontWeight: '600' }}>${slot.price}</Typography> <Typography variant='caption'>/ person</Typography>
                                                            </Box> */}
                                                            <Box
                                                                sx={{
                                                                    display: 'flex',
                                                                    flexDirection: 'row',
                                                                    alignItems: 'center',
                                                                }}
                                                            >
                                                                <Typography variant={isMobile ? 'caption' : 'subtitle1'}>
                                                                    From&nbsp;
                                                                </Typography>
                                                                <Typography sx={{ fontWeight: '600' }}>
                                                                    ${slot.price}&nbsp;
                                                                </Typography>
                                                                <Typography variant={isMobile ? 'caption' : 'subtitle1'}>
                                                                    per guest
                                                                </Typography>
                                                            </Box>
                                                            <Typography variant={isMobile ? 'caption' : 'subtitle1'}>
                                                                {slot.available_guest === 0 ? "" : slot.booked_guest_count === 0
                                                                    ? ''
                                                                    : slot.booked_guest_count === 1
                                                                        ? `Join ${slot.booked_guest_count} other guest`
                                                                        : `Join ${slot.booked_guest_count} other guests`
                                                                }
                                                            </Typography>
                                                        </Box>
                                                        {isAvailable ?
                                                            (slot.available_guest !== 0 ? <Button
                                                                variant='contained'
                                                                disabled={slot.available_guest < guest || isPastTime(slot)} // Added this codition to disable button if start time has already passed previously it was like this {slot.available_guest < guest ? true : false}
                                                                sx={{ minWidth: 112 }}
                                                                size={isMobile ? 'small' : 'medium'}
                                                                // onClick={() => { handleCheckOut(slot) }}
                                                                onClick={() => { }}
                                                            >
                                                                {
                                                                    isPastTime(slot) ? 'Closed' : 'Choose'
                                                                }
                                                            </Button>
                                                                :
                                                                <Button
                                                                    variant='contained'
                                                                    disabled={slot.available_guest === 0} // Added this codition to disable button if start time has already passed previously it was like this {slot.available_guest < guest ? true : false}
                                                                    sx={{ minWidth: 112 }}
                                                                // onClick={() => { handleCheckOut(slot) }}
                                                                >
                                                                    Sold Out
                                                                </Button>
                                                            )
                                                            :
                                                            <></>
                                                        }
                                                        {!isAvailable &&
                                                            <Button variant='contained' sx={{ backgroundColor: 'black !important' }} onClick={() => { setOpenExpEnquiryPopup(true) }} >Request</Button>
                                                        }
                                                    </Box>
                                                )
                                            })}
                                        </Box>
                                    </Box>
                                ))}
                            </Box>

                        </Box>
                        {isNineHundred && <Button variant='contained' sx={{ backgroundColor: 'black !important', mt: 3, mb: 14 }} onClick={() => { setOpenExpEnquiryPopup(true) }} >Request Availability</Button>}
                    </Box>
                </Box>
            </Box>
        </Dialog >
    );
}