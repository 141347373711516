import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { useState } from 'react';
import { Box, Chip, IconButton, Typography, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import { TOOLTIP_TEXT_FONTSIZE } from '../CommonConstants/commonKeys'

const CustomToolTip = styled(({ className, ...props }) => (
    <Tooltip sx={{ height: '24px' }} placement="bottom" arrow {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0,0,0,0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        maxWidth: "321px",
    }
}))


const ToolTipContent = ({ content, icon, title, link, linkText }) => {

    return (
        <Box sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.625rem",
            fontSize: TOOLTIP_TEXT_FONTSIZE,
            // Force 14px font size on all text elements
            "& *": {
                fontSize: `${TOOLTIP_TEXT_FONTSIZE} !important`
            }
        }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
                <Box sx={{ display: "flex", gap: 0.5 }}>
                    {icon}
                    <Typography variant="subtitle2" fontWeight="bold">{title}</Typography>
                </Box>
                {/* <Typography variant="body2" sx={{ color: "var(--grey900)", wordBreak: "break-word" }}>{content}</Typography> */}
                {content}
            </Box>
            {
                link && <Box sx={{ marginTop: 'auto', width: '100%', display: 'flex' }}>
                    <Link to={link} target='_blank' style={{ color: "var(--black) !important" }}><Typography variant="body2" sx={{ textDecoration: 'underline' }}>{linkText}</Typography></Link>
                </Box>
            }
        </Box >
    )
}

const ToolTipWithIcon = ({ content, icon, title, link, linkText }) => {
    const [open, setOpen] = useState(false)

    const handleToolTipClose = () => {
        setOpen(false)
    }

    const handleToolTipOpen = () => {
        setOpen(true)
    }

    const handleClick = () => {
        // Toggle the open state on click
        setOpen(!open);
    };

    return (
        <ClickAwayListener onClickAway={handleToolTipClose}>
            <Box sx={{
                display: 'flex', "& .MuiTooltip-popper": {
                    zIndex: 1199
                }
            }} onClick={handleClick} >
                <CustomToolTip
                    PopperProps={{
                        disablePortal: true,
                    }}
                    onClose={handleToolTipClose}
                    open={open}
                    placement={"bottom"}
                    disableFocusListener
                    disableHoverListener
                    disableTouchListener
                    title={<ToolTipContent content={content} title={title} icon={icon} link={link} linkText={linkText} />} // Pass content as a prop
                    sx={{
                        "& .MuiTooltip-tooltip": {
                            boxShadow: "0 0 10px 0 rgba(0, 0, 0, 0.21) !important",
                            borderRadius: "8px",
                            padding: "14px 17px",
                            width: 'max-content',
                            margin: '0 !important'
                        },
                        "& .MuiTooltip-popper": {
                            zIndex: 1199
                        }

                    }}
                >
                    <IconButton color='error' sx={{ width: '32px' }}>
                        {icon}
                    </IconButton>
                </CustomToolTip>
            </Box>
        </ClickAwayListener>
    )
}

export default ToolTipWithIcon